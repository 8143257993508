import React, { Component } from 'react';
import { firestore } from '../../../firebase-config';
import Cabecalho from '../../../componentes/Cabecalho';
import CabecalhoPainel from '../../../componentes/CabecalhoPainel';
import { base_url, api_key } from '../../../config/config_api';
import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import MenuItem from "@material-ui/core/MenuItem";
import { Redirect } from 'react-router-dom';

class VeiculosIntegracaoMercadoLivreIncluirMoto extends Component {

    constructor (props){
        super(props);

        this.state = {
            estaGravando: false,
            carregandoDados: true,
            returnPagina: false,
            errosSubmit: null,
            errorLoading: false,
            errorsLoading: null,
            idRevenda: props.match.params.idRevenda,
            idVeiculo: props.match.params.idVeiculo,
            nome_revenda: "",
            telefone_revenda: "",
            cep_revenda: "",
            endereco_revenda: "",
            imagens: [],
            vefipe_ano_modelo: "",
            vequilometragem: "",
            vecambio: "",
            vefipe_combustivel: "",
            vefipe_marca: "",
            vefipe_name: "",
            opcionais: [],
            veportas: "",
            veiplaca: "",
            vecor: "",
            veobservacao: "",
            vevalor: "",
            veremovido: 0,
            ml_images: [],
            ml_values: [],
            ml_required: [],
            ml_atributos: [],
            ml_titulo_anuncio: "",
            ml_descricao_anuncio: "",
            ml_preco_anuncio: "",
            ml_endereco_anuncio: "",
            ml_cep_anuncio: "",
            ml_veiculo_post: "",
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }
        this.mounted = false;
        this.goBack  = this.goBack.bind(this);
        this.handleChange  = this.handleChange.bind(this);
        this.handleChangeValues  = this.handleChangeValues.bind(this);
        this.handleSubmit  = this.handleSubmit.bind(this);

    }

    goBack(){
        this.props.history.goBack();
    }

    componentDidMount(){

        this.mounted = true;

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection("revenda").doc(this.state.idRevenda)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    nome_revenda: doc.data().penome_fantasia,
                    telefone_revenda: doc.data().petelefone1,
                    cep_revenda: doc.data().pecep_fiscal,
                    endereco_revenda: doc.data().peendereco_fiscal + " " + doc.data().pebairro_fiscal
                });
            }
        }).catch(function(error) {
        });

        firestore.collection("revenda").doc(this.state.idRevenda).collection("veiculos").doc(this.state.idVeiculo)
        .get()
        .then(doc => {
            if (doc.exists) {

                this.setState({
                    opcionais: doc.data().opcionais.sort(compareMl),
                    imagens: doc.data().imagens,
                    veano_fabricacao: doc.data().veano_fabricacao,
                    vecambio: doc.data().vecambio,
                    vecor: doc.data().vecor,
                    vefipe_ano_modelo: doc.data().vefipe_ano_modelo,
                    vefipe_combustivel: doc.data().vefipe_combustivel,
                    vefipe_marca: doc.data().vefipe_marca,
                    vefipe_name: doc.data().vefipe_name,
                    veiplaca: doc.data().veiplaca,
                    veobservacao: doc.data().veobservacao,
                    veportas: doc.data().veportas,
                    vequilometragem: doc.data().vequilometragem,
                    vevalor: doc.data().vevalor,
                    veremovido: doc.data().veremovido
                });
            }
        }).catch(function(error) {
        });

        fetch(base_url+"/api/integracao/mercadolivre/incluir", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "api_key": api_key,
                "id_veiculo": this.state.idVeiculo
            })
        })
        .then(response => {

            response.json()
            .then(data => {

                console.log(data);

                if(data.status === 'success'){
                    this.setState({
                        ml_required: data.data.requiredAtributos,
                        ml_atributos: data.data.newAtributos,
                        ml_titulo_anuncio: data.data.veiculoPost.title,
                        ml_descricao_anuncio: data.data.veiculoPost.description.plain_text,
                        ml_preco_anuncio: data.data.veiculoPost.price,
                        ml_cep_anuncio: data.data.veiculoPost.location.zip_code,
                        ml_endereco_anuncio: data.data.veiculoPost.location.address_line,
                        ml_veiculo_post: data.data.veiculoPost,
                        ml_values: data.data.ml_values,
                        ml_images: data.data.veiculoPost.pictures
                    });
                }else{
                    this.setState({
                        errorLoading: true,
                        errorsLoading: data.data,
                    });
                }
            });

            this.setState({
                carregandoDados: false,
            });

        })
        .catch(error => {
            console.error("Erro get teste", error);
            this.setState({
                carregandoDados: false,
            });
        });
        
    }

    componentWillUnmount(){
        this.mounted = false;
    }

    handleChange(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        });
    }

    handleChangeValues(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        const new_ml_values = this.state.ml_values;
        new_ml_values[name] = value;

        this.setState({
            ml_values: new_ml_values
        });

    }

    handleSubmit(event) {

        if(this.state.ml_endereco_anuncio.length < 2 || this.state.ml_cep_anuncio.length !== 8){
            
            var showErros = "";

            if(this.state.ml_endereco_anuncio.length < 2){
                showErros += "'Endereço' é obrigatório.\n";
            }
            if(this.state.ml_cep_anuncio.length !== 8){
                showErros += "'CEP' é obrigatório.\n";
            }

            this.setState({errosSubmit: showErros});

            return;

        }

        this.setState({estaGravando: true, errosSubmit: null});

        var user = JSON.parse(window.localStorage.getItem("usuarioLogado"));

        var veiculo_post = {
            "ml_titulo_anuncio": this.state.ml_titulo_anuncio,
            "ml_descricao_anuncio": this.state.ml_descricao_anuncio,
            "ml_attributes": this.state.ml_values,
            "ml_veiculo_post": this.state.ml_veiculo_post
        };

        fetch(base_url+"/api/integracao/mercadolivre/enviar", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "api_key": api_key,
                "veiculo_post": veiculo_post,
                "usuario": user.id,
                "id_veiculo": this.state.idVeiculo
            })
        })
        .then(response => {

            response.json()
            .then(data => {
                if(data.status === 'success'){

                    var integracao = {
                        status: true,
                        id_anuncio: data.data.id,
                        url_anuncio: data.data.permalink,
                    };

                    // firestore.settings({ timestampsInSnapshots: true });
                    firestore.collection('revenda').doc(this.state.idRevenda).collection('veiculos').doc(this.state.idVeiculo).collection('integracoes').doc("mercadolivre").set(integracao, { merge: true })
                    .then(res => { 
                        this.setState({returnPagina: true}); 
                    })
                    .catch(err => { 
                        this.setState({returnPagina: true});      
                    });
                   
                }else{
                    // var erros = "";
                    // data.data.cause.forEach(erro => {
                    //     erros += erro.message + ". ";
                    // });                    
                    // this.setState({errosSubmit: erros});
                    this.setState({errosSubmit: JSON.stringify(data.data)});
                }
            });
            this.setState({estaGravando: false});  

        })
        .catch(error => {
            console.log(error);
            this.setState({estaGravando: false});  
        });

    }

    render(){
        const { 
            errosSubmit,
            estaGravando,
            idRevenda,
            idVeiculo,
            nome_revenda,
            endereco_revenda,
            cep_revenda,
            opcionais,
            imagens,
            vevalor,
            ml_values,
            ml_titulo_anuncio,
            ml_descricao_anuncio,
            ml_preco_anuncio,
            ml_cep_anuncio,
            ml_endereco_anuncio,
            ml_required,
            ml_atributos,
            ml_images,
            carregandoDados,
            errorLoading,
            errorsLoading
        } = this.state;

        if(this.state.returnPagina){
            return <Redirect to={ "/revenda/"+idRevenda+"/veiculos/"+idVeiculo+"/visualizar" } />;
        }

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin' && this.state.idRevenda !== this.state.usuarioLogado.revenda){
            return <Redirect to='/acesso-negado' />
        }

        return (
            <div>
                {
                    this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ? <Cabecalho /> :  <CabecalhoPainel />
                } 
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Incluir moto Mercado Livre<br/><small>Revenda: { nome_revenda }</small></h1>
                        </div>
                    </div>
                    <div className="row formulario_integracao">
                        {
                                    
                            carregandoDados ? (<div className="col-lg-12"><img src="/img/aguarde.gif" alt="carregando"  style={{ height: '80px', marginBottom: '10px'}} /></div>) : (

                                errorLoading ? (<div style={{ display: errorsLoading !== null ? " block " : " none "  }} className="alert alert-danger" role="alert">{errorsLoading}</div>) : (
                        
                                    <div className="col-lg-12">
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                Integração Mercado Livre - Incluir veículo
                                            </div>
                                            <div className="panel-body">
      
                                                <ValidatorForm
                                                    ref="form"
                                                    onSubmit={this.handleSubmit}
                                                    onError={ errors => exibirErroSubmit(errors) }
                                                >
                                                <table className='table table-striped'>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ minWidth: '215px', width: '10%'}}></th>
                                                            <th style={{ width: '45%'}}>Dados Intermédio</th>
                                                            <th style={{ width: '45%'}}>Dados Mercado Livre</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Título anúncio <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td></td>
                                                            <td>
                                                                <TextValidator
                                                                    onChange={this.handleChange}
                                                                    name="ml_titulo_anuncio"
                                                                    value={ml_titulo_anuncio.toUpperCase()}
                                                                    className="form-ui-bootstrap form-integracao"
                                                                />
                                                            </td>
                                                        </tr> 
                                                        <tr>
                                                            <td>Descrição anúncio <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td></td>
                                                            <td><textarea name="ml_descricao_anuncio" className="form-ui-bootstrap" rows="10" value={ml_descricao_anuncio} onChange={this.handleChange} /></td>
                                                        </tr> 
                                                        <tr>
                                                            <td>Preço anúncio</td>
                                                            <td>{ formatMoney(vevalor) }</td>
                                                            <td>{ formatMoney(ml_preco_anuncio) }</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Endereço <span className="alteracao-painel">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ endereco_revenda.toUpperCase() }</td>
                                                            <td>{ ml_endereco_anuncio.toUpperCase() }</td>
                                                        </tr>
                                                        <tr>
                                                            <td>CEP <span className="alteracao-painel">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ cep_revenda.toUpperCase() }</td>
                                                            <td>{ ml_cep_anuncio.toUpperCase() }</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Opcionais</td>
                                                            <td>
                                                                {
                                                                    Object.keys(opcionais)
                                                                    .map(key => {
                                                                        return (
                                                                            <span key={key}>{ opcionais[key].descricao.toUpperCase() }<br/></span>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                            <td></td>
                                                        </tr>  

                                                        {
                                                            Object.keys(ml_required)
                                                            .map(key => {                                                           
                                                                if(key === 'string' || key === 'number' || key === 'number_unit'){
                                                                    return ml_required[key].map((o) => {
                                                                        // return (
                                                                        //     <tr key={ o.id }>
                                                                        //         <td>{ o.name } <span className="preenchimento-obrigatorio">*</span></td>
                                                                        //         <td></td>
                                                                        //         <td>
                                                                        //             {
                                                                        //                 o.values && o.values.length > 0 ? o.values[0].name : 
                                                                        //                 <TextValidator
                                                                        //                     onChange={this.handleChangeValues}
                                                                        //                     name={ o.id }
                                                                        //                     value={ ml_values[o.id] ? ml_values[o.id] : "" }
                                                                        //                     className="form-ui-bootstrap form-integracao"
                                                                        //                     validators={['required']}
                                                                        //                     errorMessages={['Preenchimento obrigatório']}
                                                                        //                 />
                                                                        //             }
                                                                        //         </td>
                                                                        //     </tr>
                                                                        // );
                                                                        return (
                                                                            <tr key={ o.id }>
                                                                                <td>{ o.name } <span className="preenchimento-obrigatorio">*</span></td>
                                                                                <td></td>
                                                                                <td>
                                                                                    {
                                                                                        o.values && o.values.length > 1 ? 
                                                                                        <SelectValidator
                                                                                        value={ ml_values[o.id] ? ml_values[o.id] : "" }
                                                                                        name={ o.id }
                                                                                        className="form-ui-bootstrap"
                                                                                        onChange={this.handleChangeValues}
                                                                                        validators={["required"]}
                                                                                        errorMessages={["Preenchimento obrigatório"]}
                                                                                        >
                                                                                            <MenuItem value=""></MenuItem>
                                                                                            {
                                                                                                Object.keys(o.values)
                                                                                                .map(kList => {
                                                                                                    return (
                                                                                                        <MenuItem key={kList} value={ o.values[kList].name }>{ o.values[kList].name }</MenuItem>
                                                                                                    )
                                                                                                })
                                                                                            }                                                        
                                                                                    </SelectValidator> : 
                                                                                        <TextValidator
                                                                                            onChange={this.handleChangeValues}
                                                                                            name={ o.id }
                                                                                            value={ ml_values[o.id] ? ml_values[o.id] : o.values && o.values.length > 0 ? o.values[0].name : "" }
                                                                                            className="form-ui-bootstrap form-integracao"
                                                                                            validators={['required']}
                                                                                            errorMessages={['Preenchimento obrigatório']}
                                                                                        />
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    });
                                                                }
                                                                if(key === 'list'){
                                                                    return ml_required[key].map((o) => {
                                                                        return (
                                                                            <tr key={ o.id }>
                                                                                <td>{ o.name } <span className="preenchimento-obrigatorio">*</span></td>
                                                                                <td></td>
                                                                                <td>
                                                                                    <SelectValidator
                                                                                        value={ ml_values[o.id] ? ml_values[o.id] : "" }
                                                                                        name={ o.id }
                                                                                        className="form-ui-bootstrap"
                                                                                        onChange={this.handleChangeValues}
                                                                                        validators={["required"]}
                                                                                        errorMessages={["Preenchimento obrigatório"]}
                                                                                        >
                                                                                            <MenuItem value=""></MenuItem>
                                                                                            {
                                                                                                Object.keys(o.values)
                                                                                                .map(kList => {
                                                                                                    return (
                                                                                                        <MenuItem key={kList} value={ o.values[kList].name }>{ o.values[kList].name }</MenuItem>
                                                                                                    )
                                                                                                })
                                                                                            }                                                        
                                                                                    </SelectValidator>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    });
                                                                }
                                                            })
                                                        }
                                                        {
                                                            Object.keys(ml_atributos)
                                                            .map(key => {                                                           
                                                                if(key === 'string' || key === 'number' || key === 'number_unit'){
                                                                    return ml_atributos[key].map((o) => {
                                                                        if(o.id === 'COLOR'){
                                                                            return (
                                                                                <tr key={ o.id }>
                                                                                    <td>{ o.name }</td>
                                                                                    <td></td>
                                                                                    <td>
                                                                                        <SelectValidator
                                                                                            value={ ml_values[o.id] ? ml_values[o.id] : "" }
                                                                                            name={ o.id }
                                                                                            className="form-ui-bootstrap"
                                                                                            onChange={this.handleChangeValues}
                                                                                            >
                                                                                                <MenuItem value=""></MenuItem>
                                                                                                {
                                                                                                    Object.keys(o.values)
                                                                                                    .map(kList => {
                                                                                                        return (
                                                                                                            <MenuItem key={kList} value={ o.values[kList].name }>{ o.values[kList].name }</MenuItem>
                                                                                                        )
                                                                                                    })
                                                                                                }                                                        
                                                                                        </SelectValidator>
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }else{
                                                                            return (
                                                                                <tr key={ o.id }>
                                                                                    <td>{ o.name }</td>
                                                                                    <td></td>
                                                                                    <td>
                                                                                        {
                                                                                            o.values && o.values.length > 0 ? o.values[0].name : 
                                                                                            <TextValidator
                                                                                                onChange={this.handleChangeValues}
                                                                                                name={ o.id }
                                                                                                value={ ml_values[o.id] ? ml_values[o.id] : "" }
                                                                                                className="form-ui-bootstrap form-integracao"
                                                                                            />
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    });
                                                                }
                                                                if(key === 'list' || key === 'boolean'){
                                                                    return ml_atributos[key].map((o) => {
                                                                        return (
                                                                            <tr key={ o.id }>
                                                                                <td>{ o.name }</td>
                                                                                <td></td>
                                                                                <td>
                                                                                    <SelectValidator
                                                                                        value={ ml_values[o.id] ? ml_values[o.id] : "" }
                                                                                        name={ o.id }
                                                                                        className="form-ui-bootstrap"
                                                                                        onChange={this.handleChangeValues}
                                                                                        >
                                                                                            <MenuItem value=""></MenuItem>
                                                                                            {
                                                                                                Object.keys(o.values)
                                                                                                .map(kList => {
                                                                                                    return (
                                                                                                        <MenuItem key={kList} value={ o.values[kList].name }>{ o.values[kList].name }</MenuItem>
                                                                                                    )
                                                                                                })
                                                                                            }                                                        
                                                                                    </SelectValidator>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    });
                                                                }
                                                            })
                                                        }
                                                        <tr>
                                                            <td>Imagens</td>
                                                            <td>
                                                                {      
                                                                    imagens !== undefined && imagens.length > 0 ? 
                                                                    ( 
                                                                        Object.keys(imagens)
                                                                        .map(key => {
                                                                            
                                                                            return (
                                                                                imagens[key].fileUrl !== null && imagens[key].fileThumb !== null ? 
                                                                                <div key={key}><img src={ imagens[key].fileThumb } alt={ imagens[key].fileName }  style={{ height: '80px', marginBottom: '10px'}} /><br/></div>
                                                                                : null
                                                                            )
                                                                            
                                                                        })
                                                                    )
                                                                    : null
                                                                }
                                                            </td>
                                                            <td>
                                                                {      
                                                                    ml_images !== undefined && ml_images.length > 0 ? 
                                                                    ( 
                                                                        Object.keys(ml_images)
                                                                        .map(key => {
                                                                            
                                                                            return (
                                                                                <div key={key}><img src={ ml_images[key].source } style={{ height: '80px', marginBottom: '10px'}}  /><br/></div>
                                                                            )
                                                                            
                                                                        })
                                                                    )
                                                                    : null
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table> 
                                                <p><span className="alteracao-painel">*</span> Dados da revenda devem ser alterados no painel <br/><span className="alteracao-app">*</span> Dados do veículo devem ser alterados no painel ou aplicativo <br/><span className="preenchimento-obrigatorio">*</span> Campo obrigatório</p>
                                                <div style={{ display: estaGravando ? " block " : " none "  }}><img src="/img/aguarde.gif" alt="carregando"  style={{ height: '80px', marginBottom: '10px'}} /></div>
                                                <div style={{ display: errosSubmit !== null ? " block " : " none "  }} className="alert alert-danger" role="alert">{errosSubmit}</div>
                                                <button onClick={this.goBack} disabled={estaGravando} className="btn btn-warning" >Voltar</button>&nbsp;&nbsp;<button type="submit" disabled={estaGravando} className="btn btn-primary" >Enviar</button>

                                                </ValidatorForm>
                                            </div>
                                        </div>
                                    </div>

                                )
                            )
                        }
                    </div>
                </div>
            </div>

        )
    }

}

function formatMoney(value) {

    var places = 2;
    var symbol = "R$ ";
    var thousand = ".";
    var decimal = ",";

	places = !isNaN(places = Math.abs(places)) ? places : 2;
	symbol = symbol !== undefined ? symbol : "$";
	thousand = thousand || ",";
	decimal = decimal || ".";
	var number = value, 
	    negative = number < 0 ? "-" : "",
	    i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
	    j = (j = i.length) > 3 ? j % 3 : 0;
	return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
};

function compareMl(a, b) {
    const genreA = a.descricao;
    const genreB = b.descricao;
    
    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
}

function exibirErroSubmit(errors){
    window.scrollTo(0, 0);
    alert("Campos obrigatórios não preenchidos!");
}

export default VeiculosIntegracaoMercadoLivreIncluirMoto