import React, { Component } from 'react';
import { firestore } from '../../../firebase-config';
import Cabecalho from '../../../componentes/Cabecalho';
import CabecalhoPainel from '../../../componentes/CabecalhoPainel';
import { Redirect } from 'react-router-dom';
import { ValidatorForm } from 'react-material-ui-form-validator';
import * as firebase from "firebase/app";
import '../../custom_form.css';
import { cancelarConfiguracaoFacebook } from '../../../services/MetaService';
import { app_facebook } from '../../../config/config_api';

class RevendaIntegracaoFacebook extends Component {

    constructor (props){
        super(props);

        this.state = {
            returnPagina: false,
            estaGravando: false,
            idRevenda: props.match.params.idRevenda,
            nome_revenda: "",
            descricao: "",
            status: 0,
            existeConfiguracao: false,
            dataAtual: null,
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
            page_id: "",
            page_name: "",
        }
        this.mounted = false;
        this.handleFacebookLogin  = this.handleFacebookLogin.bind(this);
        this.handleFacebookLogout = this.handleFacebookLogout.bind(this);
    }

    componentDidMount(){

        this.mounted = true;

        firestore.collection("revenda").doc(this.state.idRevenda)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    nome_revenda: doc.data().penome_fantasia
                });
            }
        }).catch(function(error) {
        });

        firestore.collection("revenda").doc(this.state.idRevenda).collection("integracoes").doc("facebook")
        .onSnapshot(doc => {
            if (doc.exists) {
                this.setState({
                    descricao: doc.data().descricao,
                    status: doc.data().status ? 1 : 0,
                    existeConfiguracao: true,
                    page_id: doc.data().page_id ? doc.data().page_id : "",
                    page_name: doc.data().page_name ? doc.data().page_name : "",
                });                
            }
        });
        this.state.dataAtual = firebase.firestore.FieldValue.serverTimestamp();

    }

    handleFacebookLogin() {

        const CLIENT_ID    = app_facebook;
        const DOMINIO_BASE = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
        const REDIRECT_URI = DOMINIO_BASE+"/integracao/configurar-facebook";

        const URL = 'https://www.facebook.com/v19.0/dialog/oauth?response_type=token&display=popup&client_id='+CLIENT_ID+'&redirect_uri='+REDIRECT_URI+'&auth_type=rerequest&scope=pages_show_list,pages_read_engagement,pages_manage_posts&state='+this.state.idRevenda;

        const width = 430;
        const height = 580;
        const left = (window.screen.width - width) / 2;
        const top = (window.screen.height - height) / 2;
        const options = `width=${width},height=${height},left=${left},top=${top}`;

        window.open(URL, '_blank', options);

    }

    async handleFacebookLogout() {

        const resposta = window.confirm('Confirma o cancelamento do acesso ao Facebook?');
        if (resposta) {
            const retorno = await cancelarConfiguracaoFacebook(this.state.idRevenda);
            // console.log(retorno);
            if(retorno.data !== undefined){
                alert(retorno.data);
            }else{
                alert("Ocorreu algum problema ao efetuar o cancelamento da integração com o facebook.");
            }
        }

    }

    render(){
        const { 
            nome_revenda,
            status,
            idRevenda,
            page_id,
            page_name
        } = this.state;

        if(this.state.returnPagina){
            return <Redirect to={ "/revenda/"+idRevenda+"/integracao" } />;
        }

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin' && this.state.idRevenda !== this.state.usuarioLogado.revenda){
            return <Redirect to='/acesso-negado' />
        }
        
        return (

            <div>
                {
                    this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ? <Cabecalho /> :  <CabecalhoPainel />
                } 
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Integração Facebook<br/><small>Revenda: { nome_revenda }</small></h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    Configurar integração Facebook
                                </div>
                                <div className="panel-body">
                                    <ValidatorForm
                                        ref="form"
                                        onSubmit={()=>{}}
                                    >
                                        <div className="row">
                                            <div className="col-lg-3">                                                
                                                {
                                                    status ? (
                                                        <button onClick={this.handleFacebookLogout} className='facebook-button-cancel'>Cancelar acesso Facebook</button>
                                                    ) : (
                                                        <button onClick={this.handleFacebookLogin} className='facebook-button'>Autorizar acesso Facebook</button>
                                                    )
                                                }
                                            </div>
                                            <div className="col-lg-1">
                                                <div className="form-group">
                                                    {
                                                        status ? (
                                                            <span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span>
                                                        ) : (
                                                            <span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            {
                                                status ? (
                                                    
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <strong>Página Facebook:</strong> {page_name + " - " + page_id}
                                                        </div>
                                                    </div>

                                                ) : ""
                                            }
                                        </div>                                                                             
                                    </ValidatorForm>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <a href={ "/revenda/"+idRevenda+"/integracao"} className="btn btn-warning" >Voltar</a>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}

export default RevendaIntegracaoFacebook
