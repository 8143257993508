import React, { Component } from 'react';
import { firestore } from './../../firebase-config';
import Cabecalho from './../../componentes/Cabecalho';
import CabecalhoPainel from '../../componentes/CabecalhoPainel';
import { Redirect } from 'react-router-dom';

class RevendaUsuariosExcluir extends Component {

    constructor (props){
        super(props);

        this.state = {
            returnPagina: false,
            estaGravando: false,
            nome: "",
            email: "",
            idRevenda: props.match.params.idRevenda,
            idUsuario: props.match.params.idUsuario,
            nome_revenda: "",
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }
        this.mounted = false;

        this.handleSubmit = this.handleSubmit.bind(this);
        this.goBack = this.goBack.bind(this);

    }

    goBack(){
        this.props.history.goBack();
    }

    componentDidMount(){

        this.mounted = true;

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection("revenda").doc(this.state.idRevenda)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    nome_revenda: doc.data().penome_fantasia,
                });
            }
        }).catch(function(error) {
        });

        firestore.collection("usuarios").doc(this.state.idUsuario)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    nome: doc.data().nome,
                    email: this.state.idUsuario
                });
            }
        }).catch(function(error) {
        });

    }

    handleSubmit(event) {

        this.setState({estaGravando: true});

        firestore.collection('usuarios').doc(this.state.idUsuario).delete()
        .then(res => { 
            alert("Usuário removido com sucesso!");
            this.setState({returnPagina: true, estaGravando: false});
        })
        .catch(err => { 
            alert("Falha ao remover usuário!");
            this.setState({returnPagina: true, estaGravando: false});     
        });

        event.preventDefault();

    }

    render(){
        const { nome, email, nome_revenda, estaGravando } = this.state;
        if(this.state.returnPagina){
            var link = "/revenda/"+this.state.idRevenda+"/usuarios/listar";
            return <Redirect to={link} />;
        }

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin' && this.state.idRevenda !== this.state.usuarioLogado.revenda){
            return <Redirect to='/acesso-negado' />
        }
        
        return (

            <div>
                {
                    this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ? <Cabecalho /> :  <CabecalhoPainel />
                } 
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Usuários<br/><small>Revenda: { nome_revenda }</small></h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    Excluir usuário
                                </div>
                                <div className="panel-body">

                                    <h2>Deseja remover este usuário?</h2>
                                    <p><strong>Nome: </strong>{nome}</p>
                                    <p><strong>E-mail: </strong>{email}</p>

                                    <input className="btn btn-danger" type="button" value="Remover" onClick={this.handleSubmit} disabled={estaGravando} />&nbsp;&nbsp;
                                    <button onClick={this.goBack} className="btn btn-warning" >Voltar</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}

export default RevendaUsuariosExcluir
