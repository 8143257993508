import React, { Component } from 'react';
import { firestore, storage } from './../../firebase-config';
import Cabecalho from './../../componentes/Cabecalho';
import CabecalhoPainel from '../../componentes/CabecalhoPainel';
import { Link, Redirect } from 'react-router-dom';
import Modal from 'react-modal';
import moment from 'moment';
import 'moment-timezone';
import ReactTable from "react-table";
import { baixarVeiculo } from '../../services/VeiculoService';
import 'react-table/react-table.css';
import cloudinary from "cloudinary/lib/cloudinary";
import { cloudinary_name, cloudinary_api_key, cloudinary_api_secret } from '../../config/config_api';

cloudinary.config({
  cloud_name: cloudinary_name,
  api_key: cloudinary_api_key,
  api_secret: cloudinary_api_secret
});

Modal.setAppElement('#root');

class RevendaHistoricoVeiculosListar extends Component {

    constructor (props){
        super(props);

        this.state = {
            idRevenda: props.match.params.idRevenda,
            nome_revenda: "",
            cidade_revenda: "",
            peblocotexto: "",
            veiculos: [],
            veiculosLimpar: [],
            veiculosLimparImagens: [],
            modalShow: false,
            modalAguardeShow: false,
            conteudoModal: '',
            countVeiculos: 0,
            validZipFiles: 0,
            countVeiculosDelete: 0,
            countVeiculosImagem: 0,
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }
        this.mounted = false;
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    componentDidMount(){

        this.mounted = true;

        firestore.collection("revenda").doc(this.state.idRevenda)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    nome_revenda: doc.data().penome_fantasia,
                    cidade_revenda: doc.data().pecidade + "-" + doc.data().peuf,
                    peblocotexto: doc.data().peblocotexto ? doc.data().peblocotexto : ""
                });
            }
        }).catch(function(error) {
        });

        firestore.collection("revenda").doc(this.state.idRevenda).collection("veiculos").where("veremovido", "==", 1).orderBy("vedataremovido", "asc")
        .get()
        .then((querySnapshot) => {
            var items = [];
            var itemsLimpar = [];
            var itemsLimparImagens = [];
            querySnapshot.forEach(doc => {
                var document = doc.data();
                document.id  = doc.id;
                items.push(document);

                if(document.vedataremovido === undefined || (document.vedataremovido !== undefined && moment().diff(moment(document.vedataremovido.seconds * 1000).locale('pt-BR').format("YYYY-MM-DD"), 'days') > 59)){
                    itemsLimpar.push(document);

                    if(document.imagens_verificado === undefined || document.imagens_verificado === 0){
                        itemsLimparImagens.push(document);
                    }
                    
                }

                
            });
            if(this.mounted) {
                this.setState({
                    veiculos: items,
                    veiculosLimpar: itemsLimpar,
                    veiculosLimparImagens: itemsLimparImagens,
                    countVeiculos: items.length,
                    countVeiculosDelete: itemsLimpar.length > 50 ? 50 : itemsLimpar.length,
                    countVeiculosImagem: itemsLimparImagens.length > 50 ? 50 : itemsLimparImagens.length

                    
                });
            }
        });

    }

    componentWillUnmount(){
        this.mounted = false;
    }

    handleCloseModal () {
        this.setState({ modalShow: false });
    }

    excluirVeiculo(idVeiculo){

        if(window.confirm('Deseja excluir este veículo permanentemente?')){
            // firestore.settings({ timestampsInSnapshots: true });
            firestore.collection("revenda").doc(this.state.idRevenda).collection("veiculos").doc(idVeiculo).delete()
            .then(res => { 
                // alert("Veículo removido com sucesso!");
            })
            .catch(err => { 
                alert("Falha ao remover veículo!");
            });
        }

    }

    limparHistorico() {

        if(window.confirm('Deseja limpar histórico com mais de 60 dias?')) {

            let indexLast = this.state.veiculosLimpar.length > 50 ? 50 : this.state.veiculosLimpar.length;
            for (let index = 0; index < indexLast; index++) {
                const doc = this.state.veiculosLimpar[index];
                firestore.collection("revenda").doc(this.state.idRevenda).collection("veiculos").doc(doc.id).delete()
                .then(() => {
                    if(index === indexLast - 1){
                        if(window.confirm(indexLast + " Veículos removidos com sucesso!")){
                            window.location.reload();
                        }else{
                            window.location.reload();
                        }
                    }
                }).catch((error) => {
                    console.log("Erro ao remover veículo", error);
                });
            }

        }

    }

    limparImagens() {

        if(window.confirm('Deseja limpar imagens dos veículos no histórico?')) {

            let indexLast = this.state.veiculosLimparImagens.length > 50 ? 50 : this.state.veiculosLimparImagens.length;
            for (let index = 0; index < indexLast; index++) {
                const doc = this.state.veiculosLimparImagens[index];
                if(doc.imagens.length <= 1){
                    firestore.collection('revenda').doc(this.state.idRevenda).collection('veiculos').doc(doc.id).set({imagens_verificado: 1}, { merge: true })
                    .then(res => {   
                        if(index === indexLast - 1){
                            if(window.confirm(indexLast+" Veículos com imagens removidas!")){
                                window.location.reload();
                            }else{
                                window.location.reload();
                            }
                        }
                    })
                    .catch(err => { 
                        console.log("Erro ao remover imagens dos veículos", err);    
                    });
                }else{

                    const newArrayImages = [];
                    newArrayImages.push(doc.imagens[0]);
                    doc.imagens.splice(0, 1);

                    if(doc.imagens.length > 0){

                        if(newArrayImages[0].fileUrl.includes("firebase")){

                            const filePath = this.state.idRevenda;
                            const storageRef = storage.ref(filePath + "/" + doc.id);
                            storageRef.listAll()
                            .then((res) => {
                                res.items.forEach((itemRef) => {
                                    if(itemRef.name != newArrayImages[0].fileName){
                                        console.log("firebase: Imagem removida: "+itemRef.name);
                                        itemRef.delete();
                                    }
                                });

                                console.log("firebase: finalizou foreach");

                                firestore.collection('revenda').doc(this.state.idRevenda).collection('veiculos').doc(doc.id).set({imagens: newArrayImages, imagens_verificado: 1}, { merge: true })
                                .then(res => {   
                                    if(index === indexLast - 1){
                                        if(window.confirm(indexLast+" Veículos com imagens removidas!")){
                                            window.location.reload();
                                        }else{
                                            window.location.reload();
                                        }
                                    }
                                })
                                .catch(err => { 
                                    console.log("firebase: Erro ao remover imagens dos veículos", err);    
                                });

                            }).catch((error) => {
                                console.log("firebase: erro ao listar arquivos", error);
                            });        
                
                        }

                        console.log(doc.imagens);

                        if(newArrayImages[0].fileUrl.includes("cloudinary")){
                            var promises = [];
                            doc.imagens.forEach(img => {
                                console.log("cloudinary: está no foreach");
                                var imagemSplit  = img.fileUrl.split("/");
                                if(imagemSplit.length > 1){
                                    var imagemIds = imagemSplit[imagemSplit.length - 2]+"/"+imagemSplit[imagemSplit.length - 1];
                                    var public_id_remove = imagemIds.substr(0, imagemIds.indexOf("."));

                                    promises.push(
                                        cloudinary.v2.uploader.destroy(public_id_remove, function(error, result) {     
                                            if(error != undefined) {
                                                console.log("cloudinary: Erro ao remover imagens cloudinary: "+public_id_remove);
                                                console.log(error);
                                            }        
                                            if(result != undefined){
                                                console.log("cloudinary: Imagem removida: "+public_id_remove);
                                            }                                
                                        }).then((data) => {
                                            return data;
                                        })
                                    );
                                }                                    
                            });

                            Promise.all(promises).then(() => {
                                console.log("cloudinary: finalizou promises");
                                firestore.collection('revenda').doc(this.state.idRevenda).collection('veiculos').doc(doc.id).set({imagens: newArrayImages, imagens_verificado: 1}, { merge: true })
                                .then(res => {   
                                    if(index === indexLast - 1){
                                        if(window.confirm(indexLast+" Veículos com imagens removidas!")){
                                            window.location.reload();
                                        }else{
                                            window.location.reload();
                                        }
                                    }
                                })
                                .catch(err => { 
                                    console.log("cloudinary: Erro ao remover imagens dos veículos", err);    
                                });
                            });
                
                        }

                    }
                    
                }
            }

        }

    };

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    baixarVeiculo = async (nome_revenda, idRevenda, idVeiculo) => {

        this.setState({
            modalAguardeShow: true
        });

        await baixarVeiculo(nome_revenda, idRevenda, idVeiculo);

        this.setState({
            modalAguardeShow: false
        });

    }

    rawMarkup(){
        var rawMarkup = this.state.conteudoModal
        return { __html: rawMarkup };
    }

    render(){

        const columns = [
            {
                id: 'veiculo_imagem', 
                Header: '',
                accessor: props => ( props.imagens.length > 0 ? props.imagens[0].fileThumb !== undefined ? <img src={ props.imagens[0].fileThumb } style={{ maxWidth: '100px', maxHeight: '80px' }} ></img> : props.imagens[0].fileUrl !== undefined ? <img src={ props.imagens[0].fileUrl } style={{ maxWidth: '100px', maxHeight: '80px' }} ></img> : "" : "" ),
                Cell: props => props.value,
                width: 120,
            },
            {
                id: 'manutencao', 
                Header: 'Manutenção',
                accessor: props => ( this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ? <div><Link to={ "/revenda/"+this.state.idRevenda+"/veiculos/"+props.id+"/visualizar"} >Visualizar</Link><span> / </span><a onClick={() => this.baixarVeiculo(this.state.nome_revenda, this.state.idRevenda, props.id)}>Baixar</a><span> / </span><a onClick={() => this.excluirVeiculo(props.id)}>Excluir</a></div> : <div><a onClick={() => this.excluirVeiculo(props.id)}>Excluir</a></div> ),
                width: 180,
                sortable: false
            },
            {
                id: 'veiculo', 
                Header: 'Veículo',
                accessor: props => props.vefipe_marca + " " + props.vefipe_name,
                Cell: props => props.value,
                width: 250,
            },
            {
                id: 'ano', 
                Header: 'Ano',
                accessor: props => props.veano_fabricacao + "/" + props.vefipe_ano_modelo,
                width: 80,
            },
            {
                id: 'placa', 
                Header: 'Placa',
                accessor: props => props.veiplaca
            },
            {
                id: 'valor', 
                Header: 'Valor',
                accessor: props => parseFloat(props.vevalor),
                Cell: props => formatMoney(props.value),
                width: 120
            },
            {
                id: 'exclusao', 
                Header: 'Dias exclusão',
                accessor: props => ( props.vedataremovido != undefined ? moment().diff(moment(props.vedataremovido.seconds * 1000).locale('pt-BR').format("YYYY-MM-DD"), 'days') : "" ),
                width: 125,
            },
            {
                id: 'inclusao', 
                Header: 'Inclusão',
                accessor: "created_at",
                Cell: props => ( props.value != null ? moment(props.value.seconds * 1000).locale('pt-BR').format("DD/MM/YYYY HH:mm") : "" ),
                width: 125,
            },
            {
                id: 'alteracao', 
                Header: 'Alteração',
                accessor: "updated_at",
                Cell: props => ( props.value != null ? moment(props.value.seconds * 1000).locale('pt-BR').format("DD/MM/YYYY HH:mm") : "" ),
                width: 125,
            }
        ];

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin' && this.state.idRevenda !== this.state.usuarioLogado.revenda){
            return <Redirect to='/acesso-negado' />
        }

        return (

            <div>
                {
                    this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ? <Cabecalho /> :  <CabecalhoPainel />
                } 
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Veículos<br/><small>Revenda: { this.state.nome_revenda }</small></h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    Histórico de veículos removidos - { this.state.countVeiculos } veículos
                                </div>
                                <div className="panel-body">

                                {
                                    this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ?

                                        <div>
                                            <a onClick={() => this.limparHistorico()} className="btn btn-danger">Limpar histórico com mais de 60 dias ({ this.state.countVeiculosDelete })</a>&nbsp;&nbsp;
                                            <a onClick={() => this.limparImagens()} className="btn btn-warning">Limpar imagens dos veículos ({ this.state.countVeiculosImagem })</a>
                                            <br/><br/>
                                        </div>

                                    : ""

                                }

                                <ReactTable
                                    data={this.state.veiculos}
                                    columns={columns}
                                    showPagination={false}
                                    minRows={ this.state.countVeiculos }
                                    defaultPageSize={ 0 }
                                    pageSize={ this.state.countVeiculos }
                                    showPageSizeOptions={false}
                                    className={ "-highlight" }
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={this.state.modalShow} 
                    className="modal-class"
                    overlayClassName="modal-overlay"
                    >
                    <div className="modal-header">
                        <h5 className="modal-title">Dados do veículo</h5>
                        <button type="button" className="close" aria-label="Fechar" onClick={this.handleCloseModal}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body"><span dangerouslySetInnerHTML={this.rawMarkup()} /></div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-warning" onClick={this.handleCloseModal}>Fechar</button>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.modalAguardeShow} 
                    className="modal-class modal-class-aguarde"
                    overlayClassName="modal-overlay"
                    >
                    <div>Aguarde...</div>
                </Modal>
            </div>
            
        )
    }

}

function formatMoney(value) {

    var places = 2;
    var symbol = "R$ ";
    var thousand = ".";
    var decimal = ",";

	places = !isNaN(places = Math.abs(places)) ? places : 2;
	symbol = symbol !== undefined ? symbol : "$";
	thousand = thousand || ",";
	decimal = decimal || ".";
	var number = value, 
	    negative = number < 0 ? "-" : "",
	    i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
	    j = (j = i.length) > 3 ? j % 3 : 0;
	return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
};

export default RevendaHistoricoVeiculosListar