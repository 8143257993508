import React, { Component } from 'react';
import { firestore } from './../../firebase-config';
import Cabecalho from './../../componentes/Cabecalho';
import CabecalhoPainel from '../../componentes/CabecalhoPainel';
import { Redirect } from 'react-router-dom';
import * as firebase from "firebase/app";

class RevendaVeiculosExcluir extends Component {

    constructor (props){
        super(props);

        this.state = {
            returnPagina: false,
            estaGravando: false,
            veiculo: "",
            ano: "",
            valor: "",
            idRevenda: props.match.params.idRevenda,
            idVeiculo: props.match.params.idVeiculo,
            nome_revenda: "",
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }
        this.mounted = false;

        this.handleSubmit = this.handleSubmit.bind(this);
        this.goBack = this.goBack.bind(this);

    }

    goBack(){
        this.props.history.goBack();
    }

    componentDidMount(){

        this.mounted = true;

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection("revenda").doc(this.state.idRevenda)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    nome_revenda: doc.data().penome_fantasia,
                });
            }
        }).catch(function(error) {
        });

        firestore.collection("revenda").doc(this.state.idRevenda).collection("veiculos").doc(this.state.idVeiculo)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    veiculo: doc.data().vefipe_marca + " " + doc.data().vefipe_name,
                    ano: doc.data().veano_fabricacao + "/" + doc.data().vefipe_ano_modelo,
                    valor: formatMoney(doc.data().vevalor),
                });
            }
        }).catch(function(error) {
        });

    }

    handleSubmit(event) {

        this.setState({estaGravando: true});

          if(this.state.usuarioLogado !== null){
            if(this.state.usuarioLogado.role === 'admin'){

                firestore.collection("revenda").doc(this.state.idRevenda).collection("veiculos").doc(this.state.idVeiculo).delete()
                .then(res => { 
                    alert("Veículo removido com sucesso!");
                    this.setState({returnPagina: true, estaGravando: false});
                })
                .catch(err => { 
                    console.log(err);
                    alert("Falha ao remover veículo!");
                    this.setState({returnPagina: true, estaGravando: false});     
                });

            }else if(this.state.usuarioLogado.role === 'gestor'){

                var veiculoRemover = {
                    id: this.state.idVeiculo,
                    veremovido: 1,
                    vevalidacaoremovido: 0,
                    imagens_verificado: 0,
                    veremovido_usuario: this.state.usuarioLogado.id,
                    veremovido_usuario_nome: this.state.usuarioLogado.nome,
                    vedataremovido: firebase.firestore.FieldValue.serverTimestamp(),
                    updated_at: firebase.firestore.FieldValue.serverTimestamp(),
                }

                firestore.collection('revenda').doc(this.state.idRevenda).collection('veiculos').doc(this.state.idVeiculo).set(veiculoRemover, { merge: true })
                .then(res => {     
                    alert("Veículo removido com sucesso!");       
                    this.setState({returnPagina: true, estaGravando: false});
                })
                .catch(err => { 
                    console.log(err);
                    alert("Falha ao remover veículo!");
                    this.setState({returnPagina: true, estaGravando: false});     
                });

            }
            
          }

        

        event.preventDefault();

    }

    render(){
        const { veiculo, ano, valor, nome_revenda, estaGravando } = this.state;
        if(this.state.returnPagina){
            var link = "/revenda/"+this.state.idRevenda+"/veiculos/listar";
            return <Redirect to={link} />;
        }

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin' && this.state.idRevenda !== this.state.usuarioLogado.revenda){
            return <Redirect to='/acesso-negado' />
        }
        
        return (

            <div>
                {
                    this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ? <Cabecalho /> :  <CabecalhoPainel />
                } 
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Veículos<br/><small>Revenda: { nome_revenda }</small></h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    Excluir veículo
                                </div>
                                <div className="panel-body">

                                    <h2>Deseja remover este veículo?</h2>
                                    <p><strong>Veículo: </strong>{veiculo}</p>
                                    <p><strong>Ano: </strong>{ano}</p>
                                    <p><strong>Valor: </strong>{valor}</p>

                                    <input className="btn btn-danger" type="button" value="Remover" onClick={this.handleSubmit} disabled={estaGravando} />&nbsp;&nbsp;
                                    <button onClick={this.goBack} className="btn btn-warning" >Voltar</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}

function formatMoney(value) {

    var places = 2;
    var symbol = "R$ ";
    var thousand = ".";
    var decimal = ",";

	places = !isNaN(places = Math.abs(places)) ? places : 2;
	symbol = symbol !== undefined ? symbol : "$";
	thousand = thousand || ",";
	decimal = decimal || ".";
	var number = value, 
	    negative = number < 0 ? "-" : "",
	    i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
	    j = (j = i.length) > 3 ? j % 3 : 0;
	return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
};

export default RevendaVeiculosExcluir
