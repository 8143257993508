import React, { Component } from 'react';
import * as firebase from "firebase/app";
import { configureInstagram, getPagesFacebook, getPageInstagramByFacebook, getPageInstagram } from '../../../services/MetaService';
import { ValidatorForm, SelectValidator, TextValidator } from 'react-material-ui-form-validator';
import MenuItem from "@material-ui/core/MenuItem";
import { firestore } from '../../../firebase-config';
import { base_url, api_key } from '../../../config/config_api';

class RevendaIntegracaoConfigurarInstagram extends Component {

    constructor (props){
        super(props);

        this.state = {
            idRevenda: "",
            status: 0,
            existeConfiguracao: false,
            dataAtual: null,
            paginas: [],
            isLoading: true,
            estaGravando: false,
            pagina: "",
            facebook_id: "",
            facebook_token: "",
            instagram_dados: "",
            instagram_selecionado: false,
            instagram_id: "",
            instagram_name: "",
            instagram_manual: false,
            id_conta_instagram: "",
            mensagem_erro: ""
        }
        this.mounted = false;
        this.handleChange  = this.handleChange.bind(this);
        this.handleSubmit  = this.handleSubmit.bind(this);
    }

    componentDidMount(){

        this.state.dataAtual = firebase.firestore.FieldValue.serverTimestamp();

        const hash = window.location.hash;
        if(hash){

            const hashParams  = hash.substring(1);
            const paramsArray = hashParams.split('&');
            const token_data  = {};

            paramsArray.forEach(param => {
                const [key, value] = param.split('=');
                token_data[key] = value;
            });

            if(token_data.state !== undefined && token_data.state !== null){

                token_data.status = parseInt(this.state.status) === 1 ? true : false;
                token_data.existeConfiguracao = this.state.existeConfiguracao;
                token_data.dataAtual = this.state.dataAtual;
                token_data.idRevenda = token_data.state;

                this.setState({ idRevenda: token_data.state });

                this.configureInstagram(token_data.idRevenda, token_data);
            }

        }         

    }
    
    configureInstagram = async (idRevenda, token_data) => { 
        const response = await configureInstagram(idRevenda, token_data);

        if(response.status === "success"){

            this.setState({
                isLoading: false,
                facebook_id: response.data.user_id,
                facebook_token: response.data.long_lived_token
            });

            const response2 = await getPagesFacebook(response.data.user_id, response.data.long_lived_token);

            if(response2.status === "success"){
                this.setState({
                    paginas: response2.data,
                    instagram_manual: response2.data.length === 0,
                    instagram_selecionado: response2.data.length === 0,
                });
            }else{
                const resposta3 = window.confirm('Ocorreu um erro ao carregar as páginas do Instagram! Informe o ID do Instagram manualmente!');
                // if (resposta3) {
                //     window.close();
                // } else {
                //     window.close();
                // }
                this.setState({
                    instagram_manual: true,
                    instagram_selecionado: true
                });
            }

        }else{
            const resposta2 = window.confirm('Ocorreu um erro ao configurar o Instagram. Informe o ID do Instagram manualmente!');
            // if (resposta2) {
            //     window.close();
            // } else {
            //     window.close();
            // }
            this.setState({
                instagram_manual: true,
                instagram_selecionado: true
            });
        }

    } 

    async handleChange(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value,
        });

        if(name === 'pagina'){

            this.setState({
                instagram_manual: false,
                instagram_id: "",
                instagram_name: "",
                instagram_dados: "",
                instagram_selecionado: false
            });

            const paginaSelecionada = this.state.paginas[value];
            if(paginaSelecionada !== undefined && paginaSelecionada.id !== undefined){
                const response = await getPageInstagramByFacebook(paginaSelecionada.id, this.state.facebook_token);
                if(response.status === "success"){
                    this.setState({
                        instagram_id: response.data.id,
                        instagram_name: response.data.name,
                        instagram_dados: response.data.name + " - " + response.data.id,
                        instagram_selecionado: true
                    });
                }else{
                    // Exibir campo para colocar id da conta instagram
                    this.setState({
                        instagram_manual: true,
                        instagram_selecionado: true
                    });
                }
            }
        }
    }

    async handleSubmit(event) {

        event.preventDefault();
        
        var validaConfiguracao = false;

        this.setState({
            mensagem_erro: ""
        });

        if(this.state.instagram_manual && this.state.id_conta_instagram !== ""){

            const response = await getPageInstagram(this.state.id_conta_instagram, this.state.facebook_token);
            if(response.status === "success"){
                this.setState({
                    instagram_id: response.data.id,
                    instagram_name: response.data.name,
                    instagram_dados: response.data.name + " - " + response.data.id,
                    instagram_selecionado: true
                });
                validaConfiguracao = true;
            }else{
                this.setState({
                    instagram_id: "",
                    instagram_name: "",
                    instagram_dados: "",
                    mensagem_erro: "Conta do Instagram não encontrada ou acesso não permitido, utilize um ID válido."
                });
            }
        }else{
            if(this.state.instagram_id !== ""){
                validaConfiguracao = true;
            }
        }

        if(validaConfiguracao){

            this.setState({estaGravando: true});

            var user = JSON.parse(window.localStorage.getItem("usuarioLogado"));
            var integracao = {
                page_id: this.state.instagram_id,
                page_name: this.state.instagram_name,
                updated_id: user.id,
                updated_at: this.state.dataAtual,
                status: true
            }

            firestore.collection("revenda").doc(this.state.idRevenda).collection("integracoes").doc("instagram").set(integracao, { merge: true })
            .then(res => { 

                fetch(base_url+"/api/integracao/instagram/configurar-pagina", { 
                    method: "POST", 
                    headers: { 
                        'Content-Type': 'application/json',
                    }, 
                    body: JSON.stringify({
                        "api_key": api_key,
                        "id_revenda": this.state.idRevenda,
                        "page_id": this.state.instagram_id,
                        "page_name": this.state.instagram_name,
                        "page_access_token": this.state.facebook_token,
                        "usuario": user.id,
                    })
                })
                .then(response => {
        
                    response.json()
                    .then(data => {
                        if(data.status !== 'success'){
                            firestore.collection("revenda").doc(this.state.idRevenda).collection("integracoes").doc("instagram").set({ status: false }, { merge: true });
                        }
                        this.setState({estaGravando: false});
                        const resposta4 = window.confirm('Instagram configurado com sucesso!');
                        if (resposta4) {
                            window.close();
                        } else {
                            window.close();
                        }
                    });

                    this.setState({estaGravando: false});  
        
                })
                .catch(error => {
                    this.setState({estaGravando: false});  
                    console.error("Erro get teste", error);
                });

            })
            .catch(err => { 
                firestore.collection("revenda").doc(this.state.idRevenda).collection("integracoes").doc("instagram").set({ status: false }, { merge: true });
                alert("Erro ao configurar integração.")
                this.setState({estaGravando: false});     
            });

        }        

    }

    render(){    
        const { 
            isLoading, 
            paginas,
            estaGravando,
            pagina,
            instagram_dados,
            instagram_selecionado,
            instagram_manual,
            id_conta_instagram,
            mensagem_erro
        } = this.state;

        return (
            <div className="mt-5">
                { 
                    isLoading ? 

                    <div className="progress progress-upload-images progress-upload-images-integracao">
                        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%" }}>Aguarde...</div>
                    </div>

                    : 
                
                    <div id="page-wrapper">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        Selecionar página do Facebook
                                    </div>
                                    <div className="panel-body">

                                        <ValidatorForm
                                            ref="form"
                                            onSubmit={this.handleSubmit}
                                        >

                                            { 
                                                paginas.length > 0 &&
                                                (
                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <div className="form-group">
                                                                <SelectValidator
                                                                    label="Página Facebook"
                                                                    value={ pagina }
                                                                    name="pagina"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value="" ></MenuItem>

                                                                        {
                                                                        paginas.map((element, id) =>
                                                                            <MenuItem value={id} key={id}>{element.name}</MenuItem>                                                     
                                                                        )
                                                                    }
                                                                </SelectValidator>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }

                                            {
                                                instagram_manual ?
                                                (
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <TextValidator
                                                                    label="ID Conta Instagram"
                                                                    onChange={this.handleChange}
                                                                    name="id_conta_instagram"
                                                                    value={id_conta_instagram}
                                                                    className="form-ui-bootstrap"
                                                                    validators={['required']}
                                                                    errorMessages={['Preenchimento obrigatório']}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : ""
                                            }

                                            {
                                                instagram_dados !== "" ?
                                                 (
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <h4>Conta Instagram selecionada</h4>
                                                            <p>{ instagram_dados }</p>
                                                        </div>
                                                    </div>
                                                 ) : ""
                                            }

                                            {
                                                mensagem_erro !== "" ?
                                                (
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <p className="error">{ mensagem_erro }</p>
                                                        </div>
                                                    </div>
                                                 ) : ""
                                            }
                                            
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <button type="submit" className="btn btn-primary" disabled={estaGravando || !instagram_selecionado} >Gravar</button>
                                                </div>
                                            </div>
                                        </ValidatorForm>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }

}

export default RevendaIntegracaoConfigurarInstagram
