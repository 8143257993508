import React, { Component } from 'react';
import { firestore, storage } from './../../../firebase-config';
import Cabecalho from './../../../componentes/Cabecalho';
import { Redirect } from 'react-router-dom';
import * as firebase from "firebase/app";
import Button from '@material-ui/core/Button';
import { ValidatorForm } from 'react-material-ui-form-validator';

import './../../custom_form.css';

class RevendaBannerSiteIncluir extends Component {

    constructor (props){
        super(props);

        this.state = {
            returnPagina: false,
            estaGravando: false,
            isUploading: false,
            idRevenda: props.match.params.idRevenda,
            nome_revenda: "",
            cidade_revenda: "",
            imagem_url: "",
            imagem_name: "",
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }

        this.mounted = false;
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFiles  = this.handleFiles.bind(this);

    }

    componentDidMount(){

        this.mounted = true;

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection("revenda").doc(this.state.idRevenda)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    nome_revenda: doc.data().penome_fantasia,
                    cidade_revenda: doc.data().pecidade + "-" + doc.data().peuf
                });
            }
        }).catch(function(error) {
        });

    }

    createFileName(fileName){
        const extensao = fileName.split('.').pop();
        var d = new Date(),
        n = d.getTime(),
        newFileName = n + "." + extensao;
        return newFileName;
    }

    handleFiles(event) {

        this.setState({isUploading: true, estaGravando: true});

        const target   = event.target;
        const fileList = target.files;

        Array.from(fileList).forEach(function (file) {

            const fileName = this.createFileName(file.name);
            const filePath = this.state.idRevenda + "/banner-site/" + fileName;
            const ref = storage.ref(filePath);

            var uploadTask = ref.put(file);
            uploadTask.on('state_changed', function(snapshot){
            }, function(error) {
                console.log("Erro no upload", error);
            }, function() {
                uploadTask.snapshot.ref.getDownloadURL()
                .then(downloadURL => {
                    this.setState({
                        imagem_url: downloadURL,
                        imagem_name: fileName,
                        isUploading: false,
                        estaGravando: false
                    });
                });
            }.bind(this));
            
        }.bind(this));

    }

    handleSubmit(event) {

        this.setState({estaGravando: true});
        var user = JSON.parse(window.localStorage.getItem("usuarioLogado"));

        var banner_site = {
            imagem_url: this.state.imagem_url,
            imagem_name: this.state.imagem_name,
            created_id: user.id,
            created_at: firebase.firestore.FieldValue.serverTimestamp(),
        }

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection('revenda').doc(this.state.idRevenda).collection("banner_site").add(banner_site)
        .then(res => { 
            this.setState({returnPagina: true, estaGravando: false});
        })
        .catch(err => { 
            this.setState({returnPagina: true, estaGravando: false});     
        });

        event.preventDefault();

    }

    render(){
        const { 
            estaGravando, 
            nome_revenda,
            isUploading
        } = this.state;

        if(this.state.returnPagina){
            return <Redirect to={"/revenda/"+this.state.idRevenda+"/banner-site/listar"} />;
        }
        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin'){
            return <Redirect to='/acesso-negado' />
        }
        return (

            <div>
                <Cabecalho />
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Banner Site - { nome_revenda }</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    Incluir novo banner para o site
                                </div>
                                <div className="panel-body">

                                    <ValidatorForm
                                        ref="form"
                                        onSubmit={this.handleSubmit}
                                    > 
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>Carregar imagens (Tamanho recomendado: 4032 × 1435 px)</label>
                                                    <div style={{ display: "flex" }}>
                                                        <input type="file" name="novasImagens" id="novasImagens" onChange={this.handleFiles} ></input>
                                                        { isUploading ? 
                                                            <div className="progress progress-upload-images">
                                                                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%" }}>Carregando imagem...</div>
                                                            </div>
                                                            : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                      
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <Button type="submit" variant="contained" color="primary" disabled={estaGravando} >Gravar</Button>
                                            </div>
                                        </div>
                                    </ValidatorForm>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}

export default RevendaBannerSiteIncluir
