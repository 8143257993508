import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { auth } from './../firebase-config';
import { versao } from '../config/config_api';
import { gerarStringMd5 } from '../services/OtherServices';

class Cabecalho extends Component {

    constructor(props){
        super(props);
        this.state = {
            revendaCollapsed: true,
            revendaFFCollapsed: true,
            opcionaisCollapsed: true,
            locaisAnuncioCollapsed: true,
            estaAutenticado: !!window.localStorage.getItem("usuarioLogado"),
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
            string_md5: gerarStringMd5('incluir_revenda'),
        };
        this.logout = this.logout.bind(this);
    }

    logout(){
        window.localStorage.clear();
        auth.signOut()
        .then((res) => {
            return <Redirect to="/login" />;
        })
        .catch((c) => {
            return <Redirect to="/login" />;
        });
    }

    render() {
        if(!this.state.estaAutenticado || this.state.usuarioLogado.role !== 'admin'){
            this.logout();
            return <Redirect to='/login' />
        }
        return (

            <nav className="navbar navbar-default">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                            <span className="sr-only">Menu</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <Link to="/home" className="navbar-brand" >
                            <img src="/img/logo_horizontal.png" alt="Logo Intermédio" />
                        </Link>
                    </div>

                    <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                        <ul className="nav navbar-nav">
                            <li><Link to="/home">Dashboard</Link></li>
                            <li className="dropdown">
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Revenda <span className="caret"></span></a>
                                <ul className="dropdown-menu">
                                    <li><Link to="/revenda/listar" >Listar</Link></li>
                                    <li><Link to="/revenda/incluir" >Incluir</Link></li>
                                </ul>
                            </li>
                             {                                 
                                // this.state.usuario.role === "admin" && this.state.usuario.empresa !== null && this.state.usuario.empresa === "ff" ?
                                    <li className="dropdown">
                                        <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Revenda free <span className="caret"></span></a>
                                        <ul className="dropdown-menu">
                                            <li><Link to="/revenda-free/listar" >Listar</Link></li>
                                        </ul>
                                    </li>
                                // : null
                             }
                            <li className="dropdown">
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Opcionais <span className="caret"></span></a>
                                <ul className="dropdown-menu">
                                    <li><Link to="/opcionais/listar" >Listar</Link></li>
                                    <li><Link to="/opcionais/incluir" >Incluir</Link></li>
                                </ul>
                            </li>
                            <li className="dropdown">
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Locais de anúncio <span className="caret"></span></a>
                                <ul className="dropdown-menu">
                                    <li><Link to="/locais-anuncio/listar" >Listar</Link></li>
                                    <li><Link to="/locais-anuncio/incluir" >Incluir</Link></li>
                                </ul>
                            </li>
                            <li><a href={ "https://app.intermedioveiculos.com.br/MateriaisPainel/materiaispainel.php?usuario="+this.state.usuarioLogado.id+"&string_md5="+this.state.string_md5 }>Solicitações</a></li>    
                            <li className="dropdown">
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">FIPE <span className="caret"></span></a>
                                <ul className="dropdown-menu">
                                    <li><Link to="/fipe/listar" >Listar</Link></li>
                                    <li><Link to="/fipe/incluir" >Incluir</Link></li>
                                </ul>
                            </li>                        
                        </ul>
                        <ul className="nav navbar-nav navbar-right">
                            <p className="versao-painel">v{versao}</p>
                        </ul>
                        <ul className="nav navbar-nav navbar-right">
                            <li className="dropdown">
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><span className="nome-login">{ this.state.usuarioLogado.nome }</span> <i className="fa fa-user fa-fw"></i> <i className="fa fa-caret-down"></i></a>
                                <ul className="dropdown-menu">
                                    <li><a href="" onClick={this.logout} ><i className="fa fa-sign-out fa-fw"></i> Sair</a></li>
                                </ul>
                            </li>
                        </ul>
                     </div>
                </div> 
            </nav>
        )
    }

}

export default Cabecalho