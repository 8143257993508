import React, { Component } from 'react';
import { firestore, storage } from './../../../firebase-config';
import Cabecalho from './../../../componentes/Cabecalho';
import { Redirect } from 'react-router-dom';
import * as firebase from "firebase/app";
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import MenuItem from "@material-ui/core/MenuItem";

import './../../custom_form.css';

class RevendaNotificacoesIncluir extends Component {

    constructor (props){
        super(props);

        this.state = {
            returnPagina: false,
            estaGravando: false,
            idRevenda: props.match.params.idRevenda,
            nome_revenda: "",
            titulo: "",
            link: "",
            status: "",
            acesso: "",
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }

        this.mounted = false;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount(){

        this.mounted = true;

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection("revenda").doc(this.state.idRevenda)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    nome_revenda: doc.data().penome_fantasia,
                    cidade_revenda: doc.data().pecidade + "-" + doc.data().peuf
                });
            }
        }).catch(function(error) {
        });

    }


    handleChange(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {

        this.setState({estaGravando: true});

        var user = JSON.parse(window.localStorage.getItem("usuarioLogado"));

        var notificacao_data = {
            titulo: this.state.titulo,
            link: this.state.link,
            status: 0,
            acesso: this.state.acesso,
            created_id: user.id,
            created_at: firebase.firestore.FieldValue.serverTimestamp(),
        }

        firestore.collection('revenda').doc(this.state.idRevenda).collection("notificacoes").add(notificacao_data)
        .then(res => { 
            this.setState({returnPagina: true, estaGravando: false});
        })
        .catch(err => { 
            this.setState({returnPagina: true, estaGravando: false});     
        });

        event.preventDefault();

    }

    render(){
        const { 
            estaGravando, 
            nome_revenda,
            titulo,
            link,
            acesso,
        } = this.state;

        if(this.state.returnPagina){
            return <Redirect to={"/revenda/"+this.state.idRevenda+"/notificacoes/listar"} />;
        }

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin'){
            return <Redirect to='/acesso-negado' />
        }

        return (

            <div>
                <Cabecalho />
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Notificações - { nome_revenda }</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    Incluir nova notificação
                                </div>
                                <div className="panel-body">

                                    <ValidatorForm
                                        ref="form"
                                        onSubmit={this.handleSubmit}
                                    > 
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="Título *"
                                                        onChange={this.handleChange}
                                                        name="titulo"
                                                        value={titulo}
                                                        className="form-ui-bootstrap"
                                                        validators={['required']}
                                                        errorMessages={['Preenchimento obrigatório']}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="Link"
                                                        onChange={this.handleChange}
                                                        name="link"
                                                        value={link}
                                                        className="form-ui-bootstrap"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="form-group">
                                                    <SelectValidator
                                                        label="Acesso"
                                                        value={ acesso }
                                                        name="acesso"
                                                        className="form-ui-bootstrap"
                                                        onChange={this.handleChange}
                                                        validators={["required"]}
                                                        errorMessages={["Preenchimento obrigatório"]}
                                                        >
                                                            <MenuItem value="todos">Todos</MenuItem>
                                                            <MenuItem value="gestor">Gestor</MenuItem>                                                     
                                                    </SelectValidator>
                                                </div>
                                            </div>

                                            
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <Button type="submit" variant="contained" color="primary" disabled={estaGravando} >Gravar</Button>
                                            </div>
                                        </div>
                                    </ValidatorForm>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}

export default RevendaNotificacoesIncluir
