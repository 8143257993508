import React, { Component } from 'react';
import { firestore } from '../../../firebase-config';
import Cabecalho from '../../../componentes/Cabecalho';
import CabecalhoPainel from '../../../componentes/CabecalhoPainel';
import { base_url, api_key } from '../../../config/config_api';
import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import MenuItem from "@material-ui/core/MenuItem";
import { Redirect } from 'react-router-dom';

class VeiculosIntegracaoWebmotorsAlterar extends Component {

    constructor (props){
        super(props);

        this.state = {
            estaGravando: false,
            carregandoDados: true,
            returnPagina: false,
            errosSubmit: null,
            errorLoading: false,
            errorsLoading: null,
            idRevenda: props.match.params.idRevenda,
            idVeiculo: props.match.params.idVeiculo,
            idAnuncio: props.match.params.idAnuncio,
            nome_revenda: "",
            opcionais: [],
            imagens: [],
            veano_fabricacao: "",
            vecambio: "",
            vecor: "",
            vefipe_ano_modelo: "",
            vefipe_combustivel: "",
            vefipe_marca: "",
            vefipe_name: "",
            veiplaca: "",
            veobservacao: "",
            veportas: "",
            vequilometragem: "",
            vevalor: "",
            veremovido: 0,
            webmotors_marca: "",
            webmotors_modelo: "",
            webmotors_versao: "",
            webmotors_ano_fabricacao: "",
            webmotors_ano_modelo: "",
            webmotors_plate: "",
            webmotors_mileage: "",
            webmotors_price: "",
            webmotors_description: "",
            webmotors_fuelType: "",
            webmotors_color: "",
            webmotors_ports: "",
            webmotors_transmission: "",
            webmotors_imagens: [],
            webmotors_opcionais: [],
            webmotors_list_marca: [],
            webmotors_list_modelo: [],
            webmotors_list_versao: [],
            webmotors_list_combustivel: [],
            webmotors_list_cor: [],
            webmotors_list_transmissao: [],
            webmotors_modalidade_anuncio: "",
            webmotors_list_modalidade_anuncio: [],
            versaoSelecionada: new Map(),
            webmotors_tipo_anuncio: "U",
            webmotors_blindado: false,
            webmotors_adaptado_deficiente_fisico: false,
            webmotors_unico_dono: false,
            webmotors_alienado: false,
            webmotors_licenciado: false,
            webmotors_ipva_pago: false,
            webmotors_revisoes_concessionaria: false,
            webmotors_garantia_fabrica: false,
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }
        this.mounted = false;
        this.goBack  = this.goBack.bind(this);
        this.handleChange  = this.handleChange.bind(this);
        this.handleMarca   = this.handleMarca.bind(this);
        this.handleModelo  = this.handleModelo.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.handleSubmit  = this.handleSubmit.bind(this);
        this.handleChangeRadio  = this.handleChangeRadio.bind(this);
        
    }

    goBack(){
        this.props.history.goBack();
    }

    componentDidMount(){

        this.mounted = true;

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection("revenda").doc(this.state.idRevenda)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    nome_revenda: doc.data().penome_fantasia
                });
            }
        }).catch(function(error) {
        });

        firestore.collection("revenda").doc(this.state.idRevenda).collection("veiculos").doc(this.state.idVeiculo)
        .get()
        .then(doc => {
            if (doc.exists) {

                this.setState({
                    opcionais: doc.data().opcionais.sort(compareOpcionais),
                    imagens: doc.data().imagens,
                    veano_fabricacao: doc.data().veano_fabricacao,
                    vecambio: doc.data().vecambio,
                    vecor: doc.data().vecor,
                    vefipe_ano_modelo: doc.data().vefipe_ano_modelo,
                    vefipe_combustivel: doc.data().vefipe_combustivel,
                    vefipe_marca: doc.data().vefipe_marca,
                    vefipe_name: doc.data().vefipe_name,
                    veiplaca: doc.data().veiplaca,
                    veobservacao: doc.data().veobservacao,
                    veportas: doc.data().veportas,
                    vequilometragem: doc.data().vequilometragem,
                    vevalor: doc.data().vevalor,
                    veremovido: doc.data().veremovido
                });
            }
        }).catch(function(error) {
        });

        fetch(base_url+"/api/integracao/webmotors/incluir", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "api_key": api_key,
                "id_veiculo": this.state.idVeiculo
            })
        })
        .then(response => {

            response.json()
            .then(data => {
                if(data.status === 'success'){
                    this.setState({
                        webmotors_marca: data.data.veiculoPost.CodigoMarca,
                        webmotors_modelo: data.data.veiculoPost.CodigoModelo,
                        webmotors_versao: data.data.veiculoPost.CodigoVersao,
                        webmotors_ano_fabricacao: data.data.veiculoPost.AnoFabricacao,
                        webmotors_ano_modelo: data.data.veiculoPost.AnoDoModelo,
                        webmotors_plate: data.data.veiculoPost.Placa.toUpperCase(),
                        webmotors_mileage: data.data.veiculoPost.Km,
                        webmotors_price: data.data.veiculoPost.PrecoReal,
                        webmotors_description: data.data.veiculoPost.Observacao.toUpperCase(),
                        webmotors_fuelType: data.data.veiculoPost.CodigoCombustivel,
                        webmotors_color: data.data.veiculoPost.CodigoCor,
                        webmotors_ports: data.data.veiculoPost.NrPortas,
                        webmotors_transmission: data.data.veiculoPost.CodigoCambio,
                        webmotors_imagens: data.data.imagens,
                        webmotors_opcionais: data.data.retOpcionais.sort(compareOpcionais),
                        webmotors_list_marca: data.data.retMarca.sort(compareMarca),
                        webmotors_list_modelo: data.data.retModelo.sort(compareModelo),
                        webmotors_list_versao: data.data.retVersao.sort(compareVersao),
                        webmotors_list_combustivel: data.data.retTipoCombustivel.sort(compareDescricao),
                        webmotors_list_cor: data.data.retCores.sort(compareDescricao),
                        webmotors_list_transmissao: data.data.retTipoTransmissao.sort(compareDescricao),
                        webmotors_modalidade_anuncio: data.data.veiculoPost.CodigoModalidade,
                        webmotors_list_modalidade_anuncio: data.data.retModalidade.sort(compareDescricao),
                    });
                }else{
                    this.setState({
                        errorLoading: true,
                        errorsLoading: data.data,
                    });
                }
            });

            this.setState({
                carregandoDados: false,
            });

        })
        .catch(error => {
            console.error("Erro get teste", error);
            this.setState({
                carregandoDados: false,
            });
        });
        
    }

    componentWillUnmount(){
        this.mounted = false;
    }

    handleMarca(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value,
            webmotors_list_modelo: [],
            webmotors_list_versao: [],
        });

        return fetch(base_url+"/api/integracao/webmotors/consultar-modelo", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "api_key": api_key,
                "marca": value,
                "id_veiculo": this.state.idVeiculo
            })
        })
        .then(response => {

            return response.json()
            .then(data => {
                if(data.status === 'success'){
                    this.setState({
                        webmotors_list_modelo: data.data.sort(compareModelo),
                        webmotors_modelo: ""
                    });
                }
            });

        })
        .catch(error => {
            console.error("Erro get teste", error);
        });

    }

    handleModelo(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value,
            webmotors_list_versao: [],
        });

        return fetch(base_url+"/api/integracao/webmotors/consultar-versao", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "api_key": api_key,
                "marca": this.state.webmotors_marca,
                "modelo": value,
                "id_veiculo": this.state.idVeiculo
            })
        })
        .then(response => {

            return response.json()
            .then(data => {
                if(data.status === 'success'){
                    this.setState({
                        webmotors_list_versao: data.data.sort(compareVersao),
                        webmotors_versao: ""
                    });
                }
            });

        })
        .catch(error => {
            console.error("Erro get teste", error);
        });

    }

    handleChange(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        });
    }

    handleChangeRadio(event) {

        const target = event.target;
        const name   = target.name;
        var newValue = !this.state[name];

        this.setState({
            [name]: newValue
        });
    }

    handleChecked(event) {

        const item = event.target.name; 
        var oldOpcionais = this.state.webmotors_opcionais;
        const oldChecked = oldOpcionais[item].checked;
        oldOpcionais[item].checked = !oldChecked;
        this.setState({
            webmotors_opcionais: oldOpcionais
        });

    }

    handleSubmit(event) {

        this.setState({estaGravando: true, errosSubmit: null});

        var opcionais_selecionados = this.state.webmotors_opcionais.filter(opcionais_true);
        var user = JSON.parse(window.localStorage.getItem("usuarioLogado"));
        
        var veiculo_post = {
            CodigoModalidade: this.state.webmotors_modalidade_anuncio, 
            CodigoMarca: this.state.webmotors_marca,
            CodigoModelo: this.state.webmotors_modelo,
            CodigoVersao: this.state.webmotors_versao,
            AnoDoModelo: this.state.webmotors_ano_modelo,
            AnoFabricacao: this.state.webmotors_ano_fabricacao,
            Km: this.state.webmotors_mileage,
            Placa: this.state.webmotors_plate,
            CodigoCambio: this.state.webmotors_transmission,
            CodigoCor: this.state.webmotors_color,
            CodigoCombustivel: this.state.webmotors_fuelType,
            PrecoReal: this.state.webmotors_price,
            PrecoVenda: this.state.webmotors_price,
            Observacao: this.state.webmotors_description,
            NrPortas: this.state.webmotors_ports,
            Opcional: opcionais_selecionados,
            Imagens: this.state.webmotors_imagens,
            TipoAnuncio: this.state.webmotors_tipo_anuncio,
            Blindado: this.state.webmotors_blindado ? "S" : "N",
            AdaptadoDeficientesFisicos: this.state.webmotors_adaptado_deficiente_fisico ? "S" : "N",
            UnicoDono: this.state.webmotors_unico_dono ? "S" : "N",
            Alienado: this.state.webmotors_alienado ? "S" : "N",
            Licenciado: this.state.webmotors_licenciado ? "S" : "N",
            IpvaPago: this.state.webmotors_ipva_pago ? "S" : "N",
            RevisoesEmConcessionaria: this.state.webmotors_revisoes_concessionaria ? "S" : "N",
            GarantiaDeFabrica: this.state.webmotors_garantia_fabrica ? "S" : "N",
        }

        fetch(base_url+"/api/integracao/webmotors/alterar", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "api_key": api_key,
                "veiculo_post": veiculo_post,
                "usuario": user.id,
                "id_veiculo": this.state.idVeiculo,
                "id_anuncio": this.state.idAnuncio
            })
        })
        .then(response => {

            response.json()
            .then(data => {
                if(data.status === 'success'){

                    if(parseInt(data.data.CodigoAnuncio) === 0){

                        var errosRetorno = data.data.CodigoRetorno.split(",");
                        var showErros = "";
                        errosRetorno.forEach(function(element, i){
                            showErros += "'" + exibirErrosRetorno(element) + "'";
                            if(i+1 < errosRetorno.length){
                                showErros += ", ";
                            }
                        });

                        this.setState({errosSubmit: showErros});

                    }else{

                        this.setState({returnPagina: true}); 

                    }

                    // var integracao = data.data;
                    // integracao.status = true;
                    // integracao.id_anuncio = data.data.advId;

                    // firestore.settings({ timestampsInSnapshots: true });
                    // firestore.collection('revenda').doc(this.state.idRevenda).collection('veiculos').doc(this.state.idVeiculo).collection('integracoes').doc("autoscar").set(integracao, { merge: true })
                    // .then(res => { 
                    //     this.setState({returnPagina: true}); 
                    // })
                    // .catch(err => { 
                    //     this.setState({returnPagina: true});      
                    // });

                }else{
                    this.setState({errosSubmit: data.data});
                }
            });
            this.setState({estaGravando: false});  

        })
        .catch(error => {
            console.log(error);
            this.setState({estaGravando: false});  
        });

    }

    render(){
        const { 
            errosSubmit,
            estaGravando,
            idRevenda,
            idVeiculo,
            nome_revenda,
            opcionais,
            imagens,
            veano_fabricacao,
            vecambio,
            vecor,
            vefipe_ano_modelo,
            vefipe_combustivel,
            vefipe_marca,
            vefipe_name,
            veiplaca,
            veobservacao,
            veportas,
            vequilometragem,
            vevalor,
            webmotors_marca,
            webmotors_modelo,
            webmotors_versao,
            webmotors_ano_fabricacao,
            webmotors_ano_modelo,
            webmotors_plate,
            webmotors_mileage,
            webmotors_price,
            webmotors_description,
            webmotors_fuelType,
            webmotors_color,
            webmotors_ports,
            webmotors_transmission,
            webmotors_imagens,
            webmotors_opcionais,
            webmotors_list_marca,
            webmotors_list_modelo,
            webmotors_list_versao,
            webmotors_list_combustivel,
            webmotors_list_cor,
            webmotors_list_transmissao,
            webmotors_modalidade_anuncio,
            webmotors_list_modalidade_anuncio,
            carregandoDados,
            errorLoading,
            errorsLoading,
            webmotors_tipo_anuncio,
            webmotors_blindado,
            webmotors_adaptado_deficiente_fisico,
            webmotors_unico_dono,
            webmotors_alienado,
            webmotors_licenciado,
            webmotors_ipva_pago,
            webmotors_revisoes_concessionaria,
            webmotors_garantia_fabrica
        } = this.state;

        if(this.state.returnPagina){
            return <Redirect to={ "/revenda/"+idRevenda+"/veiculos/"+idVeiculo+"/visualizar" } />;
        }

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin' && this.state.idRevenda !== this.state.usuarioLogado.revenda){
            return <Redirect to='/acesso-negado' />
        }

        return (
            <div>
                {
                    this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ? <Cabecalho /> :  <CabecalhoPainel />
                }
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Alterar veículo Webmotors<br/><small>Revenda: { nome_revenda }</small></h1>
                        </div>
                    </div>
                    <div className="row">
                        {
                                    
                            carregandoDados ? (<div className="col-lg-12"><img src="/img/aguarde.gif" alt="carregando"  style={{ height: '80px', marginBottom: '10px'}} /></div>) : (

                                errorLoading ? (<div style={{ display: errorsLoading !== null ? " block " : " none "  }} className="alert alert-danger" role="alert">{errorsLoading}</div>) : (
                        
                                    <div className="col-lg-12">
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                Integração Webmotors - Alterar veículo
                                            </div>
                                            <div className="panel-body">

                                            <ValidatorForm
                                                    ref="form"
                                                    onSubmit={this.handleSubmit}
                                                    onError={ errors => exibirErroSubmit(errors) }
                                                >
                                                <table className='table table-striped'>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ minWidth: '155px', width: '10%'}}></th>
                                                            <th style={{ width: '45%'}}>Dados Intermédio</th>
                                                            <th style={{ width: '45%'}}>Dados Webmotors</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Tipo anúncio <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td></td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ webmotors_tipo_anuncio }
                                                                    name="webmotors_tipo_anuncio"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    >
                                                                        <MenuItem value="U">USADO</MenuItem>
                                                                        <MenuItem value="N">NOVO</MenuItem>                                                     
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Modalidade anúncio <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td></td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ webmotors_modalidade_anuncio }
                                                                    name="webmotors_modalidade_anuncio"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleMarca}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(webmotors_list_modalidade_anuncio)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ webmotors_list_modalidade_anuncio[key].CodigoModalidade }>{ webmotors_list_modalidade_anuncio[key].Descricao.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>  
                                                        <tr>
                                                            <td>Marca <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_marca.toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ webmotors_marca }
                                                                    name="webmotors_marca"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleMarca}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(webmotors_list_marca)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ webmotors_list_marca[key].CodigoMarca }>{ webmotors_list_marca[key].NomeMarca.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                                {/* <select className="form-ui-bootstrap" name="webmotors_marca" onChange={this.handleChange} value={ webmotors_marca }>
                                                                    {
                                                                        Object.keys(webmotors_list_marca)
                                                                        .map(key => {
                                                                            return (
                                                                                <option key={key} value={ webmotors_list_marca[key].name.toUpperCase() } >{ webmotors_list_marca[key].name.toUpperCase() }</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select> */}
                                                            </td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Modelo <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_name.split(' ', 1)[0].toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ webmotors_modelo }
                                                                    name="webmotors_modelo"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleModelo}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        {
                                                                            Object.keys(webmotors_list_modelo)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ webmotors_list_modelo[key].CodigoModelo }>{ webmotors_list_modelo[key].NomeModelo.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>  
                                                        <tr>
                                                            <td>Ano fabricação <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ veano_fabricacao }</td>
                                                            <td>{ webmotors_ano_fabricacao }</td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Ano modelo <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_ano_modelo }</td>
                                                            <td>{ webmotors_ano_modelo }</td>
                                                        </tr>    
                                                        <tr>
                                                            <td>Versão <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_name.substring(vefipe_name.split(' ', 1)[0].length, vefipe_name.length).trim().toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ webmotors_versao }
                                                                    name="webmotors_versao"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        {
                                                                            webmotors_list_versao.length > 1 ? <MenuItem value=""></MenuItem> : null 
                                                                        }
                                                                        {
                                                                            Object.keys(webmotors_list_versao)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ webmotors_list_versao[key].CodigoVersao }>{ webmotors_list_versao[key].NomeVersao.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>  
                                                        <tr>
                                                            <td>Placa</td>
                                                            <td>{ veiplaca.toUpperCase() }</td>
                                                            <td>
                                                                <TextValidator
                                                                    onChange={this.handleChange}
                                                                    name="webmotors_plate"
                                                                    value={webmotors_plate.toUpperCase()}
                                                                    className="form-ui-bootstrap form-integracao"
                                                                />
                                                            </td>
                                                        </tr>      
                                                        <tr>
                                                            <td>Km <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vequilometragem }</td>
                                                            <td>{ webmotors_mileage }</td>
                                                        </tr>      
                                                        <tr>
                                                            <td>Preço <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ formatMoney(vevalor) }</td>
                                                            <td>{ formatMoney(webmotors_price) }</td>
                                                        </tr>                                                        
                                                        <tr>
                                                            <td>Observações</td>
                                                            <td>{ veobservacao.toUpperCase() }</td>
                                                            <td><textarea name="webmotors_description" className="form-ui-bootstrap" rows="4" value={webmotors_description} onChange={this.handleChange} /></td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Combustível <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_combustivel.toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ webmotors_fuelType }
                                                                    name="webmotors_fuelType"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(webmotors_list_combustivel)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ webmotors_list_combustivel[key].CodigoCombustivel }>{ webmotors_list_combustivel[key].Descricao.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>     
                                                        <tr>
                                                            <td>Cor <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vecor.toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ webmotors_color }
                                                                    name="webmotors_color"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(webmotors_list_cor)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ webmotors_list_cor[key].CodigoCor }>{ webmotors_list_cor[key].Descricao.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>       
                                                        <tr>
                                                            <td>Portas <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ veportas }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ webmotors_ports }
                                                                    name="webmotors_ports"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        <MenuItem value="0">0</MenuItem>
                                                                        <MenuItem value="1">1</MenuItem>
                                                                        <MenuItem value="2">2</MenuItem>
                                                                        <MenuItem value="4">4</MenuItem>                                                      
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Transmissão <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ formatCambio(vecambio) }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ webmotors_transmission }
                                                                    name="webmotors_transmission"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(webmotors_list_transmissao)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ webmotors_list_transmissao[key].CodigoCambio }>{ webmotors_list_transmissao[key].Descricao.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>    
                                                        <tr>
                                                            <td>Características</td>
                                                            <td></td>
                                                            <td>
                                                                <p className="checkbox-item">
                                                                    <label className="checkbox-label">
                                                                        <input type="checkbox" name="webmotors_blindado" defaultChecked={webmotors_blindado} onChange={this.handleChangeRadio} /> &nbsp;&nbsp;BLINDADO
                                                                    </label>
                                                                </p>
                                                                <p className="checkbox-item">
                                                                    <label className="checkbox-label">
                                                                        <input type="checkbox" name="webmotors_adaptado_deficiente_fisico" defaultChecked={webmotors_adaptado_deficiente_fisico} onChange={this.handleChangeRadio} /> &nbsp;&nbsp;ADAPTADO PARA DEFICIENTES FÍSICOS
                                                                    </label>
                                                                </p>
                                                                <p className="checkbox-item">
                                                                    <label className="checkbox-label">
                                                                        <input type="checkbox" name="webmotors_unico_dono" defaultChecked={webmotors_unico_dono} onChange={this.handleChangeRadio} /> &nbsp;&nbsp;ÚNICO DONO
                                                                    </label>
                                                                </p>
                                                                <p className="checkbox-item">
                                                                    <label className="checkbox-label">
                                                                        <input type="checkbox" name="webmotors_alienado" defaultChecked={webmotors_alienado} onChange={this.handleChangeRadio} /> &nbsp;&nbsp;ALIENADO
                                                                    </label>
                                                                </p>
                                                                <p className="checkbox-item">
                                                                    <label className="checkbox-label">
                                                                        <input type="checkbox" name="webmotors_licenciado" defaultChecked={webmotors_licenciado} onChange={this.handleChangeRadio} /> &nbsp;&nbsp;LICENCIADO
                                                                    </label>
                                                                </p>
                                                                <p className="checkbox-item">
                                                                    <label className="checkbox-label">
                                                                        <input type="checkbox" name="webmotors_ipva_pago" defaultChecked={webmotors_ipva_pago} onChange={this.handleChangeRadio} /> &nbsp;&nbsp;IPVA PAGO
                                                                    </label>
                                                                </p>
                                                                <p className="checkbox-item">
                                                                    <label className="checkbox-label">
                                                                        <input type="checkbox" name="webmotors_revisoes_concessionaria" defaultChecked={webmotors_revisoes_concessionaria} onChange={this.handleChangeRadio} /> &nbsp;&nbsp;REVISÕES NA CONCESSIONÁRIA
                                                                    </label>
                                                                </p>
                                                                <p className="checkbox-item">
                                                                    <label className="checkbox-label">
                                                                        <input type="checkbox" name="webmotors_garantia_fabrica" defaultChecked={webmotors_garantia_fabrica} onChange={this.handleChangeRadio} /> &nbsp;&nbsp;GARANTIA DE FÁBRICA
                                                                    </label>
                                                                </p>
                                                            </td>
                                                        </tr>      
                                                        <tr>
                                                            <td>Opcionais</td>
                                                            <td>
                                                                {
                                                                    Object.keys(opcionais)
                                                                    .map(key => {
                                                                        return (
                                                                            <span key={key}>{ opcionais[key].descricao.toUpperCase() }<br/></span>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    Object.keys(webmotors_opcionais)
                                                                    .map(key => {
                                                                        return (
                                                                            // <span key={key}>{ webmotors_opcionais[key].toUpperCase() }<br/></span>
                                                                            <p className="checkbox-item" key={key}>
                                                                                <label className="checkbox-label">
                                                                                    <input type="checkbox" name={key} defaultChecked={webmotors_opcionais[key].checked ? webmotors_opcionais[key].checked : false} onChange={this.handleChecked} /> &nbsp;&nbsp;{webmotors_opcionais[key].descricao.toUpperCase()}
                                                                                </label>
                                                                            </p>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Imagens <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>
                                                                {      
                                                                    imagens !== undefined && imagens.length > 0 ? 
                                                                    ( 
                                                                        Object.keys(imagens)
                                                                        .map(key => {
                                                                            
                                                                            return (
                                                                                imagens[key].fileUrl !== null && imagens[key].fileThumb !== null ? 
                                                                                <div key={key}><img src={ imagens[key].fileThumb } alt={ imagens[key].fileName }  style={{ height: '80px', marginBottom: '10px'}} /><br/></div>
                                                                                : null
                                                                            )
                                                                            
                                                                        })
                                                                    )
                                                                    : null
                                                                }
                                                            </td>
                                                            <td>
                                                                {      
                                                                    webmotors_imagens !== undefined && webmotors_imagens.length > 0 ? 
                                                                    ( 
                                                                        Object.keys(webmotors_imagens)
                                                                        .map(key => {
                                                                            
                                                                            return (
                                                                                webmotors_imagens[key].value !== null ? 
                                                                                <div key={key}><img src={ "data:image/jpeg;base64," + webmotors_imagens[key].value } style={{ height: '80px', marginBottom: '10px'}}  /><br/></div>
                                                                                : null
                                                                            )
                                                                            
                                                                        })
                                                                    )
                                                                    : null
                                                                }
                                                            </td>
                                                        </tr> 
                                                        
                                                    </tbody>
                                                </table> 
                                                <p><span className="alteracao-app">*</span> Dados devem ser alterados no aplicativo <br/><span className="preenchimento-obrigatorio">*</span> Campo obrigatório</p>
                                                <div style={{ display: estaGravando ? " block " : " none "  }}><img src="/img/aguarde.gif" alt="carregando"  style={{ height: '80px', marginBottom: '10px'}} /></div>
                                                <div style={{ display: errosSubmit !== null ? " block " : " none "  }} className="alert alert-danger" role="alert">{errosSubmit}</div>
                                                <button onClick={this.goBack} disabled={estaGravando} className="btn btn-warning" >Voltar</button>&nbsp;&nbsp;<button type="submit" disabled={estaGravando} className="btn btn-primary" >Enviar</button>

                                                </ValidatorForm>
                                            
                                            </div>
                                        </div>
                                    </div>
                                )
                            )
                        }
                    </div>
                </div>
            </div>

        )
    }

}

function formatMoney(value) {

    var places = 2;
    var symbol = "R$ ";
    var thousand = ".";
    var decimal = ",";

	places = !isNaN(places = Math.abs(places)) ? places : 2;
	symbol = symbol !== undefined ? symbol : "$";
	thousand = thousand || ",";
	decimal = decimal || ".";
	var number = value, 
	    negative = number < 0 ? "-" : "",
	    i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
	    j = (j = i.length) > 3 ? j % 3 : 0;
	return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
};

function formatCambio(value){
    if(value === 'A'){
        return "AUTOMÁTICO";
    }else{
        return "MANUAL";
    }
}

function compareOpcionais(a, b) {
    const genreA = a.descricao.toUpperCase();
    const genreB = b.descricao.toUpperCase();
    
    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
}

function compareMarca(a, b) {
    const genreA = a.NomeMarca.toUpperCase();
    const genreB = b.NomeMarca.toUpperCase();
    
    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
}


function compareModelo(a, b) {
    const genreA = a.NomeModelo.toUpperCase();
    const genreB = b.NomeModelo.toUpperCase();
    
    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
}

function compareVersao(a, b) {
    const genreA = a.NomeVersao.toUpperCase();
    const genreB = b.NomeVersao.toUpperCase();
    
    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
}

function compareDescricao(a, b) {
    const genreA = a.Descricao.toUpperCase();
    const genreB = b.Descricao.toUpperCase();
    
    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
}

function exibirErroSubmit(errors){
    window.scrollTo(0, 0);
    alert("Campos obrigatórios não preenchidos!");
}

function opcionais_true(opcional){
    return opcional.checked === true;
}

function exibirErrosRetorno(erro){
    switch(erro){
        case "(28|2)" :
            return "Preço de venda inválido.";
        case "(22|78)" :
            return "O campo PrecoReal(De) deve ser maior que o campo PrecoVenda(Por).";
        case "31" :
            return "Hash de autenticação inválido.";
        case "32" :
            return "Falha inesperada, favor entrar em contato com o Suporte Técnico.";
        case "(31|1)" :
            return "Campo {nome campo} não preenchido.";
        case "43|32" :
            return "Número de anúncios disponíveis para a modalidade esgotado.";
        case "43|1" :
            return "Somente anúncios ativos podem ser desativados.";
        case "43|2" :
            return "Prezado (a) usuário (a), lembramos que ao desativar seu anúncio os dias serão descontados do tempo de exposição contratado, não sendo possível qualquer reembolso ou crédito referente ao período em que o anúncio estiver fora de veiculação.\nVocê confirma esta desativação?";
        case "43|3" :
            return "Anúncio desativado com sucesso.";
        case "43|4" :
            return "Somente anúncios desativados podem ser reativados.";
        case "43|5" :
            return "Confirma a ativação do anúncio?";
        case "43|6" :
            return "Anúncio ativado com sucesso.";
        case "43|7" :
            return "Preço do carro deve ser preenchido.";
        case "43|8" :
            return "Ano do modelo deve ser preenchido.";
        case "43|9" :
            return "Ano de fabricação deve ser preenchido.";
        case "43|10" :
            return "Câmbio deve ser preenchido.";
        case "43|11" :
            return "Cor deve ser preenchida.";
        case "43|12" :
            return "Número de portas deve ser preenchido.";
        case "43|13" :
            return "Placa deve ser preenchida.";
        case "43|14" :
            return "Quilometragem deve ser preenchida.";
        case "43|15" :
            return "Marca deve ser preenchida.";
        case "43|16" :
            return "Modelo deve ser preenchido.";
        case "43|17" :
            return "Versão deve ser preenchida.";
        case "43|18" :
            return "Quilometragem deve ser preenchida.";
        case "43|19" :
            return "Placa deve ser preenchida.";
        case "43|20" :
            return "Câmbio deve ser preenchido.";
        case "43|21" :
            return "Número de portas deve ser preenchido.";
        case "43|22" :
            return "Cor deve ser preenchida.";
        case "43|23" :
            return "Anúncio não pode ser bloqueado.";
        case "43|24" :
            return "Somente anúncios bloqueados podem ser desbloqueados.";
        case "43|25" :
            return "Anúncios só podem ser feitos para anunciantes que não estejam em estado “Bloqueado”.";
        case "43|26" :
            return "Somente anúncio ativos, vencidos ou desativados podem ser renovados.";
        case "43|27" :
            return "Confirma a baixa de pagamento manual do anúncio ?";
        case "43|28" :
            return "Parcela a ser paga não encontrada.";
        case "43|29" :
            return "Cobrança não encontrada.";
        case "43|30" :
            return "Combustível deve ser preenchido.";
        case "43|31" :
            return "Somente anúncios que estejam aguardando pagamento podem sofrer confirmação de pagamento.";
        case "43|32" :
            return "Número de anúncios disponíveis para a modalidade esgotado.";
        case "43|33" :
            return "Número de anúncios disponíveis para o pacote de anúncios esgotado.";
        case "43|34" :
            return "Não há venda vigente para o pacote de $param1$.";
        case "43|35" :
            return "Não foi possível bloquear os anúncios do cliente.";
        case "43|36" :
            return "Anúncio não pode ser alterado.";
        case "43|37" :
            return "Ano do modelo inválido.";
        case "43|38" :
            return "Seleção opcional: $param1$.";
        case "43|39" :
            return "Anuncio não pertence ao revendedor conectado.";
        case "43|40" :
            return "Anuncio inválido.";
        case "43|41" :
            return "Marca - Modelo - Versão - Ano Modelo Inconsistentes.";        
        case "43|42" :
            return "Cor Externa inválida para a Versão.";
        case "43|43" :
            return "Lista de Opcionais de Novos possui valores inválidos.";
        case "43|44" :
            return "Tipo Combustível inválido.";
        case "43|45" :
            return "Câmbio inválido.";
        case "43|46" :
            return "AdaptadoDeficientesFisicos deve possuir S ou N.";
        case "43|47" :
            return "Alienado deve possuir S ou N.";
        case "43|48" :
            return "Blindado deve possuir S ou N.";
        case "43|49" :
            return "IpvaPago deve possuir S ou N.";
        case "43|50" :
            return "GarantiaDeFabrica deve possuir S ou N.";
        case "43|51" :
            return "Licenciado deve possuir S ou N.";
        case "43|52" :
            return "RevisadoOficinaAgendaDoCarro deve possuir S ou N.";
        case "43|53" :
            return "RevisoesEmConcessionadia deve possuir S ou N.";
        case "43|54" :
            return "UnicoDono deve possuir S ou N.";
        case "43|55" :
            return "TipoAnuncio deve possuir U ou N.";
        case "43|56" :
            return "Código da modalidade de anúncio inválida para o Revendedor/Tipo de anúncio.";
        case "43|57" :
            return "Tipo de Anuncio não pode ser alterado.";
        case "43|58" :
            return "Motivo de Exclusão deve ser informado.";
        case "43|59" :
            return "Anúncios só podem ser desbloqueados caso o anunciante esteja em estado 'Ativo' ou 'Pós-ativo'.";
        case "43|60" :
            return "Somente anúncios que estejam aguardando pagamento pode ser pagos.";
        case "43|61" :
            return "Placa não pode ser impressa.";
        case "43|62" :
            return "Número de porta inválido.";
        case "43|63" :
            return "Combustível inválido.";
        case "43|64" :
            return "Cor inválida.";
        case "43|65" :
            return "HTML injection identificado.";
        case "43|66" :
            return "O preço que você tentou cadastrar não está de acordo com o praticado pelo mercado.Por favor ,cadastre outro valor. Se houver dúvida em relação ao valor de mercado do seu carro, consulte as tabelas WebMotors e FIPE";
        case "43|67" :
            return "Preço de Revenda inválido.";
        case "43|68" :
            return "Quilometragem não permitida.";
        case "43|69" :
            return "Código de Foto não pertence ao Código de Anúncio.";
        case "43|70" :
            return "Marca deve ser preenchido.";
        case "43|71" :
            return "Modelo deve ser preenchido.";
        case "43|72" :
            return "Versão deve ser preenchido.";
        case "43|73" :
            return "Cor deve ser preenchido.";
        case "43|74" :
            return "Placa deve ser preenchido.";
        case "43|75" :
            return "Câmbio deve ser preenchido.";
        case "43|76" :
            return "Número de Portas deve ser preenchido.";
        case "43|77" :
            return "Combustível deve ser preenchido.";
        case "43|78" :
            return "Preço de Venda deve ser preenchido.";
        case "43|79" :
            return "$param1$ contém número inválido.";
        case "43|80" :
            return "O anunciante está bloqueado. O anúncio pode ser desbloqueado somente se o anunciante não estiver bloqueado.";
        case "43|81" :
            return "Somente anúncios ativos, vencidos ou desativados pode ser renovados.";
        case "43|82" :
            return "Somente o preço do anúncio pode ser alterado.";
        case "43|83" :
            return "Somente anúncios ativos, disponíveis para edição ou prestes à vencer podem ser visualizados.";
        case "43|84" :
            return "Placa inválida.";
        case "43|85" :
            return "Ano do fabricação inválido.";
        case "43|86" :
            return "O anúncio foi pago?.";
        case "43|87" :
            return "Anúncio habilitado para receber proposta em texto por celular. Clique em 'Editar' se quiser alterar";
        case "43|88" :
            return "Você não possui celulares cadastrados. Clique em 'Dados pessoais' para informá-lo";
        case "43|89" :
            return "Você não possui saldo de SMS.";
        case "43|90" :
            return "Tipo de motor deve ser preenchido.";
        case "43|91" :
            return "Tipo de partida deve ser preenchido.";
        case "43|92" :
            return "Freio dianteiro/traseiro deve ser preenchido.";
        case "43|93" :
            return "Preço da moto deve ser preenchido.";
        case "43|94" :
            return "Número de cilindradas deve ser preenchido.";
        case "43|95" :
            return "Quilometragem não permitida.";
        case "43|96" :
            return "Cor predominante deve ser preenchida.";
        case "43|97" :
            return "Observações excede o máximo de caracteres permitidos (1500).";
        case "43|98" :
            return "Você não possui nenhum anúncio nesta situação. Selecione outra situação ou TODOS.";
        case "43|99" :
            return "Nº de marchas deve ser preenchido.";
        case "43|100" :
            return "Tipo de refrigeração deve ser preenchido.";
        case "43|101" :
            return "Tipo de alimentação deve ser preenchido.";
        case "43|102" :
            return "Preço de venda inválido: valor acima da tabela FIPE mais que o permitido.";
        case "43|103" :
            return "Confirma a Desativação do Anúncio?";
        case "43|104" :
            return "O preço de revenda que você tentou cadastrar não está de acordo com o praticado pelo mercado.Por favor ,cadastre outro valor. Se houver dúvida em relação ao valor de mercado do seu carro, consulte as tabelas WebMotors e FIPE";
        case "43|105" :
            return "Preço de revenda inválido: valor acima da tabela FIPE mais que o permitido.";
        case "43|106" :
            return "Código do anúncio deve ser preenchido.";
        case "43|107" :
            return "O campo quilometragem só pode ser alterado para um valor superior ao cadastrado inicialmente.";
        case "43|108" :
            return "Somente modalidades com o estado Publicado, poderá ser realizado o pagamento.";
        case "43|109" :
            return "Pagamento já foi efetuado, escolha uma nova modalidade ou cancela seu pagamento.";
        case "43|110" :
            return "Selecione um CEP.";
        case "43|111" :
            return "Selecione um CEP do histórico.";
        case "43|112" :
            return "Ano de fabricação inválido.";
        case "53" :
            return "Cor interna inválida para a versão.";
        case "82" :
            return "{Nome do campo} contém números inválidos.";
        case "500" :
            return "Operação realizada com sucesso.";
        default:
            return erro + " (http://integracao.webmotors.com.br/manualintegracao/ServicosParaIntegracaoDeCarros/Parametros/Retorno/RetornoManutencao.html)";
    }
}


export default VeiculosIntegracaoWebmotorsAlterar