import React, { Component } from 'react';
import { auth, firestore } from './../../firebase-config';
import Cabecalho from './../../componentes/Cabecalho';
import CabecalhoPainel from '../../componentes/CabecalhoPainel';
import { Link, Redirect } from 'react-router-dom';
import Modal from 'react-modal';
import 'moment-timezone';

Modal.setAppElement('#root');

class RevendaUsuariosListar extends Component {

    constructor (props){
        super(props);

        this.state = {
            idRevenda: props.match.params.idRevenda,
            nome_revenda: "",
            revenda_ativa: 1,
            usuarios_ativos: 1,
            usuarios: [],
            modalShow: false,
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado"))
        }
        this.mounted = false;
    }

    componentDidMount(){

        this.mounted = true;

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection("usuarios").where("revenda", "==", this.state.idRevenda)
        .onSnapshot(querySnapshot => {
            var items = [];
            querySnapshot.forEach(doc => {
                var document = doc.data();
                document.id  = doc.id;
                items.push(document);
            });
            if(this.mounted) {
                this.setState({
                    usuarios: items,
                    usuarios_ativos: items.length > 0 && items[0].ativo === 0 ? 0 : 1
                });
            }
        });

        firestore.collection("revenda").doc(this.state.idRevenda)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    nome_revenda: doc.data().penome_fantasia,
                    revenda_ativa: doc.data().ativo === 0 ? 0 : 1,
                });
            }
        }).catch(function(error) {
        });

    }

    componentWillUnmount(){
        this.mounted = false;
    }

    alteracaoSenha(idUsuario){

        this.setState({modalShow: true});

        auth.sendPasswordResetEmail(idUsuario)
        .then(res => {
            alert("Link para alteração de senha enviado a "+idUsuario);
            this.setState({modalShow: false});
        })
        .catch(error => {
            alert("Erro ao enviar link para alteração de senha do e-mail "+idUsuario)
            this.setState({modalShow: false});
        });

    }

    bloquearAcesso = async () => {
        if(window.confirm('Deseja bloquear o acesso de todos usuários desta revenda?')){
            await this.state.usuarios.forEach(async (doc) => {
                await firestore.collection('usuarios').doc(doc.id).set({ativo: 0}, { merge: true });
            });
            // await firestore.collection('revenda').doc(this.state.idRevenda).set({ativo: 0}, { merge: true });
            // window.location.reload();
        }        
    }

    bloquearSite = async () => {
        if(window.confirm('Deseja bloquear o site desta revenda?')){
            await firestore.collection('revenda').doc(this.state.idRevenda).set({ativo: 0}, { merge: true });
            window.location.reload();
        }        
    }

    desbloquearAcesso = async () => {
        if(window.confirm('Deseja liberar o acesso de todos usuários desta revenda?')){
            await this.state.usuarios.forEach(async (doc) => {
                await firestore.collection('usuarios').doc(doc.id).set({ativo: 1}, { merge: true });
            });
            // await firestore.collection('revenda').doc(this.state.idRevenda).set({ativo: 1}, { merge: true });
            // window.location.reload();
        }    
    }

    desbloquearSite = async () => {
        if(window.confirm('Deseja bloquear o site desta revenda?')){
            await firestore.collection('revenda').doc(this.state.idRevenda).set({ativo: 1}, { merge: true });
            window.location.reload();
        }        
    }

    render(){

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin' && this.state.idRevenda !== this.state.usuarioLogado.revenda){
            return <Redirect to='/acesso-negado' />
        }

        return (
            <div id="modalAguarde">
                {
                    this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ? <Cabecalho /> :  <CabecalhoPainel />
                } 
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Usuários<br/><small>Revenda: { this.state.nome_revenda }</small></h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    Lista de Usuarios
                                </div>
                                <div className="panel-body">
                                
                                    <Link to={ "/revenda/"+this.state.idRevenda+"/usuarios/incluir"} className="btn btn-warning" >Novo usuário</Link>&nbsp;&nbsp;                                    
                                    {
                                        this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ?
                                            this.state.usuarios_ativos === 1 ?
                                                <a onClick={() => this.bloquearAcesso()} className="btn btn-danger">Bloquear acesso do APP</a>
                                                :
                                                <a onClick={() => this.desbloquearAcesso()} className="btn btn-success">Desbloquear acesso do APP</a>
                                        : <span></span>
                                    }
                                    {
                                        this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ? 
                                            this.state.revenda_ativa === 1 ?
                                                <a style={{ marginLeft: "7px" }} onClick={() => this.bloquearSite()} className="btn btn-danger">Bloquear site</a>
                                            :
                                                <a style={{ marginLeft: "7px" }} onClick={() => this.desbloquearSite()} className="btn btn-success">Desbloquear site</a>
                                        : <span></span>
                                    }

                                    <br/><br/>
                                    <table width="100%" className="table table-striped table-bordered table-hover" id="dtUsuarios">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>E-mail</th>
                                                <th>Acesso</th>
                                                <th>Ativo</th>
                                                <th>Token</th>
                                                <th>Data Token</th>
                                                <th>Manutenção</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Object.keys(this.state.usuarios)
                                                .map(key => {
                                                    return (
                                                        <tr key={key}>
                                                            <td>{ this.state.usuarios[key].nome }</td>
                                                            <td>{ this.state.usuarios[key].id }</td>
                                                            <td>{ this.state.usuarios[key].role }</td>
                                                            <td>{ this.state.usuarios[key].ativo === 0 ? "Não" : "Sim" }</td>
                                                            <td>{ this.state.usuarios[key].push_token !== undefined ? "Sim" : "" }</td>
                                                            <td>{ this.state.usuarios[key].push_data !== undefined ? this.state.usuarios[key].push_data : "" }</td>
                                                            <td>
                                                                <a onClick={() => this.alteracaoSenha(this.state.usuarios[key].id)}>Alterar Senha</a> / <Link to={ "/revenda/"+this.state.usuarios[key].revenda+"/usuarios/"+this.state.usuarios[key].id+"/alterar"} >Alterar</Link> / <Link to={ "/revenda/"+this.state.usuarios[key].revenda+"/usuarios/"+this.state.usuarios[key].id+"/excluir" } >Excluir</Link> 
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={this.state.modalShow} 
                    className="modal-class modal-class-aguarde"
                    overlayClassName="modal-overlay"
                    >
                    <div>Aguarde...</div>
                </Modal>

            </div>
            
        )
    }

}

export default RevendaUsuariosListar;