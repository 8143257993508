import React, { Component } from 'react';
import { firestore } from '../../../firebase-config';
import Cabecalho from '../../../componentes/Cabecalho';
import CabecalhoPainel from '../../../componentes/CabecalhoPainel';
import { base_url, api_key } from '../../../config/config_api';
import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import MenuItem from "@material-ui/core/MenuItem";
import { Redirect } from 'react-router-dom';

class VeiculosIntegracaoAutoscarIncluirCarro extends Component {

    constructor (props){
        super(props);

        this.state = {
            estaGravando: false,
            carregandoDados: true,
            returnPagina: false,
            errosSubmit: null,
            errorLoading: false,
            errorsLoading: null,
            idRevenda: props.match.params.idRevenda,
            idVeiculo: props.match.params.idVeiculo,
            nome_revenda: "",
            cidade_revenda: "",
            uf_revenda: "",
            opcionais: [],
            imagens: [],
            veano_fabricacao: "",
            vecambio: "",
            vecor: "",
            vefipe_ano_modelo: "",
            vefipe_combustivel: "",
            vefipe_marca: "",
            vefipe_name: "",
            veiplaca: "",
            veobservacao: "",
            veportas: "",
            vequilometragem: "",
            vetipoveiculo: "",
            vevalor: "",
            veremovido: 0,
            autoscar_marca: "",
            autoscar_modelo: "",
            autoscar_versao: "",
            autoscar_id_versao: 0,
            autoscar_ano_fabricacao: "",
            autoscar_ano_modelo: "",
            autoscar_plate: "",
            autoscar_mileage: "",
            autoscar_price: "",
            autoscar_city: "",
            autoscar_state: "",
            autoscar_description: "",
            autoscar_fuelType: "",
            autoscar_color: "",
            autoscar_ports: "",
            autoscar_bodyStyle: "",
            autoscar_transmission: "",
            autoscar_imagens: [],
            autoscar_opcionais: [],
            autoscar_list_marca: [],
            autoscar_list_modelo: [],
            autoscar_list_versao: [],
            autoscar_list_combustivel: [],
            autoscar_list_cor: [],
            autoscar_list_tipo_veiculo: [],
            autoscar_list_transmissao: [],
            versaoSelecionada: new Map(),
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }
        this.mounted = false;
        this.goBack  = this.goBack.bind(this);
        this.handleChange  = this.handleChange.bind(this);
        this.handleMarca   = this.handleMarca.bind(this);
        this.handleModelo  = this.handleModelo.bind(this);
        this.handleVersao  = this.handleVersao.bind(this);
        this.handleChecked = this.handleChecked.bind(this);        
        this.handleSubmit  = this.handleSubmit.bind(this);
        
    }

    goBack(){
        this.props.history.goBack();
    }

    componentDidMount(){

        this.mounted = true;

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection("revenda").doc(this.state.idRevenda)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    nome_revenda: doc.data().penome_fantasia,
                    cidade_revenda: doc.data().pecidade,
                    uf_revenda: doc.data().peuf,
                });
            }
        }).catch(function(error) {
        });

        firestore.collection("revenda").doc(this.state.idRevenda).collection("veiculos").doc(this.state.idVeiculo)
        .get()
        .then(doc => {
            if (doc.exists) {

                this.setState({
                    opcionais: doc.data().opcionais.sort(compareOpcionais),
                    imagens: doc.data().imagens,
                    veano_fabricacao: doc.data().veano_fabricacao,
                    vecambio: doc.data().vecambio,
                    vecor: doc.data().vecor,
                    vefipe_ano_modelo: doc.data().vefipe_ano_modelo,
                    vefipe_combustivel: doc.data().vefipe_combustivel,
                    vefipe_marca: doc.data().vefipe_marca,
                    vefipe_name: doc.data().vefipe_name,
                    veiplaca: doc.data().veiplaca,
                    veobservacao: doc.data().veobservacao,
                    veportas: doc.data().veportas,
                    vequilometragem: doc.data().vequilometragem,
                    vetipoveiculo: doc.data().vetipoveiculo,
                    vevalor: doc.data().vevalor,
                    veremovido: doc.data().veremovido
                });
            }
        }).catch(function(error) {
        });

        fetch(base_url+"/api/integracao/autoscar/incluir", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "api_key": api_key,
                "id_veiculo": this.state.idVeiculo
            })
        })
        .then(response => {

            response.json()
            .then(data => {

                console.log(data);
                if(data.status === 'success'){
                    this.setState({
                        autoscar_marca: data.data.veiculoData.veiculo_marca.toUpperCase(),
                        autoscar_modelo: data.data.veiculoData.veiculo_modelo.toUpperCase(),
                        autoscar_versao: data.data.veiculoData.veiculo_versao.toUpperCase(),
                        autoscar_id_versao: data.data.veiculoData.veiculo_id_versao,
                        autoscar_ano_fabricacao: data.data.veiculoData.veiculo_ano_fabricacao,
                        autoscar_ano_modelo: data.data.veiculoData.veiculo_ano_modelo,
                        autoscar_plate: data.data.veiculoPost.plate.toUpperCase(),
                        autoscar_mileage: data.data.veiculoPost.mileage,
                        autoscar_price: data.data.veiculoPost.price,
                        autoscar_city: data.data.veiculoPost.city.toUpperCase(),
                        autoscar_state: data.data.veiculoPost.state.toUpperCase(),
                        autoscar_description: data.data.veiculoPost.description.toUpperCase(),
                        autoscar_fuelType: data.data.veiculoPost.fuelType,
                        autoscar_color: data.data.veiculoPost.color,
                        autoscar_ports: data.data.veiculoPost.ports,
                        autoscar_bodyStyle: data.data.veiculoPost.bodyStyle,
                        autoscar_transmission: data.data.veiculoPost.transmission,
                        autoscar_imagens: data.data.veiculoPost.photos,
                        autoscar_opcionais: data.data.retOpcionais.sort(compareOpcionais),
                        autoscar_list_marca: data.data.retMarca.sort(compareMarca),
                        autoscar_list_modelo: data.data.retModelo.sort(),
                        autoscar_list_versao: data.data.retVersaoFilter.sort(compareVersao),
                        autoscar_list_combustivel: data.data.retTipoCombustivel.sort(),
                        autoscar_list_cor: data.data.retCores.sort(),
                        autoscar_list_tipo_veiculo: data.data.retTipoVeiculo.sort(),
                        autoscar_list_transmissao: data.data.retTipoTransmissao,
                        versaoSelecionada: data.data.retVersaoFilter.length === 1 ? data.data.retVersaoFilter[0] : new Map(),
                    });
                }else{
                    this.setState({
                        errorLoading: true,
                        errorsLoading: data.data,
                    });
                }
            });

            this.setState({
                carregandoDados: false,
            });

        })
        .catch(error => {
            console.error("Erro get teste", error);
            this.setState({
                carregandoDados: false,
            });
        });
        
    }

    componentWillUnmount(){
        this.mounted = false;
    }

    handleMarca(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value,
            autoscar_list_modelo: [],
            autoscar_list_versao: [],
        });

        return fetch(base_url+"/api/integracao/autoscar/consultar-modelo", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "api_key": api_key,
                "marca": value,
                "id_veiculo": this.state.idVeiculo
            })
        })
        .then(response => {

            return response.json()
            .then(data => {
                if(data.status === 'success'){
                    this.setState({
                        autoscar_list_modelo: data.data.sort(),
                        autoscar_modelo: ""
                    });
                }
            });

        })
        .catch(error => {
            console.error("Erro get teste", error);
        });

    }

    handleModelo(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value,
            autoscar_list_versao: [],
        });

        return fetch(base_url+"/api/integracao/autoscar/consultar-versao", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "api_key": api_key,
                "marca": this.state.autoscar_marca,
                "modelo": value,
                "id_veiculo": this.state.idVeiculo
            })
        })
        .then(response => {

            return response.json()
            .then(data => {
                if(data.status === 'success'){
                    this.setState({
                        autoscar_list_versao: data.data.sort(compareVersao),
                        autoscar_versao: ""
                    });
                }
            });

        })
        .catch(error => {
            console.error("Erro get teste", error);
        });

    }

    handleVersao(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;
        
        var i = this.state.autoscar_list_versao.find(function (versao) { return versao.id === value; });

        this.setState({
            [name]: value,
            autoscar_id_versao: value,
            versaoSelecionada: i,
        });
    }

    handleChange(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        });
    }


    handleChecked(event) {

        const item = event.target.name; 
        var oldOpcionais = this.state.autoscar_opcionais;
        const oldChecked = oldOpcionais[item].checked;
        oldOpcionais[item].checked = !oldChecked;
        this.setState({
            autoscar_opcionais: oldOpcionais
        });

    }

    handleSubmit(event) {

        this.setState({estaGravando: true, errosSubmit: null});

        var opcionais_selecionados = this.state.autoscar_opcionais.filter(opcionais_true);
        var opcionais_new = [];
        opcionais_selecionados.forEach(opcional => {
            opcionais_new.push(opcional.descricao);
        });

        var user = JSON.parse(window.localStorage.getItem("usuarioLogado"));
        
        var veiculo_post = {
            plate: this.state.autoscar_plate, 
            mileage: this.state.autoscar_mileage,
            price: this.state.autoscar_price,
            city: this.state.autoscar_city,
            state: this.state.autoscar_state,
            photos: this.state.autoscar_imagens,
            modelId: this.state.autoscar_id_versao,
            optionals: opcionais_new,
            description: this.state.autoscar_description,
            fuelType: this.state.autoscar_fuelType,
            status: "1",
            color: this.state.autoscar_color,
            ports: this.state.autoscar_ports,
            bodyStyle: this.state.autoscar_bodyStyle,
            transmission: this.state.autoscar_transmission
        }

        fetch(base_url+"/api/integracao/autoscar/enviar", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "api_key": api_key,
                "veiculo_post": veiculo_post,
                "usuario": user.id,
                "id_veiculo": this.state.idVeiculo
            })
        })
        .then(response => {

            response.json()
            .then(data => {
                if(data.status === 'success'){

                    var integracao = data.data;
                    integracao.status = true;
                    integracao.id_anuncio = data.data.advId;

                    // firestore.settings({ timestampsInSnapshots: true });
                    firestore.collection('revenda').doc(this.state.idRevenda).collection('veiculos').doc(this.state.idVeiculo).collection('integracoes').doc("autoscar").set(integracao, { merge: true })
                    .then(res => { 
                        this.setState({returnPagina: true}); 
                    })
                    .catch(err => { 
                        this.setState({returnPagina: true});      
                    });
                   
                }else{
                    this.setState({errosSubmit: data.data});
                }
            });
            this.setState({estaGravando: false});  

        })
        .catch(error => {
            this.setState({estaGravando: false});  
            console.error("Erro get teste", error);
        });

    }

    render(){
        const { 
            errosSubmit,
            estaGravando,
            idRevenda,
            idVeiculo,
            nome_revenda,
            cidade_revenda,
            uf_revenda,
            opcionais,
            imagens,
            veano_fabricacao,
            vecambio,
            vecor,
            vefipe_ano_modelo,
            vefipe_combustivel,
            vefipe_marca,
            vefipe_name,
            veiplaca,
            veobservacao,
            veportas,
            vequilometragem,
            vevalor,
            autoscar_marca,
            autoscar_modelo,
            autoscar_ano_fabricacao,
            autoscar_ano_modelo,
            autoscar_plate,
            autoscar_mileage,
            autoscar_price,
            autoscar_city,
            autoscar_state,
            autoscar_description,
            autoscar_fuelType,
            autoscar_color,
            autoscar_ports,
            autoscar_bodyStyle,
            autoscar_transmission,
            autoscar_imagens,
            autoscar_opcionais,
            autoscar_list_marca,
            autoscar_list_modelo,
            autoscar_list_versao,
            autoscar_list_combustivel,
            autoscar_list_cor,
            autoscar_list_tipo_veiculo,
            autoscar_list_transmissao,
            autoscar_id_versao,
            carregandoDados,
            errorLoading,
            errorsLoading
        } = this.state;

        if(this.state.returnPagina){
            return <Redirect to={ "/revenda/"+idRevenda+"/veiculos/"+idVeiculo+"/visualizar" } />;
        }

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin' && this.state.idRevenda !== this.state.usuarioLogado.revenda){
            return <Redirect to='/acesso-negado' />
        }

        return (
            <div>
                {
                    this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ? <Cabecalho /> :  <CabecalhoPainel />
                } 
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Incluir carro Autoscar<br/><small>Revenda: { nome_revenda }</small></h1>
                        </div>
                    </div>
                    <div className="row">
                        {
                                    
                            carregandoDados ? (<div className="col-lg-12"><img src="/img/aguarde.gif" alt="carregando"  style={{ height: '80px', marginBottom: '10px'}} /></div>) : (

                                errorLoading ? (<div style={{ display: errorsLoading !== null ? " block " : " none "  }} className="alert alert-danger" role="alert">{errorsLoading}</div>) : (
                        
                                    <div className="col-lg-12">
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                Integração Autoscar - Incluir veículo
                                            </div>
                                            <div className="panel-body">
                                            
                                            <ValidatorForm
                                                    ref="form"
                                                    onSubmit={this.handleSubmit}
                                                    onError={ errors => exibirErroSubmit(errors) }
                                                >
                                                <table className='table table-striped'>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ minWidth: '130px', width: '10%'}}></th>
                                                            <th style={{ width: '45%'}}>Dados Intermédio</th>
                                                            <th style={{ width: '45%'}}>Dados Autoscar</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Marca <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_marca.toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ autoscar_marca }
                                                                    name="autoscar_marca"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleMarca}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(autoscar_list_marca)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ autoscar_list_marca[key].name.toUpperCase() }>{ autoscar_list_marca[key].name.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                                {/* <select className="form-ui-bootstrap" name="autoscar_marca" onChange={this.handleChange} value={ autoscar_marca }>
                                                                    {
                                                                        Object.keys(autoscar_list_marca)
                                                                        .map(key => {
                                                                            return (
                                                                                <option key={key} value={ autoscar_list_marca[key].name.toUpperCase() } >{ autoscar_list_marca[key].name.toUpperCase() }</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select> */}
                                                            </td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Modelo <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_name.split(' ', 1)[0].toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ autoscar_modelo }
                                                                    name="autoscar_modelo"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleModelo}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        {
                                                                            Object.keys(autoscar_list_modelo)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ autoscar_list_modelo[key].toUpperCase() }>{ autoscar_list_modelo[key].toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>  
                                                        <tr>
                                                            <td>Ano fabricação <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ veano_fabricacao }</td>
                                                            <td>{ autoscar_ano_fabricacao }</td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Ano modelo <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_ano_modelo }</td>
                                                            <td>{ autoscar_ano_modelo }</td>
                                                        </tr>    
                                                        <tr>
                                                            <td>Versão <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_name.substring(vefipe_name.split(' ', 1)[0].length, vefipe_name.length).trim().toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ autoscar_id_versao === 0 ? "" : autoscar_id_versao }
                                                                    name="autoscar_versao"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleVersao}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        {
                                                                            autoscar_list_versao.length > 1 ? <MenuItem value=""></MenuItem> : null 
                                                                        }
                                                                        {
                                                                            Object.keys(autoscar_list_versao)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ autoscar_list_versao[key].id }>{ autoscar_list_versao[key].version.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>  
                                                        <tr>
                                                            <td>Placa</td>
                                                            <td>{ veiplaca.toUpperCase() }</td>
                                                            <td>
                                                                <TextValidator
                                                                    onChange={this.handleChange}
                                                                    name="autoscar_plate"
                                                                    value={autoscar_plate.toUpperCase()}
                                                                    className="form-ui-bootstrap form-integracao"
                                                                />
                                                            </td>
                                                        </tr>      
                                                        <tr>
                                                            <td>Km <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vequilometragem }</td>
                                                            <td>{ autoscar_mileage }</td>
                                                        </tr>      
                                                        <tr>
                                                            <td>Preço <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ formatMoney(vevalor) }</td>
                                                            <td>{ formatMoney(autoscar_price) }</td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Cidade <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ cidade_revenda.toUpperCase() }</td>
                                                            <td>
                                                                <TextValidator
                                                                    onChange={this.handleChange}
                                                                    name="autoscar_city"
                                                                    value={autoscar_city.toUpperCase()}
                                                                    validators={['required']}
                                                                    errorMessages={['Preenchimento obrigatório']}
                                                                    className="form-ui-bootstrap form-integracao"
                                                                />
                                                            </td>
                                                        </tr>   
                                                        <tr>
                                                            <td>UF <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ uf_revenda.toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ autoscar_state }
                                                                    name="autoscar_state"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        <MenuItem value="AC">ACRE</MenuItem>
                                                                        <MenuItem value="AL">ALAGOAS</MenuItem>
                                                                        <MenuItem value="AP">AMAPÁ</MenuItem>
                                                                        <MenuItem value="AM">AMAZONAS</MenuItem>
                                                                        <MenuItem value="BA">BAHIA</MenuItem>
                                                                        <MenuItem value="CE">CEARÁ</MenuItem>
                                                                        <MenuItem value="DF">DISTRITO FEDERAL</MenuItem>
                                                                        <MenuItem value="ES">ESPÍRITO SANTO</MenuItem>
                                                                        <MenuItem value="GO">GOIÁS</MenuItem>
                                                                        <MenuItem value="MA">MARANHÃO</MenuItem>
                                                                        <MenuItem value="MT">MATO GROSSO</MenuItem>
                                                                        <MenuItem value="MS">MATO GROSSO DO SUL</MenuItem>
                                                                        <MenuItem value="MG">MINAS GERAIS</MenuItem>
                                                                        <MenuItem value="PA">PARÁ</MenuItem>
                                                                        <MenuItem value="PB">PARAÍBA</MenuItem>
                                                                        <MenuItem value="PR">PARANÁ</MenuItem>
                                                                        <MenuItem value="PE">PERNAMBUCO</MenuItem>
                                                                        <MenuItem value="PI">PIAUÍ</MenuItem>
                                                                        <MenuItem value="RJ">RIO DE JANEIRO</MenuItem>
                                                                        <MenuItem value="RN">RIO GRANDE DO NORTE</MenuItem>
                                                                        <MenuItem value="RS">RIO GRANDE DO SUL</MenuItem>
                                                                        <MenuItem value="RO">RONDÔNIA</MenuItem>
                                                                        <MenuItem value="RR">RORAIMA</MenuItem>
                                                                        <MenuItem value="SC">SANTA CATARINA</MenuItem>
                                                                        <MenuItem value="SP">SÃO PAULO</MenuItem>
                                                                        <MenuItem value="SE">SERGIPE</MenuItem>
                                                                        <MenuItem value="TO">TOCANTINS</MenuItem>                                                      
                                                                </SelectValidator>                                                    
                                                            </td>
                                                        </tr>     
                                                        <tr>
                                                            <td>Observações</td>
                                                            <td>{ veobservacao.toUpperCase() }</td>
                                                            <td><textarea name="autoscar_description" className="form-ui-bootstrap" rows="4" value={autoscar_description} onChange={this.handleChange} /></td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Combustível <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_combustivel.toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ autoscar_fuelType }
                                                                    name="autoscar_fuelType"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(autoscar_list_combustivel)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ autoscar_list_combustivel[key] }>{ autoscar_list_combustivel[key].toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>     
                                                        <tr>
                                                            <td>Cor <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vecor.toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ autoscar_color }
                                                                    name="autoscar_color"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(autoscar_list_cor)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ autoscar_list_cor[key] }>{ autoscar_list_cor[key].toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>       
                                                        <tr>
                                                            <td>Portas <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ veportas }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ autoscar_ports }
                                                                    name="autoscar_ports"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        <MenuItem value="0">0</MenuItem>
                                                                        <MenuItem value="1">1</MenuItem>
                                                                        <MenuItem value="2">2</MenuItem>
                                                                        <MenuItem value="4">4</MenuItem>                                                      
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>         
                                                        <tr>
                                                            <td>Tipo veículo <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td></td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ autoscar_bodyStyle }
                                                                    name="autoscar_bodyStyle"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(autoscar_list_tipo_veiculo)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ autoscar_list_tipo_veiculo[key] }>{ autoscar_list_tipo_veiculo[key].toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>       
                                                        <tr>
                                                            <td>Transmissão <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ formatCambio(vecambio) }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ autoscar_transmission }
                                                                    name="autoscar_transmission"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(autoscar_list_transmissao)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ autoscar_list_transmissao[key] }>{ autoscar_list_transmissao[key].toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>       
                                                        <tr>
                                                            <td>Opcionais</td>
                                                            <td>
                                                                {
                                                                    Object.keys(opcionais)
                                                                    .map(key => {
                                                                        return (
                                                                            <span key={key}>{ opcionais[key].descricao.toUpperCase() }<br/></span>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    Object.keys(autoscar_opcionais)
                                                                    .map(key => {
                                                                        return (
                                                                            // <span key={key}>{ autoscar_opcionais[key].toUpperCase() }<br/></span>
                                                                            <p className="checkbox-item" key={key}>
                                                                                <label className="checkbox-label">
                                                                                    <input type="checkbox" name={key} defaultChecked={autoscar_opcionais[key].checked ? autoscar_opcionais[key].checked : false} onChange={this.handleChecked} /> &nbsp;&nbsp;{autoscar_opcionais[key].descricao.toUpperCase()}
                                                                                </label>
                                                                            </p>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        </tr>   

                                                        <tr>
                                                            <td>Imagens <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>
                                                                {      
                                                                    imagens !== undefined && imagens.length > 0 ? 
                                                                    ( 
                                                                        Object.keys(imagens)
                                                                        .map(key => {
                                                                            
                                                                            return (
                                                                                imagens[key].fileUrl !== null && imagens[key].fileThumb !== null ? 
                                                                                <div key={key}><img src={ imagens[key].fileThumb } alt={ imagens[key].fileName }  style={{ height: '80px', marginBottom: '10px'}} /><br/></div>
                                                                                : null
                                                                            )
                                                                            
                                                                        })
                                                                    )
                                                                    : null
                                                                }
                                                            </td>
                                                            <td>
                                                                {      
                                                                    autoscar_imagens !== undefined && autoscar_imagens.length > 0 ? 
                                                                    ( 
                                                                        Object.keys(autoscar_imagens)
                                                                        .map(key => {
                                                                            
                                                                            return (
                                                                                autoscar_imagens[key].value !== null ? 
                                                                                <div key={key}><img src={ autoscar_imagens[key].value } style={{ height: '80px', marginBottom: '10px'}}  /><br/></div>
                                                                                : null
                                                                            )
                                                                            
                                                                        })
                                                                    )
                                                                    : null
                                                                }
                                                            </td>
                                                        </tr> 
                                                        
                                                    </tbody>
                                                </table> 
                                                <p><span className="alteracao-app">*</span> Dados devem ser alterados no aplicativo <br/><span className="preenchimento-obrigatorio">*</span> Campo obrigatório</p>
                                                <div style={{ display: estaGravando ? " block " : " none "  }}><img src="/img/aguarde.gif" alt="carregando"  style={{ height: '80px', marginBottom: '10px'}} /></div>
                                                <div style={{ display: errosSubmit !== null ? " block " : " none "  }} className="alert alert-danger" role="alert">{errosSubmit}</div>
                                                <button onClick={this.goBack} disabled={estaGravando} className="btn btn-warning" >Voltar</button>&nbsp;&nbsp;<button type="submit" disabled={estaGravando} className="btn btn-primary" >Enviar</button>

                                                </ValidatorForm>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )
                        }
                    </div>
                </div>
            </div>

        )
    }

}

function formatMoney(value) {

    var places = 2;
    var symbol = "R$ ";
    var thousand = ".";
    var decimal = ",";

	places = !isNaN(places = Math.abs(places)) ? places : 2;
	symbol = symbol !== undefined ? symbol : "$";
	thousand = thousand || ",";
	decimal = decimal || ".";
	var number = value, 
	    negative = number < 0 ? "-" : "",
	    i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
	    j = (j = i.length) > 3 ? j % 3 : 0;
	return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
};

function formatCambio(value){
    if(value === 'A'){
        return "AUTOMÁTICO";
    }else{
        return "MANUAL";
    }
}

function compareOpcionais(a, b) {
    const genreA = a.descricao.toUpperCase();
    const genreB = b.descricao.toUpperCase();
    
    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
}

function compareMarca(a, b) {
    const genreA = a.name.toUpperCase();
    const genreB = b.name.toUpperCase();
    
    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
}

function compareVersao(a, b) {
    const genreA = a.version.toUpperCase();
    const genreB = b.version.toUpperCase();
    
    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
}

function exibirErroSubmit(errors){
    window.scrollTo(0, 0);
    alert("Campos obrigatórios não preenchidos!");
}

function opcionais_true(opcional){
    return opcional.checked === true;
}

export default VeiculosIntegracaoAutoscarIncluirCarro