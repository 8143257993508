import { base_url, api_key } from './../config/config_api';
import { firestore } from './../firebase-config';

export const publicarVendido = async (vendido, integrador) => {

  return fetch(base_url+"/api/integracao/"+integrador+"/incluir-vendido", { 
    method: "POST", 
    headers: { 
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }, 
    body: JSON.stringify({
        "api_key": api_key,
        "vendido": vendido,
    })
  })
  .then(response => {
    return response.json()
    .then(data => {

      if(data.status === 'success'){

        var integracao = {};

        if(data.data.valida_feed !== undefined){
            integracao.valida_feed = data.data.valida_feed;
        }
        if(data.data.id_feed !== undefined){
            integracao.id_feed = data.data.id_feed;
        }
        if(data.data.url_feed !== undefined){
            integracao.url_feed = data.data.url_feed;
        }

        if(data.data.valida_stories !== undefined){
            integracao.valida_stories = data.data.valida_stories;
        }
        if(data.data.id_stories !== undefined){
            integracao.id_stories = data.data.id_stories;
        }
        if(data.data.url_stories !== undefined){
            integracao.url_stories = data.data.url_stories;
        }

        if(data.data.fb_valida_feed !== undefined){
          integracao.fb_valida_feed = data.data.fb_valida_feed;
        }
        if(data.data.fb_id_feed !== undefined){
            integracao.fb_id_feed = data.data.fb_id_feed;
        }
        if(data.data.fb_url_feed !== undefined){
            integracao.fb_url_feed = data.data.fb_url_feed;
        }  

        return firestore.collection('revenda').doc(vendido.revenda).collection('vendidos').doc(vendido.id).set(integracao, { merge: true })
        .then(res => {   
          return {
            status: "success",
            data: data.data
          }; 
        })
        .catch(err => { 
          return {
            status: "error",
            data: err
          }; 
        });
         
      }else{
        return {
          status: "error",
          data: data.data
        };
      }
    })
    .catch(error => {
      return {
        status: "error",
        data: error
      };
    });
  })
  .catch(error => {
    return {
      status: "error",
      data: error
    };
  });

}