import React, { Component } from 'react';
import {firestore} from './../../../firebase-config';
import Cabecalho from './../../../componentes/Cabecalho';
import { Redirect } from 'react-router-dom';

class RevendaImagemDestaqueListar extends Component {

    constructor (props){
        super(props);

        this.state = {
            idRevenda: props.match.params.idRevenda,
            imagens: [],
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }
        this.mounted = false;

    }

    componentDidMount(){

        this.mounted = true;
        
        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection("revenda").doc(this.state.idRevenda).collection("imagem_destaque").orderBy("created_at", "desc")
        .onSnapshot(querySnapshot => {
            var items = [];
            querySnapshot.forEach(doc => {
                var document = doc.data();
                document.id  = doc.id;
                items.push(document);
            });
            if(this.mounted) {
                this.setState({
                    imagens: items
                });
            }
        });

    }

    componentWillUnmount(){
        this.mounted = false;
    }

    excluirImagem(idImagem){

        if(window.confirm('Deseja excluir esta imagem permanentemente?')){
            // firestore.settings({ timestampsInSnapshots: true });
            firestore.collection("revenda").doc(this.state.idRevenda).collection("imagem_destaque").doc(idImagem).delete()
            .then(res => { 
                alert("Imagem removida com sucesso!");
            })
            .catch(err => { 
                alert("Falha ao remover veículo!");
            });
        }

    }

    render(){
        const { 
            idRevenda, 
            imagens, 
        } = this.state;

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin'){
            return <Redirect to='/acesso-negado' />
        }

        return (
            <div>
                <Cabecalho />
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Imagens Destaque</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <a href={ "/revenda/"+idRevenda+"/imagem-destaque/incluir"} className="btn btn-success" >Incluir imagem</a><br/><br/>
                        </div>
                        <div className="col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    Lista de Imagens
                                </div>
                                <div className="panel-body">
                                    <table width="100%" className="table table-striped table-bordered table-hover" id="dtOpcionais">
                                        <thead>
                                            <tr>
                                                <th>Descrição</th>
                                                <th>Manutenção</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {      
                                                imagens !== undefined && imagens.length > 0 ? 
                                                ( 
                                                    Object.keys(imagens)
                                                    .map(key => {
                                                        
                                                        return (
                                                            imagens[key].imagem_url !== null && imagens[key].imagem_url !== '' ? 
                                                                <tr key={key}>
                                                                    <td><a target="_blank" title={ imagens[key].imagem_name } rel="noopener noreferrer" href={ imagens[key].imagem_url }><img src={ imagens[key].imagem_url } alt={ imagens[key].imagem_name } style={{ height: "80px" }} /></a></td>
                                                                    <td><a onClick={() => this.excluirImagem(imagens[key].id)}>Excluir</a></td>
                                                                </tr>
                                                            : null
                                                        )
                                                        
                                                    })
                                                )
                                                : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }

}

export default RevendaImagemDestaqueListar