import React, { Component } from 'react';
import Cabecalho from '../componentes/Cabecalho';
import CabecalhoPainel from '../componentes/CabecalhoPainel';

class AcessoNegado extends Component {

    constructor (props){
        super(props);
        this.state = {
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }
    }

    render(){
        return(
            <div>
                {
                    this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ? <Cabecalho /> :  <CabecalhoPainel />
                } 
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <br/><br/>
                            <div className="alert alert-danger" role="alert">
                                Seu usuário não tem acesso autorizado na página solicitada!
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        )
    }
}

export default AcessoNegado