import React, { Component } from 'react';
import { firestore } from '../../../firebase-config';
import Cabecalho from '../../../componentes/Cabecalho';
import CabecalhoPainel from '../../../componentes/CabecalhoPainel';
import { base_url, api_key } from '../../../config/config_api';
import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import MenuItem from "@material-ui/core/MenuItem";
import { Redirect } from 'react-router-dom';

class VeiculosIntegracaoMobiautoIncluirCarro extends Component {

    constructor (props){
        super(props);

        this.state = {
            estaGravando: false,
            carregandoDados: true,
            returnPagina: false,
            errosSubmit: null,
            errorLoading: false,
            errorsLoading: null,
            idRevenda: props.match.params.idRevenda,
            idVeiculo: props.match.params.idVeiculo,
            nome_revenda: "",
            cidade_revenda: "",
            uf_revenda: "",
            opcionais: [],
            imagens: [],
            veano_fabricacao: "",
            vecambio: "",
            vecor: "",
            vefipe_ano_modelo: "",
            vefipe_combustivel: "",
            vefipe_marca: "",
            vefipe_name: "",
            veiplaca: "",
            veobservacao: "",
            veportas: "",
            vequilometragem: "",
            vetipoveiculo: "",
            vevalor: "",
            veremovido: 0,
            veveiculonovo: 0,
            veblindado: 0,
            mobiauto_oempaid: 0,
            mobiauto_resale: 0,
            mobiauto_marca: "",
            mobiauto_modelo: "",
            mobiauto_ano_fabricacao: "",
            mobiauto_ano_modelo: "",
            mobiauto_versao: "",            
            mobiauto_plate: "",
            mobiauto_mileage: "",
            mobiauto_price: "",
            mobiauto_description: "",
            mobiauto_fuelType: "",
            mobiauto_color: "",
            mobiauto_transmission: "",
            mobiauto_ports: "",
            mobiauto_imagens: [],
            mobiauto_opcionais: [],
            mobiauto_list_marca: [],
            mobiauto_list_modelo: [],
            mobiauto_list_versao: [],
            mobiauto_list_combustivel: [],
            mobiauto_list_cor: [],
            mobiauto_list_transmissao: [],
            mobiauto_veiculo_novo: false,
            mobiauto_blindado: false,
            mobiauto_plano: "",
            mobiauto_list_plano: [],
            mobiauto_observacoes: [],
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }
        this.mounted          = false;
        this.goBack           = this.goBack.bind(this);
        this.handleChange     = this.handleChange.bind(this);
        this.handleMarca      = this.handleMarca.bind(this);
        this.handleModelo     = this.handleModelo.bind(this);
        this.handleChecked    = this.handleChecked.bind(this);  
        this.handleCheckedObs = this.handleCheckedObs.bind(this);              
        this.handleSubmit     = this.handleSubmit.bind(this);
        
    }

    goBack(){
        this.props.history.goBack();
    }

    componentDidMount(){

        this.mounted = true;

        firestore.collection("revenda").doc(this.state.idRevenda)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    nome_revenda: doc.data().penome_fantasia,
                    cidade_revenda: doc.data().pecidade,
                    uf_revenda: doc.data().peuf,
                });
            }
        }).catch(function(error) {
        });

        firestore.collection("revenda").doc(this.state.idRevenda).collection("veiculos").doc(this.state.idVeiculo)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    opcionais: doc.data().opcionais.sort(compareOpcionais),
                    imagens: doc.data().imagens,
                    veano_fabricacao: doc.data().veano_fabricacao,
                    vecambio: doc.data().vecambio,
                    vecor: doc.data().vecor,
                    vefipe_ano_modelo: doc.data().vefipe_ano_modelo,
                    vefipe_combustivel: doc.data().vefipe_combustivel,
                    vefipe_marca: doc.data().vefipe_marca,
                    vefipe_name: doc.data().vefipe_name,
                    veiplaca: doc.data().veiplaca,
                    veobservacao: doc.data().veobservacao,
                    veportas: doc.data().veportas,
                    vequilometragem: doc.data().vequilometragem,
                    vetipoveiculo: doc.data().vetipoveiculo,
                    vevalor: doc.data().vevalor,
                    veremovido: doc.data().veremovido,
                    veveiculonovo: doc.data().veveiculonovo,
                    veblindado: doc.data().veblindado,
                });
            }
        }).catch(function(error) {
        });

        fetch(base_url+"/api/integracao/mobiauto/incluir", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "api_key": api_key,
                "id_veiculo": this.state.idVeiculo
            })
        })
        .then(response => {

            response.json()
            .then(data => {

                if(data.status === 'success'){
                    this.setState({
                        mobiauto_marca: data.data.veiculoData.veiculo_marca,
                        mobiauto_modelo: data.data.veiculoData.veiculo_modelo,
                        mobiauto_versao: data.data.veiculoData.veiculo_versao,
                        mobiauto_ano_fabricacao: data.data.veiculoData.veiculo_ano_fabricacao,
                        mobiauto_ano_modelo: data.data.veiculoData.veiculo_ano_modelo,
                        mobiauto_plate: data.data.veiculoData.plate.toUpperCase(),
                        mobiauto_mileage: data.data.veiculoData.km,
                        mobiauto_price: data.data.veiculoData.price,
                        mobiauto_description: data.data.veiculoData.description.toUpperCase(),
                        mobiauto_fuelType: data.data.veiculoData.veiculo_combustivel,
                        mobiauto_color: data.data.veiculoData.veiculo_cor,
                        mobiauto_transmission: data.data.veiculoData.veiculo_transmissao,
                        mobiauto_ports: data.data.veiculoData.ports,
                        mobiauto_imagens: data.data.veiculoData.veiculo_images,
                        mobiauto_opcionais: data.data.retOpcionais.sort(compareMarca),
                        mobiauto_list_marca: data.data.retMarca.sort(compareMarca),
                        mobiauto_list_modelo: data.data.retModelo.sort(compareMarca),
                        mobiauto_list_versao: data.data.retVersaoFilter.sort(compareMarca),
                        mobiauto_list_combustivel: data.data.retTipoCombustivel.sort(compareMarca),
                        mobiauto_list_cor: data.data.retCores.sort(compareMarca),
                        mobiauto_list_transmissao: data.data.retTipoTransmissao.sort(compareMarca),
                        mobiauto_veiculo_novo: data.data.veiculoData.deal0km,
                        mobiauto_blindado: data.data.veiculoData.armored,
                        mobiauto_list_plano: data.data.retPlanos.sort(compareMarca),
                        mobiauto_observacoes: data.data.retObservacoes.sort(compareMarca),
                        mobiauto_oempaid: data.data.veiculoData.oemPaid,
                        mobiauto_mobiauto_resale: data.data.veiculoData.resale,
                    });
                }else{
                    this.setState({
                        errorLoading: true,
                        errorsLoading: data.data,
                    });
                }
            });

            this.setState({
                carregandoDados: false,
            });

        })
        .catch(error => {
            console.error("Erro get teste", error);
            this.setState({
                carregandoDados: false,
            });
        });
        
    }

    componentWillUnmount(){
        this.mounted = false;
    }

    handleMarca(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value,
            mobiauto_list_modelo: [],
            mobiauto_list_versao: [],
        });

        return fetch(base_url+"/api/integracao/mobiauto/consultar-modelo", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "api_key": api_key,
                "marca": value,
                "id_veiculo": this.state.idVeiculo
            })
        })
        .then(response => {

            return response.json()
            .then(data => {
                if(data.status === 'success'){
                    this.setState({
                        mobiauto_list_modelo: data.data.sort(compareMarca),
                        mobiauto_modelo: ""
                    });
                }
            });

        })
        .catch(error => {
            console.error("Erro get teste", error);
        });

    }

    handleModelo(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value,
            mobiauto_list_versao: [],
        });

        return fetch(base_url+"/api/integracao/mobiauto/consultar-versao", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "api_key": api_key,
                "marca": this.state.mobiauto_marca,
                "modelo": value,
                "id_veiculo": this.state.idVeiculo
            })
        })
        .then(response => {

            return response.json()
            .then(data => {
                if(data.status === 'success'){
                    this.setState({
                        mobiauto_list_versao: data.data.sort(compareMarca),
                        mobiauto_versao: ""
                    });
                }
            });

        })
        .catch(error => {
            console.error("Erro get teste", error);
        });

    }

    handleChange(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        });
    }

    handleChecked(event) {

        const item = event.target.name; 
        var oldOpcionais = this.state.mobiauto_opcionais;
        const oldChecked = oldOpcionais[item].checked;
        oldOpcionais[item].checked = !oldChecked;
        this.setState({
            mobiauto_opcionais: oldOpcionais
        });

    }

    handleCheckedObs(event) {

        const item = event.target.name; 
        var oldObs = this.state.mobiauto_observacoes;
        const oldChecked = oldObs[item].checked;
        oldObs[item].checked = !oldChecked;
        this.setState({
            mobiauto_observacoes: oldObs
        });

    }    

    handleSubmit(event) {

        this.setState({estaGravando: true, errosSubmit: null});

        var opcionais_selecionados = this.state.mobiauto_opcionais.filter(opcionais_true);
        var opcionais_new = [];
        opcionais_selecionados.forEach(opcional => {
            opcionais_new.push(opcional.id);
        });

        var observacoes_selecionados = this.state.mobiauto_observacoes.filter(opcionais_true);
        var observacoes_new = [];
        observacoes_selecionados.forEach(obs => {
            observacoes_new.push(obs.id);
        });

        var user = JSON.parse(window.localStorage.getItem("usuarioLogado"));
      
        var veiculo_post = {
            armored: parseInt(this.state.mobiauto_blindado),
            colorId: this.state.mobiauto_color,
            conditionalsIds: observacoes_new,
            deal0km: parseInt(this.state.mobiauto_veiculo_novo),
            description: this.state.mobiauto_description,
            doors: this.state.mobiauto_ports,
            featureIds: opcionais_new,
            fuelId: this.state.mobiauto_fuelType,
            images: this.state.mobiauto_imagens,
            km: this.state.mobiauto_mileage,
            modelYear: this.state.mobiauto_ano_modelo,
            planId: this.state.mobiauto_plano,
            plate: this.state.mobiauto_plate,
            price: this.state.mobiauto_price,
            productionYear: this.state.mobiauto_ano_fabricacao,
            transmissionId: this.state.mobiauto_transmission,
            trimId: this.state.mobiauto_versao,
            oemPaid: parseInt(this.state.mobiauto_oempaid),
            resale: parseInt(this.state.mobiauto_resale),
        }

        fetch(base_url+"/api/integracao/mobiauto/enviar", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "api_key": api_key,
                "veiculo_post": veiculo_post,
                "usuario": user.id,
                "id_veiculo": this.state.idVeiculo
            })
        })
        .then(response => {

            response.json()
            .then(data => {

                if(data.status === 'success'){

                    var integracao = data.data;
                    integracao.status = true;
                    integracao.id_anuncio = data.data.id_anuncio_mobiauto;

                    firestore.collection('revenda').doc(this.state.idRevenda).collection('veiculos').doc(this.state.idVeiculo).collection('integracoes').doc("mobiauto").set(integracao, { merge: true })
                    .then(res => { 
                        this.setState({returnPagina: true}); 
                    })
                    .catch(err => { 
                        this.setState({returnPagina: true});      
                    });
                   
                }else{
                    this.setState({errosSubmit: data.data});
                }
            });
            this.setState({estaGravando: false});  

        })
        .catch(error => {
            this.setState({estaGravando: false});  
            console.error("Erro get teste", error);
        });

    }

    render(){
        const { 
            errosSubmit,
            estaGravando,
            idRevenda,
            idVeiculo,
            nome_revenda,
            opcionais,
            imagens,
            veano_fabricacao,
            vecambio,
            vecor,
            vefipe_ano_modelo,
            vefipe_combustivel,
            vefipe_marca,
            vefipe_name,
            veiplaca,
            veobservacao,
            veportas,
            vequilometragem,
            vevalor,
            veveiculonovo,
            veblindado,
            mobiauto_marca,
            mobiauto_modelo,
            mobiauto_ano_fabricacao,
            mobiauto_ano_modelo,
            mobiauto_versao,
            mobiauto_plate,
            mobiauto_mileage,
            mobiauto_price,
            mobiauto_description,
            mobiauto_fuelType,
            mobiauto_color,
            mobiauto_ports,
            mobiauto_transmission,
            mobiauto_imagens,
            mobiauto_opcionais,
            mobiauto_list_marca,
            mobiauto_list_modelo,
            mobiauto_list_versao,
            mobiauto_list_combustivel,
            mobiauto_list_cor,
            mobiauto_list_transmissao,
            mobiauto_veiculo_novo,
            mobiauto_blindado,
            mobiauto_plano,
            mobiauto_list_plano,
            mobiauto_observacoes,
            mobiauto_oempaid,
            mobiauto_resale,
            carregandoDados,
            errorLoading,
            errorsLoading
        } = this.state;

        if(this.state.returnPagina){
            return <Redirect to={ "/revenda/"+idRevenda+"/veiculos/"+idVeiculo+"/visualizar" } />;
        }

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin' && this.state.idRevenda !== this.state.usuarioLogado.revenda){
            return <Redirect to='/acesso-negado' />
        }

        return (
            <div>
                {
                    this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ? <Cabecalho /> :  <CabecalhoPainel />
                } 
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Incluir carro Mobiauto<br/><small>Revenda: { nome_revenda }</small></h1>
                        </div>
                    </div>
                    <div className="row">
                        {
                                    
                            carregandoDados ? (<div className="col-lg-12"><img src="/img/aguarde.gif" alt="carregando"  style={{ height: '80px', marginBottom: '10px'}} /></div>) : (

                                errorLoading ? (<div style={{ display: errorsLoading !== null ? " block " : " none "  }} className="alert alert-danger" role="alert">{errorsLoading}</div>) : (
                        
                                    <div className="col-lg-12">
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                Integração Mobiauto - Incluir veículo
                                            </div>
                                            <div className="panel-body">
                                            
                                            <ValidatorForm
                                                    ref="form"
                                                    onSubmit={this.handleSubmit}
                                                    onError={ errors => exibirErroSubmit(errors) }
                                                >
                                                <table className='table table-striped'>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ minWidth: '130px', width: '10%'}}></th>
                                                            <th style={{ width: '45%'}}>Dados Intermédio</th>
                                                            <th style={{ width: '45%'}}>Dados Mobiauto</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Marca <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_marca.toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ mobiauto_marca }
                                                                    name="mobiauto_marca"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleMarca}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(mobiauto_list_marca)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ mobiauto_list_marca[key].id }>{ mobiauto_list_marca[key].name.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Modelo <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_name.split(' ', 1)[0].toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ mobiauto_modelo }
                                                                    name="mobiauto_modelo"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleModelo}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(mobiauto_list_modelo)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ mobiauto_list_modelo[key].id }>{ mobiauto_list_modelo[key].name.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>  
                                                        <tr>
                                                            <td>Ano fabricação <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ veano_fabricacao }</td>
                                                            <td>{ mobiauto_ano_fabricacao }</td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Ano modelo <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_ano_modelo }</td>
                                                            <td>{ mobiauto_ano_modelo }</td>
                                                        </tr>    
                                                        <tr>
                                                            <td>Versão <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_name.substring(vefipe_name.split(' ', 1)[0].length, vefipe_name.length).trim().toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ mobiauto_versao }
                                                                    name="mobiauto_versao"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(mobiauto_list_versao)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ mobiauto_list_versao[key].id }>{ mobiauto_list_versao[key].name.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>  
                                                        <tr>
                                                            <td>Placa</td>
                                                            <td>{ veiplaca.toUpperCase() }</td>
                                                            <td>
                                                                <TextValidator
                                                                    onChange={this.handleChange}
                                                                    name="mobiauto_plate"
                                                                    value={mobiauto_plate.toUpperCase()}
                                                                    className="form-ui-bootstrap form-integracao"
                                                                />
                                                            </td>
                                                        </tr>    
                                                        <tr>
                                                            <td>Tipo veículo <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ veveiculonovo == 1 ? '0 Km' : "Usado" }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ mobiauto_veiculo_novo }
                                                                    name="mobiauto_veiculo_novo"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value="0">Usado</MenuItem>
                                                                        <MenuItem value="1">0 Km</MenuItem>
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Km <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vequilometragem }</td>
                                                            <td>{ mobiauto_mileage }</td>
                                                        </tr>                                                        
                                                        <tr>
                                                            <td>Plano anúncio <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td></td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ mobiauto_plano }
                                                                    name="mobiauto_plano"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(mobiauto_list_plano)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ mobiauto_list_plano[key].dealPlanId }>{ mobiauto_list_plano[key].name.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Blindado <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ veblindado == 1 ? 'Sim' : "Não" }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ mobiauto_blindado }
                                                                    name="mobiauto_blindado"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value="0">Não</MenuItem>
                                                                        <MenuItem value="1">Sim</MenuItem>
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>   
                                                        {/* <tr>
                                                            <td>OEMPaid <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td></td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ mobiauto_oempaid }
                                                                    name="mobiauto_oempaid"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value="0">Não</MenuItem>
                                                                        <MenuItem value="1">Sim</MenuItem>
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Resale <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td></td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ mobiauto_resale }
                                                                    name="mobiauto_resale"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value="0">Não</MenuItem>
                                                                        <MenuItem value="1">Sim</MenuItem>
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>                                                        */}
                                                        <tr>
                                                            <td>Preço <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ formatMoney(vevalor) }</td>
                                                            <td>{ formatMoney(mobiauto_price) }</td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Descrição anúncio</td>
                                                            <td>{ veobservacao.toUpperCase() }</td>
                                                            <td><textarea name="mobiauto_description" className="form-ui-bootstrap" rows="4" value={mobiauto_description} onChange={this.handleChange} /></td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Combustível <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_combustivel.toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ mobiauto_fuelType }
                                                                    name="mobiauto_fuelType"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(mobiauto_list_combustivel)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ mobiauto_list_combustivel[key].id }>{ mobiauto_list_combustivel[key].name.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>     
                                                        <tr>
                                                            <td>Cor <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vecor.toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ mobiauto_color }
                                                                    name="mobiauto_color"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(mobiauto_list_cor)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ mobiauto_list_cor[key].id }>{ mobiauto_list_cor[key].name.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>       
                                                        <tr>
                                                            <td>Portas <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ veportas }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ mobiauto_ports }
                                                                    name="mobiauto_ports"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        <MenuItem value="0">0</MenuItem>
                                                                        <MenuItem value="1">1</MenuItem>
                                                                        <MenuItem value="2">2</MenuItem>
                                                                        <MenuItem value="4">4</MenuItem>
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Transmissão <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ formatCambio(vecambio) }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ mobiauto_transmission }
                                                                    name="mobiauto_transmission"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(mobiauto_list_transmissao)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ mobiauto_list_transmissao[key].id }>{ mobiauto_list_transmissao[key].name.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>       
                                                        <tr>
                                                            <td>Opcionais</td>
                                                            <td>
                                                                {
                                                                    Object.keys(opcionais)
                                                                    .map(key => {
                                                                        return (
                                                                            <span key={key}>{ opcionais[key].descricao.toUpperCase() }<br/></span>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    Object.keys(mobiauto_opcionais)
                                                                    .map(key => {
                                                                        return (
                                                                            // <span key={key}>{ mobiauto_opcionais[key].toUpperCase() }<br/></span>
                                                                            <p className="checkbox-item" key={key}>
                                                                                <label className="checkbox-label">
                                                                                    <input type="checkbox" name={key} defaultChecked={mobiauto_opcionais[key].checked ? mobiauto_opcionais[key].checked : false} onChange={this.handleChecked} /> &nbsp;&nbsp;{mobiauto_opcionais[key].name.toUpperCase()}
                                                                                </label>
                                                                            </p>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Observações</td>
                                                            <td></td>
                                                            <td>
                                                                {
                                                                    Object.keys(mobiauto_observacoes)
                                                                    .map(key => {
                                                                        return (
                                                                            <p className="checkbox-item" key={key}>
                                                                                <label className="checkbox-label">
                                                                                    <input type="checkbox" name={key} defaultChecked={mobiauto_observacoes[key].checked ? mobiauto_observacoes[key].checked : false} onChange={this.handleCheckedObs} /> &nbsp;&nbsp;{mobiauto_observacoes[key].name.toUpperCase()}
                                                                                </label>
                                                                            </p>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Imagens <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>
                                                                {      
                                                                    imagens !== undefined && imagens.length > 0 ? 
                                                                    ( 
                                                                        Object.keys(imagens)
                                                                        .map(key => {
                                                                            return (
                                                                                imagens[key].fileUrl !== null && imagens[key].fileThumb !== null ? 
                                                                                <div key={key}><img src={ imagens[key].fileThumb } alt={ imagens[key].fileName }  style={{ height: '80px', marginBottom: '10px'}} /><br/></div>
                                                                                : null
                                                                            )
                                                                        })
                                                                    )
                                                                    : null
                                                                }
                                                            </td>
                                                            <td>
                                                                {      
                                                                    mobiauto_imagens !== undefined && mobiauto_imagens.length > 0 ? 
                                                                    ( 
                                                                        Object.keys(mobiauto_imagens)
                                                                        .map(key => {
                                                                            return (
                                                                                mobiauto_imagens[key].url !== null ? 
                                                                                <div key={key}><img src={ mobiauto_imagens[key].url } style={{ height: '80px', marginBottom: '10px'}}  /><br/></div>
                                                                                : null
                                                                            )
                                                                        })
                                                                    )
                                                                    : null
                                                                }
                                                            </td>
                                                        </tr> 
                                                        
                                                    </tbody>
                                                </table> 
                                                <p><span className="alteracao-app">*</span> Dados devem ser alterados no aplicativo <br/><span className="preenchimento-obrigatorio">*</span> Campo obrigatório</p>
                                                <div style={{ display: estaGravando ? " block " : " none "  }}><img src="/img/aguarde.gif" alt="carregando"  style={{ height: '80px', marginBottom: '10px'}} /></div>
                                                <div style={{ display: errosSubmit !== null ? " block " : " none "  }} className="alert alert-danger" role="alert">{errosSubmit}</div>
                                                <button onClick={this.goBack} disabled={estaGravando} className="btn btn-warning" >Voltar</button>&nbsp;&nbsp;<button type="submit" disabled={estaGravando} className="btn btn-primary" >Enviar</button>
                                                </ValidatorForm>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )
                        }
                    </div>
                </div>
            </div>

        )
    }

}

function formatMoney(value) {

    var places = 2;
    var symbol = "R$ ";
    var thousand = ".";
    var decimal = ",";

	places = !isNaN(places = Math.abs(places)) ? places : 2;
	symbol = symbol !== undefined ? symbol : "$";
	thousand = thousand || ",";
	decimal = decimal || ".";
	var number = value, 
	    negative = number < 0 ? "-" : "",
	    i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
	    j = (j = i.length) > 3 ? j % 3 : 0;
	return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
};

function formatCambio(value){
    if(value === 'A'){
        return "AUTOMÁTICO";
    }else{
        return "MANUAL";
    }
}

function compareOpcionais(a, b) {
    const genreA = a.descricao.toUpperCase();
    const genreB = b.descricao.toUpperCase();
    
    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
}

function compareMarca(a, b) {
    const genreA = a.name.toUpperCase();
    const genreB = b.name.toUpperCase();
    
    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
}

function exibirErroSubmit(errors){
    window.scrollTo(0, 0);
    alert("Campos obrigatórios não preenchidos!");
}

function opcionais_true(opcional){
    return opcional.checked === true;
}

export default VeiculosIntegracaoMobiautoIncluirCarro