import React, { Component } from 'react';
import { firestore } from './../../firebase-config';
import Cabecalho from './../../componentes/Cabecalho';
import CabecalhoPainel from '../../componentes/CabecalhoPainel';
import { Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { ValidatorForm } from 'react-material-ui-form-validator';
import * as firebase from "firebase/app";
import './../custom_form.css';
import { base_url, api_key } from '../../config/config_api';

class RevendaBannersIncluir extends Component {

    constructor (props){
        super(props);

        this.state = {
            returnPagina: false,
            estaGravando: false,
            isUploading: false,
            validImagem: true,
            validTexto: true,
            ig_stories: true,
            ig_feed: true,
            fb_feed: true,
            idRevenda: props.match.params.idRevenda,
            imagem: [],
            peblocotextobanners: "",
            nome_revenda: "",
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
            errosUpload: null
        }
        this.mounted = false;
        this.handleChange = this.handleChange.bind(this);
        this.handleFile   = this.handleFile.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){

        this.mounted = true;

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection("revenda").doc(this.state.idRevenda)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    nome_revenda: doc.data().penome_fantasia,
                    peblocotextobanners: doc.data().peblocotextobanners,
                });
            }
        }).catch(function(error) {
        });

    }

    componentWillMount() {
    }

    handleChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        });
    }

    handleFile(event) {

        this.setState({
            isUploading: true,
            validImagem: true,
            errosUpload: null,
        });

        const target   = event.target;
        const fileList = target.files;

        var count = 0;
        Array.from(fileList).forEach(function (file) {

            const fileType = file.type.split('/')[0]; // 'image' ou 'video'
            let newImagens      = this.state.imagem;
            let fileName        = "";
            let fileUrlImage    = "";
            let fileThumbImage  = "";

            if(fileType === 'image'){

                const upload_preset = "default";

                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = () => {

                    let body_post = new URLSearchParams({
                        "upload_preset": upload_preset,
                        "file": reader.result
                    })

                    fetch("https://api.cloudinary.com/v1_1/intermedio/image/upload", { 
                        method: "POST", 
                        headers: { 
                            'Content-Type': 'application/x-www-form-urlencoded',
                        }, 
                        body: body_post
                    })
                    .then(response => {

                        response.json()
                        .then(data => {

                            fileName = data.asset_id + "." + data.format;
                            if(data.eager.length === 2){
                                fileUrlImage = data.eager[0].secure_url;
                                fileThumbImage = data.eager[1].secure_url;
                            }

                            newImagens.push({ fileName: fileName, fileUrl: fileUrlImage, fileThumb: fileThumbImage, imagem: fileName, public_id: data.public_id, asset_id: data.asset_id, video: false });

                            this.setState({imagem: newImagens});
                            if(count === fileList.length){
                                this.setState({isUploading: false});
                            }

                        })
                        .catch(error => {
                            console.log("Upload imagem error 1", error);
                            this.setState({isUploading: false});
                        });        

                    })
                    .catch(error => {
                        console.log("Upload imagem error 2", error);
                        this.setState({isUploading: false});
                    });

                }

            }
            if(fileType === 'video'){

                let formData = new FormData();
                formData.append('file', file);
                formData.append('api_key', api_key);
                formData.append('id_revenda', this.state.idRevenda);

                const uploadUrl = base_url+"/api/file/upload-video"; // Atualize com sua URL do backend

                fetch(uploadUrl, {
                    method: 'POST',
                    body: formData,
                })
                .then(response => {

                    response.json()
                    .then(data => {

                        if(data.status === 'success'){

                            newImagens.push({ 
                                fileName: data.data.fileName, 
                                fileUrl: data.data.fileUrlImage, 
                                fileThumb: data.data.fileThumbImage, 
                                imagem: data.data.fileName, 
                                public_id: data.data.public_id, 
                                asset_id: data.data.asset_id, 
                                video: data.data.video 
                            });

                            this.setState({imagem: newImagens});
                            if(count === fileList.length){
                                this.setState({isUploading: false});
                            }

                        }else{
                            this.setState({
                                errosUpload: this.state.errosUpload === null ? data.data.toString() : this.state.errosUpload + data.data.toString(),
                                isUploading: false, 
                            });
                        }

                    })
                    .catch(error => {
                        console.log("Upload vídeo error 1", error);
                        this.setState({isUploading: false});
                    }); 
                })
                .catch(error => {
                    console.log('Upload vídeo error 2', error);
                    this.setState({isUploading: false});
                });
            }

            count++;

        }.bind(this));

    }

    handleSubmit(event) {

        this.setState({
            validImagem: true,
            validTexto: true,
        });

        var user = JSON.parse(window.localStorage.getItem("usuarioLogado"));

        var testeValid = 0;

        if(this.state.peblocotextobanners.trim() === ""){
            this.setState({
                validTexto: false
            });
            testeValid++;
        }

        if(this.state.imagem.length === 0){
            this.setState({
                validImagem: false
            });
            testeValid++;
        }   

        if(testeValid === 0){

            this.setState({estaGravando: true});

            var banner = {
                imagem: this.state.imagem,
                peblocotextobanners: this.state.peblocotextobanners,
                revenda: this.state.idRevenda,
                nome_revenda: this.state.nome_revenda,
                ig_stories: this.state.ig_stories,
                ig_feed: this.state.ig_feed,
                fb_feed: this.state.fb_feed,
                valida_stories: false,
                valida_feed: false,
                created_id: user.id,
                created_at: firebase.firestore.FieldValue.serverTimestamp(),
            }       
            
            firestore.collection('revenda').doc(this.state.idRevenda).collection('banners').add(banner)
            .then(res => {             
                this.setState({returnPagina: true, estaGravando: false});
            })
            .catch(err => { 
                this.setState({returnPagina: true, estaGravando: false});     
            });

        }

        event.preventDefault();

    }

    render(){
        const { 
            estaGravando, 
            imagem,
            peblocotextobanners,
            nome_revenda,
            isUploading,
            validImagem,
            validTexto,
            ig_stories,
            ig_feed,
            fb_feed,
            errosUpload
        } = this.state;

        if(this.state.returnPagina){
            var link = "/revenda/"+this.state.idRevenda+"/banners/listar";
            return <Redirect to={link} />;
        }

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin' && this.state.idRevenda !== this.state.usuarioLogado.revenda){
            return <Redirect to='/acesso-negado' />
        }
        
        return (

            <div>
                {
                    this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ? <Cabecalho /> :  <CabecalhoPainel />
                } 
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Banners<br/><small>Revenda: { nome_revenda }</small></h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    Incluir banner
                                </div>
                                <div className="panel-body">

                                    <ValidatorForm
                                        ref="form"
                                        onSubmit={this.handleSubmit}
                                    >
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label>Imagem</label>
                                                            <div style={{ display: "flex" }}>
                                                                <input type="file" name="novaImagem" id="novaImagem" multiple onChange={this.handleFile} ></input>
                                                                { isUploading ? 
                                                                    <div className="progress progress-upload-images">
                                                                        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%" }}>Carregando imagem...</div>
                                                                    </div>
                                                                    : null
                                                                }
                                                                {
                                                                    validImagem ? null : <p className="error">Preenchimento obrigatório</p>
                                                                } 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label>Bloco texto banners</label>
                                                            <textarea name="peblocotextobanners" className="form-ui-bootstrap" rows="4" value={peblocotextobanners} onChange={this.handleChange} />
                                                            {
                                                                validTexto ? null : <p className="error">Preenchimento obrigatório</p>
                                                            } 
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <p className="checkbox-item">
                                                                <label className="checkbox-label">
                                                                    <input type="checkbox" name="ig_stories" defaultChecked={ig_stories} onChange={this.handleChange} /> 
                                                                    &nbsp;&nbsp;Publicar no stories do Instagram
                                                                </label>
                                                            </p>
                                                            <p className="checkbox-item">
                                                                <label className="checkbox-label">
                                                                    <input type="checkbox" name="ig_feed" defaultChecked={ig_feed} onChange={this.handleChange} /> 
                                                                    &nbsp;&nbsp;Publicar no feed do Instagram
                                                                </label>
                                                            </p>
                                                            <p className="checkbox-item">
                                                                <label className="checkbox-label">
                                                                    <input type="checkbox" name="fb_feed" defaultChecked={fb_feed} onChange={this.handleChange} /> 
                                                                    &nbsp;&nbsp;Publicar no feed do Facebook
                                                                </label>
                                                            </p>                                                    
                                                        </div>
                                                    </div>
                                                </div>                                                
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <div className="row">
                                                        {
                                                            imagem.map((i, index) => (
                                                                i.fileThumb !== undefined && i.fileThumb.length > 0 ? (
                                                                    <div className="col-lg-4" key={index}>
                                                                        <img src={i.video ? "/img/video.jpg" : i.fileThumb} alt="media thumbnail" className="w-100" />
                                                                    </div>
                                                                ) : null
                                                            ))                                                         
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <Button type="submit" variant="contained" color="primary" disabled={estaGravando || isUploading} >Gravar</Button>
                                            </div>
                                        </div>
                                        <div className="row" style={{ display: errosUpload !== null ? " block " : " none "  }} >
                                            <div className="col-lg-12">
                                                <div className="alert alert-danger" role="alert" style={{ marginTop: "1rem" }} >{errosUpload}</div>
                                            </div>
                                        </div>
                                    </ValidatorForm>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}

export default RevendaBannersIncluir
