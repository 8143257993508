import React, { Component } from 'react';
import {firestore} from './../../firebase-config';
import Cabecalho from './../../componentes/Cabecalho';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import 'moment-timezone';
import ReactTable from "react-table";
import { gerarStringMd5 } from '../../services/OtherServices';
import 'react-table/react-table.css';

class RevendaFFListar extends Component {

    constructor (props){
        super(props);

        this.state = {
            revendas: [],
            countRevendas: 0,
            usuario: JSON.parse(window.localStorage.getItem("usuarioLogado")),
            string_md5_solicitacoes: gerarStringMd5('incluir_revenda'),
            string_md5_site: gerarStringMd5('solicitacoes'),
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }
        this.mounted = false;

    }

    componentDidMount(){

        this.mounted = true;

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection("revenda").where("plano", "==", "basico").orderBy("penome_fantasia")
        .onSnapshot(querySnapshot => {
            var items = [];
            querySnapshot.forEach(doc => {
                var document = doc.data();
                document.id  = doc.id;
                items.push(document);
            });
            if(this.mounted) {
                this.setState({
                    revendas: items,
                    countRevendas: items.length
                });
            }
        });
    }

    componentWillUnmount(){
        this.mounted = false;
    }

    render(){

        // if(this.state.usuario.empresa === null || this.state.usuario.empresa !== "ff"){
        //     return <Redirect to='/revenda/listar' />
        // }

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin'){
            return <Redirect to='/acesso-negado' />
        }

        const columns = [
            {
                id: 'status', 
                Header: '',
                accessor: props => ( props.pestatus !== undefined && props.pestatus.length > 0 ? <div style={{ width: '15px', height: '15px', backgroundColor: props.pestatus, borderRadius: '15px' }} ></div> : "" ),
                Cell: props => props.value,
                width: 25,
            },
            {
                id: 'manutencao', 
                Header: 'Manutenção',
                accessor: props => ( <div className="btn-group"><a className="btn btn-primary dropdown-toggle" data-toggle="dropdown" href="#">Ações<span className="caret"></span></a><ul style={{ position: "relative", minWidth: "130px" }} className="dropdown-menu"><li><Link to={ "/revenda/"+props.id+"/usuarios/listar"} >Usuários</Link></li><li><Link to={ "/revenda/"+props.id+"/veiculos/listar" } >Veículos</Link></li><li><Link to={ "/revenda/"+props.id+"/integracao" } >Integrações</Link></li><li><Link to={ "/revenda/"+props.id+"/imagem-destaque/listar" } >Img destaque</Link></li><li><Link to={ "/revenda/"+props.id+"/banner-site/listar" } >Banner site</Link></li><li><a target="_blank" href={ "https://app.intermedioveiculos.com.br/SolicitarSite/solicitarsite.php?usuario="+this.state.usuario.id+"&revenda_id="+props.id+"&revenda_nome="+props.penome_fantasia+"&string_md5_get="+this.state.string_md5_site } >Site</a></li><li><a target="_blank" href={ "https://intermedio.app/revenda/"+props.id+"/locais-anuncio" } >Loc. anúncio</a></li><li><Link to={ "/revenda/"+props.id+"/veiculos/historico" } >Histórico</Link></li><li><Link to={ "/revenda/"+props.id+"/notificacoes/listar" } >Notificações</Link></li><li><a target="_blank" href={ "https://cadastro.intermedioveiculos.com.br/?revenda="+props.penome_fantasia+"&responsavel="+props.perazao_social_nome+"&telefone="+props.petelefone1+"&email="+props.peemail+"&idRevenda="+props.id+"&idNotificacao=idNotificacao" } >Atualizar dados</a></li><li><a href={ "https://app.intermedioveiculos.com.br/MateriaisPainel/materiaispainel.php?usuario="+this.state.usuario.id+"&string_md5="+this.state.string_md5_solicitacoes+"&revenda_id="+props.id+"&revenda_nome="+props.penome_fantasia }>Solicitações</a></li><li><Link to={ "/revenda/"+props.id+"/alterar" } >Alterar</Link></li><li><Link to={ "/revenda/"+props.id+"/excluir" } >Excluir</Link></li></ul></div> ),
                width: 150,
            },
            {
                id: 'nome_fantasia', 
                Header: 'Nome da loja',
                accessor: props => props.penome_fantasia,
                Cell: props => props.value,
                width: 200,
            },
            {
                id: 'valor_mensalidade', 
                Header: 'Valor',
                accessor: props => ( props.pevalormensalidade !== undefined  ? parseFloat(props.pevalormensalidade) : 0.0 ),
                Cell: props => formatMoney(props.value),                
                width: 100,
            },
            {
                id: 'dia_vencimento', 
                Header: 'Venc.',
                accessor: props => ( props.pedatavencimento !== undefined  ? <span>{ props.pedatavencimento }</span> : "" ),
                Cell: props => props.value,
                width: 50,
            },
            {
                id: 'obs_administrativas', 
                Header: 'Observações',
                accessor: props => ( props.peobsadm !== undefined  ? <span>{ props.peobsadm }</span> : "" ),
                Cell: props => props.value,
                width: 200,
            },
            {
                id: 'cpf_cnpj', 
                Header: 'CNPJ',
                accessor: props => ( props.pecpf_cnpj !== undefined  ? <span>{ props.pecpf_cnpj }</span> : "" ),
                Cell: props => props.value,
                width: 150,
            },
            {
                id: 'razao_social', 
                Header: 'Responsável',
                accessor: props => props.perazao_social_nome,
                width: 200,
            },
            {
                id: 'cidade', 
                Header: 'Cidade',
                accessor: props => props.pecidade + "-" + props.peuf,
                width: 200
            },
            {
                id: 'data_cadastro', 
                Header: 'Data cadastro',
                accessor: "created_at",
                Cell: props => ( props.value != null ? moment(props.value.seconds * 1000).locale('pt-BR').format("DD/MM/YYYY") : "" ),
                width: 100,
            },
            {
                id: 'dias_ativo', 
                Header: 'Dias ativo',
                accessor: props => ( props.created_at != null ? moment().diff(moment(props.created_at.seconds * 1000).locale('pt-BR').format("YYYY-MM-DD"), 'days') : "" ),
                width: 100,
            },
            {
                id: 'telefone', 
                Header: 'Telefone',
                accessor: props => props.petelefone1,
                width: 200,
            },
            {
                id: 'email', 
                Header: 'E-mail',
                accessor: props => props.peemail,
                width: 200,
            },
            {
                id: 'site', 
                Header: 'Site',
                accessor: props => props.pehomepage,
                width: 200,
            }
        ];

        return (
            <div>
                <Cabecalho />
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Revendas - Plano Básico</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    Lista de Revendas plano Básico - { this.state.countRevendas } revendas
                                </div>
                                <div className="panel-body">
                                <ReactTable
                                    data={this.state.revendas}
                                    columns={columns}
                                    showPagination={false}
                                    minRows={ this.state.countRevendas }
                                    defaultPageSize={ 0 }
                                    pageSize={ this.state.countRevendas }
                                    showPageSizeOptions={false}
                                    className={ "-highlight" }
                                />
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
            
        )
    }

}

function formatMoney(value) {

    var places = 2;
    var symbol = "R$ ";
    var thousand = ".";
    var decimal = ",";

	places = !isNaN(places = Math.abs(places)) ? places : 2;
	symbol = symbol !== undefined ? symbol : "$";
	thousand = thousand || ",";
	decimal = decimal || ".";
	var number = value, 
	    negative = number < 0 ? "-" : "",
	    i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
	    j = (j = i.length) > 3 ? j % 3 : 0;
	return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
};

export default RevendaFFListar