import React, { Component } from 'react';
import { firestore } from './../../firebase-config';
import Cabecalho from './../../componentes/Cabecalho';
import { Redirect } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import './../custom_form.css';

class LocaisAnuncioIncluir extends Component {

    constructor (props){
        super(props);

        this.state = {
            returnPagina: false,
            descricao: '',
            ordenacao: 0,
            estaGravando: false,
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }

        this.mounted = false;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleChange(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {

        this.setState({estaGravando: true});

        var user = JSON.parse(window.localStorage.getItem("usuarioLogado"));

        var localAnuncio = {
            descricao: this.state.descricao,
            ordenacao: parseInt(this.state.ordenacao),
            created_id: user.id,
            updated_id: user.id,
        }

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection('locais_anuncio').add(localAnuncio)
        .then(res => { 
            this.setState({returnPagina: true, estaGravando: false});
        })
        .catch(err => { 
            this.setState({returnPagina: true, estaGravando: false});     
        });

        event.preventDefault();

    }

    render(){
        const { descricao, ordenacao, estaGravando } = this.state;
        if(this.state.returnPagina){
            return <Redirect to="/locais-anuncio/listar" />;
        }
        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin'){
            return <Redirect to='/acesso-negado' />
        }
        return (

            <div>
                <Cabecalho />
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Locais de anúncio</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    Incluir novo local de anúncio
                                </div>
                                <div className="panel-body">

                                    <ValidatorForm
                                        ref="form"
                                        onSubmit={this.handleSubmit}
                                    >
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="Descrição"
                                                        onChange={this.handleChange}
                                                        name="descricao"
                                                        value={descricao}
                                                        className="form-ui-bootstrap"
                                                        validators={['required']}
                                                        errorMessages={['Preenchimento obrigatório']}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="Ordenação"
                                                        onChange={this.handleChange}
                                                        name="ordenacao"
                                                        value={ordenacao}
                                                        className="form-ui-bootstrap"
                                                        validators={['required', 'isNumber']}
                                                        errorMessages={['Preenchimento obrigatório', 'Número inválido']}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <Button type="submit" variant="contained" color="primary" disabled={estaGravando} >Gravar</Button>
                                            </div>
                                        </div>
                                    </ValidatorForm>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}

export default LocaisAnuncioIncluir
