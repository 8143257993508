import { base_url, api_key } from '../config/config_api';
import { firestore } from '../firebase-config';

export const configureInstagram = async (id_revenda, token_data) => {

  var user = JSON.parse(window.localStorage.getItem("usuarioLogado"));

  var integracao = {
    descricao: "Instagram",
    // status: true,
    updated_id: user.id,
    updated_at: token_data.dataAtual
  }

  if(!token_data.existeConfiguracao) {
    integracao.created_id = user.id;
    integracao.created_at = token_data.dataAtual;
  }

  return firestore.collection("revenda").doc(id_revenda).collection("integracoes").doc("instagram").set(integracao, { merge: true })
  .then(res => { 
    return fetch(base_url+"/api/integracao/instagram/configurar", { 
      method: "POST", 
      headers: { 
        'Content-Type': 'application/json'
      }, 
      body: JSON.stringify({
        "api_key": api_key,
        "id_revenda": id_revenda,
        "access_token": token_data.access_token,
        "long_lived_token": token_data.long_lived_token,
        "usuario": user.id,
      })
    })
    .then(response => {
      return response.json()
      .then(data => {
        if(data.status === 'success'){
          // return firestore.collection("revenda").doc(id_revenda).collection("integracoes").doc("instagram").set({ status: true }, { merge: true })
          // .then(res => { 
            return {
              status: "success",
              data: data.data
            };
          // })
          // .catch(err => { 
          //   return {
          //     status: "error",
          //     data: err
          //   };
          // });  
        }else{
          return {
            status: "error",
            data: data.data
          };
        }
      });
    })
    .catch(error => {
      return {
        status: "error",
        data: error
      };
    });        
  })
  .catch(err => { 
    return {
      status: "error",
      data: err
    };
  });

}

export const cancelarConfiguracaoInstagram = async (id_revenda) => {

  var user = JSON.parse(window.localStorage.getItem("usuarioLogado"));

  return fetch(base_url+"/api/integracao/instagram/disallow", { 
    method: "POST", 
    headers: { 
      'Content-Type': 'application/json'
    }, 
    body: JSON.stringify({
      "api_key": api_key,
      "id_revenda": id_revenda,
      "usuario": user.id,
    })
  })
  .then(response => {
    return response.json()
    .then(data => {
      // if(data.status === 'success'){
        return firestore.collection("revenda").doc(id_revenda).collection("integracoes").doc("instagram").set({ status: false }, { merge: true })
        .then(res => { 
          return {
            status: "success",
            data: data.data
          }; 
        })
        .catch(err => { 
          return {
            status: "error",
            data: err
          };
        });  
      // }else{
      //   return {
      //     status: "error",
      //     data: data.data
      //   };
      // }
    });
  })
  .catch(error => {
    return {
      status: "error",
      data: error
    };
  });   
}

export const configureFacebook = async (id_revenda, token_data) => {

  var user = JSON.parse(window.localStorage.getItem("usuarioLogado"));

  var integracao = {
    descricao: "Facebook",
    // status: true,
    updated_id: user.id,
    updated_at: token_data.dataAtual
  }

  if(!token_data.existeConfiguracao) {
    integracao.created_id = user.id;
    integracao.created_at = token_data.dataAtual;
  }

  return firestore.collection("revenda").doc(id_revenda).collection("integracoes").doc("facebook").set(integracao, { merge: true })
  .then(res => { 
    return fetch(base_url+"/api/integracao/facebook/configurar", { 
      method: "POST", 
      headers: { 
        'Content-Type': 'application/json'
      }, 
      body: JSON.stringify({
        "api_key": api_key,
        "id_revenda": id_revenda,
        "access_token": token_data.access_token,
        "long_lived_token": token_data.long_lived_token,
        "usuario": user.id,
      })
    })
    .then(response => {
      return response.json()
      .then(data => {
        if(data.status === 'success'){
          // return firestore.collection("revenda").doc(id_revenda).collection("integracoes").doc("facebook").set({ status: true }, { merge: true })
          // .then(res => { 
            return {
              status: "success",
              data: data.data
            }; 
          // })
          // .catch(err => { 
          //   return {
          //     status: "error",
          //     data: err
          //   };
          // });           
        }else{
          return {
            status: "error",
            data: data.data
          };
        }
      });
    })
    .catch(error => {
      return {
        status: "error",
        data: error
      };
    });        
  })
  .catch(err => { 
    return {
      status: "error",
      data: err
    };
  });

}

export const cancelarConfiguracaoFacebook = async (id_revenda) => {

  var user = JSON.parse(window.localStorage.getItem("usuarioLogado"));

  return fetch(base_url+"/api/integracao/facebook/disallow", { 
    method: "POST", 
    headers: { 
      'Content-Type': 'application/json'
    }, 
    body: JSON.stringify({
      "api_key": api_key,
      "id_revenda": id_revenda,
      "usuario": user.id,
    })
  })
  .then(response => {
    return response.json()
    .then(data => {
      // if(data.status === 'success'){
        return firestore.collection("revenda").doc(id_revenda).collection("integracoes").doc("facebook").set({ status: false }, { merge: true })
        .then(res => { 
          return {
            status: "success",
            data: data.data
          }; 
        })
        .catch(err => { 
          return {
            status: "error",
            data: err
          };
        });  
      // }else{
      //   return {
      //     status: "error",
      //     data: data.data
      //   };
      // }
    });
  })
  .catch(error => {
    return {
      status: "error",
      data: error
    };
  });   
}

export const getPagesFacebook = async (user_id, token) => {
  return fetch(
    'https://graph.facebook.com/v20.0/'+user_id+'/accounts', 
    { 
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    },
  )
  .then(response => {
      return response.json()
      .then(data => {
        if(data.data !== undefined){
          return {
            status: "success",
            data: data.data
          }; 
        }else{
          if(data.error !== undefined){
            return {
              status: "error",
              data: data.error
            }; 
          }
          return {
            status: "error",
            data: "Ocorreu um erro ao buscar páginas relacinadas a conta do facebook autorizada."
          };
        }          
      });
  })
  .catch(error => {
      return {
        status: "error",
        data: error
      };
  });
}

export const getPageInstagramByFacebook = async (user_id, token) => {
  return fetch(
    'https://graph.facebook.com/v20.0/'+user_id+'?fields=instagram_business_account', 
    { 
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    },
  )
  .then(response => {
      return response.json()
      .then(data => {
        if(data !== undefined && data.instagram_business_account !== undefined  && data.instagram_business_account.id !== undefined ){
          return fetch(
            'https://graph.facebook.com/v20.0/'+data.instagram_business_account.id+'?fields=id,name', 
            { 
              method: 'GET',
              headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
              }
            },
          )
          .then(response => {
              return response.json()
              .then(data => {
                if(data !== undefined && data.id !== undefined  && data.name !== undefined ){
                  return {
                    status: "success",
                    data: data
                  }; 
                }else{
                  return {
                    status: "error",
                    data: "Ocorreu um erro ao buscar os dados da conta do instagram."
                  };
                }          
              });
          })
          .catch(error => {
              return {
                status: "error",
                data: error
              };
          });
        }else{
          return {
            status: "error",
            data: "Ocorreu um erro ao buscar a conta do instagram relacionada a página do facebook selecionada."
          };
        }          
      });
  })
  .catch(error => {
      return {
        status: "error",
        data: error
      };
  });
}

export const getPageInstagram = async (user_id, token) => {
  return fetch(
    'https://graph.facebook.com/v20.0/'+user_id+'?fields=id,name', 
    { 
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    },
  )
  .then(response => {
      return response.json()
      .then(data => {
        if(data !== undefined && data.id !== undefined  && data.name !== undefined ){
          return {
            status: "success",
            data: data
          }; 
        }else{
          return {
            status: "error",
            data: "Ocorreu um erro ao buscar os dados da conta do instagram."
          };
        }          
      });
  })
  .catch(error => {
      return {
        status: "error",
        data: error
      };
  });
}