import React, { Component } from 'react';
import * as firebase from "firebase/app";
import { configureFacebook, getPagesFacebook } from '../../../services/MetaService';
import { ValidatorForm, SelectValidator} from 'react-material-ui-form-validator';
import MenuItem from "@material-ui/core/MenuItem";
import { firestore } from '../../../firebase-config';
import { base_url, api_key } from '../../../config/config_api';

class RevendaIntegracaoConfigurarFacebook extends Component {

    constructor (props){
        super(props);

        this.state = {
            idRevenda: "",
            status: 0,
            existeConfiguracao: false,
            dataAtual: null,
            paginas: [],
            isLoading: true,
            estaGravando: false,
            pagina: ""
        }
        this.mounted = false;
        this.handleChange  = this.handleChange.bind(this);
        this.handleSubmit  = this.handleSubmit.bind(this);
    }

    componentDidMount(){

        this.state.dataAtual = firebase.firestore.FieldValue.serverTimestamp();

        const hash = window.location.hash;

        if(hash){

            const hashParams  = hash.substring(1);
            const paramsArray = hashParams.split('&');
            const token_data  = {};

            paramsArray.forEach(param => {
                const [key, value] = param.split('=');
                token_data[key] = value;
            });

            if(token_data.state !== undefined && token_data.state !== null){

                token_data.status = parseInt(this.state.status) === 1 ? true : false;
                token_data.existeConfiguracao = this.state.existeConfiguracao;
                token_data.dataAtual = this.state.dataAtual;
                token_data.idRevenda = token_data.state;

                this.setState({ idRevenda: token_data.state });

                this.configureFacebook(token_data.idRevenda, token_data);
            }

        }         

    }
    
    configureFacebook = async (idRevenda, token_data) => { 
        const response = await configureFacebook(idRevenda, token_data);

        if(response.status === "success"){

            this.setState({
                isLoading: false
            });

            const response2 = await getPagesFacebook(response.data.user_id, response.data.long_lived_token);
            if(response2.status === "success"){
                this.setState({
                    paginas: response2.data
                });
            }else{
                const resposta3 = window.confirm('Ocorreu um erro ao carregar as páginas do Facebook! Tente novamente!');
                if (resposta3) {
                    window.close();
                } else {
                    window.close();
                }
            }

        }else{
            const resposta2 = window.confirm('Ocorreu um erro ao configurar o Facebook. Tente novamente!');
            if (resposta2) {
                window.close();
            } else {
                window.close();
            }
        }

        // https://graph.facebook.com/v19.0/me?fields=id,name
        // {
        //     "id": "7555348251178204",
        //     "name": "Felipe Gomes"
        // }

        // {
        //     "error": {
        //       "message": "Error validating access token: Session has expired on Wednesday, 08-May-24 09:00:00 PDT. The current time is Thursday, 09-May-24 07:12:17 PDT.",
        //       "type": "OAuthException",
        //       "code": 190,
        //       "error_subcode": 463,
        //       "fbtrace_id": "A9GZ3pyvVpwbenMHHVvsAv2"
        //     }
        //   }
    } 

    handleChange(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {

        this.setState({estaGravando: true});

        if(this.state.pagina !== ""){
            const paginaSelecionada = this.state.paginas[this.state.pagina];
            if(paginaSelecionada !== undefined && paginaSelecionada.id !== undefined && paginaSelecionada.name !== undefined){

                var user = JSON.parse(window.localStorage.getItem("usuarioLogado"));
                var integracao = {
                    page_id: paginaSelecionada.id,
                    page_name: paginaSelecionada.name,
                    updated_id: user.id,
                    updated_at: this.state.dataAtual,
                    status: true
                }

                firestore.collection("revenda").doc(this.state.idRevenda).collection("integracoes").doc("facebook").set(integracao, { merge: true })
                .then(res => { 

                    fetch(base_url+"/api/integracao/facebook/configurar-pagina", { 
                        method: "POST", 
                        headers: { 
                            'Content-Type': 'application/json',
                        }, 
                        body: JSON.stringify({
                            "api_key": api_key,
                            "id_revenda": this.state.idRevenda,
                            "page_id": paginaSelecionada.id,
                            "page_name": paginaSelecionada.name,
                            "page_access_token": paginaSelecionada.access_token,
                            "usuario": user.id,
                        })
                    })
                    .then(response => {
            
                        response.json()
                        .then(data => {
                            if(data.status !== 'success'){
                                firestore.collection("revenda").doc(this.state.idRevenda).collection("integracoes").doc("facebook").set({ status: false }, { merge: true });
                            }
                            this.setState({estaGravando: false});
                            const resposta4 = window.confirm('Facebook configurado com sucesso!');
                            if (resposta4) {
                                window.close();
                            } else {
                                window.close();
                            }
                        });

                        this.setState({estaGravando: false});  
            
                    })
                    .catch(error => {
                        this.setState({estaGravando: false});  
                        console.error("Erro get teste", error);
                    });

                })
                .catch(err => { 
                    firestore.collection("revenda").doc(this.state.idRevenda).collection("integracoes").doc("facebook").set({ status: false }, { merge: true });
                    alert("Erro ao configurar integração.")
                    this.setState({estaGravando: false});     
                });
                
            }
        }

        event.preventDefault();

    }

    render(){    

        const { 
            isLoading, 
            paginas,
            estaGravando,
            pagina
        } = this.state;

        return (
            <div className="mt-5">
                { 
                    isLoading ? 

                    <div className="progress progress-upload-images progress-upload-images-integracao">
                        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%" }}>Aguarde...</div>
                    </div>

                    : 
                
                    <div id="page-wrapper">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        Selecionar página do Facebook
                                    </div>
                                    <div className="panel-body">

                                        <ValidatorForm
                                            ref="form"
                                            onSubmit={this.handleSubmit}
                                        >
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="form-group">
                                                        <SelectValidator
                                                            label="Página Facebook"
                                                            value={ pagina }
                                                            name="pagina"
                                                            className="form-ui-bootstrap"
                                                            onChange={this.handleChange}
                                                            validators={["required"]}
                                                            errorMessages={["Preenchimento obrigatório"]}
                                                            >
                                                                <MenuItem value="" ></MenuItem>

                                                                {
                                                                paginas.map((element, id) =>
                                                                    <MenuItem value={id} key={id}>{element.name}</MenuItem>                                                     
                                                                )
                                                            }
                                                        </SelectValidator>
                                                    </div>
                                                </div>
                                            </div>                                                                             
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <button type="submit" className="btn btn-primary" disabled={estaGravando} >Gravar</button>
                                                </div>
                                            </div>
                                        </ValidatorForm>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }

}

export default RevendaIntegracaoConfigurarFacebook
