import React, { Component } from 'react';
import { firestore } from '../../../firebase-config';
import Cabecalho from '../../../componentes/Cabecalho';
import CabecalhoPainel from '../../../componentes/CabecalhoPainel';
import { Redirect } from 'react-router-dom';
import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import MenuItem from "@material-ui/core/MenuItem";
import * as firebase from "firebase/app";
import '../../custom_form.css';
import { base_url, api_key } from '../../../config/config_api';

class RevendaIntegracaoIcarros extends Component {

    constructor (props){
        super(props);

        this.state = {
            returnPagina: false,
            estaGravando: false,
            idRevenda: props.match.params.idRevenda,
            nome_revenda: "",
            descricao: "",
            email_icarros: "",
            senha_icarros: "",
            status: 1,
            existeConfiguracao: false,
            dataAtual: null,
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }
        this.mounted = false;
        this.handleChange  = this.handleChange.bind(this);
        this.handleSubmit  = this.handleSubmit.bind(this);

    }

    componentDidMount(){

        this.mounted = true;

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection("revenda").doc(this.state.idRevenda)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    nome_revenda: doc.data().penome_fantasia
                });
            }
        }).catch(function(error) {
        });

        firestore.collection("revenda").doc(this.state.idRevenda).collection("integracoes").doc("icarros")
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    descricao: doc.data().descricao,
                    email_icarros: doc.data().email_icarros,
                    senha_icarros: doc.data().senha_icarros,
                    status: doc.data().status ? 1 : 0,
                    existeConfiguracao: true
                });                
            }
        }).catch(function(error) {
        });
        this.state.dataAtual = firebase.firestore.FieldValue.serverTimestamp();

    }

    handleChange(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {

        this.setState({estaGravando: true});

        var user = JSON.parse(window.localStorage.getItem("usuarioLogado"));

        var integracao = {
            descricao: "iCarros",
            email_icarros: this.state.email_icarros,
            senha_icarros: this.state.senha_icarros,
            status: parseInt(this.state.status) === 1 ? true : false,
            updated_id: user.id,
            updated_at: this.state.dataAtual
        }

        if(!this.state.existeConfiguracao) {
            integracao.created_id = user.id;
            integracao.created_at = this.state.dataAtual;
        }

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection("revenda").doc(this.state.idRevenda).collection("integracoes").doc("icarros").set(integracao, { merge: true })
        .then(res => { 

            fetch(base_url+"/api/integracao/icarros/configurar", { 
                method: "POST", 
                headers: { 
                    'Content-Type': 'application/json',
                }, 
                body: JSON.stringify({
                    "api_key": api_key,
                    "id_revenda": this.state.idRevenda,
                    "email_icarros": this.state.email_icarros,
                    "senha_icarros": this.state.senha_icarros,
                    "usuario": user.id,
                })
            })
            .then(response => {
    
                response.json()
                .then(data => {
                    if(data.status === 'success'){
                        // console.log(data.data);
                    }else{
                        firestore.collection("revenda").doc(this.state.idRevenda).collection("integracoes").doc("icarros").set({ status: false }, { merge: true });
                    }
                    this.setState({returnPagina: true, estaGravando: false});
                });
                this.setState({estaGravando: false});  
    
            })
            .catch(error => {
                this.setState({estaGravando: false});  
                console.error("Erro get teste", error);
            });

            
        })
        .catch(err => { 

            firestore.collection("revenda").doc(this.state.idRevenda).collection("integracoes").doc("icarros").set({ status: false }, { merge: true });

            alert("Erro ao configurar integração.")

            this.setState({estaGravando: false});     
        });

        event.preventDefault();

    }

    render(){
        const { 
            estaGravando, 
            email_icarros,
            senha_icarros,
            nome_revenda,
            status,
            idRevenda
        } = this.state;

        if(this.state.returnPagina){
            return <Redirect to={ "/revenda/"+idRevenda+"/integracao" } />;
        }

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin' && this.state.idRevenda !== this.state.usuarioLogado.revenda){
            return <Redirect to='/acesso-negado' />
        }
        
        return (

            <div>
                {
                    this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ? <Cabecalho /> :  <CabecalhoPainel />
                }
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Integração iCarros<br/><small>Revenda: { nome_revenda }</small></h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    Configurar integração iCarros
                                </div>
                                <div className="panel-body">

                                    <ValidatorForm
                                        ref="form"
                                        onSubmit={this.handleSubmit}
                                    >
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="Login iCarros"
                                                        onChange={this.handleChange}
                                                        name="email_icarros"
                                                        value={email_icarros}
                                                        className="form-ui-bootstrap"
                                                        validators={['required', 'isEmail']}
                                                        errorMessages={['Preenchimento obrigatório', 'E-mail inválido']}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="Senha iCarros"
                                                        onChange={this.handleChange}
                                                        name="senha_icarros"
                                                        value={senha_icarros}
                                                        type="password"
                                                        className="form-ui-bootstrap"
                                                        validators={['required']}
                                                        errorMessages={['Preenchimento obrigatório']}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <SelectValidator
                                                        label="Status integração"
                                                        value={ status }
                                                        name="status"
                                                        className="form-ui-bootstrap"
                                                        onChange={this.handleChange}
                                                        validators={["required"]}
                                                        errorMessages={["Preenchimento obrigatório"]}
                                                        >
                                                            <MenuItem value="1">Ativo</MenuItem>
                                                            <MenuItem value="0">Inativo</MenuItem>                                                     
                                                    </SelectValidator>
                                                </div>
                                            </div>
                                        </div>                                                                             
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <a href={ "/revenda/"+idRevenda+"/integracao"} className="btn btn-warning" >Voltar</a> <button type="submit" className="btn btn-primary" disabled={estaGravando} >Gravar</button>
                                            </div>
                                        </div>
                                    </ValidatorForm>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}

export default RevendaIntegracaoIcarros
