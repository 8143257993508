import React, { Component } from 'react';
import Cabecalho from '../../componentes/Cabecalho';
import CabecalhoPainel from '../../componentes/CabecalhoPainel';
import { Link, Redirect } from 'react-router-dom';
import { firestore } from '../../firebase-config';

class RevendaIntegracao extends Component {

    constructor (props){
        super(props);

        this.state = {
            idRevenda: props.match.params.idRevenda,
            carregando: true,
            nome_revenda: "",
            integracao_list: new Map(),
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        };
        this.mounted = false;
    }

    componentDidMount(){

        this.mounted = true;

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection("revenda").doc(this.state.idRevenda)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    nome_revenda: doc.data().penome_fantasia
                });
            }
        }).catch(function(error) {
        });

        firestore.collection('revenda').doc(this.state.idRevenda).collection('integracoes')
        .get()
        .then(querySnapshot => {
            var items = [];
            querySnapshot.forEach(doc => {
                var document = doc.data();
                document.id  = doc.id;
                items[doc.id] = document;
            });
            if(this.mounted) {
                this.setState({
                    integracao_list: items,
                    carregando: false
                });
            }
        })
        .catch(function(error) {
            console.log(error);
        });
        
    }

    componentWillUnmount(){
        this.mounted = false;
    }

    render(){
        const {
            idRevenda,
            nome_revenda,
            integracao_list,
            carregando
        } = this.state;

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin' && this.state.idRevenda !== this.state.usuarioLogado.revenda){
            return <Redirect to='/acesso-negado' />
        }

        return (
            <div>
                {
                    this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ? <Cabecalho /> :  <CabecalhoPainel />
                } 
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Integrações<br/><small>Revenda: { nome_revenda }</small></h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    Lista de Integrações
                                </div>
                                <div className="panel-body">

                                    <table className='table-integracao table table-striped'>
                                        <thead>
                                            <tr>
                                                <th>Integração</th>
                                                <th>Status configuração</th>
                                                <th>
                                                    {
                                                        this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ?
                                                            "Ações" : ""
                                                    }
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Autoline</td>
                                                <td>
                                                    {
                                                        carregando ? null : (
                                                            integracao_list.autoline ? (
                                                                integracao_list.autoline.status ? (
                                                                    <span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span>
                                                                ) : (
                                                                    <span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span>
                                                                )
                                                            ) : (
                                                                <span className="glyphicon glyphicon-remove-circle" style={{ fontSize: '20px', color: 'red'}}></span>
                                                            )
                                                        )
                                                    }                                                    
                                                </td>
                                                <td>
                                                    {
                                                        // this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ?
                                                            <Link to={ "/revenda/"+idRevenda+"/configurar-autoline"} >Configurar</Link> 
                                                        // : ""
                                                    }
                                                </td>
                                            </tr> 
                                            <tr>
                                                <td>Autoscar</td>
                                                <td>
                                                    {
                                                        carregando ? null : (
                                                            integracao_list.autoscar ? (
                                                                integracao_list.autoscar.status ? (
                                                                    <span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span>
                                                                ) : (
                                                                    <span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span>
                                                                )
                                                            ) : (
                                                                <span className="glyphicon glyphicon-remove-circle" style={{ fontSize: '20px', color: 'red'}}></span>
                                                            )
                                                        )
                                                    }                                                    
                                                </td>
                                                <td>
                                                    {
                                                        this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ?
                                                            <Link to={ "/revenda/"+idRevenda+"/configurar-autoscar"} >Configurar</Link> : ""
                                                    }
                                                </td>
                                            </tr>  
                                            {
                                                // this.state.idRevenda === 'je5w6EfdbLuYo3qX1dma' ?
                                                    <tr>
                                                        <td>Facebook</td>
                                                        <td>
                                                            {
                                                                carregando ? null : (
                                                                    integracao_list.facebook ? (
                                                                        integracao_list.facebook.status ? (
                                                                            <span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span>
                                                                        ) : (
                                                                            <span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span>
                                                                        )
                                                                    ) : (
                                                                        <span className="glyphicon glyphicon-remove-circle" style={{ fontSize: '20px', color: 'red'}}></span>
                                                                    )
                                                                )
                                                            }                                                    
                                                        </td>
                                                        <td>
                                                            {
                                                                // this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ?
                                                                    <Link to={ "/revenda/"+idRevenda+"/configurar-facebook"} >Configurar</Link> 
                                                                // : ""
                                                            }
                                                        </td>
                                                    </tr>  
                                                // : ""
                                            }
                                            <tr>
                                                <td>iCarros</td>
                                                <td>
                                                    {
                                                        carregando ? null : (
                                                            integracao_list.icarros ? (
                                                                integracao_list.icarros.status ? (
                                                                    <span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span>
                                                                ) : (
                                                                    <span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span>
                                                                )
                                                            ) : (
                                                                <span className="glyphicon glyphicon-remove-circle" style={{ fontSize: '20px', color: 'red'}}></span>
                                                            )
                                                        )
                                                    }                                                    
                                                </td>
                                                <td>
                                                    {
                                                        // this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ?
                                                            <Link to={ "/revenda/"+idRevenda+"/configurar-icarros"} >Configurar</Link> 
                                                        // : ""
                                                    }
                                                </td>
                                            </tr> 
                                            {
                                                // this.state.idRevenda === 'je5w6EfdbLuYo3qX1dma' ?
                                                    <tr>
                                                        <td>Instagram</td>
                                                        <td>
                                                            {
                                                                carregando ? null : (
                                                                    integracao_list.instagram ? (
                                                                        integracao_list.instagram.status ? (
                                                                            <span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span>
                                                                        ) : (
                                                                            <span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span>
                                                                        )
                                                                    ) : (
                                                                        <span className="glyphicon glyphicon-remove-circle" style={{ fontSize: '20px', color: 'red'}}></span>
                                                                    )
                                                                )
                                                            }                                                    
                                                        </td>
                                                        <td>
                                                            {
                                                                // this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ?
                                                                    <Link to={ "/revenda/"+idRevenda+"/configurar-instagram"} >Configurar</Link> 
                                                                // : ""
                                                            }
                                                        </td>
                                                    </tr>  
                                                // : ""
                                            }
                                            <tr>
                                                <td>Mercado Livre</td>
                                                <td>
                                                    {
                                                        carregando ? null : (
                                                            integracao_list.mercadolivre ? (
                                                                integracao_list.mercadolivre.status ? (
                                                                    <span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span>
                                                                ) : (
                                                                    <span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span>
                                                                )
                                                            ) : (
                                                                <span className="glyphicon glyphicon-remove-circle" style={{ fontSize: '20px', color: 'red'}}></span>
                                                            )
                                                        )
                                                    }                                                    
                                                </td>
                                                <td>
                                                    {
                                                        this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ?
                                                            <Link to={ "/revenda/"+idRevenda+"/configurar-mercadolivre"} >Configurar</Link> : ""
                                                    }                                                    
                                                </td>
                                            </tr>  
                                            <tr>
                                                <td>Mobiauto</td>
                                                <td>
                                                    {
                                                        carregando ? null : (
                                                            integracao_list.mobiauto ? (
                                                                integracao_list.mobiauto.status ? (
                                                                    <span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span>
                                                                ) : (
                                                                    <span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span>
                                                                )
                                                            ) : (
                                                                <span className="glyphicon glyphicon-remove-circle" style={{ fontSize: '20px', color: 'red'}}></span>
                                                            )
                                                        )
                                                    }                                                    
                                                </td>
                                                <td>
                                                    {
                                                        // this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ?
                                                            <Link to={ "/revenda/"+idRevenda+"/configurar-mobiauto"} >Configurar</Link> 
                                                        // : ""
                                                    }
                                                </td>
                                            </tr>  
                                            <tr>
                                                <td>Na Pista</td>
                                                <td>
                                                    {
                                                        carregando ? null : (
                                                            integracao_list.meucarronovo ? (
                                                                integracao_list.meucarronovo.status ? (
                                                                    <span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span>
                                                                ) : (
                                                                    <span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span>
                                                                )
                                                            ) : (
                                                                <span className="glyphicon glyphicon-remove-circle" style={{ fontSize: '20px', color: 'red'}}></span>
                                                            )
                                                        )
                                                    }                                                    
                                                </td>
                                                <td>
                                                    {
                                                        // this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ?
                                                            <Link to={ "/revenda/"+idRevenda+"/configurar-meucarronovo"} >Configurar</Link> 
                                                        // : ""
                                                    }
                                                </td>
                                            </tr>  
                                            <tr>
                                                <td>Olx</td>
                                                <td>
                                                    {
                                                        carregando ? null : (
                                                            integracao_list.olx ? (
                                                                integracao_list.olx.status ? (
                                                                    <span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span>
                                                                ) : (
                                                                    <span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span>
                                                                )
                                                            ) : (
                                                                <span className="glyphicon glyphicon-remove-circle" style={{ fontSize: '20px', color: 'red'}}></span>
                                                            )
                                                        )
                                                    }                                                    
                                                </td>
                                                <td>
                                                    {
                                                        this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ?
                                                            <Link to={ "/revenda/"+idRevenda+"/configurar-olx"} >Configurar</Link> : ""
                                                    }
                                                </td>
                                            </tr>      
                                            <tr>
                                                <td>Webmotors</td>
                                                <td>
                                                    {
                                                        carregando ? null : (
                                                            integracao_list.webmotors ? (
                                                                integracao_list.webmotors.status ? (
                                                                    <span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span>
                                                                ) : (
                                                                    <span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span>
                                                                )
                                                            ) : (
                                                                <span className="glyphicon glyphicon-remove-circle" style={{ fontSize: '20px', color: 'red'}}></span>
                                                            )
                                                        )
                                                    }                                                    
                                                </td>
                                                <td>
                                                    {
                                                        // this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ?
                                                            <Link to={ "/revenda/"+idRevenda+"/configurar-webmotors"} >Configurar</Link> 
                                                        // : ""
                                                    }
                                                </td>
                                            </tr>                                          
                                        </tbody>
                                    </table> 

                                    <a href={ this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ? "/revenda/listar" : "/painel/dashboard" } className="btn btn-warning" >Voltar</a>

                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
            
        )
    }

}

export default RevendaIntegracao