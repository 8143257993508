import React, { Component } from 'react';
import {firestore} from './../../firebase-config';
import Cabecalho from './../../componentes/Cabecalho';
import ItemListarLocaisAnuncio from './ItemListar';
import { Redirect } from 'react-router-dom';

class LocaisAnuncioListar extends Component {

    constructor (props){
        super(props);

        this.state = {
            locais_anuncio: [],
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }
        this.mounted = false;

    }

    componentDidMount(){

        this.mounted = true;

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection("locais_anuncio").orderBy("ordenacao", "desc").orderBy("descricao", "asc")
        .onSnapshot(querySnapshot => {
            var items = [];
            querySnapshot.forEach(doc => {
                var document = doc.data();
                document.id  = doc.id;
                items.push(document);
            });
            if(this.mounted) {
                this.setState({
                    locais_anuncio: items
                });
            }
        });
    }

    componentWillUnmount(){
        this.mounted = false;
    }

    render(){

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin'){
            return <Redirect to='/acesso-negado' />
        }

        return (
            <div>
                <Cabecalho />
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Locais de anúncio</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    Lista de locais de anúncio
                                </div>
                                <div className="panel-body">
                                    <table width="100%" className="table table-striped table-bordered table-hover" id="dtLocaisAnuncio">
                                        <thead>
                                            <tr>
                                                <th>Descrição</th>
                                                <th>Ordem exibição</th>
                                                <th>Manutenção</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Object.keys(this.state.locais_anuncio)
                                                .map(key => {
                                                    return <ItemListarLocaisAnuncio key={key} conteudo={this.state.locais_anuncio[key]} />
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }

}

export default LocaisAnuncioListar