import React, { Component } from 'react';
import {firestore} from './../../firebase-config';
import Cabecalho from './../../componentes/Cabecalho';
import CabecalhoPainel from '../../componentes/CabecalhoPainel';
import { Link, Redirect } from 'react-router-dom';
import Modal from 'react-modal';
import moment from 'moment';
import 'moment-timezone';
import ReactTable from "react-table";
import { baixarVeiculo } from '../../services/VeiculoService';
import 'react-table/react-table.css';

Modal.setAppElement('#root');

class RevendaVeiculosListar extends Component {

    constructor (props){
        super(props);

        this.state = {
            idRevenda: props.match.params.idRevenda,
            nome_revenda: "",
            cidade_revenda: "",
            peblocotexto: "",
            veiculos: [],
            modalShow: false,
            modalAguardeShow: false,
            conteudoModal: '',
            countVeiculos: 0,
            validZipFiles: 0,
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }
        this.mounted = false;
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    componentDidMount(){

        this.mounted = true;        

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection("revenda").doc(this.state.idRevenda)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    nome_revenda: doc.data().penome_fantasia,
                    cidade_revenda: doc.data().pecidade + "-" + doc.data().peuf,
                    peblocotexto: doc.data().peblocotexto ? doc.data().peblocotexto : ""
                });
            }
        }).catch(function(error) {
        });

        firestore.collection("revenda").doc(this.state.idRevenda).collection("veiculos").where("veremovido", "==", 0).orderBy("vevendido").orderBy("updated_at", "desc")
        .onSnapshot(querySnapshot => {
            var items = [];
            querySnapshot.forEach(doc => {
                var document = doc.data();
                document.id  = doc.id;
                items.push(document);
            });
            if(this.mounted) {
                this.setState({
                    veiculos: items,
                    countVeiculos: items.length
                });
            }
        });
    }

    componentWillUnmount(){
        this.mounted = false;
    }

    handleCloseModal () {
        this.setState({ modalShow: false });
    }

    baixarVeiculo = async (nome_revenda, idRevenda, idVeiculo) => {

        this.setState({
            modalAguardeShow: true
        });

        await baixarVeiculo(nome_revenda, idRevenda, idVeiculo);

        this.setState({
            modalAguardeShow: false
        });

    }

    rawMarkup(){
        var rawMarkup = this.state.conteudoModal
        return { __html: rawMarkup };
    }

    render(){

        const columns = [
            {
                id: 'veiculo_imagem', 
                Header: '',
                accessor: props => ( props.imagens.length > 0 ? props.imagens[0].fileThumb !== undefined ? <img src={ props.imagens[0].fileThumb } style={{ maxWidth: '100px', maxHeight: '80px' }} ></img> : props.imagens[0].fileUrl !== undefined ? <img src={ props.imagens[0].fileUrl } style={{ maxWidth: '100px', maxHeight: '80px' }} ></img> : "" : "" ),
                Cell: props => props.value,
                width: 120,
            },
            {
                id: 'manutencao', 
                Header: 'Manutenção',
                accessor: props => ( <div><Link to={ "/revenda/"+this.state.idRevenda+"/veiculos/"+props.id+"/visualizar"} >Visualizar</Link><span> / </span><a onClick={() => this.baixarVeiculo(this.state.nome_revenda, this.state.idRevenda, props.id)}>Baixar</a><span> / </span><Link to={ "/revenda/"+this.state.idRevenda+"/veiculos/"+props.id+"/alterar"} >Alterar</Link><span> / </span><Link to={ "/revenda/"+this.state.idRevenda+"/veiculos/"+props.id+"/excluir" } >Excluir</Link></div> ),
                width: 230,
                sortable: false
            },
            {
                id: 'codigo_interno', 
                Header: 'Cód.',
                accessor: props => ( props.vecodigo_interno != null ? props.vecodigo_interno : "" ),
                Cell: props => props.value,
                width: 60,
            },
            {
                id: 'veiculo', 
                Header: 'Veículo',
                accessor: props => props.vefipe_marca + " " + props.vefipe_name + " " + props.vecor,
                Cell: props => props.value,
                width: 250,
            },
            {
                id: 'ano', 
                Header: 'Ano',
                accessor: props => props.veano_fabricacao + "/" + props.vefipe_ano_modelo,
                width: 80,
            },
            {
                id: 'placa', 
                Header: 'Placa',
                accessor: props => props.veiplaca
            },
            {
                id: 'valor', 
                Header: 'Valor',
                accessor: props => parseFloat(props.vevalor),
                Cell: props => formatMoney(props.value),
                width: 120
            },
            {
                id: 'estoque', 
                Header: 'Dias estoque',
                accessor: props => ( props.created_at != null ? moment().diff(moment(props.created_at.seconds * 1000).locale('pt-BR').format("YYYY-MM-DD"), 'days') : "" ),
                width: 125,
            },
            {
                id: 'inclusao', 
                Header: 'Inclusão',
                accessor: "created_at",
                Cell: props => ( props.value != null ? moment(props.value.seconds * 1000).locale('pt-BR').format("DD/MM/YYYY HH:mm") : "" ),
                width: 125,
            },
            {
                id: 'alteracao', 
                Header: 'Alteração',
                accessor: "updated_at",
                Cell: props => ( props.value != null ? moment(props.value.seconds * 1000).locale('pt-BR').format("DD/MM/YYYY HH:mm") : "" ),
                width: 125,
            }
        ];

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin' && this.state.idRevenda !== this.state.usuarioLogado.revenda){
            return <Redirect to='/acesso-negado' />
        }

        return (
            <div>
                {
                    this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ? <Cabecalho /> :  <CabecalhoPainel />
                } 
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Veículos<br/><small>Revenda: { this.state.nome_revenda }</small></h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <p><Link to={"/revenda/"+this.state.idRevenda+"/veiculos/incluir" } className="btn btn-warning" >Novo Veículo</Link></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    Lista de Veículos - { this.state.countVeiculos } veículos
                                </div>
                                <div className="panel-body">
                                <ReactTable
                                    data={this.state.veiculos}
                                    columns={columns}
                                    showPagination={false}
                                    minRows={ this.state.countVeiculos }
                                    defaultPageSize={ 0 }
                                    pageSize={ this.state.countVeiculos }
                                    showPageSizeOptions={false}
                                    className={ "-highlight" }
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={this.state.modalShow} 
                    className="modal-class"
                    overlayClassName="modal-overlay"
                    >
                    <div className="modal-header">
                        <h5 className="modal-title">Dados do veículo</h5>
                        <button type="button" className="close" aria-label="Fechar" onClick={this.handleCloseModal}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body"><span dangerouslySetInnerHTML={this.rawMarkup()} /></div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-warning" onClick={this.handleCloseModal}>Fechar</button>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.modalAguardeShow} 
                    className="modal-class modal-class-aguarde"
                    overlayClassName="modal-overlay"
                    >
                    <div>Aguarde...</div>
                </Modal>
            </div>
            
        )
    }

}

function formatMoney(value) {

    var places = 2;
    var symbol = "R$ ";
    var thousand = ".";
    var decimal = ",";

	places = !isNaN(places = Math.abs(places)) ? places : 2;
	symbol = symbol !== undefined ? symbol : "$";
	thousand = thousand || ",";
	decimal = decimal || ".";
	var number = value, 
	    negative = number < 0 ? "-" : "",
	    i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
	    j = (j = i.length) > 3 ? j % 3 : 0;
	return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
};

// function formatCambio(value){
//     if(value === 'A'){
//         return "Automático";
//     }else{
//         return "Manual";
//     }
// }

export default RevendaVeiculosListar