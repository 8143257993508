import React, { Component } from 'react';
import Cabecalho from '../componentes/Cabecalho';
import { firestore } from './../firebase-config';
import { Link } from 'react-router-dom';

class Inicio extends Component {

    constructor (props){
        super(props);

        this.state = {
            incluidos: 0,
            vendidos: 0,
            alterados: 0,
            removidos: 0,
            banners: 0,
        }
        this.mounted = false;
    }

    componentDidMount(){

        this.mounted = true;
        var audio = new Audio('audio/car-alarm.mp3');

        firestore.collection("dashboard").doc("veiculos").collection("incluidos")
        .onSnapshot(querySnapshot => {
            if(this.mounted) {
                this.setState({
                    incluidos: querySnapshot.size,
                });
                audio.play();
            }
        });

        firestore.collection("dashboard").doc("veiculos").collection("vendidos")
        .onSnapshot(querySnapshot => {
            if(this.mounted) {
                this.setState({
                    vendidos: querySnapshot.size,
                });
                audio.play();
            }
        });

        firestore.collection("dashboard").doc("veiculos").collection("alterados")
        .onSnapshot(querySnapshot => {
            if(this.mounted) {
                this.setState({
                    alterados: querySnapshot.size,
                });
                audio.play();
            }
        });

        firestore.collection("dashboard").doc("veiculos").collection("removidos")
        .onSnapshot(querySnapshot => {
            if(this.mounted) {
                this.setState({
                    removidos: querySnapshot.size,
                });
                audio.play();
            }
        });

        firestore.collection("dashboard").doc("veiculos").collection("banners")
        .onSnapshot(querySnapshot => {
            if(this.mounted) {
                this.setState({
                    banners: querySnapshot.size,
                });
                audio.play();
            }
        });

    }

    componentWillUnmount(){
        this.mounted = false;
    }

    render(){
        const { 
            incluidos, 
            vendidos,
            alterados,
            removidos,
            banners,
        } = this.state;
        return(
            <div>
                <Cabecalho />
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Dashboard</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2 col-md-6">
                            <div className="panel panel-primary">
                                <div className="panel-heading">
                                    <div className="row">
                                        <div className="col-xs-3">
                                            <i className="fa fa-car fa-5x"></i>
                                        </div>
                                        <div className="col-xs-9 text-right">
                                            <div className="huge">{ incluidos }</div>
                                            <div>Incluídos</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-footer">
                                    <Link to={ "/dashboard/incluidos" } >Ver detalhes</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="panel panel-green">
                                <div className="panel-heading">
                                    <div className="row">
                                        <div className="col-xs-3">
                                            <i className="fa fa-money fa-5x"></i>
                                        </div>
                                        <div className="col-xs-9 text-right">
                                            <div className="huge">{ vendidos }</div>
                                            <div>Vendidos</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-footer">
                                    <Link to={ "/dashboard/vendidos" } >Ver detalhes</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="panel panel-yellow">
                                <div className="panel-heading">
                                    <div className="row">
                                        <div className="col-xs-3">
                                            <i className="fa fa-edit fa-5x"></i>
                                        </div>
                                        <div className="col-xs-9 text-right">
                                            <div className="huge">{ alterados }</div>
                                            <div>Alterados</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-footer">
                                    <Link to={ "/dashboard/alterados" } >Ver detalhes</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="panel panel-red">
                                <div className="panel-heading">
                                    <div className="row">
                                        <div className="col-xs-3">
                                            <i className="fa fa-times fa-5x"></i>
                                        </div>
                                        <div className="col-xs-9 text-right">
                                            <div className="huge">{ removidos }</div>
                                            <div>Removidos</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-footer">
                                    <Link to={ "/dashboard/removidos" } >Ver detalhes</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="panel panel-purple">
                                <div className="panel-heading">
                                    <div className="row">
                                        <div className="col-xs-3">
                                            <i className="fa fa-file-image-o fa-5x"></i>
                                        </div>
                                        <div className="col-xs-9 text-right">
                                            <div className="huge">{ banners }</div>
                                            <div>Banners</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-footer">
                                    <Link to={ "/dashboard/banners" } >Ver detalhes</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-md-12"><a onClick={() => this.enviarVeiculosApi()}>Gravar veículos API</a></div>
                    </div> */}
                </div>
            </div>
        )
    }
}

export default Inicio