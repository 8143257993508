import React, { Component } from 'react';
import {firestore} from '../../firebase-config';
import Cabecalho from '../../componentes/Cabecalho';
import { Link, Redirect } from 'react-router-dom';
import Modal from 'react-modal';
import moment from 'moment';
import 'moment-timezone';
import ReactTable from "react-table";
import { baixarVeiculo, excluirVeiculoIntegrador } from '../../services/VeiculoService';
import 'react-table/react-table.css';

class DashAlterados extends Component {

    constructor (props){
        super(props);

        this.state = {
            veiculos: [],
            errosSubmit: null,
            countVeiculos: 0,
            modalShow: false,
            modalAguardeShow: false,
            conteudoModal: '',
            validZipFiles: 0,
            cidade_revenda: "",
            peblocotexto: "",
            checkedItems: new Map(),
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.onChangeValidarAlterados = this.onChangeValidarAlterados.bind(this);
        this.validaAlterado = this.validaAlterado.bind(this); 
        this.mounted = false;
    }

    componentDidMount(){

        this.mounted = true;

        // // firestore.settings({ timestampsInSnapshots: true });
        // firestore.collection("dashboard").doc("veiculos").collection("alterados")
        // .onSnapshot(querySnapshot => {
        //     var items = [];
        //     querySnapshot.forEach(doc => {
        //         var document = doc.data();
        //         document.id  = doc.id;
        //         items.push(document);
        //     });
        //     if(this.mounted) {
        //         this.setState({
        //             veiculos: items,
        //             countVeiculos: items.length
        //         });
        //     }
        // });

        firestore.collection("dashboard").doc("veiculos").collection("alterados").orderBy("created_at", "desc")
        .get()
        .then((querySnapshot) => {
        // .onSnapshot(querySnapshot => {

            if(this.mounted) {
                this.setState({
                    veiculos: [],
                    countVeiculos: 0
                });
            }

            querySnapshot.forEach(veiculo_dash => {

                var doc_dash = veiculo_dash.data();
                doc_dash.id  = veiculo_dash.id;

                firestore.collection('revenda').doc(doc_dash.idRevenda).collection('integracoes').get()
                .then((snapIntegracaoRevenda) => {

                    var itemsRevenda = [];
                    snapIntegracaoRevenda.forEach(integracao_revenda_dash => {
                        itemsRevenda[integracao_revenda_dash.id] = integracao_revenda_dash.data();
                    });

                    doc_dash.integracao_revenda = itemsRevenda;

                    firestore.collection("revenda").doc(doc_dash.idRevenda).collection("veiculos").doc(doc_dash.idVeiculo).get()
                    .then(veiculo_dash => {

                        if (veiculo_dash.exists) {

                            doc_dash.veiculo = veiculo_dash.data();
                
                            firestore.collection('revenda').doc(doc_dash.idRevenda).collection('veiculos').doc(doc_dash.idVeiculo).collection('integracoes').get()
                            .then((snapIntegracao) => {

                                var items = [];
                                snapIntegracao.forEach(integracao_dash => {
                                    items[integracao_dash.id] = integracao_dash.data();
                                });

                                doc_dash.integracao_veiculo = items;
                                let veiculos = this.state.veiculos;
                                veiculos.push(doc_dash);

                                if(this.mounted) {
                                    this.setState({
                                        veiculos: veiculos,
                                        countVeiculos: veiculos.length
                                    });
                                }

                            })
                            .catch(function(error) {
                                console.log(error);
                            });

                        }else{
                            firestore.collection("dashboard").doc("veiculos").collection("alterados").doc(doc_dash.id).delete();
                        }


                    }).catch(function(error) {
                        console.log(error);
                    });

                }).catch(function(error) {
                    console.log(error);
                });
                                 
            });

        });
        
    }

    componentWillUnmount(){
        this.mounted = false;
    }

    handleCloseModal () {
        this.setState({ modalShow: false });
    }

    baixarVeiculo = async (nome_revenda, idRevenda, idVeiculo) => {

        this.setState({
            modalAguardeShow: true
        });

        await baixarVeiculo(nome_revenda, idRevenda, idVeiculo);

        this.setState({
            modalAguardeShow: false
        });

    }

    excluirVeiculo = async (integrador, id_anuncio, id_revenda, id_veiculo) => {

        this.setState({errosSubmit: null});

        var r = window.confirm("Confirma a exclusão deste anúncio no "+integrador+"?");
        if (r === true) {
            const response = await excluirVeiculoIntegrador(integrador, id_anuncio, id_veiculo, id_revenda);
            if(response.status === "success"){
                alert("Anúncio excluído com sucesso.");
                window.location.reload();
            }else{
                alert("Erro ao excluir anúncio!");
                this.setState({errosSubmit: response.data});
            }
        }

    }

    visualizarAlteracoes(nome_revenda, idRevenda, idVeiculo){

        this.setState({
            modalAguardeShow: true
        });

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection("revenda").doc(idRevenda).collection("veiculos").doc(idVeiculo).collection("alteracoes").orderBy("vedataalteracao", "desc")
        .get()
        .then(querySnapshot => {
            var dadosVeiculo = "";
            querySnapshot.forEach(doc => {

                if (doc.exists) {

                    var alt = doc.data();

                    dadosVeiculo += "Alterações dados veículo - "+ nome_revenda + "\n\nData alteração: "+ moment(alt.vedataalteracao.seconds * 1000).locale('pt-BR').format("DD/MM/YYYY HH:mm") +"\nUsuário: "+alt.veusuarioalterou+"\n\n";
                    if(alt.vefipe_marca){
                        dadosVeiculo += "Marca: "+ alt.vefipe_marca_antiga + " -> "+ alt.vefipe_marca_nova + "\n";
                    }
                    if(alt.vefipe_name){
                        dadosVeiculo += "Modelo: "+ alt.vefipe_name_antiga + " -> "+ alt.vefipe_name_nova + "\n";
                    }
                    if(alt.veano_fabricacao){
                        dadosVeiculo += "Ano fabricação: "+ alt.veano_fabricacao_antiga + " -> "+ alt.veano_fabricacao_nova + "\n";
                    }
                    if(alt.vefipe_ano_modelo){
                        dadosVeiculo += "Ano modelo: "+ alt.vefipe_ano_modelo_antiga + " -> "+ alt.vefipe_ano_modelo_nova + "\n";
                    }
                    if(alt.vevalor){
                        dadosVeiculo += "Valor: "+ formatMoney(alt.vevalor_antiga) + " -> "+ formatMoney(alt.vevalor_nova) + "\n";
                    }
                    if(alt.veveiculonovo){
                        dadosVeiculo += "Tipo veículo: "+ formatVeiculoNovo(alt.veveiculonovo_antiga) + " -> "+ formatVeiculoNovo(alt.veveiculonovo_nova) + "\n";
                    }                    
                    if(alt.vequilometragem){
                        dadosVeiculo += "Quilometragem: "+ alt.vequilometragem_antiga + " -> "+ alt.vequilometragem_nova + "\n";
                    }
                    if(alt.vecarroceria){
                        dadosVeiculo += "Carroceria: "+ alt.vecarroceria_antiga + " -> "+ alt.vecarroceria_nova + "\n";
                    }
                    if(alt.vefipe_combustivel){
                        dadosVeiculo += "Combustível: "+ alt.vefipe_combustivel_antiga + " -> "+ alt.vefipe_combustivel_nova + "\n";
                    }
                    if(alt.vecambio){
                        dadosVeiculo += "Câmbio: "+ formatCambio(alt.vecambio_antiga) + " -> "+ formatCambio(alt.vecambio_nova) + "\n";
                    }
                    if(alt.veportas){
                        dadosVeiculo += "Portas: "+ alt.veportas_antiga + " -> "+ alt.veportas_nova + "\n";
                    }
                    if(alt.vecor){
                        dadosVeiculo += "Cor: "+ alt.vecor_antiga + " -> "+ alt.vecor_nova + "\n";
                    }
                    if(alt.veipva_pago){
                        dadosVeiculo += "IPVA pago: "+ formatSimNao(alt.veipva_pago_antiga) + " -> "+ formatSimNao(alt.veipva_pago_nova) + "\n";
                    }
                    if(alt.veblindado){
                        dadosVeiculo += "Blindado: "+ formatSimNao(alt.veblindado_antiga) + " -> "+ formatSimNao(alt.veblindado_nova) + "\n";
                    }
                    if(alt.vegarantia_fabrica){
                        dadosVeiculo += "Garantia de fábrica: "+ formatSimNao(alt.vegarantia_fabrica_antiga) + " -> "+ formatSimNao(alt.vegarantia_fabrica_nova) + "\n";
                    }
                    if(alt.veobservacao){
                        dadosVeiculo += "Observação: "+ alt.veobservacao_antiga + " -> "+ alt.veobservacao_nova + "\n";
                    }
                    if(alt.opcionais){
                        dadosVeiculo += "Opcionais alterados\n";
                    }
                    if(alt.imagens){
                        dadosVeiculo += "Imagens alteradas\n";
                    }
                    dadosVeiculo += "\n------------------------------\n\n";
    
                }

            });

            var nameFolderAndFile = "Alteracoes_dados_veiculo_"+nome_revenda;
            nameFolderAndFile = nameFolderAndFile.replace(/ /g, "_");

            var element = document.createElement("a");
            var file = new Blob([dadosVeiculo], {type: 'text/plain'});
            element.href = URL.createObjectURL(file);
            element.download = nameFolderAndFile+".txt";
            element.click();

            this.setState({
                modalAguardeShow: false
            });

        });

    }

    rawMarkup(){
        var rawMarkup = this.state.conteudoModal
        return { __html: rawMarkup };
    }

    validaAlterado(idRevenda, idVeiculo, idVeiculoDashboard){

        // firestore.settings({ timestampsInSnapshots: true });
        // firestore.collection('revenda').doc(idRevenda).collection('veiculos').doc(idVeiculo).set({ vevalidacaoalterado: 1 }, { merge: true })
        // .then(res => {             
        //     firestore.collection("dashboard").doc("veiculos").collection("alterados").doc(idVeiculoDashboard).delete()
        //     .then(res => { 
        //         alert("Veículos validados com sucesso!");
        //         window.location.reload();
        //     })
        //     .catch(err => { 
        //         alert("Erro ao validar alteração de veículo!");
        //     });
        // })
        // .catch(err => { 
        //     alert("Erro ao validar alteração de veículo!");
        // });

        firestore.collection("dashboard").doc("veiculos").collection("alterados").doc(idVeiculoDashboard).delete()
        .then(res => { 
            alert("Veículo validado com sucesso!");
            window.location.reload();
        })
        .catch(err => { 
            alert("Erro ao validar alteração de veículo!");
            window.location.reload();
        });

    }

    onChangeValidarAlterados(event, idRevenda, idVeiculo, idVeiculoDashboard){
        const v = {
            "idRevenda": idRevenda,
            "idVeiculo": idVeiculo,
            "idVeiculoDashboard": idVeiculoDashboard,
        }
        const name = event.target.name;
        const isChecked = event.target.checked;
        this.setState((prevState) => { prevState.checkedItems.set(name, isChecked) });
    }

    validarAlterados(){

        if(window.confirm('Deseja validar alteração dos veículos marcados?')){

            var items = [];

            this.state.veiculos.forEach((v) => {
                this.state.checkedItems.forEach((checked, item) => {
                    if(item === v.id && checked === true){
                        items.push(v);
                    }      
                });
            });

            const qtdList = items.length;

            for(let i=0;i<qtdList;i++){
                
                const lastIndex = qtdList - 1;
                const index = i;
                var v = items[index];
                var idVeiculoDashboard = v.id;

                firestore.collection("dashboard").doc("veiculos").collection("alterados").doc(idVeiculoDashboard).delete()
                .then(res => { 
                    if(index === lastIndex){
                        alert("Veículos validados com sucesso!");
                        window.location.reload();
                    }
                })
                .catch(err => { 
                    alert("Erro ao validar veículo alterado!");
                });

            }
        }    
    }

    render(){

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin'){
            return <Redirect to='/acesso-negado' />
        }

        const columns = [
            {
                id: 'validacao', 
                Header: 'Validação',
                accessor: props => ( <p className="checkbox-item" key={props.id}><label className="checkbox-label"><input type="checkbox" name={props.id} onChange={(e) => this.onChangeValidarAlterados(e, props.idRevenda, props.idVeiculo, props.id)} /></label></p> ),
                width: 80,
            },
            {
                id: 'revenda', 
                Header: 'Revenda',
                accessor: props => ( props.logo_revenda.length > 0 ? <img src={ props.logo_revenda } style={{ maxWidth: '180px', maxHeight: '80px' }} ></img> : props.revenda ),
                Cell: props => props.value,
                width: 120,
            },
            {
                id: 'veiculo_imagem', 
                Header: '',
                accessor: props => ( props.veiculo.imagens!== undefined && props.veiculo.imagens.length > 0 ? <img src={ props.veiculo.imagens[0].fileThumb } style={{ maxWidth: '100px', maxHeight: '80px' }} ></img> : "" ),
                Cell: props => props.value,
                width: 120,
            },
            {
                id: 'veiculo', 
                Header: 'Veículo',
                accessor: props => props.veiculo.vefipe_marca + " " + props.veiculo.vefipe_name,
                width: 400
            },
            {
                id: 'veiculo_ano', 
                Header: 'Ano',
                accessor: props => props.veiculo.veano_fabricacao + "/" + props.veiculo.vefipe_ano_modelo,
                width: 80,
            },
            {
                id: 'data_incluido', 
                Header: 'Data incluído',
                accessor: "created_at",
                Cell: props => ( props.value != null ? moment(props.value.seconds * 1000).locale('pt-BR').format("DD/MM/YYYY HH:mm") : "" ),
                width: 120,
            },

            {
                id: 'data_alterado', 
                Header: 'Data alterado',
                accessor: "updated_at",
                Cell: props => ( props.value != null ? moment(props.value.seconds * 1000).locale('pt-BR').format("DD/MM/YYYY HH:mm") : "" ),
                width: 120,
            },
            {
                id: 'manutencao', 
                Header: 'Manutenção',
                accessor: props => ( <div><Link to={ "/revenda/"+props.idRevenda+"/veiculos/"+props.idVeiculo+"/visualizar"} >Visualizar</Link> / <a onClick={() => this.visualizarAlteracoes(props.revenda, props.idRevenda, props.idVeiculo)}>Alterações</a> / <a onClick={() => this.baixarVeiculo(props.revenda, props.idRevenda, props.idVeiculo)}>Baixar</a> / <Link to={ "/revenda/"+props.idRevenda+"/veiculos/"+props.idVeiculo+"/alterar"} >Alterar</Link> / <a onClick={() => this.validaAlterado(props.idRevenda, props.idVeiculo, props.id)}>Valida alteração</a></div> ),
                width: 380,
                sortable: false
            },
            {
                id: 'autoline', 
                Header: 'Autoline',
                accessor: props => ( 
                    <table  className="table table-bordered"><thead><tr><th style={{ width: '40px' }}>S.I.</th><th style={{ width: '40px' }}>S.A.</th><th style={{ width: '123px' }}>Ações</th></tr></thead><tbody><tr> 
                        { 
                            props.integracao_revenda.autoline !== undefined ? 
                                props.integracao_revenda.autoline.status ? 
                                    <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                : <td><span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span></td> 
                            : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                        } 
                        { 
                            props.integracao_veiculo.autoline !== undefined ? 
                                props.integracao_veiculo.autoline.status ? 
                                    <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                : <td><span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span></td> 
                            : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                        } 
                        { 
                            props.integracao_revenda.autoline !== undefined && props.integracao_revenda.autoline ? 
                                props.integracao_veiculo.autoline !== undefined ? 
                                    <td>
                                        <div><Link to={ "/revenda/"+props.idRevenda+"/veiculos/"+props.idVeiculo+"/integracao/autoline/"+props.integracao_veiculo.autoline.id_anuncio+"/alterar"} >Alterar</Link>
                                        <span> / </span> <a onClick={() => this.excluirVeiculo("autoline", props.integracao_veiculo.autoline.id_anuncio, props.idRevenda, props.idVeiculo)}>Excluir</a></div>
                                    </td> 
                                : <td><Link to={ "/revenda/"+props.idRevenda+"/veiculos/"+props.idVeiculo+"/integracao/autoline/incluir"} >Incluir</Link></td>
                            : <td></td> 
                        } 
                    </tr></tbody></table> 
                ),
                width: 220,
            },
            {
                id: 'autoscar', 
                Header: 'Autoscar',
                accessor: props => ( 
                    <table  className="table table-bordered"><thead><tr><th style={{ width: '40px' }}>S.I.</th><th style={{ width: '40px' }}>S.A.</th><th style={{ width: '123px' }}>Ações</th></tr></thead><tbody><tr> 
                        { 
                            props.integracao_revenda.autoscar !== undefined ? 
                                props.integracao_revenda.autoscar.status ? 
                                    <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                : <td><span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span></td> 
                            : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                        } 
                        { 
                            props.integracao_veiculo.autoscar !== undefined ? 
                                props.integracao_veiculo.autoscar.status ? 
                                    <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                : <td><span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span></td> 
                            : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                        } 
                        { 
                            props.integracao_revenda.autoscar !== undefined && props.integracao_revenda.autoscar ? 
                                props.integracao_veiculo.autoscar !== undefined ? 
                                    <td>
                                        <div><Link to={ "/revenda/"+props.idRevenda+"/veiculos/"+props.idVeiculo+"/integracao/autoscar/"+props.integracao_veiculo.autoscar.id_anuncio+"/alterar"} >Alterar</Link>
                                        <span> / </span> <a onClick={() => this.excluirVeiculo("autoscar", props.integracao_veiculo.autoscar.id_anuncio, props.idRevenda, props.idVeiculo)}>Excluir</a></div>
                                    </td> 
                                : <td><Link to={ "/revenda/"+props.idRevenda+"/veiculos/"+props.idVeiculo+"/integracao/autoscar/incluir"} >Incluir</Link></td>
                            : <td></td> 
                        } 
                    </tr></tbody></table> 
                ),
                width: 220,
            },
            {
                id: 'facebook', 
                Header: 'Facebook',
                accessor: props => ( 
                    <table  className="table table-bordered"><thead><tr><th style={{ width: '40px' }}>S.I.</th><th style={{ width: '40px' }}>S.A.</th><th style={{ width: '123px' }}>Ações</th></tr></thead><tbody><tr> 
                        { 
                            props.integracao_revenda.facebook !== undefined ? 
                                props.integracao_revenda.facebook.status ? 
                                    <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                : <td><span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span></td> 
                            : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                        } 
                        { 
                            props.integracao_veiculo.facebook !== undefined ? 
                                props.integracao_veiculo.facebook.status ? 
                                    <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                : <td><span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span></td> 
                            : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                        } 
                        { 
                            props.integracao_revenda.facebook !== undefined && props.integracao_revenda.facebook ? 
                                props.integracao_veiculo.facebook !== undefined ? 
                                    <td>
                                        <div><a onClick={() => this.excluirVeiculo("facebook", props.integracao_veiculo.facebook.id_anuncio, props.idRevenda, props.idVeiculo)}>Excluir</a></div>
                                    </td> 
                                : <td><Link to={ "/revenda/"+props.idRevenda+"/veiculos/"+props.idVeiculo+"/integracao/facebook/incluir"} >Incluir</Link></td>
                            : <td></td> 
                        } 
                    </tr></tbody></table> 
                ),
                width: 220,
            },
            {
                id: 'icarros', 
                Header: 'iCarros',
                accessor: props => ( 
                    <table  className="table table-bordered"><thead><tr><th style={{ width: '40px' }}>S.I.</th><th style={{ width: '40px' }}>S.A.</th><th style={{ width: '123px' }}>Ações</th></tr></thead><tbody><tr> 
                        { 
                            props.integracao_revenda.icarros !== undefined ? 
                                props.integracao_revenda.icarros.status ? 
                                    <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                : <td><span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span></td> 
                            : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                        } 
                        { 
                            props.integracao_veiculo.icarros !== undefined ? 
                                props.integracao_veiculo.icarros.status ? 
                                    <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                : <td><span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span></td> 
                            : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                        } 
                        { 
                            props.integracao_revenda.icarros !== undefined && props.integracao_revenda.icarros ? 
                                props.integracao_veiculo.icarros !== undefined ? 
                                    <td>
                                        <div><Link to={ "/revenda/"+props.idRevenda+"/veiculos/"+props.idVeiculo+"/integracao/icarros/"+props.integracao_veiculo.icarros.id_anuncio+"/alterar"} >Alterar</Link>
                                        <span> / </span> <a onClick={() => this.excluirVeiculo("icarros", props.integracao_veiculo.icarros.id_anuncio, props.idRevenda, props.idVeiculo)}>Excluir</a></div>
                                    </td> 
                                : <td><Link to={ "/revenda/"+props.idRevenda+"/veiculos/"+props.idVeiculo+"/integracao/icarros/incluir"} >Incluir</Link></td>
                            : <td></td> 
                        } 
                    </tr></tbody></table> 
                ),
                width: 220,
            },
            {
                id: 'instagram', 
                Header: 'Instagram',
                accessor: props => ( 
                    <table  className="table table-bordered"><thead><tr><th style={{ width: '40px' }}>S.I.</th><th style={{ width: '40px' }}>S.A.</th><th style={{ width: '123px' }}>Ações</th></tr></thead><tbody><tr> 
                        { 
                            props.integracao_revenda.instagram !== undefined ? 
                                props.integracao_revenda.instagram.status ? 
                                    <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                : <td><span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span></td> 
                            : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                        } 
                        { 
                            props.integracao_veiculo.instagram !== undefined ? 
                                props.integracao_veiculo.instagram.status ? 
                                    <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                : <td><span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span></td> 
                            : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                        } 
                        { 
                            props.integracao_revenda.instagram !== undefined && props.integracao_revenda.instagram ? 
                                props.integracao_veiculo.instagram !== undefined ? 
                                    <td>
                                        <div><a onClick={() => this.excluirVeiculo("instagram", props.integracao_veiculo.instagram.id_anuncio, props.idRevenda, props.idVeiculo)}>Excluir</a></div>
                                    </td> 
                                : <td><Link to={ "/revenda/"+props.idRevenda+"/veiculos/"+props.idVeiculo+"/integracao/instagram/incluir"} >Incluir</Link></td>
                            : <td></td> 
                        } 
                    </tr></tbody></table> 
                ),
                width: 220,
            },
            {
                id: 'mercadolivre', 
                Header: 'Mercado Livre',
                accessor: props => ( 
                    <table  className="table table-bordered"><thead><tr><th style={{ width: '40px' }}>S.I.</th><th style={{ width: '40px' }}>S.A.</th><th style={{ width: '123px' }}>Ações</th></tr></thead><tbody><tr> 
                        { 
                            props.integracao_revenda.mercadolivre !== undefined ? 
                                props.integracao_revenda.mercadolivre.status ? 
                                    <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                : <td><span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span></td> 
                            : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                        } 
                        { 
                            props.integracao_veiculo.mercadolivre !== undefined ? 
                                props.integracao_veiculo.mercadolivre.status ? 
                                    <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                : <td><span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span></td> 
                            : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                        } 
                        { 
                            props.integracao_revenda.mercadolivre !== undefined && props.integracao_revenda.mercadolivre ? 
                                props.integracao_veiculo.mercadolivre !== undefined ? 
                                    <td>
                                        <div><Link to={ "/revenda/"+props.idRevenda+"/veiculos/"+props.idVeiculo+"/integracao/mercadolivre/"+props.integracao_veiculo.mercadolivre.id_anuncio+"/alterar"} >Alterar</Link>
                                        <span> / </span> <a onClick={() => this.excluirVeiculo("mercadolivre", props.integracao_veiculo.mercadolivre.id_anuncio, props.idRevenda, props.idVeiculo)}>Excluir</a></div>
                                    </td> 
                                : <td><Link to={ "/revenda/"+props.idRevenda+"/veiculos/"+props.idVeiculo+"/integracao/mercadolivre/incluir"} >Incluir</Link></td>
                            : <td></td> 
                        } 
                    </tr></tbody></table> 
                ),
                width: 220,
            },
            {
                id: 'mobiauto', 
                Header: 'Mobiauto',
                accessor: props => ( 
                    <table  className="table table-bordered"><thead><tr><th style={{ width: '40px' }}>S.I.</th><th style={{ width: '40px' }}>S.A.</th><th style={{ width: '123px' }}>Ações</th></tr></thead><tbody><tr> 
                        { 
                            props.integracao_revenda.mobiauto !== undefined ? 
                                props.integracao_revenda.mobiauto.status ? 
                                    <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                : <td><span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span></td> 
                            : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                        } 
                        { 
                            props.integracao_veiculo.mobiauto !== undefined ? 
                                props.integracao_veiculo.mobiauto.status ? 
                                    <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                : <td><span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span></td> 
                            : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                        } 
                        { 
                            props.integracao_revenda.mobiauto !== undefined && props.integracao_revenda.mobiauto ? 
                                props.integracao_veiculo.mobiauto !== undefined ? 
                                    <td>
                                        <div><Link to={ "/revenda/"+props.idRevenda+"/veiculos/"+props.idVeiculo+"/integracao/mobiauto/"+props.integracao_veiculo.mobiauto.id_anuncio+"/alterar"} >Alterar</Link>
                                        <span> / </span> <a onClick={() => this.excluirVeiculo("mobiauto", props.integracao_veiculo.mobiauto.id_anuncio, props.idRevenda, props.idVeiculo)}>Excluir</a></div>
                                    </td> 
                                : <td><Link to={ "/revenda/"+props.idRevenda+"/veiculos/"+props.idVeiculo+"/integracao/mobiauto/incluir"} >Incluir</Link></td>
                            : <td></td> 
                        } 
                    </tr></tbody></table> 
                ),
                width: 220,
            },
            {
                id: 'meucarronovo', 
                Header: 'Na Pista',
                accessor: props => ( 
                    <table  className="table table-bordered"><thead><tr><th style={{ width: '40px' }}>S.I.</th><th style={{ width: '40px' }}>S.A.</th><th style={{ width: '123px' }}>Ações</th></tr></thead><tbody><tr> 
                        { 
                            props.integracao_revenda.meucarronovo !== undefined ? 
                                props.integracao_revenda.meucarronovo.status ? 
                                    <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                : <td><span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span></td> 
                            : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                        } 
                        { 
                            props.integracao_veiculo.meucarronovo !== undefined ? 
                                props.integracao_veiculo.meucarronovo.status ? 
                                    <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                : <td><span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span></td> 
                            : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                        } 
                        { 
                            props.integracao_revenda.meucarronovo !== undefined && props.integracao_revenda.meucarronovo ? 
                                props.integracao_veiculo.meucarronovo !== undefined ? 
                                    <td>
                                        <div><Link to={ "/revenda/"+props.idRevenda+"/veiculos/"+props.idVeiculo+"/integracao/meucarronovo/"+props.integracao_veiculo.meucarronovo.id_anuncio+"/alterar"} >Alterar</Link>
                                        <span> / </span> <a onClick={() => this.excluirVeiculo("meucarronovo", props.integracao_veiculo.meucarronovo.id_anuncio, props.idRevenda, props.idVeiculo)}>Excluir</a></div>
                                    </td> 
                                : <td><Link to={ "/revenda/"+props.idRevenda+"/veiculos/"+props.idVeiculo+"/integracao/meucarronovo/incluir"} >Incluir</Link></td>
                            : <td></td> 
                        } 
                    </tr></tbody></table> 
                ),
                width: 220,
            },
            {
                id: 'olx', 
                Header: 'Olx',
                accessor: props => ( 
                    <table  className="table table-bordered"><thead><tr><th style={{ width: '40px' }}>S.I.</th><th style={{ width: '40px' }}>S.A.</th><th style={{ width: '123px' }}>Ações</th></tr></thead><tbody><tr> 
                        { 
                            props.integracao_revenda.olx !== undefined ? 
                                props.integracao_revenda.olx.status ? 
                                    <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                : <td><span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span></td> 
                            : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                        } 
                        { 
                            props.integracao_veiculo.olx !== undefined ? 
                                props.integracao_veiculo.olx.status ? 
                                    <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                : <td><span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span></td> 
                            : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                        } 
                        { 
                            props.integracao_revenda.olx !== undefined && props.integracao_revenda.olx ? 
                                props.integracao_veiculo.olx !== undefined ? 
                                    <td>
                                        <div><Link to={ "/revenda/"+props.idRevenda+"/veiculos/"+props.idVeiculo+"/integracao/olx/"+props.integracao_veiculo.olx.id_anuncio+"/alterar"} >Alterar</Link>
                                        <span> / </span> <a onClick={() => this.excluirVeiculo("olx", props.integracao_veiculo.olx.id_anuncio, props.idRevenda, props.idVeiculo)}>Excluir</a></div>
                                    </td> 
                                : <td><Link to={ "/revenda/"+props.idRevenda+"/veiculos/"+props.idVeiculo+"/integracao/olx/incluir"} >Incluir</Link></td>
                            : <td></td> 
                        } 
                    </tr></tbody></table> 
                ),
                width: 220,
            },
            {
                id: 'webmotors', 
                Header: 'Webmotors',
                accessor: props => ( 
                    <table  className="table table-bordered"><thead><tr><th style={{ width: '40px' }}>S.I.</th><th style={{ width: '40px' }}>S.A.</th><th style={{ width: '123px' }}>Ações</th></tr></thead><tbody><tr> 
                        { 
                            props.integracao_revenda.webmotors !== undefined ? 
                                props.integracao_revenda.webmotors.status ? 
                                    <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                : <td><span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span></td> 
                            : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                        } 
                        { 
                            props.integracao_veiculo.webmotors !== undefined ? 
                                props.integracao_veiculo.webmotors.status ? 
                                    <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                : <td><span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span></td> 
                            : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                        } 
                        { 
                            props.integracao_revenda.webmotors !== undefined && props.integracao_revenda.webmotors ? 
                                props.integracao_veiculo.webmotors !== undefined ? 
                                    <td>
                                        <div><Link to={ "/revenda/"+props.idRevenda+"/veiculos/"+props.idVeiculo+"/integracao/webmotors/"+props.integracao_veiculo.webmotors.id_anuncio+"/alterar"} >Alterar</Link>
                                        <span> / </span> <a onClick={() => this.excluirVeiculo("webmotors", props.integracao_veiculo.webmotors.id_anuncio, props.idRevenda, props.idVeiculo)}>Excluir</a></div>
                                    </td> 
                                : <td><Link to={ "/revenda/"+props.idRevenda+"/veiculos/"+props.idVeiculo+"/integracao/webmotors/incluir"} >Incluir</Link></td>
                            : <td></td> 
                        } 
                    </tr></tbody></table> 
                ),
                width: 220,
            }
        ];

        return (
            <div>
                <Cabecalho />
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Veículos alterados</h1>
                        </div>
                    </div>
                    <div style={{ display: this.state.errosSubmit !== null ? " block " : " none "  }} className="alert alert-danger" role="alert">{this.state.errosSubmit}</div>
                    <div className="row">
                        <div className="col-lg-12 dashboard-page">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    Lista de veículos alterados - { this.state.countVeiculos } veículos
                                </div>
                                <div className="panel-body">
                                    <a onClick={() => this.validarAlterados()} className="btn btn-success">Validar alterados</a>
                                    <br/><br/>
                                    <ReactTable
                                        data={this.state.veiculos}
                                        columns={columns}
                                        showPagination={false}
                                        minRows={ this.state.countVeiculos }
                                        defaultPageSize={ 0 }
                                        pageSize={ this.state.countVeiculos }
                                        showPageSizeOptions={false}
                                        className={ "-highlight" }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={this.state.modalShow} 
                    className="modal-class"
                    overlayClassName="modal-overlay"
                    >
                    <div className="modal-header">
                        <h5 className="modal-title">Dados do veículo</h5>
                        <button type="button" className="close" aria-label="Fechar" onClick={this.handleCloseModal}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body"><span dangerouslySetInnerHTML={this.rawMarkup()} /></div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-warning" onClick={this.handleCloseModal}>Fechar</button>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.modalAguardeShow} 
                    className="modal-class modal-class-aguarde"
                    overlayClassName="modal-overlay"
                    >
                    <div>Aguarde...</div>
                </Modal>
            </div>
            
        )
    }

}

function formatMoney(value) {

    var places = 2;
    var symbol = "R$ ";
    var thousand = ".";
    var decimal = ",";

	places = !isNaN(places = Math.abs(places)) ? places : 2;
	symbol = symbol !== undefined ? symbol : "$";
	thousand = thousand || ",";
	decimal = decimal || ".";
	var number = value, 
	    negative = number < 0 ? "-" : "",
	    i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
	    j = (j = i.length) > 3 ? j % 3 : 0;
	return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
};

function formatVeiculoNovo(value){
    if(value === 1){
        return "Novo (0 km)";
    }else{
        return "Seminovo";
    }
}

function formatCambio(value){
    if(value === 'A'){
        return "Automático";
    }else{
        return "Manual";
    }
}

function formatSimNao(value){
    if(value === true){
        return "Sim";
    }else{
        return "Não";
    }
}

export default DashAlterados