import React, { Component } from 'react';
import { firestore } from '../../../firebase-config';
import Cabecalho from '../../../componentes/Cabecalho';
import CabecalhoPainel from '../../../componentes/CabecalhoPainel';
import { base_url, api_key } from '../../../config/config_api';
import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import MenuItem from "@material-ui/core/MenuItem";
import { Redirect } from 'react-router-dom';

class VeiculosIntegracaoOlxIncluir extends Component {

    constructor (props){
        super(props);

        this.state = {
            estaGravando: false,
            carregandoDados: true,
            returnPagina: false,
            errosSubmit: null,
            errorLoading: false,
            errorsLoading: null,
            idRevenda: props.match.params.idRevenda,
            idVeiculo: props.match.params.idVeiculo,
            nome_revenda: "",
            telefone_revenda: "",
            cep_revenda: "",
            imagens: [],
            vefipe_ano_modelo: "",
            vequilometragem: "",
            vecambio: "",
            vefipe_combustivel: "",
            vefipe_marca: "",
            vefipe_name: "",
            opcionais: [],
            veportas: "",
            veiplaca: "",
            vecor: "",
            olx_titulo_anuncio: "",
            olx_descricao_anuncio: "",
            olx_preco_anuncio: "",
            olx_cep_anuncio: "",
            olx_telefone_anuncio: "",
            veobservacao: "",
            vevalor: "",
            veremovido: 0,
            olx_marca: "",
            olx_modelo: "",
            olx_versao: "",
            olx_ano_modelo: "",
            olx_mileage: "",
            olx_fuel: "",
            olx_gearbox: "",
            olx_doors: "",
            olx_end_tag: "",
            olx_vehicle_tag: "",
            olx_car_steering: "",
            direcao: "",
            olx_carcolor: "",
            olx_motorpower: "",
            olx_cartype: "",
            olx_imagens: [],
            olx_opcionais: [],
            olx_list_marca: [],
            olx_list_modelo: [],
            olx_list_versao: [],
            versaoSelecionada: new Map(),
            olx_aceita_trocas: 0,
            olx_estado_financeiro: [],
            olx_status_financeiro: 1,
            olx_unico_dono: 0,
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }
        this.mounted = false;
        this.goBack  = this.goBack.bind(this);
        this.handleChange  = this.handleChange.bind(this);
        this.handleMarca   = this.handleMarca.bind(this);
        this.handleModelo  = this.handleModelo.bind(this);
        this.handleCheckedOpcionais = this.handleCheckedOpcionais.bind(this);
        this.handleCheckedEstadoFinanceiro = this.handleCheckedEstadoFinanceiro.bind(this);
        this.handleSubmit  = this.handleSubmit.bind(this);
        this.handleChangeRadio  = this.handleChangeRadio.bind(this);

    }

    goBack(){
        this.props.history.goBack();
    }

    componentDidMount(){

        this.mounted = true;

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection("revenda").doc(this.state.idRevenda)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    nome_revenda: doc.data().penome_fantasia,
                    telefone_revenda: doc.data().petelefone1,
                    cep_revenda: doc.data().pecep_fiscal,
                });
            }
        }).catch(function(error) {
        });

        firestore.collection("revenda").doc(this.state.idRevenda).collection("veiculos").doc(this.state.idVeiculo)
        .get()
        .then(doc => {
            if (doc.exists) {

                this.setState({
                    opcionais: doc.data().opcionais.sort(compareOlx),
                    imagens: doc.data().imagens,
                    veano_fabricacao: doc.data().veano_fabricacao,
                    vecambio: doc.data().vecambio,
                    vecor: doc.data().vecor,
                    vefipe_ano_modelo: doc.data().vefipe_ano_modelo,
                    vefipe_combustivel: doc.data().vefipe_combustivel,
                    vefipe_marca: doc.data().vefipe_marca,
                    vefipe_name: doc.data().vefipe_name,
                    veiplaca: doc.data().veiplaca,
                    veobservacao: doc.data().veobservacao,
                    veportas: doc.data().veportas,
                    vequilometragem: doc.data().vequilometragem,
                    vevalor: doc.data().vevalor,
                    veremovido: doc.data().veremovido
                });
            }
        }).catch(function(error) {
        });

        fetch(base_url+"/api/integracao/olx/incluir", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "api_key": api_key,
                "id_veiculo": this.state.idVeiculo
            })
        })
        .then(response => {

            response.json()
            .then(data => {
                if(data.status === 'success'){
                    this.setState({
                        olx_titulo_anuncio: data.data.Subject,
                        olx_descricao_anuncio: data.data.Body,
                        olx_preco_anuncio: data.data.price,
                        olx_cep_anuncio: data.data.zipcode,
                        olx_telefone_anuncio: data.data.Phone,
                        olx_marca: data.data.params.vehicle_brand,
                        olx_modelo: data.data.params.vehicle_model,
                        olx_versao: data.data.params.vehicle_version,
                        olx_ano_modelo: data.data.params.regdate,
                        olx_mileage: data.data.params.mileage,
                        olx_gearbox: data.data.params.gearbox,
                        olx_fuel: data.data.params.fuel,
                        olx_doors: data.data.params.doors,
                        olx_end_tag: data.data.params.end_tag,
                        olx_vehicle_tag: data.data.params.vehicle_tag,
                        olx_car_steering: data.data.params.car_steering,
                        direcao: data.data.direcao,
                        olx_carcolor: data.data.params.carcolor,
                        olx_motorpower: data.data.params.motorpower,
                        olx_cartype: data.data.params.cartype,
                        olx_imagens: data.data.images,
                        olx_opcionais: data.data.retOpcionais.sort(compareOlx),
                        olx_estado_financeiro: data.data.retEstadoFinanceiro.sort(compareOlx),
                        olx_list_marca: data.data.retMarca.sort(compareOlx),
                        olx_list_modelo: data.data.retModelo.sort(compareOlx),
                        olx_list_versao: data.data.retVersao.sort(compareOlx),
                    });
                }else{
                    this.setState({
                        errorLoading: true,
                        errorsLoading: data.data,
                    });
                }
            });

            this.setState({
                carregandoDados: false,
            });

        })
        .catch(error => {
            console.error("Erro get teste", error);
            this.setState({
                carregandoDados: false,
            });
        });
        
    }

    componentWillUnmount(){
        this.mounted = false;
    }

    handleMarca(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value,
            olx_list_modelo: [],
            olx_list_versao: [],
        });

        return fetch(base_url+"/api/integracao/olx/consultar-modelo", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "api_key": api_key,
                "marca": value,
                "id_veiculo": this.state.idVeiculo
            })
        })
        .then(response => {

            return response.json()
            .then(data => {
                if(data.status === 'success'){
                    this.setState({
                        olx_list_modelo: data.data.sort(compareOlx),
                        olx_modelo: 0,
                        olx_versao: 0
                    });
                }
            });

        })
        .catch(error => {
            console.error("Erro get teste", error);
        });

    }

    handleModelo(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value,
            olx_list_versao: [],
        });

        return fetch(base_url+"/api/integracao/olx/consultar-versao", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "api_key": api_key,
                "marca": this.state.olx_marca,
                "modelo": value,
                "id_veiculo": this.state.idVeiculo
            })
        })
        .then(response => {

            return response.json()
            .then(data => {
                if(data.status === 'success'){
                    this.setState({
                        olx_list_versao: data.data.sort(compareOlx),
                        olx_versao: ""
                    });
                }
            });

        })
        .catch(error => {
            console.error("Erro get teste", error);
        });

    }

    handleChange(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        });
    }

    handleChangeRadio(event) {

        const target = event.target;
        const name   = target.name;
        var newValue = !this.state[name];

        this.setState({
            [name]: newValue
        });
    }

    handleCheckedOpcionais(event) {

        const item = event.target.name; 
        var oldOpcionais = this.state.olx_opcionais;
        const oldChecked = oldOpcionais[item].checked;
        oldOpcionais[item].checked = !oldChecked;
        this.setState({
            olx_opcionais: oldOpcionais
        });

    }

    handleCheckedEstadoFinanceiro(event) {

        const item = event.target.name; 
        var oldEstadoFinanceiro = this.state.olx_estado_financeiro;
        const oldChecked = oldEstadoFinanceiro[item].checked;
        oldEstadoFinanceiro[item].checked = !oldChecked;
        this.setState({
            olx_estado_financeiro: oldEstadoFinanceiro
        });

    }

    handleSubmit(event) {

        if(this.state.olx_titulo_anuncio.length < 2 ||
            this.state.olx_descricao_anuncio.length < 2 || 
            this.state.olx_cep_anuncio.length !== 8 || 
            this.state.olx_telefone_anuncio.length < 10 || 
            this.state.olx_telefone_anuncio.length > 11 ||
            this.state.olx_status_financeiro == 0 ||
            this.state.olx_car_steering == 0 ||
            this.state.olx_marca.length < 1 ||
            this.state.olx_modelo.length < 1 ||
            this.state.olx_versao.length < 1 ||
            this.state.olx_vehicle_tag.length < 1){
                
            var showErros = "";

            if(this.state.olx_titulo_anuncio.length < 2){
                showErros += "'Título anúncio' é obrigatório.\n";
            }
            if(this.state.olx_descricao_anuncio.length < 2){
                showErros += "'Descrição anúncio' é obrigatório.\n";
            }
            if(this.state.olx_cep_anuncio.length !== 8){
                showErros += "'CEP' é obrigatório.\n";
            }
            if(this.state.olx_telefone_anuncio.length < 10 || this.state.olx_telefone_anuncio.length > 11){
                showErros += "'Telefone' é obrigatório.\n";
            }
            if(this.state.olx_status_financeiro == 0){
                showErros += "'Status financeiro' é obrigatório.\n";
            }
            if(this.state.olx_car_steering == 0){
                showErros += "'Direção' é obrigatório.\n";
            }
            if(this.state.olx_marca.length < 1){
                showErros += "'Marca' é obrigatório.\n";
            }
            if(this.state.olx_modelo.length < 1){
                showErros += "'Modelo' é obrigatório.\n";
            }
            if(this.state.olx_versao.length < 1){
                showErros += "'Versão' é obrigatório.\n";
            }
            if(this.state.olx_vehicle_tag.length < 1){
                showErros += "'Placa' é obrigatório.\n";
            }

            this.setState({errosSubmit: showErros});

            return;

        }

        this.setState({estaGravando: true, errosSubmit: null});

        var opcionais_selecionados = this.state.olx_opcionais.filter(opcionais_true);
        var estado_financeiro_selecionados = this.state.olx_estado_financeiro.filter(opcionais_true);
        var user = JSON.parse(window.localStorage.getItem("usuarioLogado"));

        var veiculo_post = {
            "olx_titulo_anuncio": this.state.olx_titulo_anuncio,
            "olx_descricao_anuncio": this.state.olx_descricao_anuncio,
            "olx_telefone_anuncio": this.state.olx_telefone_anuncio,
            "olx_preco_anuncio": this.state.olx_preco_anuncio,
            "olx_cep_anuncio": this.state.olx_cep_anuncio,
            "olx_ano_modelo": this.state.olx_ano_modelo,
            "olx_mileage": this.state.olx_mileage,
            "olx_gearbox": this.state.olx_gearbox,
            "olx_fuel": this.state.olx_fuel,
            "olx_marca": this.state.olx_marca,
            "olx_modelo": this.state.olx_modelo,
            "olx_versao": this.state.olx_versao,
            "olx_doors": this.state.olx_doors,
            "olx_end_tag": this.state.olx_end_tag,
            "olx_vehicle_tag": this.state.olx_vehicle_tag,
            "olx_car_steering": this.state.olx_car_steering,
            "olx_motorpower": this.state.olx_motorpower,
            "olx_cartype": this.state.olx_cartype,
            "olx_carcolor": this.state.olx_carcolor,        
            "olx_aceita_trocas": this.state.olx_aceita_trocas,
            "olx_estado_financeiro": estado_financeiro_selecionados,
            "olx_status_financeiro": this.state.olx_status_financeiro,            
            "olx_unico_dono": this.state.olx_unico_dono,
            "olx_imagens": this.state.olx_imagens,
            "olx_opcionais": opcionais_selecionados,
        };

        fetch(base_url+"/api/integracao/olx/enviar", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "api_key": api_key,
                "veiculo_post": veiculo_post,
                "usuario": user.id,
                "id_veiculo": this.state.idVeiculo
            })
        })
        .then(response => {

            response.json()
            .then(data => {

                console.log(data);
                            
                if(data.status === 'success'){

                    var integracao = {
                        retorno: data.data,
                        status: true,
                        id_anuncio: data.data.CodigoAnuncio
                    };
                     
                    firestore.collection('revenda').doc(this.state.idRevenda).collection('veiculos').doc(this.state.idVeiculo).collection('integracoes').doc("olx").set(integracao, { merge: true })
                    .then(res => { 
                        this.setState({returnPagina: true}); 
                    })
                    .catch(err => { 
                        this.setState({returnPagina: true}); 
                    });
                    
                }else{         
                    this.setState({errosSubmit: data.data});
                }

            });
            this.setState({estaGravando: false});  

        })
        .catch(error => {
            this.setState({estaGravando: false});  
        });

    }

    render(){
        const { 
            errosSubmit,
            estaGravando,
            idRevenda,
            idVeiculo,
            nome_revenda,
            cep_revenda,
            telefone_revenda,
            olx_titulo_anuncio,
            olx_descricao_anuncio,
            olx_preco_anuncio,
            olx_cep_anuncio,
            olx_telefone_anuncio,
            opcionais,
            imagens,
            vecambio,
            vecor,
            vefipe_ano_modelo,
            vefipe_combustivel,
            vefipe_marca,
            vefipe_name,
            veiplaca,
            veportas,
            vequilometragem,
            vevalor,
            olx_marca,
            olx_modelo,
            olx_versao,
            olx_ano_modelo,
            olx_mileage,
            olx_gearbox,
            olx_fuel,
            olx_doors,
            olx_end_tag,
            olx_vehicle_tag,
            olx_car_steering,
            direcao,
            olx_carcolor,
            olx_motorpower,
            olx_cartype,
            olx_imagens,
            olx_opcionais,
            olx_list_marca,
            olx_list_modelo,
            olx_list_versao,
            carregandoDados,
            errorLoading,
            errorsLoading,
            olx_aceita_trocas,
            olx_estado_financeiro,
            olx_status_financeiro,
            olx_unico_dono
        } = this.state;

        if(this.state.returnPagina){
            return <Redirect to={ "/revenda/"+idRevenda+"/veiculos/"+idVeiculo+"/visualizar" } />;
        }

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin' && this.state.idRevenda !== this.state.usuarioLogado.revenda){
            return <Redirect to='/acesso-negado' />
        }

        return (
            <div>
                {
                    this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ? <Cabecalho /> :  <CabecalhoPainel />
                }
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Incluir veículo Olx<br/><small>Revenda: { nome_revenda }</small></h1>
                        </div>
                    </div>
                    <div className="row formulario_integracao">
                        {
                                    
                            carregandoDados ? (<div className="col-lg-12"><img src="/img/aguarde.gif" alt="carregando"  style={{ height: '80px', marginBottom: '10px'}} /></div>) : (

                                errorLoading ? (<div style={{ display: errorsLoading !== null ? " block " : " none "  }} className="alert alert-danger" role="alert">{errorsLoading}</div>) : (
                        
                                    <div className="col-lg-12">
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                Integração Olx - Incluir veículo
                                            </div>
                                            <div className="panel-body">
                                            
                                            <ValidatorForm
                                                    ref="form"
                                                    onSubmit={this.handleSubmit}
                                                    onError={ errors => exibirErroSubmit(errors) }
                                                >
                                                <table className='table table-striped'>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ minWidth: '155px', width: '10%'}}></th>
                                                            <th style={{ width: '45%'}}>Dados Intermédio</th>
                                                            <th style={{ width: '45%'}}>Dados Olx</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Título anúncio <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td></td>
                                                            <td>
                                                                <TextValidator
                                                                    onChange={this.handleChange}
                                                                    name="olx_titulo_anuncio"
                                                                    value={olx_titulo_anuncio.toUpperCase()}
                                                                    className="form-ui-bootstrap form-integracao"
                                                                />
                                                            </td>
                                                        </tr> 
                                                        <tr>
                                                            <td>Descrição anúncio <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td></td>
                                                            <td><textarea name="olx_descricao_anuncio" className="form-ui-bootstrap" rows="10" value={olx_descricao_anuncio} onChange={this.handleChange} /></td>
                                                        </tr> 
                                                        <tr>
                                                            <td>Preço anúncio</td>
                                                            <td>{ formatMoney(vevalor) }</td>
                                                            <td>{ formatMoney(olx_preco_anuncio) }</td>
                                                        </tr>
                                                        <tr>
                                                            <td>CEP <span className="alteracao-painel">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ cep_revenda.toUpperCase() }</td>
                                                            <td>{ olx_cep_anuncio.toUpperCase() }</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>Telefone <span className="alteracao-painel">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ telefone_revenda.toUpperCase() }</td>
                                                            <td>{ olx_telefone_anuncio.toUpperCase() }</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Marca <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_marca.toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ olx_marca }
                                                                    name="olx_marca"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleMarca}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(olx_list_marca)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ olx_list_marca[key].id }>{ olx_list_marca[key].descricao }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Modelo <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_name.split(' ', 1)[0].toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ olx_modelo }
                                                                    name="olx_modelo"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleModelo}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(olx_list_modelo)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ olx_list_modelo[key].id }>{ olx_list_modelo[key].descricao }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr> 
                                                        <tr>
                                                            <td>Versão <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_name.substring(vefipe_name.split(' ', 1)[0].length, vefipe_name.length).trim().toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ olx_versao }
                                                                    name="olx_versao"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        {
                                                                            olx_list_versao.length > 1 ? <MenuItem value=""></MenuItem> : null 
                                                                        }
                                                                        {
                                                                            Object.keys(olx_list_versao)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ olx_list_versao[key].id }>{ olx_list_versao[key].descricao }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>  
                                                        <tr>
                                                            <td>Ano modelo <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_ano_modelo }</td>
                                                            <td>{ olx_ano_modelo }</td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Km <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vequilometragem }</td>
                                                            <td>{ olx_mileage }</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>Potência do motor</td>
                                                            <td></td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ olx_motorpower }
                                                                    name="olx_motorpower"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    >
                                                                        <MenuItem value="0"></MenuItem>
                                                                        <MenuItem value="1">1.0 - 1.1</MenuItem>
                                                                        <MenuItem value="2">1.2</MenuItem>
                                                                        <MenuItem value="3">1.3</MenuItem>                                                      
                                                                        <MenuItem value="4">1.4</MenuItem>                                                        
                                                                        <MenuItem value="5">1.5</MenuItem>                                                        
                                                                        <MenuItem value="6">1.6</MenuItem>                                                        
                                                                        <MenuItem value="7">1.7</MenuItem>                                                        
                                                                        <MenuItem value="8">1.8</MenuItem>                                                        
                                                                        <MenuItem value="9">1.9</MenuItem>                                                        
                                                                        <MenuItem value="10">2.0 - 2.9</MenuItem>                                                        
                                                                        <MenuItem value="11">3.0 - 3.9</MenuItem>                                                        
                                                                        <MenuItem value="12">4.0 ou mais</MenuItem>                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Tipo de automóvel</td>
                                                            <td></td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ olx_cartype }
                                                                    name="olx_cartype"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    >
                                                                        <MenuItem value="0"></MenuItem>
                                                                        {/* <MenuItem value="1">Passeio</MenuItem> */}
                                                                        <MenuItem value="2">Conversível</MenuItem>
                                                                        <MenuItem value="3">Pick-up</MenuItem>                                                      
                                                                        {/* <MenuItem value="4">Antigo</MenuItem>                                                          */}
                                                                        <MenuItem value="5">SUV</MenuItem>                                                         
                                                                        <MenuItem value="6">Buggy</MenuItem>                                                         
                                                                        <MenuItem value="7">Van/Utilitário</MenuItem>                                                         
                                                                        <MenuItem value="8">Sedã</MenuItem>                                                         
                                                                        <MenuItem value="9">Hatch</MenuItem>                                                         
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Transmissão</td>
                                                            <td>{ formatCambio(vecambio) }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ olx_gearbox }
                                                                    name="olx_gearbox"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    >
                                                                        <MenuItem value="0"></MenuItem>
                                                                        <MenuItem value="1">Manual</MenuItem>
                                                                        <MenuItem value="2">Automático</MenuItem>
                                                                        <MenuItem value="3">Semi-Automático</MenuItem>                                                      
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Combustível</td>
                                                            <td>{ vefipe_combustivel.toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ olx_fuel }
                                                                    name="olx_fuel"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    >
                                                                        <MenuItem value="0"></MenuItem>
                                                                        <MenuItem value="1">Gasolina</MenuItem>
                                                                        <MenuItem value="2">Álcool</MenuItem>
                                                                        <MenuItem value="3">Flex</MenuItem>                                                      
                                                                        <MenuItem value="4">Gás Natural</MenuItem>                                                      
                                                                        <MenuItem value="5">Diesel</MenuItem>                                                      
                                                                </SelectValidator>
                                                            </td>
                                                        </tr> 
                                                        <tr>
                                                            <td>Portas</td>
                                                            <td>{ veportas }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ olx_doors }
                                                                    name="olx_doors"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    >
                                                                        <MenuItem value="0"></MenuItem>
                                                                        <MenuItem value="1">2</MenuItem>
                                                                        <MenuItem value="2">4</MenuItem>
                                                                </SelectValidator>
                                                            </td>
                                                        </tr> 
                                                        <tr>
                                                            <td>Placa <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ veiplaca }</td>
                                                            <td>{ olx_vehicle_tag }</td>
                                                        </tr>                                                         
                                                        <tr>
                                                            <td>Cor</td>
                                                            <td>{ vecor.toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ olx_carcolor }
                                                                    name="olx_carcolor"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    >
                                                                        <MenuItem value="0"></MenuItem>
                                                                        <MenuItem value="1">Preto</MenuItem>
                                                                        <MenuItem value="2">Branco</MenuItem>
                                                                        <MenuItem value="3">Prata</MenuItem>                                                      
                                                                        <MenuItem value="4">Vermelho</MenuItem>                                                       
                                                                        <MenuItem value="5">Cinza</MenuItem>                                                       
                                                                        <MenuItem value="6">Azul</MenuItem>                                                       
                                                                        <MenuItem value="7">Amarelo</MenuItem>                                                       
                                                                        <MenuItem value="8">Verde</MenuItem>                                                       
                                                                        <MenuItem value="9">Laranja</MenuItem>                                                       
                                                                        <MenuItem value="10">Outra</MenuItem>                                                       
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>                                                        
                                                        <tr>
                                                            <td colSpan="2">Aceita troca pelo produto</td>
                                                            <td>
                                                                <label className="radio">
                                                                    <input type="radio" name="olx_aceita_trocas" value="1" onChange={this.handleChange} /> &nbsp;&nbsp;Sim
                                                                </label>
                                                                <label className="radio">
                                                                    <input type="radio" name="olx_aceita_trocas" value="2" onChange={this.handleChange} /> &nbsp;&nbsp;Não
                                                                </label>
                                                            </td>
                                                        </tr>  
                                                        <tr>
                                                            <td colSpan="2">Status financeiro <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>
                                                                <label className="radio">
                                                                    <input type="radio" name="olx_status_financeiro" value="1" onChange={this.handleChange} /> &nbsp;&nbsp;Quitado
                                                                </label>
                                                                <label className="radio">
                                                                    <input type="radio" name="olx_status_financeiro" value="2" onChange={this.handleChange} /> &nbsp;&nbsp;Financiado
                                                                </label>
                                                            </td>
                                                        </tr> 

                                                        <tr>
                                                            <td colSpan="2">Estado financeiro</td>
                                                            <td>
                                                                {
                                                                    Object.keys(olx_estado_financeiro)
                                                                    .map(key => {
                                                                        return (
                                                                            <p className="checkbox-item" key={key}>
                                                                                <label className="checkbox-label">
                                                                                    <input type="checkbox" name={key} defaultChecked={olx_estado_financeiro[key].checked ? olx_estado_financeiro[key].checked : false} onChange={this.handleCheckedEstadoFinanceiro} /> &nbsp;&nbsp;{olx_estado_financeiro[key].descricao.toUpperCase()}
                                                                                </label>
                                                                            </p>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        </tr> 
                                                        <tr>
                                                            <td colSpan="2">Único dono</td>
                                                            <td>
                                                                <label className="radio">
                                                                    <input type="radio" name="olx_unico_dono" value="1" onChange={this.handleChange} /> &nbsp;&nbsp;Sim
                                                                </label>
                                                                <label className="radio">
                                                                    <input type="radio" name="olx_unico_dono" value="2" onChange={this.handleChange} /> &nbsp;&nbsp;Não
                                                                </label>
                                                            </td>
                                                        </tr>  
                                                        <tr>
                                                            <td>Direção <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ direcao }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ olx_car_steering }
                                                                    name="olx_car_steering"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    >
                                                                        <MenuItem value="0"></MenuItem>
                                                                        <MenuItem value="1">Hidráulica</MenuItem>
                                                                        <MenuItem value="2">Elétrica</MenuItem>
                                                                        <MenuItem value="3">Mecânica</MenuItem>                                                      
                                                                        <MenuItem value="4">Assistida</MenuItem>                                                      
                                                                </SelectValidator>
                                                            </td>
                                                        </tr> 
                                                        <tr>
                                                            <td>Opcionais</td>
                                                            <td>
                                                                {
                                                                    Object.keys(opcionais)
                                                                    .map(key => {
                                                                        return (
                                                                            <span key={key}>{ opcionais[key].descricao.toUpperCase() }<br/></span>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    Object.keys(olx_opcionais)
                                                                    .map(key => {
                                                                        return (
                                                                            <p className="checkbox-item" key={key}>
                                                                                <label className="checkbox-label">
                                                                                    <input type="checkbox" name={key} defaultChecked={olx_opcionais[key].checked ? olx_opcionais[key].checked : false} onChange={this.handleCheckedOpcionais} /> &nbsp;&nbsp;{olx_opcionais[key].descricao.toUpperCase()}
                                                                                </label>
                                                                            </p>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        </tr>  
                                                        <tr>
                                                            <td>Imagens</td>
                                                            <td>
                                                                {      
                                                                    imagens !== undefined && imagens.length > 0 ? 
                                                                    ( 
                                                                        Object.keys(imagens)
                                                                        .map(key => {
                                                                            
                                                                            return (
                                                                                imagens[key].fileUrl !== null && imagens[key].fileThumb !== null ? 
                                                                                <div key={key}><img src={ imagens[key].fileThumb } alt={ imagens[key].fileName }  style={{ height: '80px', marginBottom: '10px'}} /><br/></div>
                                                                                : null
                                                                            )
                                                                            
                                                                        })
                                                                    )
                                                                    : null
                                                                }
                                                            </td>
                                                            <td>
                                                                {      
                                                                    olx_imagens !== undefined && olx_imagens.length > 0 ? 
                                                                    ( 
                                                                        Object.keys(olx_imagens)
                                                                        .map(key => {
                                                                            
                                                                            return (
                                                                                <div key={key}><img src={ olx_imagens[key] } style={{ height: '80px', marginBottom: '10px'}}  /><br/></div>
                                                                            )
                                                                            
                                                                        })
                                                                    )
                                                                    : null
                                                                }
                                                            </td>
                                                        </tr>
                                                        
                                                    </tbody>
                                                </table> 
                                                <p><span className="alteracao-painel">*</span> Dados da revenda devem ser alterados no painel <br/><span className="alteracao-app">*</span> Dados do veículo devem ser alterados no painel ou aplicativo <br/><span className="preenchimento-obrigatorio">*</span> Campo obrigatório</p>
                                                <div style={{ display: estaGravando ? " block " : " none "  }}><img src="/img/aguarde.gif" alt="carregando"  style={{ height: '80px', marginBottom: '10px'}} /></div>
                                                <div style={{ display: errosSubmit !== null ? " block " : " none "  }} className="alert alert-danger" role="alert">{errosSubmit}</div>
                                                <button onClick={this.goBack} disabled={estaGravando} className="btn btn-warning" >Voltar</button>&nbsp;&nbsp;<button type="submit" disabled={estaGravando} className="btn btn-primary" >Enviar</button>

                                                </ValidatorForm>
                                            </div>
                                        </div>
                                    </div>

                                )
                            )
                        }
                    </div>
                </div>
            </div>

        )
    }

}

function formatMoney(value) {

    var places = 2;
    var symbol = "R$ ";
    var thousand = ".";
    var decimal = ",";

	places = !isNaN(places = Math.abs(places)) ? places : 2;
	symbol = symbol !== undefined ? symbol : "$";
	thousand = thousand || ",";
	decimal = decimal || ".";
	var number = value, 
	    negative = number < 0 ? "-" : "",
	    i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
	    j = (j = i.length) > 3 ? j % 3 : 0;
	return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
};

function formatCambio(value){
    if(value === 'A'){
        return "AUTOMÁTICO";
    }else{
        return "MANUAL";
    }
}

function compareOlx(a, b) {
    const genreA = a.descricao;
    const genreB = b.descricao;
    
    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
}

function exibirErroSubmit(errors){
    window.scrollTo(0, 0);
    alert("Campos obrigatórios não preenchidos!");
}

function opcionais_true(opcional){
    return opcional.checked === true;
}

export default VeiculosIntegracaoOlxIncluir