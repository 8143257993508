import React, { Component } from 'react';
import { firestore } from '../../../firebase-config';
import Cabecalho from '../../../componentes/Cabecalho';
import CabecalhoPainel from '../../../componentes/CabecalhoPainel';
import { Redirect } from 'react-router-dom';

class VeiculosIntegracaoMobiautoIncluir extends Component {

    constructor (props){
        super(props);

        this.state = {
            idRevenda: props.match.params.idRevenda,
            idVeiculo: props.match.params.idVeiculo,
            vetipoveiculo: "",  
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }
        this.goBack  = this.goBack.bind(this);
        
    }

    goBack(){
        this.props.history.goBack();
    }

    componentDidMount(){

        this.mounted = true;

        firestore.collection("revenda").doc(this.state.idRevenda).collection("veiculos").doc(this.state.idVeiculo)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    vetipoveiculo: doc.data().vetipoveiculo,
                });
            }
        }).catch(function(error) {
        });
        
    }

    render(){
        const { 
            vetipoveiculo,
            idRevenda, 
            idVeiculo
        } = this.state;

        if(vetipoveiculo == "carro"){
            return <Redirect to={ "/revenda/"+idRevenda+"/veiculos/"+idVeiculo+"/integracao/mobiauto/incluir-carro" } />;
        }

        // if(vetipoveiculo == "moto"){
        //     return <Redirect to={ "/revenda/"+idRevenda+"/veiculos/"+idVeiculo+"/integracao/mobiauto/incluir-moto" } />;
        // }

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin' && this.state.idRevenda !== this.state.usuarioLogado.revenda){
            return <Redirect to='/acesso-negado' />
        }

        return (
            <div>
                {
                    this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ? <Cabecalho /> :  <CabecalhoPainel />
                }
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Incluir veículo Mobiauto</h1>
                        </div>
                    </div>
                    <div className="row">
                        <p>Operação não permitida para esse tipo de veículo.</p>
                        <button onClick={this.goBack} className="btn btn-warning" >Voltar</button>
                    </div>
                </div>
            </div>

        )

    }

}

export default VeiculosIntegracaoMobiautoIncluir