import React, { Component } from 'react';
import Cabecalho from './../../componentes/Cabecalho';
import { Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { getMarcas, getModelos } from '../../services/VeiculoService';
import { insertVeiculoFipe } from '../../services/FipeService';

class FipeIncluir extends Component {

    constructor (props){
        super(props);

        this.state = {
            returnPagina: false,
            estaGravando: false,
            tipo: "",
            marca_api: "",
            modelo_api: "",
            versao_api: "",
            anomod: "",
            checkedOpcionais: new Map(),
            listMarcas: [],
            listModelos: [],
            validTipoVeiculo: true,
            validMarca: true,
            validModelo: true,
            validVersao: true,
            validAnoMod: true,
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }

        this.mounted                 = false;
        this.handleSubmit            = this.handleSubmit.bind(this);
        this.goBack                  = this.goBack.bind(this);
        this.handleChangeTipoVeiculo = this.handleChangeTipoVeiculo.bind(this);        
        this.handleChangeMarca       = this.handleChangeMarca.bind(this);    
        this.handleChange            = this.handleChange.bind(this);    
    }

    goBack(){
        this.props.history.goBack();
    }

    componentDidMount(){
        this.mounted = true;
    }

    handleChangeTipoVeiculo(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            listMarcas: []
        });

        if(value > 0){
            this.getMarcas(this.validaTipoVeiculo(value));
        }
        
        this.setState({
            [name]: value
        });

    }

    handleChangeMarca(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            listModelos: []
        });

        if(this.state.tipo > 0 && value.trim() !== ""){
            this.getModelos(this.validaTipoVeiculo(this.state.tipo), value);
        }
        
        this.setState({
            [name]: value
        });

    }

    handleChange(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        });

    }

    validaTipoVeiculo(tipo){
        tipo = parseInt(tipo);
        if(tipo === 1){
            return "carro";
        }else if(tipo === 2){
            return "moto";
        }else if(tipo === 3){
            return "caminhao";
        }else{
            return "outros";
        }
    }

    getMarcas = async (tipoVeiculo) => { 
        const response = await getMarcas(tipoVeiculo) 
        if(response.status === "success"){
            this.setState({
                listMarcas: response.data
            });
        }
    } 

    getModelos = async (tipoVeiculo, marca) => { 
        const response = await getModelos(tipoVeiculo, marca) 
        if(response.status === "success"){
            this.setState({
                listModelos: response.data
            });
        }
    } 

    handleSubmit = async (event) => {

        event.preventDefault();

        this.setState({
            validTipoVeiculo: true,
            validMarca: true,
            validModelo: true,
            validVersao: true,
            validAnoMod: true,
        });

        var testeValid = 0;

        if(this.state.tipo.trim() === ""){
            this.setState({
                validTipoVeiculo: false
            });
            testeValid++;
        }

        if(this.state.marca_api.trim() === ""){
            this.setState({
                validMarca: false
            });
            testeValid++;
        }

        if(this.state.modelo_api.trim() === ""){
            this.setState({
                validModelo: false
            });
            testeValid++;
        }

        if(this.state.versao_api.trim() === ""){
            this.setState({
                validVersao: false
            });
            testeValid++;
        }

        if(this.state.anomod.trim() === ""){
            this.setState({
                validAnoMod: false
            });
            testeValid++;
        }

        if(testeValid === 0){

            this.setState({estaGravando: true});

            var veiculo = {            
                tipo: this.state.tipo,
                marca_api: this.state.marca_api.trim(),
                modelo_api: this.state.modelo_api.trim(),
                versao_api: this.state.versao_api.trim(),
                anomod: this.state.anomod.trim(),
            }   

            const response = await insertVeiculoFipe(veiculo.tipo, veiculo.marca_api, veiculo.modelo_api, veiculo.versao_api, veiculo.anomod) ;
            if(response.status === "success"){
                alert("Veículo incluído com sucesso!");
                this.setState({returnPagina: true, estaGravando: false});     
            }else{
                alert("Erro ao incluir veículo!");
                this.setState({returnPagina: false, estaGravando: false});     
            }

        }

    }

    render(){
        const { 
            estaGravando,             
            tipo,
            marca_api,
            modelo_api,
            versao_api,
            anomod,
            listMarcas,
            listModelos,
            validTipoVeiculo,
            validMarca,
            validModelo,
            validVersao,
            validAnoMod
        } = this.state;

        if(this.state.returnPagina){
            var link = "/fipe/listar";
            return <Redirect to={link} />;
        }

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin'){
            return <Redirect to='/acesso-negado' />
        }
        
        return (
            <div>
                <Cabecalho />
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Veículos FIPE Manual</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    Incluir veículo
                                </div>
                                <div className="panel-body form-veiculo">

                                    <ValidatorForm
                                        ref="form"
                                        onSubmit={this.handleSubmit}
                                    >
                                        <div className="row">
                                            <div className="col-lg-2">
                                                <div className="form-group">
                                                    <label>Tipo veículo *</label>
                                                    <select className="form-ui-bootstrap" name="tipo" onChange={this.handleChangeTipoVeiculo} value={ tipo }>
                                                        <option value=""></option>
                                                        <option value="1">Carro</option>
                                                        <option value="2">Moto</option>
                                                        <option value="3">Caminhão</option>
                                                        <option value="0">Outros</option>
                                                    </select>
                                                    {
                                                        validTipoVeiculo ? null : <p className="error">Preenchimento obrigatório</p>
                                                    } 
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="form-group">
                                                    <label>Marca *</label><br/>    
                                                    <select className="form-ui-bootstrap" name="marca_api" onChange={this.handleChangeMarca} value={ marca_api }>
                                                        <option value=""></option>
                                                        {
                                                            listMarcas.map((element, id) =>
                                                                <option key={id} value={element.marca}>{element.marca}</option>
                                                            )
                                                        }
                                                    </select>
                                                    {
                                                        validMarca ? null : <p className="error">Preenchimento obrigatório</p>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="form-group">
                                                    <label>Modelo *</label><br/>    
                                                    <select className="form-ui-bootstrap" name="modelo_api" onChange={this.handleChange} value={ modelo_api }>
                                                        <option value=""></option>
                                                        {
                                                            listModelos.map((element, id) =>
                                                                <option key={id} value={element.modelo}>{element.modelo}</option>
                                                            )
                                                        }
                                                    </select>
                                                    {
                                                        validModelo ? null : <p className="error">Preenchimento obrigatório</p>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="Versão *"
                                                        onChange={this.handleChange}
                                                        name="versao_api"
                                                        value={versao_api}
                                                        className="form-ui-bootstrap"
                                                    />
                                                    {
                                                        validVersao ? null : <p className="error">Preenchimento obrigatório</p>
                                                    } 
                                                </div>
                                            </div>  
                                            <div className="col-lg-2">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="Ano modelo *"
                                                        onChange={this.handleChange}
                                                        name="anomod"
                                                        value={anomod}
                                                        className="form-ui-bootstrap"
                                                    />
                                                    {
                                                        validAnoMod ? null : <p className="error">Preenchimento obrigatório</p>
                                                    } 
                                                </div>
                                            </div>                                          
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <Button type="submit" variant="contained" color="primary" disabled={estaGravando} >Gravar</Button> <button onClick={this.goBack} className="btn btn-warning" >Voltar</button>
                                            </div>
                                        </div>
                                    </ValidatorForm>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}

export default FipeIncluir