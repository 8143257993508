import React, { Component } from 'react';
import { firestore } from './../../firebase-config';
import Cabecalho from './../../componentes/Cabecalho';
import CabecalhoPainel from '../../componentes/CabecalhoPainel';
import { Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';

class RevendaUsuariosAlterar extends Component {

    constructor (props){
        super(props);

        this.state = {
            returnPagina: false,
            estaGravando: false,
            nome: "",
            email: "",
            role: "",
            telefone1: "",
            telefone2: "",
            idRevenda: props.match.params.idRevenda,
            idUsuario: props.match.params.idUsuario,
            nome_revenda: "",
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }
        this.mounted = false;

        this.handleChange  = this.handleChange.bind(this);
        this.handleSubmit  = this.handleSubmit.bind(this);

    }

    componentDidMount(){

        this.mounted = true;

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection("revenda").doc(this.state.idRevenda)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    nome_revenda: doc.data().penome_fantasia,
                });
            }
        }).catch(function(error) {
        });

        firestore.collection("usuarios").doc(this.state.idUsuario)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    nome: doc.data().nome,
                    role: doc.data().role,
                    email: this.state.idUsuario,
                    telefone1: doc.data().telefone1 ? doc.data().telefone1 : "",
                    telefone2: doc.data().telefone2 ? doc.data().telefone2 : ""
                });
            }
        }).catch(function(error) {
        });
        
    }

    handleChange(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {

        this.setState({estaGravando: true});

        var user = JSON.parse(window.localStorage.getItem("usuarioLogado"));

        var usuario = {
            nome: this.state.nome,
            role: this.state.role,
            updated_id: user.id,
            telefone1: this.state.telefone1,
            telefone2: this.state.telefone2
        }

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection('usuarios').doc(this.state.idUsuario).set(usuario, { merge: true })
        .then(res => {             
            this.setState({returnPagina: true, estaGravando: false});
        })
        .catch(err => { 
            this.setState({returnPagina: true, estaGravando: false});     
        });

        event.preventDefault();

    }

    render(){
        const { 
            estaGravando, 
            nome, 
            email,
            role,
            nome_revenda,
            telefone1,
            telefone2
        } = this.state;
        if(this.state.returnPagina){
            var link = "/revenda/"+this.state.idRevenda+"/usuarios/listar";
            return <Redirect to={link} />;
        }

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin' && this.state.idRevenda !== this.state.usuarioLogado.revenda){
            return <Redirect to='/acesso-negado' />
        }
        
        return (

            <div>
                {
                    this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ? <Cabecalho /> :  <CabecalhoPainel />
                } 
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Usuários<br/><small>Revenda: { nome_revenda }</small></h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    Alterar usuário
                                </div>
                                <div className="panel-body">

                                    <ValidatorForm
                                        ref="form"
                                        onSubmit={this.handleSubmit}
                                    >
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="Nome *"
                                                        onChange={this.handleChange}
                                                        name="nome"
                                                        value={nome}
                                                        className="form-ui-bootstrap"
                                                        validators={['required']}
                                                        errorMessages={['Preenchimento obrigatório']}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="E-mail *"
                                                        name="email"
                                                        value={email}
                                                        className="form-ui-bootstrap"
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="form-group">
                                                    <label>Acesso *</label>
                                                    <select className="form-ui-bootstrap" name="role" onChange={this.handleChange} value={ role }>
                                                        <option value="client">Usuário</option>
                                                        <option value="gestor">Gestor</option>
                                                    </select>                                                
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <InputMask
                                                        mask="(99) 99999-9999"
                                                        value={telefone1}
                                                        label="Telefone 1 *"
                                                        name="telefone1"
                                                        onChange={this.handleChange}
                                                    >
                                                        {() => <TextField
                                                        label="Telefone 1 *"
                                                        name="telefone1"
                                                        type="text"
                                                        className="form-ui-bootstrap"
                                                        />}
                                                    </InputMask>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <InputMask
                                                        mask="(99) 99999-9999"
                                                        value={telefone2}
                                                        label="Telefone 2 *"
                                                        name="telefone2"
                                                        onChange={this.handleChange}
                                                    >
                                                        {() => <TextField
                                                        label="Telefone 2 *"
                                                        name="telefone2"
                                                        type="text"
                                                        className="form-ui-bootstrap"
                                                        />}
                                                    </InputMask>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <Button type="submit" variant="contained" color="primary" disabled={estaGravando} >Gravar</Button>
                                            </div>
                                        </div>
                                    </ValidatorForm>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}

export default RevendaUsuariosAlterar