import React from 'react';
import { Link } from 'react-router-dom';

const ItemListarLocaisAnuncio = props => {

    return (
        <tr>
            <td>{ props.conteudo.descricao }</td>
            <td>{ props.conteudo.ordenacao }</td>
            <td>
                <Link to={ "/locais-anuncio/"+props.conteudo.id+"/alterar"} >Alterar</Link> / <Link to={ "/locais-anuncio/"+props.conteudo.id+"/excluir" } >Excluir</Link> 
           </td>
        </tr>
    )

}

export default ItemListarLocaisAnuncio