import { fipe_url, fipe_api_token } from '../config/config_api';

export const getVeiculosFipe = async () => {

  return fetch(fipe_url+"/fipe-manual/listar", { 
    method: "POST", 
    headers: { 
      'Content-Type': 'application/json',
    }, 
    body: JSON.stringify({
        "api_token": fipe_api_token
    })  
  })
  .then(response => {
    return response.json()
    .then(data => {
    if(data.status === 'success'){
        return {
          status: "success",
          data: data.data
        };            
      }else{
        return {
          status: "error",
          data: data.data
        };
      }
    })
    .catch(error => {
      return {
        status: "error",
        data: error
      };
    });
  })
  .catch(error => {
    return {
      status: "error",
      data: error
    };
  });
}

export const deleteVeiculoFipe = async (idVeiculo) => {

  return fetch(fipe_url+"/fipe-manual/deletar", { 
    method: "POST", 
    headers: { 
      'Content-Type': 'application/json',
    }, 
    body: JSON.stringify({
        "api_token": fipe_api_token,
        "veiculo_id": idVeiculo
    })  
  })
  .then(response => {
    return response.json()
    .then(data => {
    if(data.status === 'success'){
        return {
          status: "success",
          data: data.data
        };            
      }else{
        return {
          status: "error",
          data: data.data
        };
      }
    })
    .catch(error => {
      return {
        status: "error",
        data: error
      };
    });
  })
  .catch(error => {
    return {
      status: "error",
      data: error
    };
  });
}

export const insertVeiculoFipe = async (tipo, marca_api, modelo_api, versao_api, anomod) => {

  return fetch(fipe_url+"/fipe-manual/incluir", { 
    method: "POST", 
    headers: { 
      'Content-Type': 'application/json',
    }, 
    body: JSON.stringify({
        "api_token": fipe_api_token,
        "tipo": tipo,
        "marca_api": marca_api,
        "modelo_api": modelo_api,
        "versao_api": versao_api,
        "anomod": anomod,
    })  
  })
  .then(response => {
    return response.json()
    .then(data => {
    if(data.status === 'success'){
        return {
          status: "success",
          data: data.data
        };            
      }else{
        return {
          status: "error",
          data: data.data
        };
      }
    })
    .catch(error => {
      return {
        status: "error",
        data: error
      };
    });
  })
  .catch(error => {
    return {
      status: "error",
      data: error
    };
  });
}