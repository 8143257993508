import MD5 from "crypto-js/md5";
import momtime from "moment-timezone/moment-timezone";
import { token_key } from '../config/config_api';

export const gerarStringMd5 = (string) => {
    // var data_atual = new Date();
    // var dia_atual = data_atual.getUTCDate();
    // var mes_atual = data_atual.getUTCMonth() + 1;
    // var ano_atual = data_atual.getFullYear();
    // var string_md5 = dia_atual.toString()+mes_atual.toString()+ano_atual.toString()+string;

    var string_md5 = momtime().tz('America/Sao_Paulo').format("DMY") + string;

    return MD5(string_md5).toString();
}

export const sendNotificacao = (data_notificacao) => {
    if (data_notificacao.tokens.length > 0) {
        return fetch("https://fcm.googleapis.com/fcm/send", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'key=' + token_key,
            },
            body: JSON.stringify({
                "notification": {
                    "body": data_notificacao.titulo,
                    "title": "Lembrete de notificação",
                },
                "registration_ids": data_notificacao.tokens
            })
        })
        .then(response => {
            return response.json()
                .then(data => {
                    if (data.success === data_notificacao.tokens.length) {
                        return {
                            status: true,
                            data: "Envio concluído com sucesso."
                        };
                    } else {
                        return {
                            status: false,
                            data: "Erro ao enviar lembrete."
                        };
                    }
                })
                .catch(error => {
                    return {
                        status: false,
                        data: error
                    };
                });
        })
        .catch(error => {
            return {
                status: false,
                data: error
            };
        });
    } else {
        return {
            status: false,
            data: "Nenhum usuário habilitado para receber notificações."
        };
    }

}