import React, { Component } from 'react';
import { firestore, storage } from './../../firebase-config';
import Cabecalho from './../../componentes/Cabecalho';
import { Redirect } from 'react-router-dom';
import * as firebase from "firebase/app";
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import CurrencyInput from 'react-currency-input';

class RevendaAlterar extends Component {

    constructor (props){
        super(props);

        this.state = {
            returnPagina: false,
            isUploading: false,
            idRevenda: props.match.params.idRevenda,
            estaGravando: false,
            perazao_social_nome: "",
            penome_fantasia: "",
            pecpf_cnpj: "",
            peinscricaoestadual: "",
            peinscricaomunicipal: "",
            peemail: "",
            pehomepage: "",
            petelefone1: "",
            petelefone2: "",
            petelefone3: "",
            pecep_fiscal: "",
            peendereco_fiscal: "",
            peendereco_fiscal_numero: 0,
            peendereco_fiscal_compl: "",
            pebairro_fiscal: "",
            pecidade: "",
            peuf: "",
            peblocotexto: "",
            peblocotextovendidos: "",
            peblocotextobanners: "",
            plano: "",
            recursos: [],
            logotipo: "",
            transformation: "",
            transformation_vendidos: "",
            transformation_videos: "",
            locaisanuncio: [],
            checkedItems: new Map(),
            checkedRecursos: new Map(),
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
            validPlano: true,
            texto_sobre_nos_site: "",
            horario_seg_sex: "",
            horario_sab: "",
            mapa_link: "",
            instagram_link: "",
            facebook_link: "",
            pedatavencimento: 0,
            pevalormensalidade: 0,
            pevalormensalidade_mask: "",
            pestatus: "",
            peobsadm: "", 
        }
        this.mounted = false;

        this.handleChange  = this.handleChange.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.handleCheckedRecursos = this.handleCheckedRecursos.bind(this); 
        this.handleFiles   = this.handleFiles.bind(this);
        this.handleSubmit  = this.handleSubmit.bind(this);
        this.handleChangeValue = this.handleChangeValue.bind(this);

    }

    componentDidMount(){

        this.mounted = true;

        firestore.collection("revenda").doc(this.state.idRevenda)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    perazao_social_nome: doc.data().perazao_social_nome === undefined || doc.data().perazao_social_nome === null ? "" : doc.data().perazao_social_nome,
                    penome_fantasia: doc.data().penome_fantasia === undefined || doc.data().penome_fantasia === null ? "" : doc.data().penome_fantasia,
                    pecpf_cnpj: doc.data().pecpf_cnpj === undefined || doc.data().pecpf_cnpj === null ? "" : doc.data().pecpf_cnpj,
                    peinscricaoestadual: doc.data().peinscricaoestadual === undefined || doc.data().peinscricaoestadual === null ? "" : doc.data().peinscricaoestadual,
                    peinscricaomunicipal: doc.data().peinscricaomunicipal === undefined || doc.data().peinscricaomunicipal === null ? "" : doc.data().peinscricaomunicipal,
                    peemail: doc.data().peemail === undefined || doc.data().peemail === null ? "" : doc.data().peemail,
                    pehomepage: doc.data().pehomepage === undefined || doc.data().pehomepage === null ? "" : doc.data().pehomepage,
                    petelefone1: doc.data().petelefone1 === undefined || doc.data().petelefone1 === null ? "" : doc.data().petelefone1,
                    petelefone2: doc.data().petelefone2 === undefined || doc.data().petelefone2 === null ? "" : doc.data().petelefone2,
                    petelefone3: doc.data().petelefone3 === undefined || doc.data().petelefone3 === null ? "" : doc.data().petelefone3,
                    pecep_fiscal: doc.data().pecep_fiscal === undefined || doc.data().pecep_fiscal === null ? "" : doc.data().pecep_fiscal,
                    peendereco_fiscal: doc.data().peendereco_fiscal === undefined || doc.data().peendereco_fiscal === null ? "" : doc.data().peendereco_fiscal,
                    peendereco_fiscal_compl: doc.data().peendereco_fiscal_compl === undefined || doc.data().peendereco_fiscal_compl === null ? "" : doc.data().peendereco_fiscal_compl,
                    pebairro_fiscal: doc.data().pebairro_fiscal === undefined || doc.data().pebairro_fiscal === null ? "" : doc.data().pebairro_fiscal,
                    pecidade: doc.data().pecidade === undefined || doc.data().pecidade === null ? "" : doc.data().pecidade,
                    peuf: doc.data().peuf === undefined || doc.data().peuf === null ? "" : doc.data().peuf,
                    plano: doc.data().plano === undefined || doc.data().plano === null ? "" : doc.data().plano,
                    transformation: doc.data().transformation === undefined || doc.data().transformation === null ? "" : doc.data().transformation,
                    transformation_vendidos: doc.data().transformation_vendidos === undefined || doc.data().transformation_vendidos === null ? "" : doc.data().transformation_vendidos,
                    transformation_videos: doc.data().transformation_videos === undefined || doc.data().transformation_videos === null ? "" : doc.data().transformation_videos,
                    logotipo: doc.data().logotipo === undefined || doc.data().logotipo === null ? "" : doc.data().logotipo,
                    peblocotexto: doc.data().peblocotexto === undefined || doc.data().peblocotexto === null ? "" : doc.data().peblocotexto,
                    peblocotextovendidos: doc.data().peblocotextovendidos === undefined || doc.data().peblocotextovendidos === null ? "" : doc.data().peblocotextovendidos,
                    peblocotextobanners: doc.data().peblocotextobanners === undefined || doc.data().peblocotextobanners === null ? "" : doc.data().peblocotextobanners,
                    texto_sobre_nos_site: doc.data().texto_sobre_nos_site === undefined || doc.data().texto_sobre_nos_site === null ? "" : doc.data().texto_sobre_nos_site,
                    horario_seg_sex: doc.data().horario_seg_sex === undefined || doc.data().horario_seg_sex === null ? "" : doc.data().horario_seg_sex,
                    horario_sab: doc.data().horario_sab === undefined || doc.data().horario_sab === null ? "" : doc.data().horario_sab,
                    mapa_link: doc.data().mapa_link === undefined || doc.data().mapa_link === null ? "" : doc.data().mapa_link,
                    instagram_link: doc.data().instagram_link === undefined || doc.data().instagram_link === null ? "" : doc.data().instagram_link,
                    facebook_link: doc.data().facebook_link === undefined || doc.data().facebook_link === null ? "" : doc.data().facebook_link,                    
                    pedatavencimento: doc.data().pedatavencimento === undefined || doc.data().pedatavencimento === null ? "" : doc.data().pedatavencimento,
                    pevalormensalidade: doc.data().pevalormensalidade === undefined || doc.data().pevalormensalidade === null ? "" : doc.data().pevalormensalidade,
                    pevalormensalidade_mask: doc.data().pevalormensalidade === undefined || doc.data().pevalormensalidade === null ? formatMoney(0.0) : formatMoney(doc.data().pevalormensalidade),                    
                    pestatus: doc.data().pestatus === undefined || doc.data().pestatus === null ? "" : doc.data().pestatus,
                    peobsadm: doc.data().peobsadm === undefined || doc.data().peobsadm === null ? "" : doc.data().peobsadm,
                });

                if(isNaN(doc.data().peendereco_fiscal_numero)){
                    this.setState({ peendereco_fiscal_numero: 0 });
                }else{
                    this.setState({ peendereco_fiscal_numero: parseInt(doc.data().peendereco_fiscal_numero) });
                }

                var recursos_plano = [
                    {
                        "id": "historico",
                        "descricao": "Histórico de veículos excluídos",
                        "checked": true
                    },
                    {
                        "id": "locais_anuncio",
                        "descricao": "Selecionar locais de anúncio",
                        "checked": true
                    },
                    {
                        "id": "marca_dagua",
                        "descricao": "Logo personalizada nas fotos",
                        "checked": true
                    },
                    {
                        "id": "site_revenda",
                        "descricao": "Site revenda com intermédio",
                        "checked": false
                    },
                    {
                        "id": "solicitacao_arte",
                        "descricao": "Solicitações arte e outros",
                        "checked": true
                    },
                ];

                var recursos_revenda = doc.data().recursos;

                recursos_plano.forEach(rec => {
                    if(rec.checked){
                        this.setState( this.state.checkedRecursos.set(rec.id, true) );
                    }
                });

                if(recursos_revenda !== undefined){
                    recursos_plano.forEach(rec => {
                        if(recursos_revenda[rec.id] !== undefined){
                            rec.checked = recursos_revenda[rec.id];
                            this.setState( this.state.checkedRecursos.set(rec.id, recursos_revenda[rec.id]) );
                        }
                    });
                }

                this.setState({
                    recursos: recursos_plano
                });

            }

            var locais_anuncio_revenda = [];
            firestore.collection('revenda').doc(this.state.idRevenda).collection('locais_anuncio_revenda')
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    locais_anuncio_revenda.push(doc.data());
                });

                firestore.collection("locais_anuncio").orderBy("ordenacao", "desc").orderBy("descricao", "asc")
                .get()
                .then(querySnapshot => {
                    var items = [];
                    querySnapshot.forEach(doc => {
                        var document = doc.data();
                        document.id  = doc.id;
                        document.checked = false;

                        locais_anuncio_revenda.forEach(local => {
                            if(local.idLocal === document.id){
                                document.checked = true;
                                this.setState( this.state.checkedItems.set(document.id, true) );
                            }
                        });
                        items.push(document);
                    });
                    if(this.mounted) {
                        this.setState({
                            locaisanuncio: items
                        });
                    }
                });
                
            });        

        }).catch(function(error) {
        });        
        
    }

    handleChangeValue(event, maskedvalue, floatvalue){
        this.setState({
            pevalormensalidade: floatvalue,
            pevalormensalidade_mask: maskedvalue,
        });
    }

    handleChange(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        });
    }

    handleChecked(event) {
        const item = event.target.name; 
        const isChecked = event.target.checked;
        this.setState((prevState) => { prevState.checkedItems.set(item, isChecked) });
    }

    handleCheckedRecursos(event) {
        const item = event.target.name; 
        const isChecked = event.target.checked;
        this.setState((prevState) => { prevState.checkedRecursos.set(item, isChecked) });
    }

    createFileName(fileName){
        const extensao = fileName.split('.').pop();
        var d = new Date(),
        n = d.getTime(),
        newFileName = n + "." + extensao;
        return newFileName;
    }

    handleFiles(event) {

        this.setState({isUploading: true, estaGravando: true});

        const target   = event.target;
        const fileList = target.files;

        if(fileList.length > 0){

            var file = fileList[0];

            const fileName = this.state.peemail + "_" + this.createFileName(file.name);
            const filePath = "__cliente_novo_logo/" + fileName;
            const ref = storage.ref(filePath);

            var uploadTask = ref.put(file);
            uploadTask.on('state_changed', function(snapshot){
            }, function(error) {
                console.log("Erro no upload", error);
                this.setState({
                    isUploading: false,
                    estaGravando: false
                });
            }, function() {
                uploadTask.snapshot.ref.getDownloadURL()
                .then(downloadURL => {
                    this.setState({
                        logotipo: downloadURL, 
                        isUploading: false,
                        estaGravando: false
                    });
                });
            }.bind(this));

        }

    }

    handleSubmit(event) {

        if(this.state.plano === undefined || this.state.plano === ""){
            this.setState({
                validPlano: false
            });
        }else{

            this.setState({estaGravando: true});

            this.setState({
                validPlano: true
            });

            let new_recursos = {};
            this.state.recursos.forEach(o => {
                new_recursos[o.id] = false;
                this.state.checkedRecursos.forEach((recurso, id) => {
                    if(id === o.id){
                        new_recursos[o.id] = recurso;
                    }
                });
            });

            var revenda = {
                perazao_social_nome: this.state.perazao_social_nome,
                penome_fantasia: this.state.penome_fantasia,
                pecpf_cnpj: this.state.pecpf_cnpj,
                peinscricaoestadual: this.state.peinscricaoestadual,
                peinscricaomunicipal: this.state.peinscricaomunicipal,
                peemail: this.state.peemail,
                pehomepage: this.state.pehomepage,
                petelefone1: this.state.petelefone1,
                petelefone2: this.state.petelefone2,
                petelefone3: this.state.petelefone3,
                pecep_fiscal: this.state.pecep_fiscal,
                peendereco_fiscal: this.state.peendereco_fiscal,
                peendereco_fiscal_compl: this.state.peendereco_fiscal_compl,
                pebairro_fiscal: this.state.pebairro_fiscal,
                pecidade: this.state.pecidade,
                peuf: this.state.peuf,
                peblocotexto: this.state.peblocotexto,
                peblocotextovendidos: this.state.peblocotextovendidos,          
                peblocotextobanners: this.state.peblocotextobanners,          
                logotipo: this.state.logotipo,
                plano: this.state.plano,
                recursos: new_recursos,
                created_id: this.state.usuarioLogado.id,
                updated_id: this.state.usuarioLogado.id,
                updated_at: firebase.firestore.FieldValue.serverTimestamp(),
                texto_sobre_nos_site: this.state.texto_sobre_nos_site,
                horario_seg_sex: this.state.horario_seg_sex,
                horario_sab: this.state.horario_sab,
                mapa_link: this.state.mapa_link,
                instagram_link: this.state.instagram_link,
                facebook_link: this.state.facebook_link,
                pedatavencimento: this.state.pedatavencimento,
                pevalormensalidade: this.state.pevalormensalidade.toString(),
                pestatus: this.state.pestatus,
                peobsadm: this.state.peobsadm, 
            }

            if(this.state.transformation !== undefined && this.state.transformation.length > 0){
                revenda.transformation = this.state.transformation;
            }else{
                revenda.transformation = null;
            }

            if(this.state.transformation_vendidos !== undefined && this.state.transformation_vendidos.length > 0){
                revenda.transformation_vendidos = this.state.transformation_vendidos;
            }else{
                revenda.transformation_vendidos = null;
            }
            if(this.state.transformation_videos !== undefined && this.state.transformation_videos.length > 0){
                revenda.transformation_videos = this.state.transformation_videos;
            }else{
                revenda.transformation_videos = null;
            }

            if(this.state.peendereco_fiscal_numero.length > 0 && !isNaN(this.state.peendereco_fiscal_numero)){
                revenda.peendereco_fiscal_numero = parseInt(this.state.peendereco_fiscal_numero);
            }

            firestore.collection('revenda').doc(this.state.idRevenda).set(revenda, { merge: true })
            .then(res => { 

                firestore.collection('revenda').doc(this.state.idRevenda).collection('locais_anuncio_revenda')
                .get()
                .then(querySnapshot => {

                    querySnapshot.forEach((doc) => {
                        firestore.collection('revenda').doc(this.state.idRevenda).collection('locais_anuncio_revenda').doc(doc.id).delete();
                    });

                    this.state.checkedItems.forEach((localSelecionado, id) => {
                        if(localSelecionado){
                            this.state.locaisanuncio.forEach(local1 => {
                                if(local1.id === id){
                                    var localDeAnuncio = {
                                        descricao: local1.descricao,
                                        idLocal: local1.id,
                                        ordenacao: parseInt(local1.ordenacao)
                                    }
                                    firestore.collection('revenda').doc(this.state.idRevenda).collection('locais_anuncio_revenda').add(localDeAnuncio);
                                }
                            });
                        }        
                    });
                });

                this.setState({returnPagina: true, estaGravando: false});
            })
            .catch(err => { 
                this.setState({returnPagina: true, estaGravando: false});     
            });

        }

        event.preventDefault();

    }

    createListRecursos = () => {

        const listItems = this.state.recursos.map((element, id) =>
            <p className="checkbox-item" key={element.id}>
                <label className="checkbox-label">
                    <input type="checkbox" name={element.id} defaultChecked={element.checked} onChange={this.handleCheckedRecursos} /> 
                    &nbsp;&nbsp;{element.descricao}
                </label>
            </p>
        );

        return (
            <div className="form-group">{listItems}</div>
        );

    }

    render(){
        const { 
            isUploading,
            estaGravando, 
            perazao_social_nome, 
            penome_fantasia,
            pecpf_cnpj,
            peinscricaoestadual,
            peinscricaomunicipal,
            peemail,
            pehomepage,
            petelefone1,
            petelefone2,
            petelefone3,
            pecep_fiscal,
            peendereco_fiscal,
            peendereco_fiscal_numero,
            peendereco_fiscal_compl,
            pebairro_fiscal,
            pecidade,
            peuf,
            peblocotexto,
            peblocotextovendidos,
            peblocotextobanners,
            logotipo,
            locaisanuncio,
            plano,
            validPlano,
            transformation,
            transformation_vendidos,
            transformation_videos,                      
            texto_sobre_nos_site,
            horario_seg_sex,
            horario_sab,
            mapa_link,
            instagram_link,
            facebook_link ,
            pedatavencimento,
            pevalormensalidade_mask,
            pestatus,
            peobsadm,
        } = this.state;
        if(this.state.returnPagina){
            return <Redirect to="/revenda/listar" />;
        }
        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin' && this.state.idRevenda !== this.state.usuarioLogado.revenda){
            return <Redirect to='/acesso-negado' />
        }
        return (

            <div>
                {/* {
                    this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ? <Cabecalho /> :  <CabecalhoPainel />
                } */}
                <Cabecalho />
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Revendas</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    Alterar revenda
                                </div>
                                <div className="panel-body">

                                    <ValidatorForm
                                        ref="form"
                                        onSubmit={this.handleSubmit}
                                    >
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="Nome do responsável *"
                                                        onChange={this.handleChange}
                                                        name="perazao_social_nome"
                                                        value={perazao_social_nome}
                                                        className="form-ui-bootstrap"
                                                        validators={['required']}
                                                        errorMessages={['Preenchimento obrigatório']}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="Nome da loja *"
                                                        onChange={this.handleChange}
                                                        name="penome_fantasia"
                                                        value={penome_fantasia}
                                                        className="form-ui-bootstrap"
                                                        validators={['required']}
                                                        errorMessages={['Preenchimento obrigatório']}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="CPF/CNPJ"
                                                        onChange={this.handleChange}
                                                        name="pecpf_cnpj"
                                                        value={pecpf_cnpj}
                                                        className="form-ui-bootstrap"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="Inscrição estadual"
                                                        onChange={this.handleChange}
                                                        name="peinscricaoestadual"
                                                        value={peinscricaoestadual}
                                                        className="form-ui-bootstrap"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="Inscrição municipal"
                                                        onChange={this.handleChange}
                                                        name="peinscricaomunicipal"
                                                        value={peinscricaomunicipal}
                                                        className="form-ui-bootstrap"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="E-mail *"
                                                        onChange={this.handleChange}
                                                        name="peemail"
                                                        value={peemail}
                                                        className="form-ui-bootstrap"
                                                        validators={['required', 'isEmail']}
                                                        errorMessages={['Preenchimento obrigatório', 'E-mail inválido']}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="Site"
                                                        onChange={this.handleChange}
                                                        name="pehomepage"
                                                        value={pehomepage}
                                                        className="form-ui-bootstrap"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    {/* <TextValidator
                                                        label="Telefone fixo *"
                                                        onChange={this.handleChange}
                                                        name="petelefone1"
                                                        value={petelefone1}
                                                        className="form-ui-bootstrap"
                                                        validators={['required']}
                                                        errorMessages={['Preenchimento obrigatório']}
                                                    /> */}
                                                    <InputMask
                                                        mask="(99) 99999-9999"
                                                        value={petelefone1}
                                                        label="Telefone 1 *"
                                                        name="petelefone1"
                                                        onChange={this.handleChange}
                                                    >
                                                        {() => <TextField
                                                        label="Telefone 1 *"
                                                        name="petelefone1"
                                                        type="text"
                                                        className="form-ui-bootstrap"
                                                        />}
                                                    </InputMask>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <InputMask
                                                        mask="(99) 99999-9999"
                                                        value={petelefone2}
                                                        label="Telefone 2"
                                                        name="petelefone2"
                                                        onChange={this.handleChange}
                                                    >
                                                        {() => <TextField
                                                        label="Telefone 2 (whatsapp)"
                                                        name="petelefone2"
                                                        type="text"
                                                        className="form-ui-bootstrap"
                                                        />}
                                                    </InputMask>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <InputMask
                                                        mask="(99) 99999-9999"
                                                        value={petelefone3}
                                                        label="Telefone 3"
                                                        name="petelefone3"
                                                        onChange={this.handleChange}
                                                    >
                                                        {() => <TextField
                                                        label="Telefone 3 (whatsapp)"
                                                        name="petelefone3"
                                                        type="text"
                                                        className="form-ui-bootstrap"
                                                        />}
                                                    </InputMask>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <InputMask
                                                        mask="99999-999"
                                                        value={pecep_fiscal}
                                                        label="CEP *"
                                                        name="pecep_fiscal"
                                                        onChange={this.handleChange}
                                                    >
                                                        {() => <TextField
                                                        label="CEP *"
                                                        name="pecep_fiscal"
                                                        type="text"
                                                        className="form-ui-bootstrap"
                                                        />}
                                                    </InputMask>
                                                </div>
                                            </div>
                                            <div className="col-lg-7">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="Endereço"
                                                        onChange={this.handleChange}
                                                        name="peendereco_fiscal"
                                                        value={peendereco_fiscal}
                                                        className="form-ui-bootstrap"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="Número"
                                                        onChange={this.handleChange}
                                                        name="peendereco_fiscal_numero"
                                                        value={peendereco_fiscal_numero}
                                                        className="form-ui-bootstrap"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="Complemento"
                                                        onChange={this.handleChange}
                                                        name="peendereco_fiscal_compl"
                                                        value={peendereco_fiscal_compl}
                                                        className="form-ui-bootstrap"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="Bairro"
                                                        onChange={this.handleChange}
                                                        name="pebairro_fiscal"
                                                        value={pebairro_fiscal}
                                                        className="form-ui-bootstrap"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="Cidade"
                                                        onChange={this.handleChange}
                                                        name="pecidade"
                                                        value={pecidade}
                                                        className="form-ui-bootstrap"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="form-group">
                                                    <label>UF</label>
                                                    <select className="form-ui-bootstrap" name="peuf" onChange={this.handleChange} value={ peuf }>
                                                        <option value=""></option>
                                                        <option value="AC">ACRE</option>
                                                        <option value="AL">ALAGOAS</option>
                                                        <option value="AP">AMAPA</option>
                                                        <option value="AM">AMAZONAS</option>
                                                        <option value="BA">BAHIA</option>
                                                        <option value="CE">CEARA</option>
                                                        <option value="DF">DISTRITO FEDERAL</option>
                                                        <option value="ES">ESPIRITO SANTO</option>
                                                        <option value="GO">GOIAS</option>
                                                        <option value="MA">MARANHAO</option>
                                                        <option value="MT">MATO GROSSO</option>
                                                        <option value="MS">MATO GROSSO DO SUL</option>
                                                        <option value="MG">MINAS GERAIS</option>
                                                        <option value="PA">PARA</option>
                                                        <option value="PB">PARAIBA</option>
                                                        <option value="PR">PARANA</option>
                                                        <option value="PE">PERNAMBUCO</option>
                                                        <option value="PI">PIAUI</option>
                                                        <option value="RJ">RIO DE JANEIRO</option>
                                                        <option value="RN">RIO GRANDE DO NORTE</option>
                                                        <option value="RS">RIO GRANDE DO SUL</option>
                                                        <option value="RO">RONDONIA</option>
                                                        <option value="RR">RORAIMA</option>
                                                        <option value="SC">SANTA CATARINA</option>
                                                        <option value="SP">SAO PAULO</option>
                                                        <option value="SE">SERGIPE</option>
                                                        <option value="TO">TOCANTINS</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="Dia vencimento"
                                                        onChange={this.handleChange}
                                                        name="pedatavencimento"
                                                        value={pedatavencimento}
                                                        className="form-ui-bootstrap"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label>Valor mensalidade</label><br/>   
                                                    <CurrencyInput 
                                                        onChangeEvent={this.handleChangeValue}
                                                        label="Valor mensalidade"
                                                        name="pevalormensalidade"
                                                        value={pevalormensalidade_mask}
                                                        className="form-ui-bootstrap"
                                                        decimalSeparator=","
                                                        thousandSeparator="."
                                                        precision="2"
                                                        prefix="R$ "
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label>Status</label><br/>
                                                    <input className="form-ui-bootstrap" name="pestatus" type="color" onChange={this.handleChange} value={pestatus} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>Logo da revenda</label>
                                                    <div style={{ display: "flex" }}>
                                                        <input type="file" name="novasImagens" id="novasImagens" onChange={this.handleFiles} ></input>
                                                        { isUploading ? 
                                                            <div className="progress progress-upload-images">
                                                                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%" }}>Carregando imagem...</div>
                                                            </div>
                                                            : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    {
                                                        logotipo.length > 0 ?
                                                            <img src={ logotipo } style={{ height: '60px'}} ></img>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <TextField
                                                        label="Moldura veículos"
                                                        onChange={this.handleChange}
                                                        name="transformation"
                                                        value={transformation}
                                                        className="form-ui-bootstrap"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <TextField
                                                        label="Moldura vendidos"
                                                        onChange={this.handleChange}
                                                        name="transformation_vendidos"
                                                        value={transformation_vendidos}
                                                        className="form-ui-bootstrap"
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-4">
                                                <div className="form-group">
                                                    <TextField
                                                        label="Moldura vídeos"
                                                        onChange={this.handleChange}
                                                        name="transformation_videos"
                                                        value={transformation_videos}
                                                        className="form-ui-bootstrap"
                                                    />
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>Bloco texto veículos</label>
                                                    <textarea name="peblocotexto" className="form-ui-bootstrap" rows="4" value={peblocotexto} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>Bloco texto vendidos</label>
                                                    <textarea name="peblocotextovendidos" className="form-ui-bootstrap" rows="4" value={peblocotextovendidos} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>Bloco texto banners</label>
                                                    <textarea name="peblocotextobanners" className="form-ui-bootstrap" rows="4" value={peblocotextobanners} onChange={this.handleChange} />
                                                </div>
                                            </div>                                            
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>Observações administrativas</label>
                                                    <textarea name="peobsadm" className="form-ui-bootstrap" rows="4" value={peobsadm} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="Horário segunda a sexta"
                                                        onChange={this.handleChange}
                                                        name="horario_seg_sex"
                                                        value={horario_seg_sex}
                                                        className="form-ui-bootstrap"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="Horário sábado"
                                                        onChange={this.handleChange}
                                                        name="horario_sab"
                                                        value={horario_sab}
                                                        className="form-ui-bootstrap"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="URL endereço mapa"
                                                        onChange={this.handleChange}
                                                        name="mapa_link"
                                                        value={mapa_link}
                                                        className="form-ui-bootstrap"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="URL Instagram"
                                                        onChange={this.handleChange}
                                                        name="instagram_link"
                                                        value={instagram_link}
                                                        className="form-ui-bootstrap"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <TextValidator
                                                        label="URL Facebook"
                                                        onChange={this.handleChange}
                                                        name="facebook_link"
                                                        value={facebook_link}
                                                        className="form-ui-bootstrap"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label>Bloco texto sobre nós SITE</label>
                                                    <textarea name="texto_sobre_nos_site" className="form-ui-bootstrap" value={texto_sobre_nos_site} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>Locais de anúncio</label><br/>
                                                    <div className="form-group">
                                                        { 
                                                            locaisanuncio.map(element => ( 
                                                                <p className="checkbox-item" key={element.id}>
                                                                    <label className="checkbox-label">
                                                                        <input type="checkbox" name={element.id} defaultChecked={element.checked ? element.checked : false} onChange={this.handleChecked} /> &nbsp;&nbsp;{element.descricao}
                                                                    </label>
                                                                </p>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>Plano *</label>
                                                    <select className="form-ui-bootstrap" name="plano" onChange={this.handleChange} value={ plano }>
                                                        <option value=""></option>
                                                        {                                
                                                            // !!this.state.usuario && this.state.usuario.role === "admin" && this.state.usuario.empresa !== null && this.state.usuario.empresa === "ff" ?
                                                                <option value="basico">Básico</option>
                                                            // : null
                                                        }
                                                        <option value="vendedor">Vendedor</option>
                                                        <option value="familiar">Loja familiar</option>
                                                        <option value="showroom">Showroom</option>
                                                        <option value="concessionaria">Concessionária</option>
                                                    </select>
                                                    {
                                                        validPlano ? null : <p className="error">Preenchimento obrigatório</p>
                                                    }                                                    
                                                </div>
                                                <div className="form-group">
                                                    <label>Recursos</label><br/>
                                                    {this.createListRecursos()}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <Button type="submit" variant="contained" color="primary" disabled={estaGravando} >Gravar</Button>
                                            </div>
                                        </div>
                                    </ValidatorForm>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}

function formatMoney(value) {

    var places = 2;
    var symbol = "R$ ";
    var thousand = ".";
    var decimal = ",";

	places = !isNaN(places = Math.abs(places)) ? places : 2;
	symbol = symbol !== undefined ? symbol : "$";
	thousand = thousand || ",";
	decimal = decimal || ".";
	var number = value, 
	    negative = number < 0 ? "-" : "",
	    i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
	    j = (j = i.length) > 3 ? j % 3 : 0;
	return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
};

export default RevendaAlterar
