import React, { Component } from 'react';
import { auth, firestore } from './../../../firebase-config';
import Cabecalho from './../../../componentes/Cabecalho';
import CabecalhoPainel from './../../../componentes/CabecalhoPainel';
import { Link, Redirect } from 'react-router-dom';
import Modal from 'react-modal';
import { sendNotificacao } from '../../../services/OtherServices';

Modal.setAppElement('#root');

class RevendaNotificacoesListar extends Component {

    constructor (props){
        super(props);

        this.state = {
            idRevenda: props.match.params.idRevenda,
            nome_revenda: "",
            notificacoes: [],
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }
        this.mounted = false;
    }

    componentDidMount(){

        this.mounted = true;

        firestore.collection("revenda").doc(this.state.idRevenda).collection("notificacoes")
        .onSnapshot(querySnapshot => {
            var items = [];
            querySnapshot.forEach(doc => {
                var document = doc.data();
                document.id  = doc.id;
                items.push(document);
            });
            if(this.mounted) {
                this.setState({
                    notificacoes: items
                });
            }
        });

        firestore.collection("revenda").doc(this.state.idRevenda)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    nome_revenda: doc.data().penome_fantasia
                });
            }
        }).catch(function(error) {
        });

    }

    componentWillUnmount(){
        this.mounted = false;
    }

    lembreteNotificacao = async (notificacao) => {

        var r = window.confirm("Confirma o envio de lembrete desta notificação?");
        if (r === true) {

            var token_usuarios = [];
            firestore.collection("usuarios").where("revenda", "==", this.state.idRevenda).get()
            .then((querySnapshot) => {
                var items = [];
                querySnapshot.forEach(doc => {
                    var document = doc.data();
                    document.id  = doc.id;
                    if(document.push_token !== undefined){   
                        if(notificacao.acesso !== undefined && notificacao.acesso === "todos"){
                            items.push(document.push_token);
                        }else{
                            if(document.role === "gestor" || document.role === "admin"){
                                items.push(document.push_token);    
                            }
                        }
                    }
                });
                if(this.mounted){
                    token_usuarios = items;
                    var data_lembrete = {
                        "titulo": notificacao.titulo,
                        "tokens": token_usuarios
                    }
                    sendNotificacao(data_lembrete)
                    .then(data => { 
                        alert(data.data);
                    })
                    .catch(error => { 
                        alert("Erro ao enviar notificação: ", error);
                    });
                }
            })
            .catch(error => { 
                console.log("Erro ao consultar usuários: ", error); 
            });
        
        }

    }

    excluirNotificacao = async (id_notificacao) => {

        var r = window.confirm("Confirma a exclusão desta notificação?");
        if (r === true) {            
            return firestore.collection('revenda').doc(this.state.idRevenda).collection('notificacoes').doc(id_notificacao).delete()
            .then(res => { 
                alert("Anúncio excluído com sucesso.");
            })
            .catch(err => { 
                alert("Erro ao excluir anúncio!");
            });
        }

    }    

    render(){

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin'){
            return <Redirect to='/acesso-negado' />
        }
        
        return (

            <div id="modalAguarde">
                {
                    this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ? <Cabecalho /> :  <CabecalhoPainel />
                }
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Notificações<br/><small>Revenda: { this.state.nome_revenda }</small></h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    Lista de Notificações
                                </div>
                                <div className="panel-body">
                                    <Link to={ "/revenda/"+this.state.idRevenda+"/notificacoes/incluir"} className="btn btn-warning" >Nova notificação</Link>                                    
                                    <br/><br/>
                                    <table width="100%" className="table table-striped table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>Título</th>
                                                <th>Link</th>
                                                {/* <th>Status</th> */}
                                                <th>Acesso</th>
                                                <th>Lembrete</th>
                                                <th>Manutenção</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Object.keys(this.state.notificacoes)
                                                .map(key => {
                                                    return (
                                                        <tr key={key}>
                                                            <td>{ this.state.notificacoes[key].titulo }</td>
                                                            <td>{ this.state.notificacoes[key].link }</td>
                                                            <td>{ this.state.notificacoes[key].acesso === undefined ? "gestor" : this.state.notificacoes[key].acesso }</td>
                                                            {/* <td>{ this.state.notificacoes[key].status }</td> */}
                                                            <td>
                                                                <a onClick={() => this.lembreteNotificacao(this.state.notificacoes[key])}>Enviar</a>
                                                            </td>
                                                            <td>
                                                                <a onClick={() => this.excluirNotificacao(this.state.notificacoes[key].id)}>Excluir</a>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }

}

export default RevendaNotificacoesListar;