import React, { Component } from 'react';
import CabecalhoPainel from '../../componentes/CabecalhoPainel';
import { firestore } from './../../firebase-config';
import { Link, Redirect } from 'react-router-dom';

class Inicio extends Component {

    constructor (props){
        super(props);

        this.state = {
            veiculos: 0,
            usuarios: 0,
            notificacoes: 0,
            historico: 0,
            vendidos: 0,
            banners: 0,
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }
        this.mounted = false;
    }

    componentDidMount(){

        this.mounted = true;

        if(this.state.usuarioLogado === null){
            return <Redirect to='/' />
        }

        firestore.collection("revenda").doc(this.state.usuarioLogado.revenda).collection("veiculos").where("veremovido", "==", 0).orderBy("vevendido").orderBy("updated_at", "desc")
        .get()
        .then((querySnapshot) => {
            if(this.mounted) {
                this.setState({
                    veiculos: querySnapshot.size,
                });
            }
        });

        firestore.collection("usuarios").where("revenda", "==", this.state.usuarioLogado.revenda)
        .get()
        .then((querySnapshot) => {
            if(this.mounted) {
                this.setState({
                    usuarios: querySnapshot.size,
                });
            }
        });

        firestore.collection("revenda").doc(this.state.usuarioLogado.revenda).collection("vendidos").orderBy("created_at", "desc")
        .get()
        .then((querySnapshot) => {
            if(this.mounted) {
                this.setState({
                    vendidos: querySnapshot.size,
                });
            }
        });

        firestore.collection("revenda").doc(this.state.usuarioLogado.revenda).collection("banners").orderBy("created_at", "desc")
        .get()
        .then((querySnapshot) => {
            if(this.mounted) {
                this.setState({
                    banners: querySnapshot.size,
                });
            }
        });

        // firestore.collection("revenda").doc(this.state.usuarioLogado.revenda).collection("notificacoes")
        // .get()
        // .then((querySnapshot) => {
        //     if(this.mounted) {
        //         this.setState({
        //             notificacoes: querySnapshot.size,
        //         });
        //     }
        // });

        firestore.collection("revenda").doc(this.state.usuarioLogado.revenda).collection("veiculos").where("veremovido", "==", 1).orderBy("vedataremovido", "asc")
        .get()
        .then((querySnapshot) => {
            if(this.mounted) {
                this.setState({
                    historico: querySnapshot.size,
                });
            }
        });

    }

    componentWillUnmount(){
        this.mounted = false;
    }

    render(){
        const { 
            veiculos, 
            usuarios,
            notificacoes,
            historico,
            vendidos,
            banners
        } = this.state;
        if(this.state.usuarioLogado === null){
            return <Redirect to='/' />
        }
        return(
            <div>
                <CabecalhoPainel />
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Dashboard</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2 col-md-6">
                            <div className="panel panel-primary">
                                <div className="panel-heading">
                                    <div className="row">
                                        <div className="col-xs-3">
                                            <i className="fa fa-car fa-5x"></i>
                                        </div>
                                        <div className="col-xs-9 text-right">
                                            <div className="huge">{ veiculos }</div>
                                            <div>Veículos</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-footer">
                                    <Link to={"/revenda/"+this.state.usuarioLogado.revenda+"/veiculos/listar"} >Ver detalhes</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="panel panel-green">
                                <div className="panel-heading">
                                    <div className="row">
                                        <div className="col-xs-3">
                                            <i className="fa fa-money fa-5x"></i>
                                        </div>
                                        <div className="col-xs-9 text-right">
                                            <div className="huge">{ vendidos }</div>
                                            <div>Vendidos</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-footer">
                                    <Link to={"/revenda/"+this.state.usuarioLogado.revenda+"/vendidos/listar"} >Ver detalhes</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="panel panel-yellow">
                                <div className="panel-heading">
                                    <div className="row">
                                        <div className="col-xs-3">
                                            <i className="fa fa-user fa-5x"></i>
                                        </div>
                                        <div className="col-xs-9 text-right">
                                            <div className="huge">{ usuarios }</div>
                                            <div>Usuários</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-footer">
                                    <Link to={"/revenda/"+this.state.usuarioLogado.revenda+"/usuarios/listar"} >Ver detalhes</Link>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-2 col-md-6">
                            <div className="panel panel-yellow">
                                <div className="panel-heading">
                                    <div className="row">
                                        <div className="col-xs-3">
                                            <i className="fa fa-bell fa-5x"></i>
                                        </div>
                                        <div className="col-xs-9 text-right">
                                            <div className="huge">{ notificacoes }</div>
                                            <div>Notificações</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-footer">
                                    <Link to={"/revenda/"+this.state.usuarioLogado.revenda+"/notificacoes/listar"} >Ver detalhes</Link>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-lg-2 col-md-6">
                            <div className="panel panel-red">
                                <div className="panel-heading">
                                    <div className="row">
                                        <div className="col-xs-3">
                                            <i className="fa fa-history fa-5x"></i>
                                        </div>
                                        <div className="col-xs-9 text-right">
                                            <div className="huge">{ historico }</div>
                                            <div>Histórico</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-footer">
                                    <Link to={"/revenda/"+this.state.usuarioLogado.revenda+"/veiculos/historico"} >Ver detalhes</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="panel panel-purple">
                                <div className="panel-heading">
                                    <div className="row">
                                        <div className="col-xs-3">
                                            <i className="fa fa-file-image-o fa-5x"></i>
                                        </div>
                                        <div className="col-xs-9 text-right">
                                            <div className="huge">{ banners }</div>
                                            <div>Banners</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-footer">
                                    <Link to={ "/revenda/"+this.state.usuarioLogado.revenda+"/banners/listar" } >Ver detalhes</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Inicio