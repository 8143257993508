import React, { Component } from 'react';
import { firestore } from './../../firebase-config';
import Cabecalho from './../../componentes/Cabecalho';
import { Redirect } from 'react-router-dom';

class RevendaExcluir extends Component {

    constructor (props){
        super(props);

        this.state = {
            returnPagina: false,
            idRevenda: props.match.params.idRevenda,
            perazao_social_nome: "",
            penome_fantasia: "",
            peemail: "",
            petelefone1: "",
            estaGravando: false,
            usuarios: [],
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }
        this.mounted = false;

        this.handleSubmit = this.handleSubmit.bind(this);
        this.goBack       = this.goBack.bind(this);

    }

    goBack(){
        this.props.history.goBack();
    }

    componentDidMount(){

        this.mounted = true;

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection("revenda").doc(this.state.idRevenda)
        .get().then(doc => {
            if (doc.exists) {
                this.setState({
                    perazao_social_nome: doc.data().perazao_social_nome,
                    penome_fantasia: doc.data().penome_fantasia,
                    peemail: doc.data().peemail,
                    petelefone1: doc.data().petelefone1,
                });
            }
        }).catch(function(error) {
        });

    }

    handleSubmit(event) {

        this.setState({estaGravando: true});

        firestore.collection('revenda').doc(this.state.idRevenda).delete()
        .then(res => { 

            firestore.collection("usuarios").where("revenda", "==", this.state.idRevenda)
            .get()
            .then(querySnapshot => {

                querySnapshot.forEach(doc => {
                    firestore.collection('usuarios').doc(doc.id).delete();
                });
                
                alert("Revenda removida com sucesso!");
                this.setState({returnPagina: true, estaGravando: false});
                
            });

        })
        .catch(err => { 
            alert("Falha ao remover revenda!");
            this.setState({returnPagina: true, estaGravando: false});     
        });

        event.preventDefault();

    }

    render(){
        const { perazao_social_nome, penome_fantasia, peemail, petelefone1, estaGravando } = this.state;
        if(this.state.returnPagina){
            return <Redirect to="/revenda/listar" />;
        }
        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin' && this.state.idRevenda !== this.state.usuarioLogado.revenda){
            return <Redirect to='/acesso-negado' />
        }
        return (

            <div>
                <Cabecalho />
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Revendas</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    Excluir revenda
                                </div>
                                <div className="panel-body">

                                    <h2>Deseja remover esta revenda?</h2>
                                    <p><strong>Nome do responsável: </strong>{perazao_social_nome}</p>
                                    <p><strong>Nome fantasia: </strong>{penome_fantasia}</p>
                                    <p><strong>E-mail: </strong>{peemail}</p>
                                    <p><strong>Telefone: </strong>{petelefone1}</p>

                                    <input className="btn btn-danger" type="button" value="Remover" onClick={this.handleSubmit} disabled={estaGravando} />&nbsp;&nbsp;
                                    <button onClick={this.goBack} className="btn btn-warning" >Voltar</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}

export default RevendaExcluir
