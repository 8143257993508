import React, { Component } from 'react';
import { firestore } from '../../../firebase-config';
import Cabecalho from '../../../componentes/Cabecalho';
import CabecalhoPainel from '../../../componentes/CabecalhoPainel';
import { base_url, api_key } from '../../../config/config_api';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Redirect } from 'react-router-dom';

class VeiculosIntegracaoInstagramIncluir extends Component {

    constructor (props){
        super(props);

        this.state = {
            estaGravando: false,
            carregandoDados: true,
            returnPagina: false,
            errosSubmit: null,
            errorLoading: false,
            errorsLoading: null,
            idRevenda: props.match.params.idRevenda,
            idVeiculo: props.match.params.idVeiculo,
            nome_revenda: "",
            imagens: [],
            ig_images: [],
            ig_descricao: "",
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }
        this.mounted = false;
        this.goBack  = this.goBack.bind(this);
        this.handleChange  = this.handleChange.bind(this);
        this.handleChangeValues  = this.handleChangeValues.bind(this);
        this.handleSubmit  = this.handleSubmit.bind(this);
    }

    goBack(){
        this.props.history.goBack();
    }

    componentDidMount(){

        this.mounted = true;

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection("revenda").doc(this.state.idRevenda)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    nome_revenda: doc.data().penome_fantasia,
                });
            }
        }).catch(function(error) {
        });

        firestore.collection("revenda").doc(this.state.idRevenda).collection("veiculos").doc(this.state.idVeiculo)
        .get()
        .then(doc => {
            if (doc.exists) {

                this.setState({
                    imagens: doc.data().imagens,
                });
            }
        }).catch(function(error) {
        });

        fetch(base_url+"/api/integracao/instagram/incluir", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "api_key": api_key,
                "id_veiculo": this.state.idVeiculo
            })
        })
        .then(response => {

            response.json()
            .then(data => {

                console.log(data);

                if(data.status === 'success'){
                    this.setState({
                        ig_descricao: data.data.veiculoPost.message,
                        ig_images: data.data.veiculoPost.images
                    });
                }else{
                    this.setState({
                        errorLoading: true,
                        errorsLoading: data.data,
                    });
                }
            });

            this.setState({
                carregandoDados: false,
            });

        })
        .catch(error => {
            console.error("Erro get teste", error);
            this.setState({
                carregandoDados: false,
            });
        });
        
    }

    componentWillUnmount(){
        this.mounted = false;
    }

    handleChange(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        });
    }

    handleChangeValues(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        const new_ml_values = this.state.ml_values;
        new_ml_values[name] = value;

        this.setState({
            ml_values: new_ml_values
        });

    }

    handleSubmit(event) {

        if(this.state.ig_descricao.length < 20){            
            
            var showErros = "";

            if(this.state.ig_descricao.length < 20){
                showErros += "'Descrição do anúncio' é obrigatório.\n";
            }
            this.setState({errosSubmit: showErros});

            return;

        }

        this.setState({estaGravando: true, errosSubmit: null});

        var user = JSON.parse(window.localStorage.getItem("usuarioLogado"));

        var veiculo_post = {
            "ig_descricao": this.state.ig_descricao
        };

        fetch(base_url+"/api/integracao/instagram/enviar", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "api_key": api_key,
                "veiculo_post": veiculo_post,
                "usuario": user.id,
                "id_veiculo": this.state.idVeiculo
            })
        })
        .then(response => {

            response.json()
            .then(data => {
                if(data.status === 'success'){

                    var integracao = {
                        status: true,
                        id_anuncio: data.data.id_anuncio,
                        url_anuncio: data.data.url_anuncio,
                    };

                    firestore.collection('revenda').doc(this.state.idRevenda).collection('veiculos').doc(this.state.idVeiculo).collection('integracoes').doc("instagram").set(integracao, { merge: true })
                    .then(res => { 
                        this.setState({returnPagina: true}); 
                    })
                    .catch(err => { 
                        this.setState({returnPagina: true});      
                    });
                   
                }else{
                    // var erros = "";
                    // data.data.cause.forEach(erro => {
                    //     erros += erro.message + ". ";
                    // });                    
                    // this.setState({errosSubmit: erros});
                    this.setState({errosSubmit: JSON.stringify(data.data)});
                }
            });
            this.setState({estaGravando: false});  

        })
        .catch(error => {
            console.log(error);
            this.setState({estaGravando: false});  
        });

    }

    render(){
        const { 
            errosSubmit,
            estaGravando,
            idRevenda,
            idVeiculo,
            nome_revenda,
            imagens,
            ig_descricao,
            ig_images,
            carregandoDados,
            errorLoading,
            errorsLoading
        } = this.state;

        if(this.state.returnPagina){
            return <Redirect to={ "/revenda/"+idRevenda+"/veiculos/"+idVeiculo+"/visualizar" } />;
        }

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin' && this.state.idRevenda !== this.state.usuarioLogado.revenda){
            return <Redirect to='/acesso-negado' />
        }

        return (
            <div>
                {
                    this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ? <Cabecalho /> :  <CabecalhoPainel />
                } 
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Incluir carro Instagram<br/><small>Revenda: { nome_revenda }</small></h1>
                        </div>
                    </div>
                    <div className="row formulario_integracao">
                        {
                                    
                            carregandoDados ? (<div className="col-lg-12"><img src="/img/aguarde.gif" alt="carregando"  style={{ height: '80px', marginBottom: '10px'}} /></div>) : (

                                errorLoading ? (<div style={{ display: errorsLoading !== null ? " block " : " none "  }} className="alert alert-danger" role="alert">{errorsLoading}</div>) : (
                        
                                    <div className="col-lg-12">
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                Integração Instagram - Incluir veículo
                                            </div>
                                            <div className="panel-body">
      
                                                <ValidatorForm
                                                    ref="form"
                                                    onSubmit={this.handleSubmit}
                                                    onError={ errors => exibirErroSubmit(errors) }
                                                >
                                                <table className='table table-striped'>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ minWidth: '155px', width: '10%'}}></th>
                                                            <th style={{ width: '45%'}}>Dados Intermédio</th>
                                                            <th style={{ width: '45%'}}>Dados Instagram</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Descrição <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td></td>
                                                            <td><textarea name="ig_descricao" className="form-ui-bootstrap" rows="10" value={ig_descricao} onChange={this.handleChange} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Imagens</td>
                                                            <td>
                                                                {      
                                                                    imagens !== undefined && imagens.length > 0 ? 
                                                                    ( 
                                                                        Object.keys(imagens)
                                                                        .map(key => {
                                                                            
                                                                            return (
                                                                                imagens[key].fileUrl !== null && imagens[key].fileThumb !== null ? 
                                                                                <div key={key}><img src={ imagens[key].fileThumb } alt={ imagens[key].fileName }  style={{ height: '80px', marginBottom: '10px'}} /><br/></div>
                                                                                : null
                                                                            )
                                                                            
                                                                        })
                                                                    )
                                                                    : null
                                                                }
                                                            </td>
                                                            <td>
                                                                {      
                                                                    ig_images !== undefined && ig_images.length > 0 ? 
                                                                    ( 
                                                                        Object.keys(ig_images)
                                                                        .map(key => {
                                                                            
                                                                            return (
                                                                                <div key={key}><img src={ ig_images[key] } style={{ height: '80px', marginBottom: '10px'}}  /><br/></div>
                                                                            )
                                                                            
                                                                        })
                                                                    )
                                                                    : null
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table> 
                                                <p><span className="preenchimento-obrigatorio">*</span> Campo obrigatório</p>
                                                <div style={{ display: estaGravando ? " block " : " none "  }}><img src="/img/aguarde.gif" alt="carregando"  style={{ height: '80px', marginBottom: '10px'}} /></div>
                                                <div style={{ display: errosSubmit !== null ? " block " : " none "  }} className="alert alert-danger" role="alert">{errosSubmit}</div>
                                                <button onClick={this.goBack} disabled={estaGravando} className="btn btn-warning" >Voltar</button>&nbsp;&nbsp;<button type="submit" disabled={estaGravando} className="btn btn-primary" >Enviar</button>

                                                </ValidatorForm>
                                            </div>
                                        </div>
                                    </div>

                                )
                            )
                        }
                    </div>
                </div>
            </div>

        )
    }

}

function exibirErroSubmit(errors){
    window.scrollTo(0, 0);
    alert("Campos obrigatórios não preenchidos!");
}

export default VeiculosIntegracaoInstagramIncluir