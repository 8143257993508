import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Login from './admin/Login';
import Inicio from './admin/Inicio';
import RevendaListar from './admin/revenda/Listar';
import RevendaFFListar from './admin/revenda/ListarFF';
import RevendaIncluir from './admin/revenda/Incluir';
import RevendaAlterar from './admin/revenda/Alterar';
import RevendaExcluir from './admin/revenda/Excluir';
import RevendaIntegracao from './admin/revenda/Integracao';
import RevendaIntegracaoAutoscar from './admin/revenda/integracao/Autoscar';
import RevendaIntegracaoWebmotors from './admin/revenda/integracao/Webmotors';
import RevendaIntegracaoOlx from './admin/revenda/integracao/Olx';
import RevendaIntegracaoMercadoLivre from './admin/revenda/integracao/MercadoLivre';
import RevendaIntegracaoAutoline from './admin/revenda/integracao/Autoline';
import RevendaIntegracaoIcarros from './admin/revenda/integracao/Icarros';
import RevendaIntegracaoMeuCarroNovo from './admin/revenda/integracao/MeuCarroNovo';
import RevendaIntegracaoMobiauto from './admin/revenda/integracao/Mobiauto';
import RevendaIntegracaoFacebook from './admin/revenda/integracao/Facebook';
import RevendaIntegracaoInstagram from './admin/revenda/integracao/Instagram';
import RevendaUsuariosListar from './admin/usuarios/Listar';
import RevendaUsuariosIncluir from './admin/usuarios/Incluir';
import RevendaUsuariosAlterar from './admin/usuarios/Alterar';
import RevendaUsuariosExcluir from './admin/usuarios/Excluir';
import RevendaVeiculosListar from './admin/veiculos/Listar';
import RevendaHistoricoVeiculosListar from './admin/veiculos/Historico';
import RevendaVeiculosIncluir from './admin/veiculos/Incluir';
import RevendaVeiculosVisualizar from './admin/veiculos/Visualizar';
import RevendaVeiculosAlterar from './admin/veiculos/Alterar';
import RevendaVeiculosExcluir from './admin/veiculos/Excluir';
import RevendaImagemDestaqueListar from './admin/revenda/imagem_destaque/Listar';
import RevendaImagemDestaqueIncluir from './admin/revenda/imagem_destaque/Incluir';
import RevendaBannerSiteListar from './admin/revenda/banner_site/Listar';
import RevendaBannerSiteIncluir from './admin/revenda/banner_site/Incluir';
import RevendaNotificacoesListar from './admin/revenda/notificacoes/Listar';
import RevendaNotificacoesIncluir from './admin/revenda/notificacoes/Incluir';
import VeiculosIntegracaoAutoscarIncluir from './admin/veiculos/integracao/AutoscarIncluir';
import VeiculosIntegracaoAutoscarIncluirCarro from './admin/veiculos/integracao/AutoscarIncluirCarro';
import VeiculosIntegracaoAutoscarIncluirMoto from './admin/veiculos/integracao/AutoscarIncluirMoto';
import VeiculosIntegracaoAutoscarAlterar from './admin/veiculos/integracao/AutoscarAlterar';
import VeiculosIntegracaoAutoscarAlterarCarro from './admin/veiculos/integracao/AutoscarAlterarCarro';
import VeiculosIntegracaoAutoscarAlterarMoto from './admin/veiculos/integracao/AutoscarAlterarMoto';
import VeiculosIntegracaoWebmotorsIncluir from './admin/veiculos/integracao/WebmotorsIncluir';
import VeiculosIntegracaoWebmotorsIncluirCarro from './admin/veiculos/integracao/WebmotorsIncluirCarro';
import VeiculosIntegracaoWebmotorsIncluirMoto from './admin/veiculos/integracao/WebmotorsIncluirMoto';
import VeiculosIntegracaoWebmotorsAlterar from './admin/veiculos/integracao/WebmotorsAlterar';
import VeiculosIntegracaoOlxIncluir from './admin/veiculos/integracao/OlxIncluir';
import VeiculosIntegracaoAutolineIncluir from './admin/veiculos/integracao/AutolineIncluir';
import VeiculosIntegracaoMercadoLivreIncluir from './admin/veiculos/integracao/MercadoLivreIncluir';
import VeiculosIntegracaoMercadoLivreIncluirMoto from './admin/veiculos/integracao/MercadoLivreIncluirMoto';
import VeiculosIntegracaoMercadoLivreIncluirCarro from './admin/veiculos/integracao/MercadoLivreIncluirCarro';
import VeiculosIntegracaoIcarrosIncluir from './admin/veiculos/integracao/IcarrosIncluir';
import VeiculosIntegracaoMeuCarroNovoIncluir from './admin/veiculos/integracao/MeuCarroNovoIncluir';
import VeiculosIntegracaoMobiautoIncluir from './admin/veiculos/integracao/MobiautoIncluir';
import VeiculosIntegracaoMobiautoIncluirCarro from './admin/veiculos/integracao/MobiautoIncluirCarro';
import VeiculosIntegracaoFacebookIncluir from './admin/veiculos/integracao/FacebookIncluir';
import VeiculosIntegracaoInstagramIncluir from './admin/veiculos/integracao/InstagramIncluir';
import RevendaBannersListar from './admin/banners/Listar';
import RevendaBannersIncluir from './admin/banners/Incluir';
import RevendaVendidosListar from './admin/vendidos/Listar';
import RevendaVendidosIncluir from './admin/vendidos/Incluir';
import DashIncluidos from './admin/veiculos/DashIncluidos';
import DashBanners from './admin/veiculos/DashBanners';
import DashVendidos from './admin/veiculos/DashVendidos';
import DashAlterados from './admin/veiculos/DashAlterados';
import DashRemovidos from './admin/veiculos/DashRemovidos';
import OpcionaisListar from './admin/opcionais/Listar';
import OpcionaisIncluir from './admin/opcionais/Incluir';
import OpcionaisAlterar from './admin/opcionais/Alterar';
import OpcionaisExcluir from './admin/opcionais/Excluir';
import LocaisAnuncioListar from './admin/locais_anuncio/Listar';
import LocaisAnuncioIncluir from './admin/locais_anuncio/Incluir';
import LocaisAnuncioAlterar from './admin/locais_anuncio/Alterar';
import LocaisAnuncioExcluir from './admin/locais_anuncio/Excluir';
import FipeListar from './admin/fipe/Listar';
import FipeIncluir from './admin/fipe/Incluir';
import Dashboard from './admin/painel/Dashboard';
import AcessoNegado from './admin/AcessoNegado';
import IntegracaoConfigurarInstagram from './admin/revenda/integracao/ConfigurarInstagram';
import IntegracaoConfigurarFacebook from './admin/revenda/integracao/ConfigurarFacebook';

class App extends Component {

  render(){
    return (    
      <BrowserRouter>      
        <div className="App">      
          <Route path='/' exact component={Login} />
          <Route path='/login' component={Login} />
          <Route path='/home' component={Inicio} />          
          <Route path='/dashboard/incluidos' component={DashIncluidos} />
          <Route path='/dashboard/banners' component={DashBanners} />
          <Route path='/dashboard/vendidos' component={DashVendidos} />
          <Route path='/dashboard/alterados' component={DashAlterados} />
          <Route path='/dashboard/removidos' component={DashRemovidos} />
          <Route path='/revenda/listar' component={RevendaListar} />          
          <Route path='/revenda-free/listar' component={RevendaFFListar} />          
          <Route path='/revenda/incluir' component={RevendaIncluir} />          
          <Route path='/revenda/:idRevenda/alterar' component={RevendaAlterar} />          
          <Route path='/revenda/:idRevenda/excluir' component={RevendaExcluir} />          
          <Route path='/revenda/:idRevenda/integracao' component={RevendaIntegracao} />       
          <Route path='/revenda/:idRevenda/configurar-autoscar' component={RevendaIntegracaoAutoscar} />       
          <Route path='/revenda/:idRevenda/configurar-webmotors' component={RevendaIntegracaoWebmotors} />       
          <Route path='/revenda/:idRevenda/configurar-olx' component={RevendaIntegracaoOlx} />          
          <Route path='/revenda/:idRevenda/configurar-mercadolivre' component={RevendaIntegracaoMercadoLivre} />          
          <Route path='/revenda/:idRevenda/configurar-autoline' component={RevendaIntegracaoAutoline} />          
          <Route path='/revenda/:idRevenda/configurar-icarros' component={RevendaIntegracaoIcarros} />      
          <Route path='/revenda/:idRevenda/configurar-meucarronovo' component={RevendaIntegracaoMeuCarroNovo} />      
          <Route path='/revenda/:idRevenda/configurar-mobiauto' component={RevendaIntegracaoMobiauto} />      
          <Route path='/revenda/:idRevenda/configurar-facebook' component={RevendaIntegracaoFacebook} />      
          <Route path='/revenda/:idRevenda/configurar-instagram' component={RevendaIntegracaoInstagram} />      
          <Route path='/revenda/:idRevenda/usuarios/listar' component={RevendaUsuariosListar} />          
          <Route path='/revenda/:idRevenda/usuarios/incluir' component={RevendaUsuariosIncluir} />          
          <Route path='/revenda/:idRevenda/usuarios/:idUsuario/alterar' component={RevendaUsuariosAlterar} />          
          <Route path='/revenda/:idRevenda/usuarios/:idUsuario/excluir' component={RevendaUsuariosExcluir} />          
          <Route path='/revenda/:idRevenda/veiculos/listar' component={RevendaVeiculosListar} />                
          <Route path='/revenda/:idRevenda/veiculos/historico' component={RevendaHistoricoVeiculosListar} />          
          <Route path='/revenda/:idRevenda/veiculos/incluir' component={RevendaVeiculosIncluir} />          
          <Route path='/revenda/:idRevenda/veiculos/:idVeiculo/visualizar' component={RevendaVeiculosVisualizar} />          
          <Route path='/revenda/:idRevenda/veiculos/:idVeiculo/alterar' component={RevendaVeiculosAlterar} />          
          <Route path='/revenda/:idRevenda/veiculos/:idVeiculo/excluir' component={RevendaVeiculosExcluir} />         
          <Route path='/revenda/:idRevenda/veiculos/:idVeiculo/integracao/autoscar/incluir' component={VeiculosIntegracaoAutoscarIncluir} />   
          <Route path='/revenda/:idRevenda/veiculos/:idVeiculo/integracao/autoscar/incluir-carro' component={VeiculosIntegracaoAutoscarIncluirCarro} />   
          <Route path='/revenda/:idRevenda/veiculos/:idVeiculo/integracao/autoscar/incluir-moto' component={VeiculosIntegracaoAutoscarIncluirMoto} />   
          <Route path='/revenda/:idRevenda/veiculos/:idVeiculo/integracao/autoscar/:idAnuncio/alterar' component={VeiculosIntegracaoAutoscarAlterar} />   
          <Route path='/revenda/:idRevenda/veiculos/:idVeiculo/integracao/autoscar/:idAnuncio/alterar-carro' component={VeiculosIntegracaoAutoscarAlterarCarro} />   
          <Route path='/revenda/:idRevenda/veiculos/:idVeiculo/integracao/autoscar/:idAnuncio/alterar-moto' component={VeiculosIntegracaoAutoscarAlterarMoto} />   
          <Route path='/revenda/:idRevenda/veiculos/:idVeiculo/integracao/webmotors/incluir' component={VeiculosIntegracaoWebmotorsIncluir} />   
          <Route path='/revenda/:idRevenda/veiculos/:idVeiculo/integracao/webmotors/incluir-carro' component={VeiculosIntegracaoWebmotorsIncluirCarro} />   
          <Route path='/revenda/:idRevenda/veiculos/:idVeiculo/integracao/webmotors/incluir-moto' component={VeiculosIntegracaoWebmotorsIncluirMoto} />   
          <Route path='/revenda/:idRevenda/veiculos/:idVeiculo/integracao/webmotors/:idAnuncio/alterar' component={VeiculosIntegracaoWebmotorsAlterar} />   
          <Route path='/revenda/:idRevenda/veiculos/:idVeiculo/integracao/olx/incluir' component={VeiculosIntegracaoOlxIncluir} />   
          <Route path='/revenda/:idRevenda/veiculos/:idVeiculo/integracao/autoline/incluir' component={VeiculosIntegracaoAutolineIncluir} />   
          <Route path='/revenda/:idRevenda/veiculos/:idVeiculo/integracao/mercadolivre/incluir' component={VeiculosIntegracaoMercadoLivreIncluir} /> 
          <Route path='/revenda/:idRevenda/veiculos/:idVeiculo/integracao/mercadolivre/incluir-moto' component={VeiculosIntegracaoMercadoLivreIncluirMoto} /> 
          <Route path='/revenda/:idRevenda/veiculos/:idVeiculo/integracao/mercadolivre/incluir-carro' component={VeiculosIntegracaoMercadoLivreIncluirCarro} />   
          <Route path='/revenda/:idRevenda/veiculos/:idVeiculo/integracao/icarros/incluir' component={VeiculosIntegracaoIcarrosIncluir} />   
          <Route path='/revenda/:idRevenda/veiculos/:idVeiculo/integracao/meucarronovo/incluir' component={VeiculosIntegracaoMeuCarroNovoIncluir} />      
          <Route path='/revenda/:idRevenda/veiculos/:idVeiculo/integracao/mobiauto/incluir' component={VeiculosIntegracaoMobiautoIncluir} />   
          <Route path='/revenda/:idRevenda/veiculos/:idVeiculo/integracao/mobiauto/incluir-carro' component={VeiculosIntegracaoMobiautoIncluirCarro} />  
          <Route path='/revenda/:idRevenda/veiculos/:idVeiculo/integracao/facebook/incluir' component={VeiculosIntegracaoFacebookIncluir} />  
          <Route path='/revenda/:idRevenda/veiculos/:idVeiculo/integracao/instagram/incluir' component={VeiculosIntegracaoInstagramIncluir} />  
          <Route path='/revenda/:idRevenda/banners/listar' component={RevendaBannersListar} />
          <Route path='/revenda/:idRevenda/banners/incluir' component={RevendaBannersIncluir} />
          <Route path='/revenda/:idRevenda/vendidos/listar' component={RevendaVendidosListar} />
          <Route path='/revenda/:idRevenda/vendidos/incluir' component={RevendaVendidosIncluir} />
          <Route path='/revenda/:idRevenda/imagem-destaque/listar' component={RevendaImagemDestaqueListar} />   
          <Route path='/revenda/:idRevenda/imagem-destaque/incluir' component={RevendaImagemDestaqueIncluir} />  
          <Route path='/revenda/:idRevenda/banner-site/listar' component={RevendaBannerSiteListar} />   
          <Route path='/revenda/:idRevenda/banner-site/incluir' component={RevendaBannerSiteIncluir} />   
          <Route path='/revenda/:idRevenda/notificacoes/listar' component={RevendaNotificacoesListar} />   
          <Route path='/revenda/:idRevenda/notificacoes/incluir' component={RevendaNotificacoesIncluir} />   
          <Route path='/opcionais/listar' component={OpcionaisListar} />          
          <Route path='/opcionais/incluir' component={OpcionaisIncluir} />          
          <Route path='/opcionais/:idOpcional/alterar' component={OpcionaisAlterar} />          
          <Route path='/opcionais/:idOpcional/excluir' component={OpcionaisExcluir} />          
          <Route path='/locais-anuncio/listar' component={LocaisAnuncioListar} /> 
          <Route path='/locais-anuncio/incluir' component={LocaisAnuncioIncluir} /> 
          <Route path='/locais-anuncio/:idLocalAnuncio/alterar' component={LocaisAnuncioAlterar} /> 
          <Route path='/locais-anuncio/:idLocalAnuncio/excluir' component={LocaisAnuncioExcluir} />          
          <Route path='/fipe/listar' component={FipeListar} /> 
          <Route path='/fipe/incluir' component={FipeIncluir} /> 
          <Route path='/painel/dashboard' component={Dashboard} />           
          <Route path='/acesso-negado' component={AcessoNegado} />   
          <Route path='/integracao/configurar-instagram' component={IntegracaoConfigurarInstagram} />   
          <Route path='/integracao/configurar-facebook' component={IntegracaoConfigurarFacebook} />   
        </div>
      </BrowserRouter>
    );
  }
}

export default App;