import React, { Component } from 'react';
import { auth, firestore } from './../firebase-config';
import { Redirect } from 'react-router-dom';

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            estaAutenticado: false,
            estaLogando: false,
            erro: false,
            usuario: null
        }

        this.email = null;
        this.senha = null;

        this.autenticaUsuario = this.autenticaUsuario.bind(this);
    }

    autenticaUsuario(){

        this.setState({estaLogando: true, erro: false});

        auth.signInWithEmailAndPassword(this.email.value, this.senha.value)
        .then(data => {

            // firestore.settings({ timestampsInSnapshots: true });
            firestore.collection('usuarios').doc(data.user.email).get()
            .then(res => { 
                var user = res.data();
                user.id  = data.user.email;
                window.localStorage.setItem("usuarioLogado", JSON.stringify(user));
                if(user.role === 'admin' || user.role === 'gestor'){ 
                    this.setState({estaLogando: false, estaAutenticado: true, usuario: user});
                }else{
                    this.setState({estaLogando: false, estaAutenticado: false, erro: true});
                }
            });

        })
        .catch(err => {
            this.setState({estaLogando: false, estaAutenticado: false, erro: true});
        });

    }

    render() {
        if(this.state.estaAutenticado && this.state.usuario !== null && this.state.usuario.role === 'admin'){
           return <Redirect to="/home" />;
        }
        if(this.state.estaAutenticado && this.state.usuario !== null && this.state.usuario.role === 'gestor'){
            return <Redirect to="/painel/dashboard" />;
        }
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1">
                        <div className="login-panel panel panel-default">
                            <div className="panel-heading">
                                <h3 className="panel-title">Acesso ao painel</h3>
                            </div>
                            <div className="panel-body">
                                <div className="logo-login">
                                    <img src="img/logo.png" alt="Logo Intermédio" />
                                </div>
                                <fieldset>
                                    <div className="form-group">
                                        <input className="form-control" placeholder="E-mail" name="email" type="email" ref={ref => this.email = ref} />
                                    </div>
                                    <div className="form-group">
                                        <input className="form-control" placeholder="Senha" name="senha" type="password" ref={ref => this.senha = ref} />
                                        {this.state.erro && <small id="emailHelp" className="form-text text-muted">E-mail e/ou senha inválidos.</small>}
                                    </div>
                                    <button disabled={this.state.estaLogando} onClick={this.autenticaUsuario} className="btn btn-lg btn-success btn-block" type="button">Acessar</button>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login