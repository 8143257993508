import React, { Component } from 'react';
import Cabecalho from './../../componentes/Cabecalho';
import { getVeiculosFipe, deleteVeiculoFipe } from '../../services/FipeService';
import { Link, Redirect } from 'react-router-dom';

class FipeListar extends Component {

    constructor (props){
        super(props);

        this.state = {
            veiculos: [],
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }
        this.mounted = false;

    }

    getVeiculosFipe = async () => { 
        const response = await getVeiculosFipe();
        if(response.status === "success"){
            this.setState({
                veiculos: response.data            
            });
        }
    } 

    componentDidMount(){
        this.mounted = true;
        this.getVeiculosFipe();
    }

    componentWillUnmount(){
        this.mounted = false;
    }

    excluirVeiculo = async (idVeiculo, descricao) => {

        if(window.confirm('Deseja excluir este veículo ('+descricao+') permanentemente?')){
            const response = await deleteVeiculoFipe(idVeiculo) ;
            if(response.status === "success"){
                alert("Veículo excluído com sucesso!");
                window.location.reload();
            }else{
                alert("Erro ao excluir veículo!");
                window.location.reload();
            }
        }

    }


    validaTipoVeiculo(tipo){
        if(tipo === 1){
            return "Carro";
        }else if(tipo === 2){
            return "Moto";
        }else if(tipo === 3){
            return "Caminhão";
        }else{
            return "Outros";
        }
    }

    render(){

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin'){
            return <Redirect to='/acesso-negado' />
        }

        return (
            <div>
                <Cabecalho />
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Veículos FIPE Manual</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <p><Link to={"/fipe/incluir" } className="btn btn-warning" >Novo Veículo</Link></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    Lista de veículos FIPE Manual
                                </div>
                                <div className="panel-body">
                                    <table width="100%" className="table table-striped table-bordered table-hover" >
                                        <thead>
                                            <tr>
                                                <th>Tipo</th>
                                                <th>Marca</th>
                                                <th>Modelo</th>
                                                <th>Versão</th>
                                                <th>Ano Modelo</th>
                                                <th>Manutenção</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Object.keys(this.state.veiculos)
                                                .map(key => {

                                                    var descricaoVeiculo = this.state.veiculos[key].marca_api + " " + this.state.veiculos[key].modelo_api + " " + this.state.veiculos[key].versao_api + " " + this.state.veiculos[key].anomod;

                                                    return (
                                                        <tr key={key} >
                                                            <td>{ this.validaTipoVeiculo(this.state.veiculos[key].tipo) }</td>
                                                            <td>{ this.state.veiculos[key].marca_api }</td>
                                                            <td>{ this.state.veiculos[key].modelo_api }</td>
                                                            <td>{ this.state.veiculos[key].versao_api }</td>
                                                            <td>{ this.state.veiculos[key].anomod }</td>
                                                            <td>
                                                                <a onClick={() => this.excluirVeiculo(this.state.veiculos[key].id, descricaoVeiculo)}>Excluir</a>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }
}

export default FipeListar