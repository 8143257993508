import React, { Component } from 'react';
import { firestore } from './../../firebase-config';
import Cabecalho from './../../componentes/Cabecalho';
import CabecalhoPainel from '../../componentes/CabecalhoPainel';
import { Link, Redirect } from 'react-router-dom';
import { excluirVeiculoIntegrador } from '../../services/VeiculoService';

class RevendaVeiculosVisualizar extends Component {

    constructor (props){
        super(props);

        this.state = {
            idRevenda: props.match.params.idRevenda,
            idVeiculo: props.match.params.idVeiculo,
            nome_revenda: "",
            cidade_revenda: "",
            peblocotexto: "",
            locais_anuncio: [],
            opcionais: [],
            imagens: [],
            veano_fabricacao: "",
            vecambio: "",
            vecor: "",
            vefipe_ano_modelo: "",
            vefipe_combustivel: "",
            vefipe_marca: "",
            vefipe_name: "",
            veiplaca: "",
            veobservacao: "",
            veportas: "",
            vequilometragem: "",
            vetipoveiculo: "",
            vevalor: "",
            vevalidacaoremovido: 1,
            carregando: true,
            carregando_veiculo: true,
            integracao_list: new Map(),
            integracao_veiculo_list: new Map(),
            veipva_pago: false,
            veblindado: false,
            vegarantia_fabrica: false,
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }
        this.mounted = false;
        this.goBack = this.goBack.bind(this);

    }

    goBack(){
        this.props.history.goBack();
    }

    componentDidMount(){

        this.mounted = true;

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection("revenda").doc(this.state.idRevenda)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    nome_revenda: doc.data().penome_fantasia,
                    cidade_revenda: doc.data().pecidade + "-" + doc.data().peuf,
                    peblocotexto: doc.data().peblocotexto ? doc.data().peblocotexto : ""
                });
            }
        }).catch(function(error) {
        });

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection('revenda').doc(this.state.idRevenda).collection('integracoes')
        .get()
        .then(querySnapshot => {
            var items = [];
            querySnapshot.forEach(doc => {
                var document = doc.data();
                document.id  = doc.id;
                items.push(document);
            });
            if(this.mounted) {
                this.setState({
                    integracao_list: items,
                    carregando: false
                });
            }
        })
        .catch(function(error) {
        });

        firestore.collection("revenda").doc(this.state.idRevenda).collection("veiculos").doc(this.state.idVeiculo)
        .get()
        .then(doc => {
            if (doc.exists) {

                this.setState({
                    locais_anuncio: doc.data().locais_anuncio.sort(compareLocaisAnuncio),
                    opcionais: doc.data().opcionais,
                    imagens: doc.data().imagens,
                    veano_fabricacao: doc.data().veano_fabricacao,
                    vecambio: doc.data().vecambio,
                    vecor: doc.data().vecor,
                    vefipe_ano_modelo: doc.data().vefipe_ano_modelo,
                    vefipe_combustivel: doc.data().vefipe_combustivel,
                    vefipe_marca: doc.data().vefipe_marca,
                    vefipe_name: doc.data().vefipe_name,
                    veiplaca: doc.data().veiplaca,
                    veobservacao: doc.data().veobservacao,
                    veportas: doc.data().veportas,
                    vequilometragem: doc.data().vequilometragem,
                    vetipoveiculo: doc.data().vetipoveiculo,
                    vevalor: doc.data().vevalor,
                    vevalidacaoremovido: doc.data().vevalidacaoremovido,
                    veipva_pago: doc.data().veipva_pago,
                    veblindado: doc.data().veblindado,
                    vegarantia_fabrica: doc.data().vegarantia_fabrica,
                });

                firestore.collection('revenda').doc(this.state.idRevenda).collection('veiculos').doc(this.state.idVeiculo).collection('integracoes')
                .get()
                .then(querySnapshot => {
                    var items = [];
                    querySnapshot.forEach(doc => {
                        items[doc.id] = doc.data();
                    });
                    if(this.mounted) {
                        this.setState({
                            integracao_veiculo_list: items,
                            carregando_veiculo: false
                        });
                    }
                })
                .catch(function(error) {
                });
            }
        }).catch(function(error) {
        });
        
    }

    componentWillUnmount(){
        this.mounted = false;
    }

    excluirVeiculo = async (integrador, id_anuncio) => {

        var r = window.confirm("Confirma a exclusão deste anúncio no "+integrador+"?");
        if (r === true) {
            const response = await excluirVeiculoIntegrador(integrador, id_anuncio, this.state.idVeiculo, this.state.idRevenda);
            if(response.status === "success"){
                alert("Anúncio excluído com sucesso.");
                window.location.reload();
            }else{
                alert("Erro ao excluir anúncio!");
                this.setState({errosSubmit: response.data});
            }
        }

    }

    downloadImage(url, fileName){

        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function(event) {
            var blob = xhr.response;
            var element = document.createElement("a");
            element.href = window.URL.createObjectURL(blob);
            element.download = fileName;
            element.click();
        };
        xhr.open('GET', url);
        xhr.send();

    }

    render(){
        const { 
            idRevenda,
            idVeiculo,
            nome_revenda,
            cidade_revenda,
            peblocotexto,
            locais_anuncio,
            opcionais,
            imagens,
            veano_fabricacao,
            vefipe_ano_modelo,
            vefipe_combustivel,
            vefipe_marca,
            vefipe_name,
            veobservacao,
            vequilometragem,
            vevalor,
            vevalidacaoremovido,
            integracao_list,
            carregando,
            integracao_veiculo_list,
            carregando_veiculo,
            veipva_pago,
            veblindado,
            vegarantia_fabrica,
        } = this.state;

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin' && this.state.idRevenda !== this.state.usuarioLogado.revenda){
            return <Redirect to='/acesso-negado' />
        }

        return (
            <div>
                {
                    this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ? <Cabecalho /> :  <CabecalhoPainel />
                }
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Veículos<br/><small>Revenda: { nome_revenda }</small></h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    Visualizar veículo
                                </div>
                                <div className="panel-body">

                                    <table className='table-veiculos'>
                                        <tbody>
                                            <tr>
                                                <td>{ nome_revenda.toUpperCase() } - { cidade_revenda.toUpperCase() }<br/></td>
                                            </tr>
                                            <tr>

                                                <td>
                                                    { vefipe_marca.toUpperCase() } <br/>
                                                    { vefipe_name.toUpperCase() } { vefipe_combustivel.toUpperCase() } <br/>
                                                    { veano_fabricacao.toUpperCase() }/{ vefipe_ano_modelo.toUpperCase() } <br/>
                                                    { 
                                                        opcionais !== undefined && opcionais.length > 0 ? 
                                                        ( 
                                                            <div>
                                                                {
                                                                    Object.keys(opcionais)
                                                                    .map(key => {
                                                                        return (
                                                                            <span key={key}>{ opcionais[key].descricao.toUpperCase() }<br/></span>
                                                                        )
                                                                    })
                                                                }
                                                            </div> 
                                                        )
                                                        : null
                                                    }
                                                    {
                                                        veipva_pago !== undefined && veipva_pago === true ?
                                                            <span>IPVA Pago<br/></span>
                                                        : null
                                                    }
                                                    {
                                                        veblindado !== undefined && veblindado === true ?
                                                            <span>Blindado<br/></span>
                                                        : null
                                                    }
                                                    {
                                                        vegarantia_fabrica !== undefined && vegarantia_fabrica === true ?
                                                            <span>Garantia de fábrica<br/></span>
                                                        : null
                                                    }
                                                    { "KM " + vequilometragem.toUpperCase() } <br/><br/>
                                                    { formatMoney(vevalor) } <br/><br/>

                                                    { 
                                                        veobservacao.length > 0 ? 
                                                        (
                                                            <div>{ veobservacao } <br/><br/> </div>
                                                        ) : null
                                                    }
                                                    
                                                </td>
                                                <td>
                                                    <xmp>{ peblocotexto.toUpperCase() }</xmp>
                                                </td>                                                

                                                {      
                                                    imagens !== undefined && imagens.length > 0 ? 
                                                    ( 
                                                        <td>
                                                            <strong>Download de imagens:</strong><br/>
                                                            {
                                                                Object.keys(imagens)
                                                                .map(key => {
                                                                    return (
                                                                        imagens[key].fileUrl !== null ? 
                                                                            <div key={key}><a onClick={() => this.downloadImage(imagens[key].fileUrl, imagens[key].fileName)} >{ imagens[key].fileName }</a><br/></div>
                                                                        : null
                                                                    )
                                                                })
                                                            }
                                                            <br/>
                                                        </td> 
                                                    )
                                                    : null
                                                }
                                            </tr>
                                        </tbody>
                                    </table> 

                                    <div className="row" style={{ marginBottom: '20px'}}>                                    
                                        {      
                                            imagens !== undefined && imagens.length > 0 ? 
                                            ( 
                                                Object.keys(imagens)
                                                .map(key => {
                                                    
                                                    return (
                                                        imagens[key].fileThumb !== undefined ? 
                                                            <div key={key} className="col-md-3 visualizar-veiculo" ><a target="_blank" title={ imagens[key].fileName } rel="noopener noreferrer" href={ imagens[key].fileUrl }><img src={ imagens[key].fileThumb } alt={ imagens[key].fileName } /></a><br/></div>
                                                        : imagens[key].fileUrl !== undefined ?
                                                            <div key={key} className="col-md-3 visualizar-veiculo" ><a target="_blank" title={ imagens[key].fileName } rel="noopener noreferrer" href={ imagens[key].fileUrl }><img src={ imagens[key].fileUrl } alt={ imagens[key].fileName } /></a><br/></div>
                                                        : null
                                                    )
                                                    
                                                })
                                            )
                                            : null
                                        }
                                    </div>

                                    <div>
                                        {      
                                            locais_anuncio !== undefined && locais_anuncio.length > 0 ? 
                                            ( 
                                                <div>
                                                    <strong>Locais de anúncio:</strong><br/>
                                                    {
                                                        Object.keys(locais_anuncio)
                                                        .map(key => {
                                                            return (
                                                                <div key={key}>{ locais_anuncio[key].descricao == 'Meu carro novo' ? 'Na Pista' : locais_anuncio[key].descricao }<br/></div>
                                                            )
                                                        })
                                                    }
                                                    <br/>
                                                </div> 
                                            )
                                            : null
                                        }
                                    </div>

                                    <div>
                                        <table className='table-integracao table table-striped'>
                                            <thead>
                                                <tr>
                                                    <th>Integração</th>
                                                    <th>Status configuração</th>
                                                    <th>Status publicação</th>
                                                    <th>Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {      
                                                    Object.keys(integracao_list)
                                                    .map(key => {
                                                        
                                                        return (
                                                            <tr key={key}>
                                                                <td>{ integracao_list[key].descricao == 'Meu Carro Novo' ? 'Na Pista' : integracao_list[key].descricao }</td>
                                                                <td>
                                                                    {
                                                                        carregando ? null : (
                                                                            integracao_list[key].status ? (
                                                                                <span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span>
                                                                            ) : (
                                                                                <span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span>
                                                                            )
                                                                        )
                                                                    } 
                                                                </td>
                                                                <td>
                                                                    {
                                                                        carregando_veiculo ? null : (
                                                                            integracao_veiculo_list[integracao_list[key].id] ? (
                                                                                integracao_veiculo_list[integracao_list[key].id].status ? (
                                                                                    <span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span>
                                                                                ) : (
                                                                                    <span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span>
                                                                                )
                                                                            ) : null
                                                                        )
                                                                        
                                                                    }
                                                                    
                                                                    {/* <span className="glyphicon glyphicon-remove-circle" style={{ fontSize: '20px', color: 'red'}}></span> <span className="glyphicon glyphicon glyphicon-ban-circle" style={{ fontSize: '20px', color: 'gold'}}></span></td> */}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        integracao_list[key].status ? (
                                                                            integracao_veiculo_list[integracao_list[key].id] ? (
                                                                                integracao_list[key].id === 'autoscar' || integracao_list[key].id === 'webmotors' ?
                                                                                    <div><Link to={ "/revenda/"+idRevenda+"/veiculos/"+idVeiculo+"/integracao/"+integracao_list[key].id+"/"+integracao_veiculo_list[integracao_list[key].id].id_anuncio+"/alterar"} >Alterar</Link><span> / </span> <a onClick={() => this.excluirVeiculo(integracao_list[key].id, integracao_veiculo_list[integracao_list[key].id].id_anuncio)}>Excluir</a></div>
                                                                                        : 
                                                                                    <div> <a onClick={() => this.excluirVeiculo(integracao_list[key].id, integracao_veiculo_list[integracao_list[key].id].id_anuncio)}>Excluir</a></div>
                                                                            ) : (
                                                                                <Link to={ "/revenda/"+idRevenda+"/veiculos/"+idVeiculo+"/integracao/"+integracao_list[key].id+"/incluir"} >Incluir</Link>
                                                                            )                                                                            
                                                                        ) : null
                                                                    } 
                                                                </td>
                                                            </tr>
                                                        )
                                                        
                                                    })
                                                }                                            
                                            </tbody>
                                        </table> 
                                    </div>

                                    <button onClick={this.goBack} className="btn btn-warning" >Voltar</button> { vevalidacaoremovido !== 1 ? <Link to={ "/revenda/"+idRevenda+"/veiculos/"+idVeiculo+"/alterar"} className="btn btn-primary" >Alterar</Link> : null } 

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}

function formatMoney(value) {

    var places = 2;
    var symbol = "R$ ";
    var thousand = ".";
    var decimal = ",";

	places = !isNaN(places = Math.abs(places)) ? places : 2;
	symbol = symbol !== undefined ? symbol : "$";
	thousand = thousand || ",";
	decimal = decimal || ".";
	var number = value, 
	    negative = number < 0 ? "-" : "",
	    i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
	    j = (j = i.length) > 3 ? j % 3 : 0;
	return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
};

function compareLocaisAnuncio(a, b) {
    const genreA = a.descricao.toUpperCase();
    const genreB = b.descricao.toUpperCase();
    
    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
}

export default RevendaVeiculosVisualizar