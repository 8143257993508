import React, { Component } from 'react';
import { firestore } from '../../../firebase-config';
import Cabecalho from '../../../componentes/Cabecalho';
import CabecalhoPainel from '../../../componentes/CabecalhoPainel';
import { base_url, api_key } from '../../../config/config_api';
import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import MenuItem from "@material-ui/core/MenuItem";
import { Redirect } from 'react-router-dom';

class VeiculosIntegracaoAutolineIncluir extends Component {

    constructor (props){
        super(props);

        this.state = {
            estaGravando: false,
            carregandoDados: true,
            returnPagina: false,
            errosSubmit: null,
            errorLoading: false,
            errorsLoading: null,
            idRevenda: props.match.params.idRevenda,
            idVeiculo: props.match.params.idVeiculo,
            nome_revenda: "",
            opcionais: [],
            imagens: [],
            veano_fabricacao: "",
            vecambio: "",
            vecor: "",
            vefipe_ano_modelo: "",
            vefipe_combustivel: "",
            vefipe_marca: "",
            vefipe_name: "",
            veiplaca: "",
            veobservacao: "",
            veportas: "",
            vequilometragem: "",
            vevalor: "",
            vecarroceria: "",
            veremovido: 0,
            autoline_marca: "",
            autoline_modelo: "",
            autoline_versao: "",
            autoline_ano_fabricacao: "",
            autoline_ano_modelo: "",
            autoline_plate: "",
            autoline_mileage: "",
            autoline_price: "",
            autoline_description: "",
            autoline_fuelType: "",
            autoline_color: "",
            autoline_ports: "",
            autoline_transmission: "",
            autoline_imagens: [],
            autoline_opcionais: [],
            autoline_list_marca: [],
            autoline_list_modelo: [],
            autoline_list_versao: [],
            autoline_list_combustivel: [],
            autoline_list_cor: [],
            autoline_list_transmissao: [],
            autoline_modalidade_anuncio: "",
            autoline_list_modalidade_anuncio: [],
            autoline_carroceria: "",
            autoline_list_carroceria: [],
            versaoSelecionada: new Map(),
            autoline_tipo_anuncio: "U",
            autoline_blindado: false,
            autoline_adaptado_deficiente_fisico: false,
            autoline_unico_dono: false,
            autoline_alienado: false,
            autoline_licenciado: false,
            autoline_ipva_pago: false,
            autoline_revisoes_concessionaria: false,
            autoline_garantia_fabrica: false,
            autoline_aceita_troca: false,
            autoline_veiculo_colecao: false,
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }
        this.mounted = false;
        this.goBack  = this.goBack.bind(this);
        this.handleChange  = this.handleChange.bind(this);
        this.handleMarca   = this.handleMarca.bind(this);
        this.handleModelo  = this.handleModelo.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.handleSubmit  = this.handleSubmit.bind(this);
        this.handleChangeRadio  = this.handleChangeRadio.bind(this);

    }

    goBack(){
        this.props.history.goBack();
    }

    componentDidMount(){

        this.mounted = true;

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection("revenda").doc(this.state.idRevenda)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    nome_revenda: doc.data().penome_fantasia,
                });
            }
        }).catch(function(error) {
        });

        firestore.collection("revenda").doc(this.state.idRevenda).collection("veiculos").doc(this.state.idVeiculo)
        .get()
        .then(doc => {
            if (doc.exists) {

                this.setState({
                    opcionais: doc.data().opcionais.sort(compareOpcionais),
                    imagens: doc.data().imagens,
                    veano_fabricacao: doc.data().veano_fabricacao,
                    vecambio: doc.data().vecambio,
                    vecor: doc.data().vecor,
                    vefipe_ano_modelo: doc.data().vefipe_ano_modelo,
                    vefipe_combustivel: doc.data().vefipe_combustivel,
                    vefipe_marca: doc.data().vefipe_marca,
                    vefipe_name: doc.data().vefipe_name,
                    veiplaca: doc.data().veiplaca,
                    veobservacao: doc.data().veobservacao,
                    veportas: doc.data().veportas,
                    vequilometragem: doc.data().vequilometragem,
                    vevalor: doc.data().vevalor,
                    veremovido: doc.data().veremovido,
                    vecarroceria: doc.data().vecarroceria,
                    autoline_blindado: doc.data().veblindado,
                    autoline_ipva_pago: doc.data().veipva_pago,
                    autoline_garantia_fabrica: doc.data().vegarantia_fabrica,
                });
            }
        }).catch(function(error) {
        });

        fetch(base_url+"/api/integracao/autoline/incluir", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "api_key": api_key,
                "id_veiculo": this.state.idVeiculo
            })
        })
        .then(response => {

            response.json()
            .then(data => {
                
                if(data.status === 'success'){
                    this.setState({
                        autoline_marca: data.data.veiculoPost.CodigoMarca,
                        autoline_modelo: data.data.veiculoPost.CodigoModelo,
                        autoline_versao: data.data.veiculoPost.CodigoVersao,
                        autoline_ano_fabricacao: data.data.veiculoPost.AnoFabricacao,
                        autoline_ano_modelo: data.data.veiculoPost.AnoDoModelo,
                        autoline_plate: data.data.veiculoPost.Placa.toUpperCase(),
                        autoline_mileage: data.data.veiculoPost.Km,
                        autoline_price: data.data.veiculoPost.Preco,
                        autoline_description: data.data.veiculoPost.Observacao.toUpperCase(),
                        autoline_fuelType: data.data.veiculoPost.CodigoCombustivel,
                        autoline_color: data.data.veiculoPost.CodigoCor,
                        autoline_ports: data.data.veiculoPost.NrPortas,
                        autoline_transmission: data.data.veiculoPost.CodigoCambio,
                        autoline_imagens: data.data.imagens,
                        autoline_opcionais: data.data.retOpcionais.sort(compareOpcionais),
                        autoline_list_marca: data.data.retMarca,
                        autoline_list_modelo: data.data.retModelo,
                        autoline_list_versao: data.data.retVersao,
                        autoline_list_combustivel: data.data.retTipoCombustivel.sort(compareItem),
                        autoline_list_cor: data.data.retCores.sort(compareItem),
                        autoline_list_transmissao: data.data.retTipoTransmissao.sort(compareItem),
                        autoline_carroceria: data.data.veiculoPost.CodigoCategoria,
                        autoline_list_carroceria: data.data.retCategoria.sort(compareItem),
                    });
                }else{
                    this.setState({
                        errorLoading: true,
                        errorsLoading: data.data,
                    });
                }
            });

            this.setState({
                carregandoDados: false,
            });

        })
        .catch(error => {
            console.error("Erro get teste", error);
            this.setState({
                carregandoDados: false,
            });
        });
        
    }

    componentWillUnmount(){
        this.mounted = false;
    }

    handleMarca(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value,
            autoline_list_modelo: [],
            autoline_list_versao: [],
        });

        return fetch(base_url+"/api/integracao/autoline/consultar-modelo", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "api_key": api_key,
                "marca": value,
                "id_veiculo": this.state.idVeiculo
            })
        })
        .then(response => {

            return response.json()
            .then(data => {
                if(data.status === 'success'){
                    this.setState({
                        autoline_list_modelo: data.data.modelos,
                        autoline_modelo: ""
                    });
                }
            });

        })
        .catch(error => {
            console.error("Erro get teste", error);
        });

    }

    handleModelo(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value,
            autoline_list_versao: [],
        });

        return fetch(base_url+"/api/integracao/autoline/consultar-versao", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "api_key": api_key,
                "marca": this.state.autoline_marca,
                "modelo": value,
                "id_veiculo": this.state.idVeiculo
            })
        })
        .then(response => {

            return response.json()
            .then(data => {
                if(data.status === 'success'){
                    this.setState({
                        autoline_list_versao: data.data.versoes,
                        autoline_versao: ""
                    });
                }
            });

        })
        .catch(error => {
            console.error("Erro get teste", error);
        });

    }

    handleChange(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        });
    }

    handleChangeRadio(event) {

        const target = event.target;
        const name   = target.name;
        var newValue = !this.state[name];

        this.setState({
            [name]: newValue
        });
    }

    handleChecked(event) {

        const item = event.target.name; 
        var oldOpcionais = this.state.autoline_opcionais;
        const oldChecked = oldOpcionais[item].checked;
        oldOpcionais[item].checked = !oldChecked;
        this.setState({
            autoline_opcionais: oldOpcionais
        });

    }

    handleSubmit(event) {

        this.setState({estaGravando: true, errosSubmit: null});

        var opcionais_selecionados = this.state.autoline_opcionais.filter(opcionais_true);
        var user = JSON.parse(window.localStorage.getItem("usuarioLogado"));
        
        var veiculo_post = {
            MakeId: this.state.autoline_marca,
            ModelId: this.state.autoline_modelo,
            ModelVersionId: this.state.autoline_versao,
            ModelYear: this.state.autoline_ano_modelo,
            BuiltYear: this.state.autoline_ano_fabricacao,
            Odometer: this.state.autoline_mileage,
            IsZeroKm: this.state.autoline_mileage > 0 ? 0 : 1,
            RegistrationPlate: this.state.autoline_plate,
            TransmissionId: this.state.autoline_transmission,
            ColorId: this.state.autoline_color,
            FuelTypeId: this.state.autoline_fuelType,
            Price: this.state.autoline_price,
            AdditionalInformation: this.state.autoline_description,
            DoorNumberId: this.state.autoline_ports,
            BodyTypeId: this.state.autoline_carroceria,
            Features: opcionais_selecionados,
            IsArmored: this.state.autoline_blindado ? 1 : 0,
            IsDisabilityAdapted: this.state.autoline_adaptado_deficiente_fisico ? 1 : 0,
            IsOneOwnerUsed: this.state.autoline_unico_dono ? 1 : 0,
            IsFinanced: this.state.autoline_alienado ? 1 : 0,
            IsRegistrationPaid: this.state.autoline_licenciado ? 1 : 0,
            IsTaxPaid: this.state.autoline_ipva_pago ? 1 : 0,
            AreAllServicesMadeByAuthorizedCenters: this.state.autoline_revisoes_concessionaria ? 1 : 0,
            IsManufacturerWarrantyActive: this.state.autoline_garantia_fabrica ? 1 : 0,
            IsSwapNotAccepted: this.state.autoline_aceita_troca ? 0 : 1,
            IsCollectorVehicle: this.state.autoline_veiculo_colecao ? 1 : 0,
        }   
        
        fetch(base_url+"/api/integracao/autoline/enviar", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "api_key": api_key,
                "veiculo_post": veiculo_post,
                "usuario": user.id,
                "id_veiculo": this.state.idVeiculo
            })
        })
        .then(response => {

            response.json()
            .then(data => {
                if(data.status === 'success'){

                    var integracao = {
                        status: true,
                        id_anuncio: data.data.id_anuncio,
                        url_anuncio: data.data.url_anuncio,
                    };

                    firestore.collection('revenda').doc(this.state.idRevenda).collection('veiculos').doc(this.state.idVeiculo).collection('integracoes').doc("autoline").set(integracao, { merge: true })
                    .then(res => { 
                        this.setState({returnPagina: true}); 
                    })
                    .catch(err => { 
                        this.setState({returnPagina: true});      
                    });
                   
                }else{
                    this.setState({errosSubmit: data.data});
                }
            });
            this.setState({estaGravando: false});  

        })
        .catch(error => {
            console.log(error);
            this.setState({estaGravando: false});  
        });

    }

    render(){
        const { 
            errosSubmit,
            estaGravando,
            idRevenda,
            idVeiculo,
            nome_revenda,
            opcionais,
            imagens,
            veano_fabricacao,
            vecambio,
            vecor,
            vefipe_ano_modelo,
            vefipe_combustivel,
            vefipe_marca,
            vefipe_name,
            veiplaca,
            veobservacao,
            veportas,
            vequilometragem,
            vevalor,
            autoline_marca,
            autoline_modelo,
            autoline_versao,
            autoline_ano_fabricacao,
            autoline_ano_modelo,
            autoline_plate,
            autoline_mileage,
            autoline_price,
            autoline_description,
            autoline_fuelType,
            autoline_color,
            autoline_ports,
            autoline_transmission,
            autoline_imagens,
            autoline_opcionais,
            autoline_list_marca,
            autoline_list_modelo,
            autoline_list_versao,
            autoline_list_combustivel,
            autoline_list_cor,
            autoline_list_transmissao,
            carregandoDados,
            errorLoading,
            errorsLoading,
            autoline_blindado,
            autoline_adaptado_deficiente_fisico,
            autoline_unico_dono,
            autoline_alienado,
            autoline_licenciado,
            autoline_ipva_pago,
            autoline_revisoes_concessionaria,
            autoline_garantia_fabrica,
            autoline_aceita_troca,
            autoline_veiculo_colecao,
            vecarroceria,
            autoline_carroceria,
            autoline_list_carroceria,
        } = this.state;

        if(this.state.returnPagina){
            return <Redirect to={ "/revenda/"+idRevenda+"/veiculos/"+idVeiculo+"/visualizar" } />;
        }

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin' && this.state.idRevenda !== this.state.usuarioLogado.revenda){
            return <Redirect to='/acesso-negado' />
        }

        return (
            <div>
                {
                    this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ? <Cabecalho /> :  <CabecalhoPainel />
                } 
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Incluir veículo Autoline<br/><small>Revenda: { nome_revenda }</small></h1>
                        </div>
                    </div>
                    <div className="row">
                        {
                                    
                            carregandoDados ? (<div className="col-lg-12"><img src="/img/aguarde.gif" alt="carregando"  style={{ height: '80px', marginBottom: '10px'}} /></div>) : (

                                errorLoading ? (<div style={{ display: errorsLoading !== null ? " block " : " none "  }} className="alert alert-danger" role="alert">{errorsLoading}</div>) : (
                        
                                    <div className="col-lg-12">
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                Integração Autoline - Incluir veículo
                                            </div>
                                            <div className="panel-body">
                                            
                                            <ValidatorForm
                                                    ref="form"
                                                    onSubmit={this.handleSubmit}
                                                    onError={ errors => exibirErroSubmit(errors) }
                                                >
                                                <table className='table table-striped'>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ minWidth: '155px', width: '10%'}}></th>
                                                            <th style={{ width: '45%'}}>Dados Intermédio</th>
                                                            <th style={{ width: '45%'}}>Dados Autoline</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody> 
                                                        <tr>
                                                            <td>Marca <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_marca.toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ autoline_marca }
                                                                    name="autoline_marca"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleMarca}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(autoline_list_marca)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ autoline_list_marca[key].id }>{ autoline_list_marca[key].marca.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Modelo <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_name.split(' ', 1)[0].toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ autoline_modelo }
                                                                    name="autoline_modelo"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleModelo}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        {
                                                                            Object.keys(autoline_list_modelo)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ autoline_list_modelo[key].id }>{ autoline_list_modelo[key].modelo.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>  
                                                        <tr>
                                                            <td>Ano fabricação <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ veano_fabricacao }</td>
                                                            <td>{ autoline_ano_fabricacao }</td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Ano modelo <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_ano_modelo }</td>
                                                            <td>{ autoline_ano_modelo }</td>
                                                        </tr>    
                                                        <tr>
                                                            <td>Versão <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_name.substring(vefipe_name.split(' ', 1)[0].length, vefipe_name.length).trim().toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ autoline_versao }
                                                                    name="autoline_versao"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        {
                                                                            autoline_list_versao.length > 1 ? <MenuItem value=""></MenuItem> : null 
                                                                        }
                                                                        {
                                                                            Object.keys(autoline_list_versao)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ autoline_list_versao[key].id }>{ autoline_list_versao[key].versao.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>  
                                                        <tr>
                                                            <td>Placa</td>
                                                            <td>{ veiplaca.toUpperCase() }</td>
                                                            <td>
                                                                <TextValidator
                                                                    onChange={this.handleChange}
                                                                    name="autoline_plate"
                                                                    value={autoline_plate.toUpperCase()}
                                                                    className="form-ui-bootstrap form-integracao"
                                                                />
                                                            </td>
                                                        </tr>      
                                                        <tr>
                                                            <td>Km <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vequilometragem }</td>
                                                            <td>{ autoline_mileage }</td>
                                                        </tr>      
                                                        <tr>
                                                            <td>Preço <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ formatMoney(vevalor) }</td>
                                                            <td>{ formatMoney(autoline_price) }</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Observações</td>
                                                            <td>{ veobservacao.toUpperCase() }</td>
                                                            <td>
                                                                <textarea name="autoline_description" className="form-ui-bootstrap" rows="4" value={autoline_description} onChange={this.handleChange} ></textarea>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Combustível <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_combustivel.toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ autoline_fuelType }
                                                                    name="autoline_fuelType"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(autoline_list_combustivel)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ autoline_list_combustivel[key].Id }>{ autoline_list_combustivel[key].Name.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>     
                                                        <tr>
                                                            <td>Cor <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vecor.toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ autoline_color }
                                                                    name="autoline_color"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(autoline_list_cor)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ autoline_list_cor[key].Id }>{ autoline_list_cor[key].Name.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>       
                                                        <tr>
                                                            <td>Portas <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ veportas }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ autoline_ports }
                                                                    name="autoline_ports"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        <MenuItem value="0">0</MenuItem>
                                                                        <MenuItem value="1">1</MenuItem>
                                                                        <MenuItem value="2">2</MenuItem>
                                                                        <MenuItem value="4">4</MenuItem>                                                      
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Carroceria <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vecarroceria }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ autoline_carroceria }
                                                                    name="autoline_carroceria"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(autoline_list_carroceria)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ autoline_list_carroceria[key].Id }>{ autoline_list_carroceria[key].Name.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Transmissão <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ formatCambio(vecambio) }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ autoline_transmission }
                                                                    name="autoline_transmission"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(autoline_list_transmissao)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ autoline_list_transmissao[key].Id }>{ autoline_list_transmissao[key].Name.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Características</td>
                                                            <td></td>
                                                            <td>
                                                                <p className="checkbox-item">
                                                                    <label className="checkbox-label">
                                                                        <input type="checkbox" name="autoline_blindado" defaultChecked={autoline_blindado} onChange={this.handleChangeRadio} /> &nbsp;&nbsp;BLINDADO
                                                                    </label>
                                                                </p>
                                                                <p className="checkbox-item">
                                                                    <label className="checkbox-label">
                                                                        <input type="checkbox" name="autoline_adaptado_deficiente_fisico" defaultChecked={autoline_adaptado_deficiente_fisico} onChange={this.handleChangeRadio} /> &nbsp;&nbsp;ADAPTADO PARA DEFICIENTES FÍSICOS
                                                                    </label>
                                                                </p>
                                                                <p className="checkbox-item">
                                                                    <label className="checkbox-label">
                                                                        <input type="checkbox" name="autoline_unico_dono" defaultChecked={autoline_unico_dono} onChange={this.handleChangeRadio} /> &nbsp;&nbsp;ÚNICO DONO
                                                                    </label>
                                                                </p>
                                                                <p className="checkbox-item">
                                                                    <label className="checkbox-label">
                                                                        <input type="checkbox" name="autoline_alienado" defaultChecked={autoline_alienado} onChange={this.handleChangeRadio} /> &nbsp;&nbsp;ALIENADO
                                                                    </label>
                                                                </p>
                                                                <p className="checkbox-item">
                                                                    <label className="checkbox-label">
                                                                        <input type="checkbox" name="autoline_licenciado" defaultChecked={autoline_licenciado} onChange={this.handleChangeRadio} /> &nbsp;&nbsp;LICENCIADO
                                                                    </label>
                                                                </p>
                                                                <p className="checkbox-item">
                                                                    <label className="checkbox-label">
                                                                        <input type="checkbox" name="autoline_ipva_pago" defaultChecked={autoline_ipva_pago} onChange={this.handleChangeRadio} /> &nbsp;&nbsp;IPVA PAGO
                                                                    </label>
                                                                </p>
                                                                <p className="checkbox-item">
                                                                    <label className="checkbox-label">
                                                                        <input type="checkbox" name="autoline_revisoes_concessionaria" defaultChecked={autoline_revisoes_concessionaria} onChange={this.handleChangeRadio} /> &nbsp;&nbsp;REVISÕES NA CONCESSIONÁRIA
                                                                    </label>
                                                                </p>
                                                                <p className="checkbox-item">
                                                                    <label className="checkbox-label">
                                                                        <input type="checkbox" name="autoline_garantia_fabrica" defaultChecked={autoline_garantia_fabrica} onChange={this.handleChangeRadio} /> &nbsp;&nbsp;GARANTIA DE FÁBRICA
                                                                    </label>
                                                                </p>
                                                                <p className="checkbox-item">
                                                                    <label className="checkbox-label">
                                                                        <input type="checkbox" name="autoline_aceita_troca" defaultChecked={autoline_aceita_troca} onChange={this.handleChangeRadio} /> &nbsp;&nbsp;ACEITA TROCA
                                                                    </label>
                                                                </p>
                                                                <p className="checkbox-item">
                                                                    <label className="checkbox-label">
                                                                        <input type="checkbox" name="autoline_veiculo_colecao" defaultChecked={autoline_veiculo_colecao} onChange={this.handleChangeRadio} /> &nbsp;&nbsp;VEÍCULO DE COLEÇÃO
                                                                    </label>
                                                                </p>

                                                            </td>
                                                        </tr>      
                                                        <tr>
                                                            <td>Opcionais</td>
                                                            <td>
                                                                {
                                                                    Object.keys(opcionais)
                                                                    .map(key => {
                                                                        return (
                                                                            <span key={key}>{ opcionais[key].descricao.toUpperCase() }<br/></span>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    Object.keys(autoline_opcionais)
                                                                    .map(key => {
                                                                        return (
                                                                            <p className="checkbox-item" key={key}>
                                                                                <label className="checkbox-label">
                                                                                    <input type="checkbox" name={key} defaultChecked={autoline_opcionais[key].checked ? autoline_opcionais[key].checked : false} onChange={this.handleChecked} /> &nbsp;&nbsp;{autoline_opcionais[key].descricao.toUpperCase()}
                                                                                </label>
                                                                            </p>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Imagens <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>
                                                                {      
                                                                    imagens !== undefined && imagens.length > 0 ? 
                                                                    ( 
                                                                        Object.keys(imagens)
                                                                        .map(key => {
                                                                            
                                                                            return (
                                                                                imagens[key].fileUrl !== null && imagens[key].fileThumb !== null  ? 
                                                                                <div key={key}><img src={ imagens[key].fileThumb } alt={ imagens[key].fileName }  style={{ height: '80px', marginBottom: '10px'}} /><br/></div>
                                                                                : null
                                                                            )
                                                                            
                                                                        })
                                                                    )
                                                                    : null
                                                                }
                                                            </td>
                                                            <td>
                                                                {      
                                                                    autoline_imagens !== undefined && autoline_imagens.length > 0 ? 
                                                                    ( 
                                                                        Object.keys(autoline_imagens)
                                                                        .map(key => {
                                                                            
                                                                            return (
                                                                                <div key={key}><img src={ autoline_imagens[key] } style={{ height: '80px', marginBottom: '10px'}}  /><br/></div>
                                                                            )
                                                                            
                                                                        })
                                                                    )
                                                                    : null
                                                                }
                                                            </td>
                                                        </tr> 
                                                    </tbody>
                                                </table> 
                                                <p><span className="alteracao-app">*</span> Dados devem ser alterados no aplicativo <br/><span className="preenchimento-obrigatorio">*</span> Campo obrigatório</p>
                                                <div style={{ display: estaGravando ? " block " : " none "  }}><img src="/img/aguarde.gif" alt="carregando"  style={{ height: '80px', marginBottom: '10px'}} /></div>
                                                <div style={{ display: errosSubmit !== null ? " block " : " none "  }} className="alert alert-danger" role="alert">{errosSubmit}</div>
                                                <button onClick={this.goBack} disabled={estaGravando} className="btn btn-warning" >Voltar</button>&nbsp;&nbsp;<button type="submit" disabled={estaGravando} className="btn btn-primary" >Enviar</button>

                                                </ValidatorForm>
                                            </div>
                                        </div>
                                    </div>

                                )
                            )
                        }
                    </div>
                </div>
            </div>

        )
    }

}

function formatMoney(value) {

    var places = 2;
    var symbol = "R$ ";
    var thousand = ".";
    var decimal = ",";

	places = !isNaN(places = Math.abs(places)) ? places : 2;
	symbol = symbol !== undefined ? symbol : "$";
	thousand = thousand || ",";
	decimal = decimal || ".";
	var number = value, 
	    negative = number < 0 ? "-" : "",
	    i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
	    j = (j = i.length) > 3 ? j % 3 : 0;
	return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
};

function formatCambio(value){
    if(value === 'A'){
        return "AUTOMÁTICO";
    }else{
        return "MANUAL";
    }
}

function compareOpcionais(a, b) {
    const genreA = a.descricao.toUpperCase();
    const genreB = b.descricao.toUpperCase();
    
    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
}

function compareItem(a, b) {
    const genreA = a.Name.toUpperCase();
    const genreB = b.Name.toUpperCase();
    
    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
}

function exibirErroSubmit(errors){
    window.scrollTo(0, 0);
    alert("Campos obrigatórios não preenchidos!");
}

function opcionais_true(opcional){
    return opcional.checked === true;
}

export default VeiculosIntegracaoAutolineIncluir