import React, { Component } from 'react';
import { firestore } from './../../firebase-config';
import Cabecalho from './../../componentes/Cabecalho';
import { Redirect } from 'react-router-dom';

class LocaisAnuncioExcluir extends Component {

    constructor (props){
        super(props);

        this.state = {
            returnPagina: false,
            idLocalAnuncio: props.match.params.idLocalAnuncio,
            descricao: "",
            ordenacao: "",
            estaGravando: false,
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }
        this.mounted = false;

        this.handleSubmit = this.handleSubmit.bind(this);
        this.goBack       = this.goBack.bind(this);

    }

    goBack(){
        this.props.history.goBack();
    }

    componentDidMount(){

        this.mounted = true;

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection("locais_anuncio").doc(this.state.idLocalAnuncio)
        .get().then(doc => {
            if (doc.exists) {
                this.setState({
                    descricao: doc.data().descricao,
                    ordenacao: doc.data().ordenacao,
                });
            }
        }).catch(function(error) {
        });

    }

    handleSubmit(event) {

        this.setState({estaGravando: true});

        firestore.collection('locais_anuncio').doc(this.state.idLocalAnuncio).delete()
        .then(res => { 
            alert("Opcional removido com sucesso!");
            this.setState({returnPagina: true, estaGravando: false});
        })
        .catch(err => { 
            alert("Falha ao remover opcional!");
            this.setState({returnPagina: true, estaGravando: false});     
        });

        event.preventDefault();

    }

    render(){
        const { descricao, ordenacao, estaGravando } = this.state;
        if(this.state.returnPagina){
            return <Redirect to="/locais-anuncio/listar" />;
        }
        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin'){
            return <Redirect to='/acesso-negado' />
        }
        return (

            <div>
                <Cabecalho />
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Locais de anúncio</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    Excluir local de anúncio
                                </div>
                                <div className="panel-body">

                                    <h2>Deseja remover este local de anúncio?</h2>
                                    <p><strong>Descrição: </strong>{descricao}</p>
                                    <p><strong>Ordenação: </strong>{ordenacao}</p>
                                    <input className="btn btn-danger" type="button" value="Remover" onClick={this.handleSubmit} disabled={estaGravando} />&nbsp;&nbsp;
                                    <button onClick={this.goBack} className="btn btn-warning" >Voltar</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}

export default LocaisAnuncioExcluir
