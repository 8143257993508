import React, { Component } from 'react';
import { firestore } from '../../../firebase-config';
import Cabecalho from '../../../componentes/Cabecalho';
import CabecalhoPainel from '../../../componentes/CabecalhoPainel';
import { base_url, api_key } from '../../../config/config_api';
import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import MenuItem from "@material-ui/core/MenuItem";
import { Redirect } from 'react-router-dom';

class VeiculosIntegracaoMeuCarroNovoIncluir extends Component {

    constructor (props){
        super(props);

        this.state = {
            estaGravando: false,
            carregandoDados: true,
            returnPagina: false,
            errosSubmit: null,
            errorLoading: false,
            errorsLoading: null,
            idRevenda: props.match.params.idRevenda,
            idVeiculo: props.match.params.idVeiculo,
            nome_revenda: "",
            opcionais: [],
            imagens: [],
            revenda_cidade: "",
            veano_fabricacao: "",
            vecambio: "",
            vecor: "",
            vefipe_ano_modelo: "",
            vefipe_combustivel: "",
            vefipe_marca: "",
            vefipe_name: "",
            veiplaca: "",
            veobservacao: "",
            veportas: "",
            vequilometragem: "",
            vevalor: "",
            veremovido: 0,
            veveiculonovo: "",
            idCategoria: 0,
            meucarronovo_marca: "",
            meucarronovo_modelo: "",
            meucarronovo_versao: "",
            meucarronovo_ano_fabricacao: "",
            meucarronovo_ano_modelo: "",
            meucarronovo_placa: "",
            meucarronovo_km: "",
            meucarronovo_valor: "",
            meucarronovo_observacoes: "",
            meucarronovo_combustivel: "",
            meucarronovo_cor: "",
            meucarronovo_portas: "",
            meucarronovo_cambio: "",
            aceitaTroca: false,
            documentacaoEmDia: false,
            blindado: false,
            adaptadoPne: false,
            veiculoUsado: false,
            meucarronovo_imagens: [],
            meucarronovo_opcionais: [],
            meucarronovo_list_marca: [],
            meucarronovo_list_modelo: [],
            meucarronovo_list_versao: [],
            meucarronovo_list_combustivel: [],
            meucarronovo_list_cor: [],
            meucarronovo_list_transmissao: [],
            meucarronovo_list_portas: [],
            versaoSelecionada: new Map(),
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
        }
        this.mounted = false;
        this.goBack  = this.goBack.bind(this);
        this.handleChange  = this.handleChange.bind(this);
        this.handleMarca   = this.handleMarca.bind(this);
        this.handleModelo  = this.handleModelo.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.handleSubmit  = this.handleSubmit.bind(this);
        this.handleChangeRadio  = this.handleChangeRadio.bind(this);

    }

    goBack(){
        this.props.history.goBack();
    }

    componentDidMount(){

        this.mounted = true;

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection("revenda").doc(this.state.idRevenda)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    nome_revenda: doc.data().penome_fantasia,
                    revenda_cidade: doc.data().pecidade + "-" + doc.data().peuf,
                });
            }
        }).catch(function(error) {
        });

        firestore.collection("revenda").doc(this.state.idRevenda).collection("veiculos").doc(this.state.idVeiculo)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    opcionais: doc.data().opcionais.sort(compareOpcionais),
                    imagens: doc.data().imagens,
                    veano_fabricacao: doc.data().veano_fabricacao,
                    vecambio: doc.data().vecambio,
                    vecor: doc.data().vecor,
                    vefipe_ano_modelo: doc.data().vefipe_ano_modelo,
                    vefipe_combustivel: doc.data().vefipe_combustivel,
                    vefipe_marca: doc.data().vefipe_marca,
                    vefipe_name: doc.data().vefipe_name,
                    veiplaca: doc.data().veiplaca,
                    veobservacao: doc.data().veobservacao,
                    veportas: doc.data().veportas,
                    vequilometragem: doc.data().vequilometragem,
                    vevalor: doc.data().vevalor,
                    veremovido: doc.data().veremovido,
                    vecarroceria: doc.data().vecarroceria,
                    veveiculonovo: doc.data().veveiculonovo
                });
            }
        }).catch(function(error) {
        });

        fetch(base_url+"/api/integracao/meucarronovo/incluir", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "api_key": api_key,
                "id_veiculo": this.state.idVeiculo
            })
        })
        .then(response => {

            response.json()
            .then(data => {
                if(data.status === 'success'){
                    this.setState({
                        idCategoria: data.data.veiculoPost.idCategoria,
                        meucarronovo_marca: data.data.veiculoPost.idMarca,
                        meucarronovo_modelo: data.data.veiculoPost.idModelo,
                        meucarronovo_versao: data.data.veiculoPost.idVersao,
                        meucarronovo_ano_fabricacao: data.data.veiculoPost.anoFabricacao,
                        meucarronovo_ano_modelo: data.data.veiculoPost.anoModelo,
                        meucarronovo_placa: data.data.veiculoPost.placa,
                        meucarronovo_km: data.data.veiculoPost.quilometragem,
                        meucarronovo_valor: data.data.veiculoPost.valor,
                        meucarronovo_observacoes: data.data.veiculoPost.observacoes,
                        meucarronovo_combustivel: data.data.veiculoPost.idCombustivel,
                        meucarronovo_cor: data.data.veiculoPost.idCor,
                        meucarronovo_portas: data.data.veiculoPost.idPortas,
                        meucarronovo_cambio: data.data.veiculoPost.idCambio,
                        meucarronovo_list_marca: data.data.retMarca.sort(compareItem),
                        meucarronovo_list_modelo: data.data.retModelo.sort(compareItem),
                        meucarronovo_list_versao: data.data.retVersao.sort(compareItem),
                        meucarronovo_list_combustivel: data.data.retCombustivel.sort(compareItem),
                        meucarronovo_list_cor: data.data.retCores.sort(compareItem),
                        meucarronovo_list_transmissao: data.data.retTransmissao.sort(compareItem),
                        aceitaTroca: data.data.veiculoPost.aceitaTroca,
                        documentacaoEmDia: data.data.veiculoPost.documentacaoEmDia,
                        blindado: data.data.veiculoPost.blindado,
                        adaptadoPne: data.data.veiculoPost.adaptadoPne,
                        veiculoUsado: data.data.veiculoPost.veiculoUsado,
                        meucarronovo_list_portas: data.data.retPortas.sort(compareItem),
                        meucarronovo_opcionais: data.data.retOpcionais.sort(compareOpcionais),  
                    });
                }else{
                    this.setState({
                        errorLoading: true,
                        errorsLoading: data.data,
                    });
                }
            });

            this.setState({
                carregandoDados: false,
            });

        })
        .catch(error => {
            console.error("Erro get teste", error);
            this.setState({
                carregandoDados: false,
            });
        });
        
    }

    componentWillUnmount(){
        this.mounted = false;
    }

    handleMarca(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value,
            meucarronovo_list_modelo: [],
            meucarronovo_list_versao: [],
        });

        return fetch(base_url+"/api/integracao/meucarronovo/consultar-modelo", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "api_key": api_key,
                "marca": value,
                "id_veiculo": this.state.idVeiculo
            })
        })
        .then(response => {

            return response.json()
            .then(data => {
                if(data.status === 'success'){
                    this.setState({
                        meucarronovo_list_modelo: data.data.sort(compareItem),
                        meucarronovo_modelo: ""
                    });
                }
            });

        })
        .catch(error => {
            console.error("Erro get teste", error);
        });

    }

    handleModelo(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value,
            meucarronovo_list_versao: [],
        });

        return fetch(base_url+"/api/integracao/meucarronovo/consultar-versao", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "api_key": api_key,
                "marca": this.state.meucarronovo_marca,
                "modelo": value,
                "id_veiculo": this.state.idVeiculo
            })
        })
        .then(response => {

            return response.json()
            .then(data => {
                if(data.status === 'success'){
                    this.setState({
                        meucarronovo_list_versao: data.data.sort(compareItem),
                        meucarronovo_versao: ""
                    });
                }
            });

        })
        .catch(error => {
            console.error("Erro get teste", error);
        });

    }

    handleChange(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        });
    }

    handleChangeRadio(event) {

        const target = event.target;
        const name   = target.name;
        var newValue = !this.state[name];

        this.setState({
            [name]: newValue
        });
    }

    handleChecked(event) {

        const item = event.target.name; 
        var oldOpcionais = this.state.meucarronovo_opcionais;
        const oldChecked = oldOpcionais[item].checked;
        oldOpcionais[item].checked = !oldChecked;
        this.setState({
            meucarronovo_opcionais: oldOpcionais
        });

    }

    handleSubmit(event) {

        this.setState({estaGravando: true, errosSubmit: null});

        var opcionais_selecionados = this.state.meucarronovo_opcionais.filter(opcionais_true);
        var user = JSON.parse(window.localStorage.getItem("usuarioLogado"));
        
        var veiculo_post = {
            idCategoria: this.state.idCategoria,
            idVersao: this.state.meucarronovo_versao,
            anoFabricacao: this.state.meucarronovo_ano_fabricacao,
            anoModelo: this.state.meucarronovo_ano_modelo,
            placa: this.state.meucarronovo_placa,
            quilometragem: this.state.meucarronovo_km,
            idCambio: this.state.meucarronovo_cambio,
            idCor: this.state.meucarronovo_cor,
            idCombustivel: this.state.meucarronovo_combustivel,
            valor: this.state.meucarronovo_valor,
            observacoes: this.state.meucarronovo_observacoes,
            idPortas: this.state.meucarronovo_portas,
            opcionais: opcionais_selecionados,
            aceitaTroca: this.state.aceitaTroca,
            documentacaoEmDia: this.state.documentacaoEmDia,
            blindado: this.state.blindado,
            adaptadoPne: this.state.adaptadoPne,
            veiculoUsado: this.state.veiculoUsado,
        }   
        
        fetch(base_url+"/api/integracao/meucarronovo/enviar", { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json',
            }, 
            body: JSON.stringify({
                "api_key": api_key,
                "veiculo_post": veiculo_post,
                "usuario": user.id,
                "id_veiculo": this.state.idVeiculo
            })
        })
        .then(response => {

            response.json()
            .then(data => {
                console.log(data);
                if(data.status === 'success'){

                    var integracao = {
                        status: true,
                        id_anuncio: data.data.id_anuncio,
                        url_anuncio: data.data.url_anuncio,
                    };

                    firestore.collection('revenda').doc(this.state.idRevenda).collection('veiculos').doc(this.state.idVeiculo).collection('integracoes').doc("meucarronovo").set(integracao, { merge: true })
                    .then(res => { 
                        this.setState({returnPagina: true}); 
                    })
                    .catch(err => { 
                        this.setState({returnPagina: true});      
                    });
                   
                }else{
                    this.setState({errosSubmit: data.data});
                }
            });
            this.setState({estaGravando: false});  

        })
        .catch(error => {
            console.log(error);
            this.setState({estaGravando: false});  
        });

    }

    render(){
        const { 
            errosSubmit,
            estaGravando,
            idRevenda,
            idVeiculo,
            nome_revenda,
            opcionais,
            imagens,
            veano_fabricacao,
            vecambio,
            vecor,
            vefipe_ano_modelo,
            vefipe_combustivel,
            vefipe_marca,
            vefipe_name,
            veiplaca,
            veobservacao,
            veportas,
            vequilometragem,
            vevalor,         
            veveiculonovo,   
            carregandoDados,
            errorLoading,
            errorsLoading,            
            meucarronovo_marca,
            meucarronovo_modelo,
            meucarronovo_versao,
            meucarronovo_ano_fabricacao,
            meucarronovo_ano_modelo,
            meucarronovo_placa,
            meucarronovo_km,
            meucarronovo_valor,
            meucarronovo_observacoes,
            meucarronovo_combustivel,
            meucarronovo_cor,
            meucarronovo_portas,
            meucarronovo_cambio,
            meucarronovo_list_marca,
            meucarronovo_list_modelo,
            meucarronovo_list_versao,
            meucarronovo_list_combustivel,
            meucarronovo_list_cor,
            meucarronovo_list_transmissao,
            meucarronovo_opcionais,
            aceitaTroca,
            documentacaoEmDia,
            blindado,
            adaptadoPne,
            meucarronovo_list_portas
        } = this.state;

        if(this.state.returnPagina){
            return <Redirect to={ "/revenda/"+idRevenda+"/veiculos/"+idVeiculo+"/visualizar" } />;
        }

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin' && this.state.idRevenda !== this.state.usuarioLogado.revenda){
            return <Redirect to='/acesso-negado' />
        }

        return (
            <div>
                {
                    this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ? <Cabecalho /> :  <CabecalhoPainel />
                }
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Incluir veículo Na Pista<br/><small>Revenda: { nome_revenda }</small></h1>
                        </div>
                    </div>
                    <div className="row">
                        {
                                    
                            carregandoDados ? (<div className="col-lg-12"><img src="/img/aguarde.gif" alt="carregando"  style={{ height: '80px', marginBottom: '10px'}} /></div>) : (

                                errorLoading ? (<div style={{ display: errorsLoading !== null ? " block " : " none "  }} className="alert alert-danger" role="alert">{errorsLoading}</div>) : (
                        
                                    <div className="col-lg-12">
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                Integração Na Pista - Incluir veículo
                                            </div>
                                            <div className="panel-body">
                                            
                                            <ValidatorForm
                                                    ref="form"
                                                    onSubmit={this.handleSubmit}
                                                    onError={ errors => exibirErroSubmit(errors) }
                                                >
                                                <table className='table table-striped'>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ minWidth: '155px', width: '10%'}}></th>
                                                            <th style={{ width: '45%'}}>Dados Intermédio</th>
                                                            <th style={{ width: '45%'}}>Dados Na Pista</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>                                                      
                                                        <tr>
                                                            <td>Marca <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_marca.toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ meucarronovo_marca }
                                                                    name="meucarronovo_marca"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleMarca}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(meucarronovo_list_marca)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ meucarronovo_list_marca[key].id }>{ meucarronovo_list_marca[key].nome.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Modelo <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_name.split(' ', 1)[0].toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ meucarronovo_modelo }
                                                                    name="meucarronovo_modelo"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleModelo}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        {
                                                                            Object.keys(meucarronovo_list_modelo)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ meucarronovo_list_modelo[key].nome }>{ meucarronovo_list_modelo[key].nome.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>  
                                                        <tr>
                                                            <td>Ano fabricação <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ veano_fabricacao }</td>
                                                            <td>{ meucarronovo_ano_fabricacao }</td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Ano modelo <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_ano_modelo }</td>
                                                            <td>{ meucarronovo_ano_modelo }</td>
                                                        </tr>    
                                                        <tr>
                                                            <td>Versão <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_name.toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ meucarronovo_versao }
                                                                    name="meucarronovo_versao"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        {
                                                                            meucarronovo_list_versao.length > 1 ? <MenuItem value=""></MenuItem> : null 
                                                                        }
                                                                        {
                                                                            Object.keys(meucarronovo_list_versao)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ meucarronovo_list_versao[key].id }>{ meucarronovo_list_versao[key].nome.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>  
                                                        {
                                                            veveiculonovo === 1 ? 
                                                                <tr>
                                                                    <td>Placa</td>
                                                                    <td>{ veiplaca.toUpperCase() }</td>
                                                                    <td>
                                                                        { meucarronovo_placa.toUpperCase() }
                                                                    </td>
                                                                </tr>
                                                            :
                                                                <tr>
                                                                    <td>Placa</td>
                                                                    <td>{ veiplaca.toUpperCase() }</td>
                                                                    <td>
                                                                        <TextValidator
                                                                            onChange={this.handleChange}
                                                                            name="meucarronovo_placa"
                                                                            value={meucarronovo_placa.toUpperCase()}
                                                                            className="form-ui-bootstrap form-integracao"
                                                                        />
                                                                    </td>
                                                                </tr>
                                                        }
                                                        <tr>
                                                            <td>Km <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vequilometragem }</td>
                                                            <td>{ meucarronovo_km }</td>
                                                        </tr> 
                                                        <tr>
                                                            <td>Preço <span className="alteracao-app">*</span><span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ formatMoney(vevalor) }</td>
                                                            <td>{ formatMoney(meucarronovo_valor) }</td>
                                                        </tr>                                                  
                                                        <tr>
                                                            <td>Observações</td>
                                                            <td>{ veobservacao.toUpperCase() }</td>
                                                            <td><textarea name="meucarronovo_observacoes" className="form-ui-bootstrap" rows="4" value={meucarronovo_observacoes} onChange={this.handleChange} /></td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Combustível <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vefipe_combustivel.toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ meucarronovo_combustivel }
                                                                    name="meucarronovo_combustivel"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(meucarronovo_list_combustivel)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ meucarronovo_list_combustivel[key].id }>{ meucarronovo_list_combustivel[key].nome.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>     
                                                        <tr>
                                                            <td>Cor <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ vecor.toUpperCase() }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ meucarronovo_cor }
                                                                    name="meucarronovo_cor"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(meucarronovo_list_cor)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ meucarronovo_list_cor[key].id }>{ meucarronovo_list_cor[key].nome.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>       
                                                         <tr>
                                                            <td>Portas <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ veportas }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ meucarronovo_portas }
                                                                    name="meucarronovo_portas"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(meucarronovo_list_portas)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ meucarronovo_list_portas[key].id }>{ meucarronovo_list_portas[key].nome.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                       
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Transmissão <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>{ formatCambio(vecambio) }</td>
                                                            <td>
                                                                <SelectValidator
                                                                    value={ meucarronovo_cambio }
                                                                    name="meucarronovo_cambio"
                                                                    className="form-ui-bootstrap"
                                                                    onChange={this.handleChange}
                                                                    validators={["required"]}
                                                                    errorMessages={["Preenchimento obrigatório"]}
                                                                    >
                                                                        <MenuItem value=""></MenuItem>
                                                                        {
                                                                            Object.keys(meucarronovo_list_transmissao)
                                                                            .map(key => {
                                                                                return (
                                                                                    <MenuItem key={key} value={ meucarronovo_list_transmissao[key].id }>{ meucarronovo_list_transmissao[key].nome.toUpperCase() }</MenuItem>
                                                                                )
                                                                            })
                                                                        }                                                        
                                                                </SelectValidator>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Características</td>
                                                            <td></td>
                                                            <td>
                                                                <p className="checkbox-item">
                                                                    <label className="checkbox-label">
                                                                        <input type="checkbox" name="blindado" checked={blindado} onChange={this.handleChangeRadio} /> &nbsp;&nbsp;BLINDADO
                                                                    </label>
                                                                </p>
                                                                <p className="checkbox-item">
                                                                    <label className="checkbox-label">
                                                                        <input type="checkbox" name="adaptadoPne" checked={adaptadoPne} onChange={this.handleChangeRadio} /> &nbsp;&nbsp;ADAPTADO PARA PNE
                                                                    </label>
                                                                </p>
                                                                <p className="checkbox-item">
                                                                    <label className="checkbox-label">
                                                                        <input type="checkbox" name="aceitaTroca" checked={aceitaTroca} onChange={this.handleChangeRadio} /> &nbsp;&nbsp;ACEITA TROCA
                                                                    </label>
                                                                </p>
                                                                <p className="checkbox-item">
                                                                    <label className="checkbox-label">
                                                                        <input type="checkbox" name="documentacaoEmDia" checked={documentacaoEmDia} onChange={this.handleChangeRadio} /> &nbsp;&nbsp;DOCUMENTAÇÃO EM DIA
                                                                    </label>
                                                                </p>
                                                            </td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Opcionais</td>
                                                            <td>
                                                                {
                                                                    Object.keys(opcionais)
                                                                    .map(key => {
                                                                        return (
                                                                            <span key={key}>{ opcionais[key].descricao.toUpperCase() }<br/></span>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    Object.keys(meucarronovo_opcionais)
                                                                    .map(key => {
                                                                        return (
                                                                            <p className="checkbox-item" key={key}>
                                                                                <label className="checkbox-label">
                                                                                    <input type="checkbox" name={key} defaultChecked={meucarronovo_opcionais[key].checked ? meucarronovo_opcionais[key].checked : false} onChange={this.handleChecked} /> &nbsp;&nbsp;{meucarronovo_opcionais[key].descricao.toUpperCase()}
                                                                                </label>
                                                                            </p>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        </tr>   
                                                        <tr>
                                                            <td>Imagens <span className="preenchimento-obrigatorio">*</span></td>
                                                            <td>
                                                                {      
                                                                    imagens !== undefined && imagens.length > 0 ? 
                                                                    ( 
                                                                        Object.keys(imagens)
                                                                        .map(key => {
                                                                            
                                                                            return (
                                                                                imagens[key].fileUrl !== null && imagens[key].fileThumb !== null ? 
                                                                                <div key={key}><img src={ imagens[key].fileThumb } alt={ imagens[key].fileName }  style={{ height: '80px', marginBottom: '10px'}} /><br/></div>
                                                                                : null
                                                                            )
                                                                            
                                                                        })
                                                                    )
                                                                    : null
                                                                }
                                                            </td>
                                                            <td>
                                                                {      
                                                                    imagens !== undefined && imagens.length > 0 ? 
                                                                    ( 
                                                                        Object.keys(imagens)
                                                                        .map(key => {
                                                                            
                                                                            return (
                                                                                imagens[key].fileUrl !== null && imagens[key].fileThumb !== null ? 
                                                                                <div key={key}><img src={ imagens[key].fileThumb } alt={ imagens[key].fileName }  style={{ height: '80px', marginBottom: '10px'}} /><br/></div>
                                                                                : null
                                                                            )
                                                                            
                                                                        })
                                                                    )
                                                                    : null
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table> 
                                                <p><span className="alteracao-app">*</span> Dados devem ser alterados no aplicativo <br/><span className="preenchimento-obrigatorio">*</span> Campo obrigatório</p>
                                                <div style={{ display: estaGravando ? " block " : " none "  }}><img src="/img/aguarde.gif" alt="carregando"  style={{ height: '80px', marginBottom: '10px'}} /></div>
                                                <div style={{ display: errosSubmit !== null ? " block " : " none "  }} className="alert alert-danger" role="alert">{errosSubmit}</div>
                                                <button onClick={this.goBack} disabled={estaGravando} className="btn btn-warning" >Voltar</button>&nbsp;&nbsp;<button type="submit" disabled={estaGravando} className="btn btn-primary" >Enviar</button>

                                                </ValidatorForm>
                                            </div>
                                        </div>
                                    </div>

                                )
                            )
                        }
                    </div>
                </div>
            </div>

        )
    }

}

function formatMoney(value) {

    var places = 2;
    var symbol = "R$ ";
    var thousand = ".";
    var decimal = ",";

	places = !isNaN(places = Math.abs(places)) ? places : 2;
	symbol = symbol !== undefined ? symbol : "$";
	thousand = thousand || ",";
	decimal = decimal || ".";
	var number = value, 
	    negative = number < 0 ? "-" : "",
	    i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
	    j = (j = i.length) > 3 ? j % 3 : 0;
	return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
};

function formatCambio(value){
    if(value === 'A'){
        return "AUTOMÁTICO";
    }else{
        return "MANUAL";
    }
}

function compareOpcionais(a, b) {
    const genreA = a.descricao.toUpperCase();
    const genreB = b.descricao.toUpperCase();
    
    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
}

function compareItem(a, b) {
    const genreA = a.nome.toUpperCase();
    const genreB = b.nome.toUpperCase();
    
    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
}

function exibirErroSubmit(errors){
    window.scrollTo(0, 0);
    alert("Campos obrigatórios não preenchidos!");
}

function opcionais_true(opcional){
    return opcional.checked === true;
}

export default VeiculosIntegracaoMeuCarroNovoIncluir