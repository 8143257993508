import {firestore} from '../firebase-config';
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { base_url, api_key, fipe_url } from '../config/config_api';

// var fipe_url = 'https://api.ffsistemas.com.br';

export const getMarcas = async (tipoVeiculo) => {
    var tipo = validaTipoVeiculo(tipoVeiculo);
    return fetch(fipe_url+'/fipe-consulta/'+tipo+'/marcas', { method: 'GET' })
    .then(response => {
        return response.json()
        .then(data => {
            return data;
        });
    })
    .catch(error => {
        console.log(error);
        return error;
    });
}

export const getModelos = async (tipoVeiculo, marca) => {
  var tipo = validaTipoVeiculo(tipoVeiculo);
  return fetch(fipe_url+'/fipe-consulta/'+tipo+'/marcas/'+marca+'/modelos', { method: 'GET' })
  .then(response => {
      return response.json()
      .then(data => {
          return data;
      });
  })
  .catch(error => {
      console.log(error);
      return error;
  });
}

export const getAnoModelos = async (tipoVeiculo, marca, modelo) => {
  var tipo = validaTipoVeiculo(tipoVeiculo);
  return fetch(fipe_url+'/fipe-consulta/'+tipo+'/marcas/'+marca+'/modelos/'+modelo+'/ano-modelo', { method: 'GET' })
  .then(response => {
      return response.json()
      .then(data => {
          return data;
      });
  })
  .catch(error => {
      console.log(error);
      return error;
  });
}

export const getVersoes = async (tipoVeiculo, marca, modelo, anoModelo) => {
  var tipo = validaTipoVeiculo(tipoVeiculo);
  return fetch(fipe_url+'/fipe-consulta/'+tipo+'/marcas/'+marca+'/modelos/'+modelo+'/ano-modelo/'+anoModelo+'/versao', { method: 'GET' })
  .then(response => {
      return response.json()
      .then(data => {
          return data;
      });
  })
  .catch(error => {
      console.log(error);
      return error;
  });
}

export const getVersaoIntegradores = async (id_revenda, veiculo_data, locais_anuncio) => {

  return fetch(base_url+"/api/locais-anuncio/buscar-versoes", { 
    method: "POST", 
    headers: { 
      'Content-Type': 'application/json'
    }, 
    body: JSON.stringify({
        "api_key": api_key,
        "revenda": id_revenda,
        "veiculo": veiculo_data,
        "locais_anuncio": locais_anuncio
    })
  })
  .then(response => {
    return response.json()
    .then(data => {
      if(data.status === 'success'){
        return {
          status: "success",
          data: data.data
        };            
      }else{
        return {
          status: "error",
          data: data.data
        };
      }
    })
    .catch(error => {
      return {
        status: "error",
        data: error
      };
    });
  })
  .catch(error => {
    return {
      status: "error",
      data: error
    };
  });

}

export const excluirVeiculoIntegrador = async (integrador, id_anuncio, id_veiculo, id_revenda) => {

  var user = JSON.parse(window.localStorage.getItem("usuarioLogado"));

  return fetch(base_url+"/api/integracao/"+integrador+"/excluir", { 
    method: "POST", 
    headers: { 
      'Content-Type': 'application/json'
    }, 
    body: JSON.stringify({
        "api_key": api_key,
        "id_anuncio": id_anuncio,
        "usuario": user.id,
        "id_veiculo": id_veiculo
    })
  })
  .then(response => {

    return response.json()
    .then(data => {
      if(data.status === 'success'){

        return firestore.collection('revenda').doc(id_revenda).collection('veiculos').doc(id_veiculo).collection('integracoes').doc(integrador).delete()
        .then(res => { 
            return {
              status: "success",
              data: ""
            };
        })
        .catch(err => { 
          return {
            status: "error",
            data: err
          };
        });
      
      }else{
        return {
          status: "error",
          data: data.data
        };
      }
    })
    .catch(error => {
      return {
        status: "error",
        data: error
      };
    });

  })
  .catch(error => {
    return {
      status: "error",
      data: error
    };
  });

}

export const baixarVeiculo = async (nome_revenda, idRevenda, idVeiculo) => {

  return firestore.collection("revenda").doc(idRevenda)
  .get()
  .then(doc1 => {
    if (doc1.exists) {

      let cidade_revenda = doc1.data().pecidade + "-" + doc1.data().peuf;
      let peblocotexto = doc1.data().peblocotexto ? doc1.data().peblocotexto : "";

      return firestore.collection("revenda").doc(idRevenda).collection("veiculos").doc(idVeiculo)
      .get()
      .then(doc => {
        if (doc.exists) {

          // var dadosVeiculo = nome_revenda + " - " + cidade_revenda + "\n\n";
          var dadosVeiculo = "\n";
          dadosVeiculo += doc.data().vefipe_marca + "\n";
          dadosVeiculo += doc.data().vefipe_name+ "\n";
          dadosVeiculo += doc.data().veano_fabricacao + "/" + doc.data().vefipe_ano_modelo + "\n";

          if(doc.data().opcionais && doc.data().opcionais.length > 0){
            doc.data().opcionais.forEach(opcional => {
                dadosVeiculo += opcional.descricao + "\n";
            });
          }

          if(doc.data().veipva_pago !== undefined && doc.data().veipva_pago === true){
            dadosVeiculo += "IPVA Pago\n";
          }

          if(doc.data().veblindado !== undefined && doc.data().veblindado === true){
            dadosVeiculo += "Blindado\n";
          }

          if(doc.data().vegarantia_fabrica !== undefined && doc.data().vegarantia_fabrica === true){
            dadosVeiculo += "Garantia de fábrica\n";
          }

          dadosVeiculo += "km " + doc.data().vequilometragem + "\n\n";
          dadosVeiculo += formatMoney(doc.data().vevalor) + "\n\n";
          if(doc.data().veobservacao.length > 0){
              dadosVeiculo += doc.data().veobservacao + "\n\n";
          }
          dadosVeiculo += peblocotexto;
          dadosVeiculo = dadosVeiculo.toUpperCase();

          var zip = new JSZip();
          var nameFolderAndFile = nome_revenda + "-" + doc.data().vefipe_marca + "_" + doc.data().vefipe_name + "_" + doc.data().vefipe_ano_modelo;
          nameFolderAndFile = nameFolderAndFile.replace(/ /g, "_");

          zip.file(nameFolderAndFile+".txt", dadosVeiculo);

          if(doc.data().imagens && doc.data().imagens.length > 0){
            var validTemp = 0;
            var countImage = 1;
            doc.data().imagens.forEach(imagem => {

              var fileNameImage = countImage + "_" +imagem.fileName;
              if(imagem.fileUrl !== null){
                  if(fileNameImage.includes("/")){
                      fileNameImage = countImage + "_" +imagem.fileName.substring(imagem.fileName.lastIndexOf("/") + 1, imagem.fileName.length);
                  }
                  countImage++;
              }

              JSZipUtils.getBinaryContent(imagem.fileUrl, function (err, data) {
                if(err){
                    console.log(err);
                    return false;
                }
                if(imagem.fileUrl !== null){
                    zip.file(fileNameImage, data, {binary: true});
                }

                validTemp++;
                if(validTemp === doc.data().imagens.length){

                  zip.generateAsync({type:"blob"}).then(function (blob) {
                    var element = document.createElement("a");
                    element.href = URL.createObjectURL(blob);
                    element.download = nameFolderAndFile+".zip";
                    element.click();
                  }, function (err) {
                      console.log(err);
                      return false;
                  });

                }

              }.bind(this));
                
            });
          }else{

            zip.generateAsync({type:"blob"}).then(function (blob) {
              var element = document.createElement("a");
              element.href = URL.createObjectURL(blob);
              element.download = nameFolderAndFile+".zip";
              element.click();
            }, function (err) {
                console.log(err);
                return false;
            });

          }
        }
      }).catch(function(error) {
        console.log(error);
          return false;
      }.bind(this));
    }
  }).catch(function(error) {
    console.log(error);
    return false;
  });

};

export const baixarVendido = async (vendido) => {

    var dadosVendido = "Veículo Vendido" + "\n\nRevenda: " + vendido.nome_revenda + "\n\n";
    dadosVendido += "Texto publicação:\n\n" + vendido.peblocotextovendidos;
    dadosVendido = dadosVendido.toUpperCase();

          var zip = new JSZip();
          var nameFolderAndFile = "Vendido_"+vendido.nome_revenda + "-" + vendido.id;
          nameFolderAndFile = nameFolderAndFile.replace(/ /g, "_");

          zip.file(nameFolderAndFile+".txt", dadosVendido);

          if(vendido.imagem !== undefined && vendido.imagem.fileUrl != undefined){

              var imagem = vendido.imagem;
              var fileNameImage = imagem.fileName;

              if(imagem.fileUrl !== null){
                  if(fileNameImage.includes("/")){
                      fileNameImage = imagem.fileName.substring(imagem.fileName.lastIndexOf("/") + 1, imagem.fileName.length);
                  }
              }

              JSZipUtils.getBinaryContent(imagem.fileUrl, function (err, data) {
                if(err){
                    console.log(err);
                    return false;
                }
                if(imagem.fileUrl !== null){
                    zip.file(fileNameImage, data, {binary: true});
                }

                zip.generateAsync({type:"blob"}).then(function (blob) {
                  var element = document.createElement("a");
                  element.href = URL.createObjectURL(blob);
                  element.download = nameFolderAndFile+".zip";
                  element.click();
                }, function (err) {
                    console.log(err);
                    return false;
                });

              }.bind(this));
                
          }else{

            zip.generateAsync({type:"blob"}).then(function (blob) {
              var element = document.createElement("a");
              element.href = URL.createObjectURL(blob);
              element.download = nameFolderAndFile+".zip";
              element.click();
            }, function (err) {
                console.log(err);
                return false;
            });

          }

};

export const baixarBanner = async (banner) => {

  var dadosBanner = "Banner" + "\n\nRevenda: " + banner.nome_revenda + "\n\n";
  dadosBanner += "Texto publicação:\n\n" + banner.peblocotextobanners;
  dadosBanner = dadosBanner.toUpperCase();

        var zip = new JSZip();
        var nameFolderAndFile = "Banner_"+banner.nome_revenda + "-" + banner.id;
        nameFolderAndFile = nameFolderAndFile.replace(/ /g, "_");

        zip.file(nameFolderAndFile+".txt", dadosBanner);

        // if(banner.imagem !== undefined && banner.imagem.fileUrl != undefined){
        let imagens = [];
        if(banner.imagem !== undefined && banner.imagem.length === undefined){
          // let imagens = array(banner.imagem);
          imagens.push(banner.imagem)
        }else{
          imagens = banner.imagem;
        }

        if(imagens.length > 0){

          var validTemp = 0;
          var countImage = 1;
          imagens.forEach(imagem => {

            var fileNameImage = countImage + "_" +imagem.fileName;
            if(imagem.fileUrl !== null){
                if(fileNameImage.includes("/")){
                    fileNameImage = countImage + "_" +imagem.fileName.substring(imagem.fileName.lastIndexOf("/") + 1, imagem.fileName.length);
                }
                countImage++;
            }

            JSZipUtils.getBinaryContent(imagem.fileUrl, function (err, data) {
              if(err){
                  console.log(err);
                  return false;
              }
              if(imagem.fileUrl !== null){
                  zip.file(fileNameImage, data, {binary: true});
              }

              validTemp++;
              if(validTemp === imagens.length){

                zip.generateAsync({type:"blob"}).then(function (blob) {
                  var element = document.createElement("a");
                  element.href = URL.createObjectURL(blob);
                  element.download = nameFolderAndFile+".zip";
                  element.click();
                }, function (err) {
                    console.log(err);
                    return false;
                });

              }

            }.bind(this));

          });
              
        }else{

          zip.generateAsync({type:"blob"}).then(function (blob) {
            var element = document.createElement("a");
            element.href = URL.createObjectURL(blob);
            element.download = nameFolderAndFile+".zip";
            element.click();
          }, function (err) {
              console.log(err);
              return false;
          });

        }

};

function validaTipoVeiculo(tipo){
  if(tipo === "carro"){
    return 1;
  }else if(tipo === "moto"){
    return 2;
  }else if(tipo === "caminhao"){
    return 3;
  }else{
    return 0;
  }
}

function formatMoney(value) {

  var places = 2;
  var symbol = "R$ ";
  var thousand = ".";
  var decimal = ",";

  places = !isNaN(places = Math.abs(places)) ? places : 2;
  symbol = symbol !== undefined ? symbol : "$";
  thousand = thousand || ",";
  decimal = decimal || ".";
  var number = value, 
      negative = number < 0 ? "-" : "",
      i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
      j = (j = i.length) > 3 ? j % 3 : 0;
  return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
};