import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { auth } from './../firebase-config';
import { versao } from '../config/config_api';
import {firestore} from '../firebase-config';

class CabecalhoPainel extends Component {

    constructor(props){
        super(props);
        this.state = {
            revendaCollapsed: true,
            revendaFFCollapsed: true,
            opcionaisCollapsed: true,
            locaisAnuncioCollapsed: true,
            estaAutenticado: !!window.localStorage.getItem("usuarioLogado"),
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado")),
            nome_revenda: "",
        };
        this.mounted = false;
        this.logout = this.logout.bind(this);
    }

    // W5aJ8PN8HBmBmzaZ92W5
    componentDidMount(){
        if(this.state.usuarioLogado === null){
            return <Redirect to='/login' />
        }

        this.mounted = true;        

        if(this.state.usuarioLogado !== null){
            firestore.collection("revenda").doc(this.state.usuarioLogado.revenda)
            .get()
            .then(doc => {
                if (doc.exists) {
                    this.setState({
                        nome_revenda: doc.data().penome_fantasia,
                    });
                }
            }).catch(function(error) {
            });
        }
    }

    logout(){
        window.localStorage.clear();
        auth.signOut()
        .then((res) => {
            return <Redirect to="/login" />;
        })
        .catch((c) => {
            return <Redirect to="/login" />;
        });
    }

    render() {
        if(!this.state.estaAutenticado || this.state.usuarioLogado === null || this.state.usuarioLogado.role !== 'gestor'){
            this.logout();
            return <Redirect to='/login' />
        }

        return (
            <nav className="navbar navbar-default">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                            <span className="sr-only">Menu</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <Link to="/painel/dashboard" className="navbar-brand" >
                            <img src="/img/logo_horizontal.png" alt="Logo Intermédio" />
                        </Link>
                    </div>

                    <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                        <ul className="nav navbar-nav">
                            <li><Link to="/painel/dashboard">Dashboard</Link></li>
                            <li><Link to={"/revenda/"+this.state.usuarioLogado.revenda+"/veiculos/listar"}>Veículos</Link></li>
                            <li><Link to={"/revenda/"+this.state.usuarioLogado.revenda+"/vendidos/listar"}>Vendidos</Link></li>
                            <li><Link to={"/revenda/"+this.state.usuarioLogado.revenda+"/banners/listar"}>Banners</Link></li>
                            <li><Link to={"/revenda/"+this.state.usuarioLogado.revenda+"/usuarios/listar"}>Usuários</Link></li>
                            <li><Link to={"/revenda/"+this.state.usuarioLogado.revenda+"/integracao"}>Integrações</Link></li>
                            <li><Link to={"/revenda/"+this.state.usuarioLogado.revenda+"/veiculos/historico"}>Histórico</Link></li>
                            {/* <li><Link to={"/revenda/"+this.state.usuarioLogado.revenda+"/notificacoes/listar"}>Notificações</Link></li> */}
                            <li><a href={ "https://app.intermedioveiculos.com.br?usuario="+this.state.usuarioLogado.id+"&revenda_id="+this.state.usuarioLogado.revenda+"&revenda_nome="+this.state.nome_revenda }>Solicitações</a></li>
                            {/* <li><Link to={"/revenda/"+this.state.usuarioLogado.revenda+"/alterar"}>Dados Revenda</Link></li> */}
                        </ul>
                        <ul className="nav navbar-nav navbar-right">
                            <p className="versao-painel">v{versao}</p>
                        </ul>
                        <ul className="nav navbar-nav navbar-right">
                            <li className="dropdown">
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><span className="nome-login">{ this.state.usuarioLogado.nome }</span> <i className="fa fa-user fa-fw"></i> <i className="fa fa-caret-down"></i></a>
                                <ul className="dropdown-menu">
                                    <li><a href="" onClick={this.logout} ><i className="fa fa-sign-out fa-fw"></i> Sair</a></li>
                                </ul>
                            </li>
                        </ul>
                     </div>
                </div> 
            </nav>
        )
    }

}

export default CabecalhoPainel