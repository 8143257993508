import React, { Component } from 'react';
import { auth, firestore } from './../../firebase-config';
import Cabecalho from './../../componentes/Cabecalho';
import CabecalhoPainel from '../../componentes/CabecalhoPainel';
import { Link, Redirect } from 'react-router-dom';
import Modal from 'react-modal';
import moment from 'moment';
import 'moment-timezone';
import { publicarVendido } from '../../services/VendidoService';
import { baixarVendido } from '../../services/VeiculoService';

Modal.setAppElement('#root');

class RevendaVendidosListar extends Component {

    constructor (props){
        super(props);

        this.state = {
            idRevenda: props.match.params.idRevenda,
            nome_revenda: "",
            vendidos: [],
            integracao_revenda: [],
            modalShow: false,
            usuarioLogado: JSON.parse(window.localStorage.getItem("usuarioLogado"))
        }
        this.mounted = false;
        this.publicarInstagramStories = this.publicarInstagramStories.bind(this);
        this.publicarInstagramFeed    = this.publicarInstagramFeed.bind(this);
        this.publicarFacebookFeed     = this.publicarFacebookFeed.bind(this);
    }

    componentDidMount(){

        this.mounted = true;

        // firestore.settings({ timestampsInSnapshots: true });
        firestore.collection("revenda").doc(this.state.idRevenda).collection("vendidos").orderBy("created_at", "desc")
        .onSnapshot(querySnapshot => {
            var items = [];
            querySnapshot.forEach(doc => {
                var document = doc.data();
                document.id  = doc.id;
                items.push(document);
            });
            if(this.mounted) {
                this.setState({
                    vendidos: items,
                });
            }
        });

        firestore.collection('revenda').doc(this.state.idRevenda).collection('integracoes')
        .get()
        .then((snapIntegracaoRevenda) => {
            var itemsRevenda = [];
            snapIntegracaoRevenda.forEach(integracao_revenda_dash => {
                itemsRevenda[integracao_revenda_dash.id] = integracao_revenda_dash.data();
            });
            if(this.mounted) {
                this.setState({
                    integracao_revenda: itemsRevenda,
                });
            }
        }).catch(function(error) {
        });

        firestore.collection("revenda").doc(this.state.idRevenda)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    nome_revenda: doc.data().penome_fantasia,
                    revenda_ativa: doc.data().ativo === 0 ? 0 : 1,
                });
            }
        }).catch(function(error) {
        });

    }

    async publicarInstagramStories(idVendido){

        this.setState({modalShow: true});

        const vendido = this.state.vendidos.find(o => o.id === idVendido);
        
        vendido.ig_stories = true;
        vendido.ig_feed    = false;

        const response = await publicarVendido(vendido, "instagram");
        if(response.status === "success"){
            alert("Vendido publicado no stories do instagram.");
            this.setState({modalShow: false});
        }else{
            alert("Erro ao publicar vendido no stories do instagram.");
            this.setState({modalShow: false});
        }
        
    }

    async publicarInstagramFeed(idVendido){

        this.setState({modalShow: true});

        const vendido = this.state.vendidos.find(o => o.id === idVendido);

        vendido.ig_stories = false;
        vendido.ig_feed    = true;

        const response = await publicarVendido(vendido, "instagram");
        if(response.status === "success"){
            alert("Vendido publicado no feed do instagram.");
            this.setState({modalShow: false});
        }else{
            alert("Erro ao publicar vendido no feed do instagram.");
            this.setState({modalShow: false});
        }

    }

    async publicarFacebookFeed(idVendido){

        this.setState({modalShow: true});

        const vendido = this.state.vendidos.find(o => o.id === idVendido);

        vendido.fb_feed = true;

        const response = await publicarVendido(vendido, "facebook");
        if(response.status === "success"){
            alert("Vendido publicado no feed do facebook.");
            this.setState({modalShow: false});
        }else{
            alert("Erro ao publicar vendido no feed do facebook.");
            this.setState({modalShow: false});
        }

    }

    baixarVendido = async (idVendido) => {

        this.setState({
            modalShow: true
        });

        const vendido = this.state.vendidos.find(o => o.id === idVendido);

        await baixarVendido(vendido);

        this.setState({
            modalShow: false
        });

    }

    componentWillUnmount(){
        this.mounted = false;
    }

    render(){

        if(this.state.usuarioLogado !== null && this.state.usuarioLogado.role !== 'admin' && this.state.idRevenda !== this.state.usuarioLogado.revenda){
            return <Redirect to='/acesso-negado' />
        }

        return (
            <div id="modalAguarde">
                {
                    this.state.usuarioLogado !== null && this.state.usuarioLogado.role === 'admin' ? <Cabecalho /> :  <CabecalhoPainel />
                } 
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">Vendidos<br/><small>Revenda: { this.state.nome_revenda }</small></h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    Lista de Vendidos
                                </div>
                                <div className="panel-body">
                                
                                    <Link to={ "/revenda/"+this.state.idRevenda+"/vendidos/incluir"} className="btn btn-warning" >Novo vendido</Link>&nbsp;&nbsp;                                    
                                    
                                    <br/><br/>
                                    <table width="100%" className="table table-striped table-bordered table-hover" id="dtUsuarios">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Texto</th>
                                                <th>Data</th>
                                                <th>Ações</th>
                                                <th>Instagram Stories</th>
                                                <th>Instagram Feed</th>
                                                <th>Facebook Feed</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Object.keys(this.state.vendidos)
                                                .map(key => {
                                                    return (
                                                        <tr key={key}>
                                                            <td>{ this.state.vendidos[key].imagem.fileThumb !== undefined ? <img src={ this.state.vendidos[key].imagem.fileThumb } style={{ maxWidth: '100px', maxHeight: '80px' }} ></img> : this.state.vendidos[key].imagem.fileUrl !== undefined ? <img src={ this.state.vendidos[key].imagem.fileUrl } style={{ maxWidth: '100px', maxHeight: '80px' }} ></img> : "" }</td>
                                                            <td>{ this.state.vendidos[key].peblocotextovendidos !== undefined ? this.state.vendidos[key].peblocotextovendidos : "" }</td>
                                                            <td>{ this.state.vendidos[key].created_at !== undefined && this.state.vendidos[key].created_at !== null && this.state.vendidos[key].created_at.seconds !== null && this.state.vendidos[key].created_at.seconds !== undefined ? moment(this.state.vendidos[key].created_at.seconds * 1000).locale('pt-BR').format("DD/MM/YYYY HH:mm") : "" }</td>
                                                            <td><a onClick={() => this.baixarVendido(this.state.vendidos[key].id)}>Baixar</a></td>
                                                            <td>
                                                                <table  className="table table-bordered">
                                                                    <thead><tr><th style={{ width: '40px' }}>Publicar</th><th style={{ width: '40px' }}>Status</th><th style={{ width: '40px' }}>Ações</th></tr></thead>
                                                                    <tbody><tr> 
                                                                    { 
                                                                        this.state.vendidos[key].ig_stories !== undefined && this.state.vendidos[key].ig_stories === true ?
                                                                            <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                                                        : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                                                                    } 
                                                                    { 
                                                                        this.state.vendidos[key].valida_stories !== undefined && this.state.vendidos[key].valida_stories === true ? 
                                                                            <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                                                        : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                                                                    } 
                                                                    { 
                                                                        this.state.integracao_revenda.instagram !== undefined && this.state.integracao_revenda.instagram.status !== undefined && this.state.integracao_revenda.instagram.status && this.state.vendidos[key].valida_stories !== undefined && !this.state.vendidos[key].valida_stories ? 
                                                                            <td><a onClick={() => this.publicarInstagramStories(this.state.vendidos[key].id)}>Publicar</a></td>
                                                                        : <td></td> 
                                                                    } 
                                                                </tr></tbody></table> 
                                                            </td>
                                                            <td>
                                                                <table  className="table table-bordered">
                                                                    <thead><tr><th style={{ width: '40px' }}>Publicar</th><th style={{ width: '40px' }}>Status</th><th style={{ width: '40px' }}>Ações</th></tr></thead>
                                                                    <tbody><tr> 
                                                                    { 
                                                                        this.state.vendidos[key].ig_feed !== undefined && this.state.vendidos[key].ig_feed === true ?
                                                                            <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                                                        : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                                                                    } 
                                                                    { 
                                                                        this.state.vendidos[key].valida_feed !== undefined && this.state.vendidos[key].valida_feed === true ? 
                                                                            <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                                                        : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                                                                    } 
                                                                    { 
                                                                        this.state.integracao_revenda.instagram !== undefined && this.state.integracao_revenda.instagram.status !== undefined && this.state.integracao_revenda.instagram.status === true && (this.state.vendidos[key].valida_feed === undefined || this.state.vendidos[key].valida_feed === false) ? 
                                                                            <td><a onClick={() => this.publicarInstagramFeed(this.state.vendidos[key].id)}>Publicar</a></td>
                                                                        : <td></td> 
                                                                    } 
                                                                </tr></tbody></table> 
                                                            </td>
                                                            <td>
                                                                <table  className="table table-bordered">
                                                                    <thead><tr><th style={{ width: '40px' }}>Publicar</th><th style={{ width: '40px' }}>Status</th><th style={{ width: '40px' }}>Ações</th></tr></thead>
                                                                    <tbody><tr> 
                                                                    { 
                                                                        this.state.vendidos[key].fb_feed !== undefined && this.state.vendidos[key].fb_feed === true ?
                                                                            <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                                                        : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                                                                    } 
                                                                    { 
                                                                        this.state.vendidos[key].fb_valida_feed !== undefined && this.state.vendidos[key].fb_valida_feed === true ? 
                                                                            <td><span className="glyphicon glyphicon-ok-circle" style={{ fontSize: '20px', color: 'green'}}></span></td> 
                                                                        : <td><span className="glyphicon glyphicon-record" style={{ fontSize: '20px', color: 'gray'}}></span></td> 
                                                                    } 
                                                                    { 
                                                                        this.state.integracao_revenda.facebook !== undefined && this.state.integracao_revenda.facebook.status !== undefined && this.state.integracao_revenda.facebook.status === true && (this.state.vendidos[key].fb_valida_feed === undefined || this.state.vendidos[key].fb_valida_feed === false) ? 
                                                                            <td><a onClick={() => this.publicarFacebookFeed(this.state.vendidos[key].id)}>Publicar</a></td>
                                                                        : <td></td> 
                                                                    } 
                                                                </tr></tbody></table> 
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={this.state.modalShow} 
                    className="modal-class modal-class-aguarde"
                    overlayClassName="modal-overlay"
                    >
                    <div>Aguarde...</div>
                </Modal>
            </div>
            
        )
    }

}

export default RevendaVendidosListar;